import {
  Button,
  Caption,
  Dropdown,
  Input,
  StyledModal,
  validateURL,
} from "@bikdotai/bik-component-library";
import {
  EachTailoredSectionI,
  EachWhatsNewContent,
  JourneyLevels,
  UiMeta,
} from "../type";
import {
  DropdownOption,
  SingleOption,
} from "@bikdotai/bik-component-library/dist/esm/components/dropdown/type";
import { MenuItems } from "..";
import { useEffect, useMemo, useState } from "react";
import {
  AddOrEditFeatureModalContainer,
  BackgroundImageContainer,
} from "../style";
import { AllModules } from "@bikdotai/bik-models/growth/models/feature";
import { GrowthRepo } from "../GrowthRepo";
import RichTextEditor from "../../../ui-components/rich-text-editor/RichTextEditor";
import React from "react";
import { UploadFile } from "../../../ui-components/file-uploader/fileUploader";

interface AddOrEditFeatureModalI {
  isOpen: boolean;
  onCloseModal: (reload?: boolean) => void;
  curentMenu: string; //keyof typeof MenuList;
  operationtype: "add" | "edit";
  moduleMenu?: string;
  menuItemToEdit?: EachTailoredSectionI | UiMeta | EachWhatsNewContent;
  indexToUpdate?: number | string;
}

const DefaultErrorMessages = {
  section: "",
  module: "",
  level: "",
  title: "",
  desc: "",
  redirectUrl: "",
  image: "",
  body: "",
};
const DEFAULT_BG =
  "https://firebasestorage.googleapis.com/v0/b/bikayi-chat.appspot.com/o/images-uid%2F1c11e926-f67d-4325-a6d7-cd665dbedef4?alt=media&token=20a8a8f3-d6a8-4e41-871f-5d06772476ab";
const DEFAULT_WHATS_NEW =
  "https://firebasestorage.googleapis.com/v0/b/staging-bikayi.appspot.com/o/images-uid%2F8fb1d6a7-bc56-4512-859b-67f4a9282f61?alt=media&token=27f15953-0938-4d05-bce0-3b1061c75851";

const AddOrEditFeatureModal: React.FC<AddOrEditFeatureModalI> = ({
  isOpen,
  onCloseModal,
  curentMenu,
  operationtype,
  moduleMenu,
  menuItemToEdit,
  indexToUpdate,
}) => {
  const growthRepo = useMemo(() => new GrowthRepo(), []);
  const [updating, setUpdating] = useState(false);
  function isEachTailoredSectionI(
    item: EachTailoredSectionI | UiMeta | EachWhatsNewContent | undefined
  ): item is EachTailoredSectionI {
    return (
      item !== undefined &&
      "level" in item &&
      typeof (item as EachTailoredSectionI)?.level === "string"
    );
  }
  const [SectionList, setSectionList] = useState(
    MenuItems.slice(0, 2).map((item) => ({
      value: item.key,
      label: item.displayName,
      selected: curentMenu === item.key,
    }))
  );
  const [LevelList, setLevelList] = useState(
    Object.values(JourneyLevels).map((key) => ({
      value: key,
      label: key.charAt(0).toUpperCase() + key.slice(1).toLowerCase(),
      selected:
        isEachTailoredSectionI(menuItemToEdit) && menuItemToEdit?.level === key,
    }))
  );
  const [ModuleList, setModuleList] = useState(
    Object.values(AllModules).map((key) => ({
      value: key,
      label: key.charAt(0).toUpperCase() + key.slice(1).toLowerCase(),
      selected: moduleMenu === key,
    }))
  );

  const [formDetails, setFormDetails] = useState({
    section: curentMenu,
    module: moduleMenu,
    level: isEachTailoredSectionI(menuItemToEdit) ? menuItemToEdit.level : "",
    title: "",
    desc: "",
    image: "",
    redirectUrl: "",
  });
  const [bodyText, setBodyText] = useState({ body: "" });

  const [errorMessage, setErrorMessage] = useState(DefaultErrorMessages);

  useEffect(() => {
    if (menuItemToEdit) {
      if (isEachTailoredSectionI(menuItemToEdit)) {
        setFormDetails({
          ...formDetails,
          title: menuItemToEdit.uiMeta.title,
          desc: menuItemToEdit.uiMeta.desc,
          image: menuItemToEdit.uiMeta.image,
          redirectUrl: menuItemToEdit.uiMeta.redirectUrl,
        });
      } else if ("desc" in menuItemToEdit) {
        setFormDetails({
          ...formDetails,
          title: menuItemToEdit.title,
          desc: menuItemToEdit.desc,
          image: menuItemToEdit.image,
          redirectUrl: menuItemToEdit.redirectUrl,
        });
      } else {
        setFormDetails({
          ...formDetails,
          title: menuItemToEdit.title,
          image: menuItemToEdit.image,
          redirectUrl: menuItemToEdit.redirectUrl,
        });
        setBodyText({ body: menuItemToEdit.body });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuItemToEdit]);

  const determineErrorMessage = (changed: string, type: string) => {
    if (!changed) {
      setErrorMessage({
        ...errorMessage,
        [type]: `Please enter a valid value for ${type}`,
      });
    } else {
      setErrorMessage({ ...errorMessage, [type]: "" });
    }
  };
  const submitAllFeatureDetails = async () => {
    setErrorMessage({ ...errorMessage, body: "" });
    const isWhatsNew = curentMenu === MenuItems[2].key;
    if (errorMessage.title || errorMessage.redirectUrl || errorMessage.level) {
      return;
    }
    if (!isWhatsNew && errorMessage.desc) {
      return;
    }

    if (!formDetails.title) {
      const updatedErrorMessage = { ...errorMessage };
      updatedErrorMessage.title = "Title is required";
      setErrorMessage(updatedErrorMessage);
      return;
    }

    if (!formDetails.redirectUrl) {
      const updatedErrorMessage = { ...errorMessage };
      updatedErrorMessage.redirectUrl = "Redirect URL is required";
      setErrorMessage(updatedErrorMessage);
      return;
    }

    if (isWhatsNew && !bodyText.body) {
      setErrorMessage({ ...errorMessage, body: "Body is required" });
      return;
    }
    if (!isWhatsNew && !formDetails.desc) {
      setErrorMessage({ ...errorMessage, body: "Description is required" });
      return;
    }

    if (curentMenu === MenuItems[0].key && !formDetails.level) {
      if (!formDetails.level) {
        setErrorMessage({ ...errorMessage, level: "Level is required" });
      }
      return;
    }
    if (!validateURL(formDetails.redirectUrl)) {
      setErrorMessage({
        ...errorMessage,
        redirectUrl: "Please enter a valid URL",
      });
      return;
    }
    setUpdating(true);
    if (isWhatsNew) {
      const payload = {
        title: formDetails.title,
        body: bodyText["body"],
        image: formDetails.image || DEFAULT_WHATS_NEW,
        redirectUrl: formDetails.redirectUrl,
        createdAt: new Date(),
      };
      await growthRepo.createOrUpdateArticleDocument(
        payload,
        operationtype === "edit"
          ? typeof indexToUpdate == "string"
            ? indexToUpdate
            : undefined
          : undefined
      );
      setUpdating(false);
      onCloseModal(true);
      return;
    }
    if (curentMenu === MenuItems[0].key) {
      const payload = {
        uiMeta: {
          title: formDetails.title,
          desc: formDetails.desc,
          image: formDetails.image || DEFAULT_BG,
          redirectUrl: formDetails.redirectUrl,
        },
        level: formDetails.level as JourneyLevels,
      };
      await growthRepo.updateFeatureDetails(
        formDetails.module || "",
        payload,
        operationtype === "edit" ? Number(indexToUpdate) : undefined
      );
    } else {
      const payload = {
        title: formDetails.title,
        desc: formDetails.desc,
        image: formDetails.image || DEFAULT_BG,
        redirectUrl: formDetails.redirectUrl,
      };
      await growthRepo.updateFeatureDetails(
        "also-try",
        payload,
        operationtype === "edit" ? Number(indexToUpdate) : undefined
      );
    }
    setUpdating(false);
    onCloseModal(true);
  };

  return (
    <StyledModal
      open={isOpen}
      headingTitle="Enter the feature details"
      onClose={onCloseModal}
      width="442px"
    >
      <AddOrEditFeatureModalContainer>
        {curentMenu !== MenuItems[2].key && (
          <div className="each--section">
            <Caption>Section</Caption>
            <Dropdown
              options={SectionList as DropdownOption[]}
              placeHolder="Select section"
              disabled={!!moduleMenu}
              onSelect={(selected) => {
                setSectionList(
                  SectionList.map((item) => ({
                    ...item,
                    selected:
                      (selected as SingleOption)["value"] === item.value,
                  }))
                );
                setFormDetails({
                  ...formDetails,
                  section: (selected as SingleOption)["value"],
                });
              }}
            ></Dropdown>
          </div>
        )}

        {curentMenu === MenuItems[0].key && (
          <div className="each--section">
            <Caption>Module</Caption>
            <Dropdown
              options={ModuleList as DropdownOption[]}
              placeHolder="Select Module"
              disabled={!!moduleMenu}
              onSelect={(selected) => {
                setModuleList(
                  ModuleList.map((item) => ({
                    ...item,
                    selected:
                      (selected as SingleOption)["value"] === item.value,
                  }))
                );
                setFormDetails({
                  ...formDetails,
                  module: (selected as SingleOption)["value"],
                });
              }}
            ></Dropdown>
          </div>
        )}
        {curentMenu === MenuItems[0].key && (
          <div className="each--section">
            <Caption>Level</Caption>
            <Dropdown
              options={LevelList}
              placeHolder="Select level"
              onSelect={(option) => {
                determineErrorMessage((option as SingleOption).value, "level");
                setLevelList(
                  LevelList.map((item) => ({
                    ...item,
                    selected: (option as SingleOption)["value"] === item.value,
                  }))
                );
                setFormDetails({
                  ...formDetails,
                  level: (option as SingleOption)["value"],
                });
              }}
              error={errorMessage.level}
            ></Dropdown>
          </div>
        )}
        <div className="each--section">
          <Input
            labelText="Feature title"
            value={formDetails.title}
            onChangeText={(changed) => {
              determineErrorMessage(changed, "title");
              setFormDetails({ ...formDetails, title: changed });
            }}
            isRequired
            errorMessage={errorMessage.title}
            state={
              curentMenu !== MenuItems[2].key
                ? operationtype === "edit"
                  ? "disabled"
                  : "none"
                : "none"
            }
          ></Input>
        </div>
        {curentMenu === MenuItems[2].key && (
          <div className="each--section">
            <RichTextEditor
              setSelectedVariableList={() => {}}
              selectedVariableList={[]}
              setHtmlMessage={setBodyText}
              htmlMessage={bodyText.body}
              showVariableOnly={false}
              customHeight={"72px"}
              headerText={"Notes"}
              headerTextColor={"#212121"}
              placeHolder={"Enter your text here"}
              uniqueId={"body"}
              errorText={errorMessage.body}
              hideWordLimit={true}
            />
          </div>
        )}
        {curentMenu !== MenuItems[2].key && (
          <div className="each--section">
            <Input
              labelText="Feature description"
              value={formDetails.desc}
              onChangeText={(changed) => {
                determineErrorMessage(changed, "desc");
                setFormDetails({ ...formDetails, desc: changed });
              }}
              maxCharLimit={1024}
              type="multiline"
              isRequired
              errorMessage={errorMessage.desc}
            ></Input>
          </div>
        )}
        <div className="each--section">
          <Input
            labelText="Redirection URL"
            value={formDetails.redirectUrl}
            onChangeText={(changed) => {
              determineErrorMessage(changed, "redirectUrl");
              setFormDetails({ ...formDetails, redirectUrl: changed });
            }}
            isRequired
            errorMessage={errorMessage.redirectUrl}
          ></Input>
        </div>
        <div className="each--section">
          <Caption>Image</Caption>

          <div
            className="flex--row"
            style={{ justifyContent: "space-between", alignItems : 'center' , gap : 16}}
          >
            {formDetails.image && (
              <div className="image-panel">
                <BackgroundImageContainer
                  imageUrl={formDetails.image}
                  height="200px"
                />
              </div>
            )}
            <div className="upload--image" style={{width : '100%'}}>
              <UploadFile
                type={"Image"}
                onFileUpload={(fileMetaData) => {
                  setFormDetails({
                    ...formDetails,
                    image: fileMetaData.mediaUrl,
                  });
                }}
                channel={"whatsapp"}
              />
            </div>
          </div>
        </div>

        <div className="each--section">
          <Button
            buttonText="Submit"
            onClick={submitAllFeatureDetails}
            matchParentWidth
            size="medium"
            isLoading={updating}
          ></Button>
        </div>
      </AddOrEditFeatureModalContainer>
    </StyledModal>
  );
};

export default AddOrEditFeatureModal;
