import { Icon } from "./icons-props";

const RefreshIcon: Icon = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4073 1.50682C17.2565 1.16076 16.9237 0.929102 16.5469 0.907844C16.17 0.886585 15.8132 1.07935 15.6245 1.40626L14.5007 3.35276C10.6155 2.22622 6.31951 3.84052 4.2063 7.50071C1.72102 11.8053 3.19589 17.3097 7.50053 19.7949C11.8052 22.2802 17.3095 20.8053 19.7948 16.5007C20.6137 15.0822 21.0033 13.5306 21.0019 11.9998C21.0013 11.4475 20.5532 11.0002 20.0009 11.0008C19.4486 11.0013 19.0013 11.4494 19.0019 12.0017C19.003 13.1902 18.7012 14.3949 18.0627 15.5007C16.1297 18.8488 11.8486 19.9959 8.50053 18.0629C5.15248 16.1299 4.00535 11.8488 5.93835 8.50071C7.49469 5.80504 10.5732 4.53614 13.4614 5.15291L12.7961 6.30524C12.6073 6.63214 12.6188 7.03746 12.8256 7.35321C13.0325 7.66897 13.3995 7.84134 13.7746 7.79889L18.5043 7.26362C18.8196 7.22794 19.0992 7.04475 19.2579 6.76996C19.4165 6.49517 19.4353 6.1614 19.3086 5.87052L17.4073 1.50682Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default RefreshIcon;
