import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorDataState } from "./storeDataStateType";

const initialState: ErrorDataState = {
  errors: {},
  selectedNode: "",
  errorModalOpenStatus: false,
  errorsCount: 0,
  warningsCount: 0,
};

const errorDataSlice = createSlice({
  name: "errorData",
  initialState: initialState,
  reducers: {
    updateErrorState: (state, action: PayloadAction<ErrorDataState>) => {
      state = action.payload;
      return state;
    },
  },
});

export const errorDataActions = errorDataSlice.actions;
export default errorDataSlice;
