import styled from "styled-components";

export const FileUploadStyles = styled.div`
  .upload-button {
    padding: 14px 16px;
    border: 1px solid ${(props) => props.theme.colors.stroke.primary};
    background-color: white;
    display: flex;
    border-radius: 4px;
    align-items: center;
    cursor: pointer;
    width: 100%;
    .btn-text {
      color: ${(props) => props.theme.colors.content.brand};
      ${(props) => props.theme.fonts.buttonRegular};
      margin-left: 8px;
    }
  }
`;

export const ProgressModalStyles = styled.div<{
  height: number;
  width: number;
}>`
  width: ${(props) => props.width ?? 440}px;
  height: ${(props) => props.height ?? 200}px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 24px;
  display: flex;
  flex-direction: column;

  .heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 24px;
  }
  .title {
    flex: 1;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #212121;
  }
  .footer {
    display: flex;
    flex-direction: row-reverse;
    gap: 16px;
    padding-top: 24px;
  }
  .discard-text {
    flex: 1;
    text-align: right;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #616161;
    padding-top: 10px;
  }
  .icon-holder {
    background: ${(props) => props.theme.colors.background.base};
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-content: center;
    align-items: center;
  }
`;

export const LoaderContainer = styled.div<{
  background?: string;
  padding?: number;
}>`
  background: ${(props) => props.background ?? "#FAFAFA"};
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: ${(props) => props.padding ?? 24};
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
`;

export const Progress = styled.div<{ progress: number }>`
  height: 8px;
  width: ${(props) => props.progress ?? 10}%;
  background: #1a872c;
  border-radius: 8px;
`;
