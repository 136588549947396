import { CustomNodeIconProps } from "../utilities/flowBuilder.utility";

const StartFlowIcon = (props: CustomNodeIconProps) => {
  const { backgroundColor, color, height, width, style } = props;
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.5774 3.57737C3.73368 3.42109 3.94564 3.33329 4.16666 3.33329H7.49999C7.96023 3.33329 8.33332 2.9602 8.33332 2.49996C8.33332 2.03972 7.96023 1.66663 7.49999 1.66663H4.16666C3.50362 1.66663 2.86773 1.93002 2.39889 2.39886C1.93005 2.8677 1.66666 3.50358 1.66666 4.16663V15.8333C1.66666 16.4963 1.93005 17.1322 2.39889 17.6011C2.86773 18.0699 3.50361 18.3333 4.16666 18.3333H7.49999C7.96023 18.3333 8.33332 17.9602 8.33332 17.5C8.33332 17.0397 7.96023 16.6666 7.49999 16.6666H4.16666C3.94564 16.6666 3.73368 16.5788 3.5774 16.4225C3.42112 16.2663 3.33332 16.0543 3.33332 15.8333V4.16663C3.33332 3.94561 3.42112 3.73365 3.5774 3.57737Z"
        fill={color || "#212121"}
      />
      <path
        d="M13.9226 5.24404C13.5971 4.9186 13.0695 4.9186 12.7441 5.24404C12.4186 5.56947 12.4186 6.09711 12.7441 6.42255L15.4881 9.16663H7.49999C7.03975 9.16663 6.66666 9.53972 6.66666 9.99996C6.66666 10.4602 7.03975 10.8333 7.49999 10.8333H15.4881L12.7441 13.5774C12.4186 13.9028 12.4186 14.4304 12.7441 14.7559C13.0695 15.0813 13.5971 15.0813 13.9226 14.7559L18.0892 10.5892C18.4147 10.2638 18.4147 9.73614 18.0892 9.4107L13.9226 5.24404Z"
        fill={color || "#212121"}
      />
    </svg>
  );
};

export default StartFlowIcon;
