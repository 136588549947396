import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HandleNodeClick } from "./storeDataStateType";

const initialState: HandleNodeClick = {
  nodeType: "",
  nodeId: "",
  nodeSubType: "",
  nodeIndex: 0,
  automationChannel: "",
  data: {},
};

const nodeClickDataSlice = createSlice({
  name: "nodeClickData",
  initialState: initialState,
  reducers: {
    setEditNode: (state, action: PayloadAction<HandleNodeClick>) => {
      state = action.payload;
      return state;
    },
  },
});

export const nodeClickDataActions = nodeClickDataSlice.actions;
export default nodeClickDataSlice;
