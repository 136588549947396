import { getApp } from "firebase/app";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import {
  EachTailoredSectionI,
  EachWhatsNewContent,
  JourneyLevels,
  PostOnboardingJourneyRawI,
  UiMeta,
} from "./type";

const JOURNEY_REPO = "bik-onboarding-journeys";
const MISC_REPO = "bik-miscellaneous";
const WHATS_NEW_COLLECTION = "bik-whats-new";

export class GrowthRepo {
  getDatabase() {
    const app = getApp();
    return getFirestore(app);
  }
  async fetchRecommendedAndTailoredData() {
    const database = this.getDatabase();
    const keywordRef = collection(database, JOURNEY_REPO);
    const queryRef = query(keywordRef, where("type", "==", "POST-ONBOARDING"));
    const querySnapshot = await getDocs(queryRef);
    if (!querySnapshot.empty) {
      return querySnapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id } as PostOnboardingJourneyRawI;
      });
    }
  }
  async updateFeatureDetails(
    moduleName: string,
    newFeature: EachTailoredSectionI | UiMeta,
    indexToUpdate?: number,
    deleteFeature?: boolean
  ) {
    const database = this.getDatabase();
    const docRef = doc(database, JOURNEY_REPO, moduleName);

    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      const data = docSnapshot.data();
      const features = data.features || [];

      if (
        deleteFeature &&
        indexToUpdate !== undefined &&
        features[indexToUpdate]
      ) {
        features.splice(indexToUpdate, 1);
      } else if (indexToUpdate !== undefined && features[indexToUpdate]) {
        features[indexToUpdate] = {
          ...features[indexToUpdate],
          ...newFeature,
        };
      } else if (!deleteFeature) {
        features.push(newFeature);
      }

      await updateDoc(docRef, { features });
    } else {
      if (!deleteFeature) {
        await setDoc(docRef, { features: [newFeature] });
      } else {
        throw new Error("Cannot delete from a non-existent document");
      }
    }
  }

  async updateSelectedIndices(
    moduleName: string,
    selectedIndices: number[],
    level: JourneyLevels
  ) {
    const database = this.getDatabase();
    const docRef = doc(database, JOURNEY_REPO, moduleName);

    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      const docData = docSnapshot.data();

      const currentIndices = docData?.indicesToShow || {
        basic: [],
        intermediate: [],
        advanced: [],
      };

      if (currentIndices[level]) {
        const updatedIndices = Array.from(
          new Set([...currentIndices[level], ...selectedIndices])
        );

        await updateDoc(docRef, {
          [`indicesToShow.${level}`]: updatedIndices,
        });
      } else {
        await updateDoc(docRef, {
          [`indicesToShow.${level}`]: selectedIndices,
        });
      }
    } else {
      alert("Document not found. Please create a feature first");
    }
  }

  async fetchBikFeaturesCustomized() {
    const database = this.getDatabase();
    const keywordRef = collection(database, MISC_REPO);
    const bikWhatsNewDocRef = doc(keywordRef, WHATS_NEW_COLLECTION);
    const articlesRef = collection(bikWhatsNewDocRef, "articles");
    const bikWhatsNewFeatures = await getDocs(articlesRef);
    if (!bikWhatsNewFeatures.empty) {
      return bikWhatsNewFeatures.docs.map((doc) => {
        return { ...doc.data(), docId: doc.id } as EachWhatsNewContent;
      });
    }
    return [];
  }

  async createOrUpdateArticleDocument(
    data: Record<string, any>,
    documentId?: string
  ) {
    const database = this.getDatabase();
    const mainCollectionRef = collection(database, MISC_REPO);
    const bikWhatsNewDocRef = doc(mainCollectionRef, WHATS_NEW_COLLECTION);
    const articlesCollectionRef = collection(bikWhatsNewDocRef, "articles");

    try {
      if (documentId) {
        const articleDocRef = doc(articlesCollectionRef, documentId);
        const articleDocSnapshot = await getDoc(articleDocRef);

        if (articleDocSnapshot.exists()) {
          await updateDoc(articleDocRef, data);
        } else {
          await setDoc(articleDocRef, data);
        }
      } else {
        await addDoc(articlesCollectionRef, data);
      }
    } catch (error) {
      console.error("Error creating or updating article document:", error);
      throw error;
    }
  }
}
