import {
  BaseFrontendConfig,
  BaseTemplateBackendConfig,
} from "../../models/blockEdit";

export enum OrderDetailsButton {
  REVIEW_AND_PAY = "Review and Pay",
  PAY_NOW = "Pay Now",
}

export enum OrderDetailsStatus {
  SUCCESS = "success",
  FAILED = "failed",
  PENDING = "pending",
}

export type OrderDetailsBackendConfig = BaseTemplateBackendConfig<
  unknown,
  unknown
>;

export type OrderStatusBackendConfig = BaseTemplateBackendConfig<
  { reference_id: string },
  unknown
>;

export type OrderBlocksFrontendConfig = BaseFrontendConfig<{
  header: string;
  media: string;
  mediaUrl?: string;
}>;
