import styled from "styled-components";

const SearchBarStyle = styled.div`
  border: 1px solid #e0e0e0;
  filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.12));
  border-radius: 4px;
  max-height: 200px;
  overflow-y: scroll;

  .search input {
    border: 0;
    border-radius: 2px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    font-size: 18px;
    padding: 15px;
    height: 30px;
    width: 300px;
  }

  .searchIcon {
    height: 60px;
    width: 50px;
    background-color: red;
    display: grid;
    place-items: center;
  }

  input:focus {
    outline: none;
  }
  .searchIcon svg {
    font-size: 35px;
  }

  .dataResult {
    width: 300px;
    height: 200px;
    background-color: grey;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    overflow: hidden;
    overflow-y: auto;
  }

  .dataResult::-webkit-scrollbar {
    display: none;
  }

  .dataResult .dataItem {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    color: black;
  }

  .dataItem p {
    margin-left: 10px;
  }
  a {
    text-decoration: none;
  }

  a:hover {
    background-color: lightgrey;
  }

  #clearBtn {
    cursor: pointer;
  }
`;

export default SearchBarStyle;

export const ListItemStyle = styled.div`
  .list-item {
    display: flex;
    padding-left: 16px;
    width: 100%;
    height: 48px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #212121;
    align-items: center;
  }
  .heading-item {
    display: flex;
    align-items: center;
    padding-left: 16px;
    height: 32px;
    background: #f0f0f0;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #616161;
  }
  .type-item {
    display: flex;
    padding-right: 16px;
    width: 100%;
    height: 48px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #616161;
    align-items: center;
  }
`;

export const ListGroupStyle = styled.div<{ isHeading: boolean }>`
    width: 100%;
    background-color: ${(props) => (props.isHeading ? "#F0F0F0" : "#FFFFFF")}
    border-bottom: "1px solid #E0E0E0";
    display: "flex";
    flex-direction: "row";
    justify-content: "space-between"
`;
