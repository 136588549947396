import {
  AnalyticsDropdown,
  AnalyticsMultiChip,
  BASE_COLORS,
  DropdownOptions,
  COLORS,
  IconButton,
  Tooltip,
  AnalyticsChipInterface,
  truncateValueToK,
  DynamicTabs,
  MenuItemDropdown,
  DropdownProps,
} from "@bikdotai/bik-component-library";
import React, { useEffect, useState } from "react";
import { SummaryAnalyticsStyled } from "./style";
import DownloadIcon from "../../icons/DownloadIcon";
import { useDispatch } from "react-redux";
import { homeDataAction } from "../../state/homeState";
import { useAppSelector } from "../../state/store";
import {
  AnalyticsPayload,
  getAnalyticsData,
  getSummaryAnalyticsData,
  getUserResponseReport,
} from "../../services/helpers/AnalyticsHelper";
import { cloneDeep } from "lodash";
import { SummaryShimmers } from "./Utils";
import { generateCSV } from "../../utilities/fileDownload";
import { CURRENCY_SYMBOLS } from "../../app/messageBlock/Constants";
import InfoIcon from "../../icons/infoIcon";
import { SingleOption } from "@bikdotai/bik-component-library/dist/esm/components/dropdown/type";
import MessageIcon from "../../icons/messageIcon";
import { dasboardUrl } from "../../config";
import { RecordEventHelper } from "../../utilities/RecordEventHelpers";
import {
  AmplitudeEventStep,
  AmplitudeKeys,
  TargetPlatform,
} from "../../utilities/AnalyticsTypes";

export interface SummaryAnalyticsProps {}

export const enum SummaryAnalyticsViewTabName {
  ACTIVITY_VIEW = "Activity View",
  FUNNEL_VIEW = "Funnel View",
}

const NlaDropdownOptions: DropdownProps["options"] = [
  {
    label: "Download Report",
    value: "download_report",
    leadingIcon: <DownloadIcon />,
  },
  {
    label: "User Response Report",
    value: "user_response_report",
    leadingIcon: <DownloadIcon />,
  },
  {
    label: "View All Conversations",
    value: "view_all_conversations",
    leadingIcon: (
      <MessageIcon width={20} height={20} color={COLORS.content.primary} />
    ),
  },
];

const SummaryAnalytics: React.FC<SummaryAnalyticsProps> = (props) => {
  const dispatcher = useDispatch();
  const homeState = useAppSelector((state) => state.homeState);
  const storeInfoState = useAppSelector((state) => state.storeInfoState);
  const flowMeta = useAppSelector((state) => state.flowMetaState.flowMeta);
  const [summaryData, setSummaryData] = useState<any>();
  const [dropdownData, setDropdownData] = useState<any>();
  const [error, setError] = useState<string>();
  const channel = homeState.channel ?? "whatsapp";

  const currency = CURRENCY_SYMBOLS[storeInfoState.currency];
  const selectedView = homeState?.analyticsData?.selectedView;

  const DROPDOWN_DATA = {
    options: [
      {
        label: "Today",
        value: "today",
        key: 1,
      },
      {
        label: "Yesterday",
        value: "yesterday",
        key: 2,
      },
      {
        label: "Last 24 hours",
        value: "24 hours",
        key: 24,
      },
      {
        label: "Last 7 days",
        value: "7 days",
        key: 7,
      },
      {
        label: "Last 30 days",
        value: "30 days",
        key: 30,
      },
      {
        label: "Custom range",
        value: "calender",
        key: "custom",
        isCustom: true,
        dateRangeSelectionOptions: {
          selectRange: true,
          minDate: new Date(),
          maxDate: new Date(),
        },
      },
    ],
  };
  const [selectedDropdown, setSelectedDropdown] = useState<DropdownOptions>(
    DROPDOWN_DATA.options[3]
  );

  const CARD_DATA_WHATSAPP: { chipDetails: AnalyticsChipInterface[] } = {
    chipDetails: [
      {
        title: "Triggered",
        primaryValue: "--",
        backgroundColor: BASE_COLORS.grayscale["100"],
        width: "calc(100% / 6 - 16px)",
        tooltipContent: "",
        channelConfig: {},
        style: { justifyContent: "normal" },
      },
      {
        title: "Unique customers",
        primaryValue: "--",
        backgroundColor: BASE_COLORS.grayscale["100"],
        width: "calc(100% / 6 - 16px)",
        tooltipContent: "",
        channelConfig: {},
        style: { justifyContent: "normal" },
      },
      {
        title: "Messages sent",
        primaryValue: "--",
        backgroundColor: BASE_COLORS.grayscale["100"],
        width: "calc(100% / 6 - 16px)",
        tooltipContent: "",
        channelConfig: {},
        style: { justifyContent: "normal" },
      },
      {
        title: "Messages delivered",
        primaryValue: "--",
        backgroundColor: BASE_COLORS.grayscale["100"],
        width: "calc(100% / 6 - 16px)",
        tooltipContent: "",
        channelConfig: {},
        style: { justifyContent: "normal" },
      },
      {
        title: "Messages opened",
        primaryValue: "--",
        backgroundColor: BASE_COLORS.grayscale["100"],
        width: "calc(100% / 6 - 16px)",
        tooltipContent: "",
        channelConfig: {},
        style: { justifyContent: "normal" },
      },
      {
        title: "Order revenue",
        primaryValue: "--",
        backgroundColor: BASE_COLORS.grayscale["100"],
        width: "calc(100% / 6 - 16px)",
        tooltipContent: "",
        channelConfig: {},
        style: { justifyContent: "normal" },
      },
    ],
  };

  const CARD_DATA_INSTAGRAM: { chipDetails: AnalyticsChipInterface[] } = {
    chipDetails: [
      {
        title: "Triggered",
        primaryValue: "--",
        backgroundColor: BASE_COLORS.grayscale["100"],
        width: "calc(100% / 5 - 16px)",
        tooltipContent: "",
        channelConfig: {},
        style: { justifyContent: "normal" },
      },
      {
        title: "Unique customers",
        primaryValue: "--",
        backgroundColor: BASE_COLORS.grayscale["100"],
        width: "calc(100% / 5 - 16px)",
        tooltipContent: "",
        channelConfig: {},
        style: { justifyContent: "normal" },
      },
      {
        title: "Messages sent",
        primaryValue: "--",
        backgroundColor: BASE_COLORS.grayscale["100"],
        width: "calc(100% / 5 - 16px)",
        tooltipContent: "",
        channelConfig: {},
        style: { justifyContent: "normal" },
      },
      {
        title: "Messages opened",
        primaryValue: "--",
        backgroundColor: BASE_COLORS.grayscale["100"],
        width: "calc(100% / 5 - 16px)",
        tooltipContent: "",
        channelConfig: {},
        style: { justifyContent: "normal" },
      },
      {
        title: "Order revenue",
        primaryValue: "--",
        backgroundColor: BASE_COLORS.grayscale["100"],
        width: "calc(100% / 5 - 16px)",
        tooltipContent: "",
        channelConfig: {},
        style: { justifyContent: "normal" },
      },
    ],
  };

  useEffect(() => {
    let data = cloneDeep(DROPDOWN_DATA);
    if (data?.options?.[5]?.dateRangeSelectionOptions?.minDate) {
      const createdAtDate = homeState?.createdAt
        ? new Date(parseInt(homeState?.createdAt as string))
        : new Date(Date.now() - 60 * 24 * 60 * 60 * 1000);
      const thirtyDaysOld = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
      data.options[5].dateRangeSelectionOptions.minDate =
        createdAtDate.getTime() > thirtyDaysOld.getTime()
          ? thirtyDaysOld
          : createdAtDate;
    }
    setDropdownData(data);
  }, [homeState?.flowId, homeState?.createdAt, homeState?.channel]);

  useEffect(() => {
    const flowAnalytics = homeState?.analyticsData?.flowAnalytics;
    const secondaryChannels: ("whatsapp" | "instagram" | "email" | "sms")[] =
      (flowMeta as { [key: string]: any })?.[homeState?.flowId]
        ?.secondaryChannels ?? [];
    if (flowAnalytics && Object.keys(flowAnalytics).length > 0) {
      const triggered = truncateValueToK(
        flowAnalytics?.total_triggered_count ?? 0
      );
      const read = truncateValueToK(flowAnalytics?.total_read ?? 0);
      const sent = truncateValueToK(flowAnalytics?.total_sent ?? 0);
      const delivered = truncateValueToK(flowAnalytics?.total_delivered ?? 0);
      const unique = truncateValueToK(flowAnalytics?.unique_customers ?? 0);
      let revenue = truncateValueToK(flowAnalytics?.total_revenue ?? 0);
      let flowData = cloneDeep(
        channel === "instagram" ? CARD_DATA_INSTAGRAM : CARD_DATA_WHATSAPP
      );
      flowData.chipDetails[0].primaryValue =
        triggered !== "0" ? triggered : "--";
      flowData.chipDetails[1].primaryValue = unique !== "0" ? unique : "--";
      if (channel !== "instagram") {
        flowData.chipDetails[2].primaryValue = sent !== "0" ? sent : "--";
        flowData.chipDetails[3].primaryValue =
          delivered !== "0" ? delivered : "--";
        flowData.chipDetails[4].primaryValue = read !== "0" ? read : "--";
        flowData.chipDetails[5].primaryValue =
          revenue && revenue !== "0" ? `${currency} ${revenue}` : "--";

        // If not instagram and has secondary channel, this is whatsapp
        if (secondaryChannels.length) {
          const sentChannelWise = flowAnalytics?.sent_channel_wise;
          const deliveredChannelWise = flowAnalytics?.delivered_channel_wise;
          const readChannelWise = flowAnalytics?.read_channel_wise;
          const revenueChannelWise = flowAnalytics?.revenue_channel_wise;

          flowData.chipDetails[2].channelConfig!.whatsapp =
            sentChannelWise?.whatsapp > 0
              ? truncateValueToK(sentChannelWise?.whatsapp)
              : "--";
          flowData.chipDetails[3].channelConfig!.whatsapp =
            deliveredChannelWise?.whatsapp > 0
              ? truncateValueToK(deliveredChannelWise?.whatsapp)
              : "--";
          flowData.chipDetails[4].channelConfig!.whatsapp =
            readChannelWise?.whatsapp > 0
              ? truncateValueToK(readChannelWise?.whatsapp)
              : "--";
          flowData.chipDetails[5].channelConfig!.whatsapp =
            revenueChannelWise?.whatsapp > 0
              ? `${currency} ${truncateValueToK(revenueChannelWise?.whatsapp)}`
              : "--";
          // Loops through secondary channels and assigns
          for (const channel of secondaryChannels) {
            if (channel === "sms") {
              const smsSent =
                sentChannelWise?.["message"] ??
                0 + sentChannelWise?.["sms"] ??
                0;
              const smsDelivered =
                deliveredChannelWise?.["message"] ??
                0 + deliveredChannelWise?.["sms"] ??
                0;
              const smsRead =
                readChannelWise?.["message"] ??
                0 + readChannelWise?.["sms"] ??
                0;
              const smsRevenue =
                revenueChannelWise?.["message"] ??
                0 + revenueChannelWise?.["sms"] ??
                0;
              flowData.chipDetails[2].channelConfig![channel] =
                smsSent > 0 ? truncateValueToK(smsSent) : "--";
              flowData.chipDetails[3].channelConfig![channel] =
                smsDelivered > 0 ? truncateValueToK(smsDelivered) : "--";
              flowData.chipDetails[4].channelConfig![channel] =
                smsRead > 0 ? truncateValueToK(smsRead) : "--";
              flowData.chipDetails[5].channelConfig![channel] =
                smsRevenue > 0
                  ? `${currency} ${truncateValueToK(smsRevenue)}`
                  : "--";
            } else {
              flowData.chipDetails[2].channelConfig![channel] =
                sentChannelWise?.[channel] > 0
                  ? truncateValueToK(sentChannelWise?.[channel])
                  : "--";
              flowData.chipDetails[3].channelConfig![channel] =
                deliveredChannelWise?.[channel] > 0
                  ? truncateValueToK(deliveredChannelWise?.[channel])
                  : "--";
              flowData.chipDetails[4].channelConfig![channel] =
                readChannelWise?.[channel] > 0
                  ? truncateValueToK(readChannelWise?.[channel])
                  : "--";
              flowData.chipDetails[5].channelConfig![channel] =
                revenueChannelWise?.[channel] > 0
                  ? `${currency} ${truncateValueToK(
                      revenueChannelWise?.[channel]
                    )}`
                  : "--";
            }
          }
        }
      } else {
        flowData.chipDetails[2].primaryValue = sent !== "0" ? sent : "--";
        flowData.chipDetails[3].primaryValue = read !== "0" ? read : "--";
        flowData.chipDetails[4].primaryValue =
          revenue && revenue !== "0" ? `${currency} ${revenue}` : "--";
        const readChannelWise = flowAnalytics?.read_channel_wise;
        const revenueChannelWise = flowAnalytics?.revenue_channel_wise;

        // For instagram
        if (secondaryChannels.length) {
          const sentChannelWise = flowAnalytics?.sent_channel_wise;
          flowData.chipDetails[2].channelConfig!.instagram =
            sentChannelWise?.instagram > 0
              ? truncateValueToK(sentChannelWise?.instagram)
              : "--";
          flowData.chipDetails[3].channelConfig!.instagram =
            readChannelWise?.instagram > 0
              ? truncateValueToK(readChannelWise?.instagram)
              : "--";
          flowData.chipDetails[4].channelConfig!.instagram =
            revenueChannelWise?.instagram > 0
              ? `${currency} ${truncateValueToK(revenueChannelWise?.instagram)}`
              : "--";
          // Loops through secondary channels and assigns
          for (const channel of secondaryChannels) {
            if (channel === "sms") {
              const smsSent =
                sentChannelWise?.["message"] ??
                0 + sentChannelWise?.["sms"] ??
                0;
              const smsRead =
                readChannelWise?.["message"] ??
                0 + readChannelWise?.["sms"] ??
                0;
              const smsRevenue =
                revenueChannelWise?.["message"] ??
                0 + revenueChannelWise?.["sms"] ??
                0;
              flowData.chipDetails[2].channelConfig![channel] =
                smsSent > 0 ? truncateValueToK(smsSent) : "--";
              flowData.chipDetails[3].channelConfig![channel] =
                smsRead > 0 ? truncateValueToK(smsRead) : "--";
              flowData.chipDetails[4].channelConfig![channel] =
                smsRevenue > 0
                  ? `${currency} ${truncateValueToK(smsRevenue)}`
                  : "--";
            } else {
              flowData.chipDetails[2].channelConfig![channel] =
                sentChannelWise?.[channel] > 0
                  ? truncateValueToK(sentChannelWise?.[channel])
                  : "--";
              flowData.chipDetails[3].channelConfig![channel] =
                readChannelWise?.[channel] > 0
                  ? truncateValueToK(readChannelWise?.[channel])
                  : "--";
              flowData.chipDetails[4].channelConfig![channel] =
                revenueChannelWise?.[channel] > 0
                  ? `${currency} ${truncateValueToK(
                      revenueChannelWise?.[channel]
                    )}`
                  : "--";
            }
          }
        }
      }

      setSummaryData(flowData);
    }
  }, [homeState?.analyticsData?.flowAnalytics]);

  const getAnalytics = async (payload: any) => {
    dispatcher(
      homeDataAction.addState({
        ...homeState,
        analyticsData: {
          ...homeState?.analyticsData,
          shimmer: true,
          selectedView,
        },
      })
    );
    await getAnalyticsData(payload).then(async (nodeData) => {
      await getSummaryAnalyticsData(payload).then((flowData) => {
        dispatcher(
          homeDataAction.addState({
            ...homeState,
            analyticsData: {
              ...homeState.analyticsData,
              nodeAnalytics: nodeData,
              flowAnalytics: flowData,
              shimmer: false,
              selectedView,
            },
          })
        );
      });
    });
  };

  useEffect(() => {
    if (!homeState || !homeState?.flowId || !selectedView) {
      return;
    }
    const payload: AnalyticsPayload = {
      storeId: homeState.storeId,
      flowId: homeState.flowId,
      startDate: homeState.analyticsData!.startDate!,
      endDate: homeState.analyticsData?.endDate!,
      channel: homeState?.channel!,
      basedOnTriggeredDate:
        selectedView === SummaryAnalyticsViewTabName.FUNNEL_VIEW,
    };
    getAnalytics(payload);
  }, [selectedView]);

  const DateChangeHandler = async (
    data: DropdownOptions & {
      selectedRange?: [any, any];
    }
  ) => {
    let date = new Date();
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() + offset * 60 * 1000);

    let startDate: any = "";
    if (data.key === "custom") {
      const diffTime = Math.abs(
        data?.selectedRange?.[1] - data?.selectedRange?.[0]
      );
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays > 90) {
        setError("Please select a date range within 90 days.");
        return;
      }
      startDate = data?.selectedRange?.[0]?.getTime();
    }
    dispatcher(
      homeDataAction.addState({
        ...homeState,
        analyticsData: {
          ...homeState?.analyticsData,
          shimmer: true,
          selectedView,
        },
      })
    );
    setError("");

    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);

    const yesterday = new Date(today.getTime());
    yesterday.setDate(yesterday.getDate() - 1);

    const last24Hours = new Date();
    last24Hours.setDate(last24Hours.getDate() - 1);

    const last7Days = new Date(today.getTime());
    last7Days.setDate(last7Days.getDate() - 6);

    const last30Days = new Date(today.getTime());
    last30Days.setDate(last30Days.getDate() - 29);

    const customStartDate =
      data.key === 1
        ? today.getTime()
        : data.key === 2
          ? yesterday.getTime()
          : data.key === 24
            ? last24Hours.getTime()
            : data.key === 7
              ? last7Days.getTime()
              : data.key === 30
                ? last30Days.getTime()
                : data.key === "custom"
                  ? startDate
                  : "";
    const endDate =
      data.key === "custom"
        ? data?.selectedRange?.[1]?.getTime()
        : data.key === 2
          ? new Date(
              yesterday.getFullYear(),
              yesterday.getMonth(),
              yesterday.getDate(),
              23,
              59,
              59
            ).getTime()
          : new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate(),
              23,
              59,
              59
            ).getTime();
    const payload: AnalyticsPayload = {
      storeId: homeState.storeId,
      flowId: homeState.flowId,
      startDate: customStartDate,
      endDate: endDate,
      channel: homeState?.channel!,
      basedOnTriggeredDate:
        selectedView === SummaryAnalyticsViewTabName.FUNNEL_VIEW,
    };
    await getAnalyticsData(payload).then(async (nodeData) => {
      await getSummaryAnalyticsData(payload).then((flowData) => {
        dispatcher(
          homeDataAction.addState({
            ...homeState,
            analyticsData: {
              nodeAnalytics: nodeData,
              flowAnalytics: flowData,
              startDate: customStartDate,
              endDate: endDate,
              shimmer: false,
              selectedView,
            },
          })
        );
      });
    });

    const eventHelper = RecordEventHelper.getInstance();
    eventHelper.trackEvent(
      TargetPlatform.Amplitude,
      AmplitudeKeys.service_used,
      {
        step: AmplitudeEventStep.filter_changed,
        startDate: customStartDate,
        endDate: endDate,
        screen: "journey_builder_analytics",
      }
    );
    setSelectedDropdown(data);
  };

  const handleDownload = async () => {
    const payload: AnalyticsPayload = {
      storeId: homeState.storeId,
      flowId: homeState.flowId,
      startDate: homeState.analyticsData?.startDate!,
      endDate: homeState.analyticsData?.endDate!,
      channel: homeState.channel!,
      csvReport: true,
      basedOnTriggeredDate:
        selectedView === SummaryAnalyticsViewTabName.FUNNEL_VIEW,
    };
    const response = await getSummaryAnalyticsData(payload);
    if (response) {
      generateCSV(response, `${homeState?.flowId}_summary_analytics`);
      const eventHelper = RecordEventHelper.getInstance();
      eventHelper.trackEvent(
        TargetPlatform.Amplitude,
        AmplitudeKeys.service_used,
        {
          step: AmplitudeEventStep.overall_download,
          screen: "journey_builder_analytics",
        }
      );
    }
  };

  const handleUserRespDownload = async () => {
    const payload: AnalyticsPayload = {
      storeId: homeState.storeId,
      flowId: homeState.flowId,
      startDate: homeState.analyticsData?.startDate!,
      endDate: homeState.analyticsData?.endDate!,
      channel: homeState.channel!,
      basedOnTriggeredDate:
        selectedView === SummaryAnalyticsViewTabName.FUNNEL_VIEW,
    };
    const response = await getUserResponseReport(payload);
    if (response) {
      generateCSV(response, `${homeState?.flowId}_user_response_report`);
      const eventHelper = RecordEventHelper.getInstance();
      eventHelper.trackEvent(
        TargetPlatform.Amplitude,
        AmplitudeKeys.service_used,
        {
          step: AmplitudeEventStep.user_report_downloaded,
          screen: "journey_builder_analytics",
        }
      );
    }
  };

  return (
    <SummaryAnalyticsStyled>
      <div className="header__bar">
        <div className="left__side__flex">
          <div className="title">Overall Analytics</div>
          <div id="pick-date">
            {dropdownData && (
              <AnalyticsDropdown
                {...dropdownData}
                selected={selectedDropdown}
                onOptionSelected={async (chosen) => {
                  await DateChangeHandler(chosen);
                }}
              />
            )}
          </div>
          <Tooltip body="This Analytics gives a holistic view of all activities in the specified time, regardless of when the automation was first triggered (Note: This is not a funnel view)">
            <IconButton
              Icon={() => (
                <InfoIcon
                  color={COLORS.content.placeholder}
                  width={16}
                  height={16}
                />
              )}
              onClick={() => {}}
            />
          </Tooltip>
          <DynamicTabs
            tabsInfo={[
              {
                id: 0,
                text: SummaryAnalyticsViewTabName.ACTIVITY_VIEW,
                action: (tabName: string) => {
                  dispatcher(
                    homeDataAction.setSelectedView(
                      tabName as SummaryAnalyticsViewTabName
                    )
                  );
                },
                tooltipProps: {
                  body: "Shows everything that's happened during the specified time, no matter when the journey started.",
                  children: <></>,
                  placement: "bottom",
                },
              },
              {
                id: 1,
                text: SummaryAnalyticsViewTabName.FUNNEL_VIEW,
                action: (tabName: string) => {
                  dispatcher(
                    homeDataAction.setSelectedView(
                      tabName as SummaryAnalyticsViewTabName
                    )
                  );
                },
                tooltipProps: {
                  body: "Shows details about journeys that began within the specified time frame.",
                  children: <></>,
                  placement: "bottom",
                },
              },
            ]}
            tabStyleInfo={{ type: "SQUARE", size: "SMALL" }}
            tabWidth={"120px"}
          />
          <div className="error__text">{error}</div>
        </div>
        <div style={{ display: "flex" }} id="download">
          <MenuItemDropdown
            width={"auto"}
            height={"auto"}
            options={NlaDropdownOptions}
            placement={"bottom-end"}
            isHorizontal={false}
            onSelect={(option) => {
              if ((option as SingleOption).value === "download_report") {
                handleDownload();
              }
              if ((option as SingleOption).value === "user_response_report") {
                handleUserRespDownload();
              }
              if ((option as SingleOption).value === "view_all_conversations") {
                window.open(
                  `${dasboardUrl}/inbox/marketing?flowId=${homeState?.flowId}`,
                  "_blank"
                );
              }
            }}
          />
        </div>
      </div>
      <div className="summary__cards">
        {summaryData?.chipDetails[0]?.primaryValue === undefined ||
        homeState?.analyticsData?.shimmer ? (
          <SummaryShimmers />
        ) : (
          <div id="summary-analytics">
            <AnalyticsMultiChip
              wrapperStyle={{ alignItems: "normal" }}
              {...summaryData}
            />
          </div>
        )}
      </div>
    </SummaryAnalyticsStyled>
  );
};

export default SummaryAnalytics;
