import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { FirebaseService } from "../services/firebase/FirebaseService";
import { variableHeadingMapper } from "../app/messageBlock/Constants";
import { getChatbotEndpoints, isProd } from "../config";
import { variableTypeMapper } from "../utilities/validationUtilities";
import { captureErrorToSentry } from "../utilities/sentryHelper";
import { fetchCustomerTags } from "../services/helpers/StoreHelper";

interface Variable {
  is_optional: boolean;
  variable_type: string;
  block_sub_type?: string;
  should_fetch?: string;
}

type ProcessedList = Array<
  | { text: string; id: string; dataType: string; blockSubType?: string }
  | { text: string; isHeading: boolean; blockSubType?: string }
>;

type RawList = { [index: string]: { [index: string]: Variable } };

export const variablesApi: any = createApi({
  reducerPath: "variablesApi",
  keepUnusedDataFor: 21600,
  baseQuery: fetchBaseQuery({
    baseUrl: getChatbotEndpoints(),
    prepareHeaders: async (headers, { getState }) => {
      const idToken = await FirebaseService.fetchIdToken();
      if (idToken) {
        headers.set("Authorization", idToken);
      }
      headers.set("content-type", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getVariablesForStore: builder.query<any, string>({
      query: (storeId) => {
        return {
          url: "chatbot-variables",
          method: "POST",
          body: {
            store_id: storeId,
          },
          responseHandler: async (response) => {
            const apiResponse: {
              status: number;
              variables: RawList;
            } = await response.json();

            if (apiResponse.status !== 200) {
              if (
                (apiResponse as any)?.detail?.indexOf("invalid user token") !==
                -1
              ) {
                const user = await FirebaseService.fetchCurrentUser();
                const projectId = (user as any)?.auth.app._options.projectId;
                console.error(
                  "Invalid auth",
                  JSON.stringify({ env: isProd, projectId: projectId })
                );
                captureErrorToSentry(
                  `Invalid auth, ${isProd} and ${projectId} and ${window.location}`
                );
              }
              return {};
            }

            const variableList: ProcessedList = [];
            const toFetchVariables: string[] = [];
            let fetchedVariables: {
              [key: string]: {
                label: string;
                value: string;
                selected: boolean;
              }[];
            } = {};

            Object.entries(apiResponse.variables).forEach(
              (
                item: [
                  string,
                  {
                    [index: string]: Variable;
                  },
                ]
              ) => {
                const [variableType, variables] = item;
                variableList.push(variableHeadingMapper[variableType]);
                Object.entries(variables).forEach(
                  (variable: [string, Variable]) => {
                    const [variableKey, variableOpts] = variable;
                    if (variableOpts.should_fetch) {
                      toFetchVariables.push(variableKey);
                    }
                    const variableText = variableKey.split(".").join(" ");
                    variableList.push({
                      dataType: variableTypeMapper[variableOpts.variable_type],
                      text: variableText,
                      id: `{{${variableKey}}}`,
                      blockSubType: variableOpts.block_sub_type,
                    });
                  }
                );
              }
            );

            for await (let toFetch of toFetchVariables) {
              if (toFetch === "customer.tags") {
                const res: { id: string; name: string }[] =
                  await fetchCustomerTags(storeId);
                const fetchedListItems = res.map((item) => {
                  return {
                    label: item.name,
                    value: item.id,
                    selected: false,
                  };
                });
                fetchedVariables = {
                  ...fetchedVariables,
                  [toFetch]: fetchedListItems,
                };
              }
            }
            return {
              variableList,
              rawList: apiResponse.variables,
              fetchedVariables,
            };
          },
        };
      },
    }),
  }),
});

export const { useGetVariablesForStoreQuery } = variablesApi;
