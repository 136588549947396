import React, { useEffect, useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import { DeltaStatic, RangeStatic, Sources } from "quill";
import "react-quill/dist/quill.snow.css";
import { EditorHeader } from "./EditorHeader";
import { RichTextEditorStyled } from "./RichTextEditor.styled";
import EmojiPicker from "emoji-picker-react";
import { useOutside } from "./useOutside";
import { WordCounterRTE } from "./WordCounterRTE";
import {
  VariablePicker,
  PickerType,
  Floater,
} from "@bikdotai/bik-component-library";
import useVariablesListHookV2 from "../../services/helpers/VariablesListHookV2";
import { RecordEventHelper } from "../../utilities/RecordEventHelpers";
import {
  AmplitudeEventStep,
  AmplitudeKeys,
  TargetPlatform,
} from "../../utilities/AnalyticsTypes";
export const MAX_LIMIT_REACHED_ERROR = "Maximum 1,024 characters are allowed";
export const EMPTY_HTML_IN_TEXT = "<p><br></p>";
export const globalVariablePattern = /\{\{[A-Za-z0-9 \._#\[\] A-Za-z0-9]+\}\}/g;
export const globalHalfVariablePattern =
  /\{\{[a-zA-Z0-9 \._#\[\]]+(?=( |\}))(?!\}\})/g;
export const NO_MANUAL_VARIABLE =
  "Please select variables from variable list using above Add Variable Button";
const Size = Quill.import("formats/size");
Size.whitelist = ["small", "default", "large", "huge"];
Quill.register(Size, true);

export interface RTEProps {
  headerText?: string | number;
  headerTextColor?: string;
  showVariableOnly?: boolean;
  hideWordLimit?: boolean;
  customHeight?: string;
  placeHolder?: string;
  uniqueId: string;
  customWordCount?: number;
  onChange?: (text: string) => void;
  onFocusOutExecute?: (textReference: string) => void;

  setTextMessage?: any;
  setHtmlMessage?: any;
  setSelectedVariableList?: any;
  textMessage?: string;
  htmlMessage?: string;
  selectedVariableList?: string[];
  onlyShowMediaVars?: boolean;

  checkKeyPress?: any;
  errorText?: string;
  readonly?: boolean;
  subText?: string;
  isCarousel?: boolean;
  showFontSize?: boolean;
  hideAddVariables?: boolean;
  showEmojiAlso?: boolean;
  pickerSize?: string;
  enableVariable?: boolean;
  customWordCountPosition?: boolean;
}

const RichTextEditor: React.FC<RTEProps> = (props) => {
  const editorRef = useRef<any>();
  const superRef = useRef<any>();
  const [error, setError] = useState<string>();
  const [emoji, setEmoji] = useState<any>();
  const onEmojiClick = (emojiData: any, event: MouseEvent) => {
    setEmoji(emojiData.emoji);
  };
  const eventHelper = new RecordEventHelper();
  const [showEmoji, setShowEmoji] = useState<boolean>(false);
  const [showVariableDropdown, setShowVariableDropdown] = useState(false);
  const { variablesList } = useVariablesListHookV2();
  const [focusEmoji, setFocusEmoji] = useState<boolean>(true);
  const [focusVariable, setFocusVariable] = useState<boolean>(true);
  const [variableValue, setVariableValue] = useState<string>("");

  const [selectedList, setSelectedList] = useState<string[]>(
    (props.selectedVariableList as string[]) || []
  );
  const [text, setText] = useState<string>("");
  const [textChange, onTextChange] = useState<string>("");
  const [highlight, setHighlight] = useState<string[]>([]);
  const textRef = useRef<any>(textChange);
  const [rtePosition, setRtePosition] = useState<any>();
  let wordCount: number;

  /*
    text         :: htmlMessage
    textChange   :: textMessage
    selectedList :: selectedVariableList
    For no error state keyword: noError
  */

  useOutside(superRef, () => {
    onFocusOut();
  });

  useEffect(() => {
    onFocusOut();
  }, []);

  useEffect(() => {
    textRef.current = textChange;
  }, [textChange]);

  useEffect(() => {
    //processing error state
    if (!props.errorText) {
      return;
    }
    const element = document.getElementById(props.uniqueId);
    const classElement = element?.getElementsByClassName(
      "ql-container"
    )?.[0] as HTMLElement;
    let list = classElement?.classList;

    let headerError, headerElement;
    if (props.headerText) {
      headerElement = element?.getElementsByClassName(
        "headerText"
      )[0] as HTMLElement;
      headerError = headerElement?.classList;
    }
    if (list) {
      if (props.errorText === "noError") {
        list?.remove("error");
        headerError?.remove("headerError");
      } else {
        list?.remove("onFocusOut");
        list?.remove("onFocusIn");
        list?.add("error");
        headerError?.add("headerError");
      }
    }
  }, [props.errorText]);

  useEffect(() => {
    //re-rendering html message
    if (props.htmlMessage && props.htmlMessage !== text) {
      setText(props.htmlMessage + " ");
      let varList: string[] =
        props.htmlMessage.match(globalVariablePattern) ?? [];
      if (varList) {
        varList = varList?.map((data: string) => {
          return data?.replaceAll(/{{/g, "")?.replaceAll(/}}/g, "");
        });
        props.setSelectedVariableList((prevState: any) => {
          return {
            ...prevState,
            [props.uniqueId]: varList,
          };
        });
        setSelectedList(varList);
      }
      const editor = editorRef?.current?.getEditor();
      let editorText = editor?.getText() as string;
      onTextChange(editorText);
      props?.setTextMessage?.((prevState: any) => {
        return { ...prevState, [props.uniqueId]: editorText };
      });
    }
  }, [props.htmlMessage]);

  useEffect(() => {
    //re-rendering text message
    if (props.textMessage && props.textMessage !== textChange) {
      const editor = editorRef?.current?.getEditor();
      editor.insertText(0, props.textMessage);
      let varList: string[] =
        props.textMessage.match(globalVariablePattern) ?? [];
      if (varList) {
        varList = varList?.map((data: string) => {
          return data?.replaceAll(/{{/g, "")?.replaceAll(/}}/g, "");
        });
        props.setSelectedVariableList((prevState: any) => {
          return {
            ...prevState,
            [props.uniqueId]: varList,
          };
        });
        setSelectedList(varList);
        addStyles();
      }
    }
  }, [props.textMessage]);

  useEffect(() => {
    //process emojis
    if (!emoji || emoji == "") {
      return;
    }
    const editor = editorRef?.current?.getEditor();
    const selection = editor?.getSelection(true);
    editor.insertText(selection?.index, emoji);
    const newSelection = editor?.getSelection(true);
    editor?.setSelection(newSelection?.index + 1);
    setEmoji("");
  }, [emoji]);

  useEffect(() => {
    //process variables
    if (!variableValue) {
      return;
    }
    const editor = editorRef?.current?.getEditor();
    const selection = editor?.getSelection(true);
    editor.insertText(selection?.index, variableValue + " ");
    addStyles();
    const variableWithoutBracket = variableValue
      .replaceAll(/{{/g, "")
      .replaceAll(/}}/g, "");
    setSelectedList([...selectedList, variableWithoutBracket]);
    if (props?.setSelectedVariableList) {
      props.setSelectedVariableList((prevState: any) => {
        return {
          ...prevState,
          [props.uniqueId]: [...selectedList, variableWithoutBracket],
        };
      });
    }
    setVariableValue("");
  }, [variableValue]);

  const onFocusIn = () => {
    //focusing the RTE
    if (props?.readonly) {
      return;
    }
    wordCount = WordCounterRTE(textChange?.replace(/^\s+|\s+$/g, "")).length;
    const element = document.getElementById(props.uniqueId);
    const classElement = element?.getElementsByClassName(
      "ql-container"
    )[0] as HTMLElement;
    let list = classElement?.classList;
    list.remove("onFocusOut");
    if (
      props.customWordCount
        ? wordCount > props.customWordCount
        : wordCount > 1024
    ) {
      list.remove("onFocusIn");
      list.add("error");
    } else {
      list.remove("error");
      list.add("onFocusIn");
    }

    setFocusEmoji(true);
    setFocusVariable(true);

    const barElement = document.getElementById(props.uniqueId + "tool-bar");
    const toolBar = barElement?.getElementsByClassName(
      "ql-toolbar"
    )[0] as HTMLElement;
    const toolList = toolBar?.classList;
    toolList.remove("hide__toolbar");
  };

  const onFocusOut = () => {
    //removing focusing of the RTE
    wordCount = WordCounterRTE(textChange?.replace(/^\s+|\s+$/g, "")).length;
    const element = document?.getElementById(props.uniqueId);
    const classElement = element?.getElementsByClassName(
      "ql-container"
    )[0] as HTMLElement;
    const list = classElement?.classList;
    list.remove("onFocusIn");
    list.add("onFocusOut");

    setFocusEmoji(false);
    setFocusVariable(false);

    const barElement = document.getElementById(props.uniqueId + "tool-bar");
    const toolBar = barElement?.getElementsByClassName(
      "ql-toolbar"
    )[0] as HTMLElement;
    const toolList = toolBar?.classList;
    toolList.add("hide__toolbar");

    if (
      props.customWordCount
        ? wordCount > props.customWordCount
        : wordCount > 1024
    ) {
      setError(MAX_LIMIT_REACHED_ERROR);
      list.add("error");
    } else {
      setError("");
      list.remove("error");
    }
    props?.onFocusOutExecute?.(textRef.current);
  };

  const addStyles = (data?: string) => {
    //adding styles for variables
    const editor = editorRef?.current?.getEditor();
    let editorText = editor?.getText() as string;
    let match = globalVariablePattern.test(data ?? editorText);
    if (match) {
      let result,
        indices = [];
      while ((result = globalVariablePattern.exec(data ?? editorText))) {
        indices.push(result.index);
      }
      indices = Array.from(
        (data ?? editorText).matchAll(globalVariablePattern)
      ).map((match) => match.index);
      let varList = editorText.match(globalVariablePattern);
      let length = varList?.map((data) => {
        return data.length;
      });
      indices.forEach((index: any, idx: number) => {
        editor.formatText(index, length?.[idx], { color: "#731dcf" }, true);
      });
    }
  };

  const onChange = (
    str: string,
    delta: DeltaStatic,
    source: Sources,
    editorFunctions: ReactQuill.UnprivilegedEditor
  ) => {
    //on change in RTE
    wordCount = WordCounterRTE(textChange?.replace(/^\s+|\s+$/g, "")).length;
    const editor = editorRef?.current?.getEditor();
    const raw = editor?.getText(0, editor.getLength() - 1);
    if (str.trim() === EMPTY_HTML_IN_TEXT) {
      setText("");
      if (props?.setHtmlMessage && !props?.readonly) {
        props?.setHtmlMessage((prevState: any) => {
          return { ...prevState, [props.uniqueId]: "" };
        });
      }

      onTextChange("");
      props?.setTextMessage?.((prevState: any) => {
        return { ...prevState, [props.uniqueId]: "" };
      });
    } else {
      setText(str);
      if (props?.setHtmlMessage && !props?.readonly) {
        props?.setHtmlMessage((prevState: any) => {
          return { ...prevState, [props.uniqueId]: str };
        });
      }

      onTextChange(raw);
      props.setTextMessage &&
        props.setTextMessage?.((prevState: any) => {
          return {
            ...prevState,
            [props.uniqueId]: raw,
          };
        });
    }
    props?.onChange?.(raw);
    textChangeProcess(str);
  };

  const textChangeProcess = (text: string) => {
    //extending onChange in RTE
    if (!text) {
      return;
    }
    wordCount = WordCounterRTE(textChange?.replace(/^\s+|\s+$/g, "")).length;
    const element = document.getElementById(props.uniqueId as string);
    const classElement = element?.getElementsByClassName(
      "ql-container"
    )[0] as HTMLElement;
    const list = classElement?.classList;
    if (
      props.customWordCount
        ? wordCount > props.customWordCount
        : wordCount > 1024
    ) {
      list.remove("onFocusOut");
      list.remove("onFocusIn");
      list.add("error");
      setError(MAX_LIMIT_REACHED_ERROR);
    } else {
      list.add("onFocusOut");
      list.remove("error");
      setError("");
    }

    //add variable
    const newVarList = text.match(globalVariablePattern);
    if (newVarList && newVarList.length !== selectedList.length) {
      const newVarListWithoutBracket = newVarList?.map((data: string) => {
        return data?.replaceAll(/{{/g, "")?.replaceAll(/}}/g, "");
      });
      setSelectedList(newVarListWithoutBracket);
      if (props.setSelectedVariableList) {
        props.setSelectedVariableList((prevState: any) => {
          return {
            ...prevState,
            [props.uniqueId]: newVarListWithoutBracket,
          };
        });
      }
      addStyles();
    } else if(!newVarList) {
      setSelectedList([]);
      if (props.setSelectedVariableList) {
        props.setSelectedVariableList((prevState: any) => {
          return {
            ...prevState,
            [props.uniqueId]: [],
          };
        });
      }
    }

    //deletion of variables
    const currVariableList = text.match(globalHalfVariablePattern);
    if (
      currVariableList &&
      selectedList.includes(
        currVariableList[0].replaceAll(/{{/g, "").replaceAll(/}/g, "")
      )
    ) {
      const editor = editorRef?.current?.getEditor();
      const selection = editor?.getSelection(true);
      const newPosition = selection.index - currVariableList[0].length;
      const newText = text
        .split(globalHalfVariablePattern)
        .map((str: any) => {
          if (str === "}") {
            return "";
          }
          if (str.startsWith("}")) {
            return str?.replace("}", "");
          }
          return str;
        })
        .join("");
      setText(newText);
      if (props?.setHtmlMessage && !props?.readonly) {
        props?.setHtmlMessage((prevState: any) => {
          return { ...prevState, [props.uniqueId]: newText };
        });
      }
      const idx = selectedList?.findIndex(
        (element) =>
          element ===
          currVariableList[0].replaceAll(/{{/g, "").replaceAll(/}/g, "")
      );
      removeVariableFromList(idx);
      if (newPosition >= 0) {
        editor.setSelection(newPosition);
      } else {
        editor.setSelection(textChange.length);
      }
    }
  };

  const removeVariableFromList = (index: number) => {
    //removing variables from lists on variable deletion
    setSelectedList([
      ...selectedList.slice(0, index),
      ...selectedList.slice(index + 1, selectedList.length),
    ]);
    if (props.setSelectedVariableList) {
      props.setSelectedVariableList((prevState: any) => {
        return {
          ...prevState,
          [props.uniqueId]: [
            ...selectedList.slice(0, index),
            ...selectedList.slice(index + 1, selectedList.length),
          ],
        };
      });
    }
  };

  const KeyDown = (e: any) => {
    //on key press of enter custom function
    const input = e.target.value;
    if (props?.checkKeyPress && e.key === "Enter") {
      props.checkKeyPress();
      onTextChange("");
      setText("");
      setSelectedList([]);
    }
  };

  const onChangeSelection = (
    range: RangeStatic,
    source: Sources,
    editor: ReactQuill.UnprivilegedEditor
  ) => {
    if (range) {
      onFocusIn();
      const quill = editorRef?.current?.getEditor();
      quill.update();
      const style = quill?.getFormat();
      let formats: string[] = [...highlight];
      formats = ToolbarHighlighter(formats, style, "bold");
      formats = ToolbarHighlighter(formats, style, "italic");
      formats = ToolbarHighlighter(formats, style, "strike");
      setHighlight(formats);
    }
  };

  const ToolbarHighlighter = (
    formats: string[],
    style: Object,
    option: string
  ) => {
    if (JSON.stringify(style).includes(option)) {
      formats = formats.includes(option) ? [...formats] : [...formats, option];
    } else {
      formats = formats.filter((item) => item !== option);
    }
    return formats;
  };

  const FormatSetter = (format: string) => {
    setHighlight(
      highlight.includes(format)
        ? highlight.filter((item) => item !== format)
        : [...highlight, format]
    );
  };

  return (
    <RichTextEditorStyled
      {...{
        customHeight: props.customHeight,
        headerTextColor: props.headerTextColor,
      }}
      id={props.uniqueId}
      style={{ position: "relative" }}
    >
      {props.headerText && <div className="headerText">{props.headerText}</div>}
      <div ref={superRef}>
        <div className="textEditor">
          <ReactQuill
            ref={editorRef}
            placeholder={
              props.placeHolder ? props.placeHolder : "Enter your text here"
            }
            onKeyDown={
              props.checkKeyPress ? async (e) => await KeyDown(e) : () => {}
            }
            modules={{
              toolbar: {
                container: `#toolbar${props?.uniqueId}`,
              },
            }}
            style={{
              color: !text.length ? "#9E9E9E" : "#212121",
            }}
            theme="snow"
            value={text}
            onChange={onChange}
            onFocus={onFocusIn}
            readOnly={props.readonly}
            onChangeSelection={onChangeSelection}
            preserveWhitespace
          />
        </div>

        <EditorHeader
          uniqueId={props.uniqueId}
          messageText={text}
          showEmoji={showEmoji}
          setShowEmoji={setShowEmoji}
          showVariableDropdown={showVariableDropdown}
          showEmojiAlso={props.showEmojiAlso}
          setShowVariableDropdown={() => {
            setShowVariableDropdown(!showVariableDropdown);
            const divPosition = (
              document.getElementById(props.uniqueId) as HTMLElement
            )?.getBoundingClientRect();
            let position = {};
            if (window.innerWidth - divPosition.right <= 480) {
              position = {
                right: divPosition.left - 524,
              };
            } else {
              position = {
                right: divPosition.right,
              };
            }
            setRtePosition(position);
          }}
          error={error as string}
          setError={setError}
          showVariableOnly={props.showVariableOnly as boolean}
          hideWordLimit={props.hideWordLimit as boolean}
          wordLimit={props.customWordCount ? props.customWordCount : 1024}
          errorText={props.errorText}
          subText={props.subText}
          isCarousel={props.isCarousel}
          format={highlight}
          setFormat={FormatSetter}
          showFontSize={props.showFontSize || false}
          hideAddVariables={props.hideAddVariables || false}
          customWordCountPosition={props.customWordCountPosition || false}
        />
        {showEmoji && focusEmoji && (
          <EmojiPicker
            height={500}
            width={400}
            onEmojiClick={(emojiData, onclick) =>
              onEmojiClick(emojiData, onclick)
            }
          />
        )}
        {showVariableDropdown && focusVariable && (
          <div
            style={{ position: "relative", zIndex: 1 }}
            onClick={(e) => e.stopPropagation()}
          >
            <Floater
              refElement={superRef}
              children={
                <div style={{ height: 500 }}>
                  <VariablePicker
                    onClose={() => setShowVariableDropdown(false)}
                    type={PickerType.FLOATER}
                    data={variablesList}
                    onSelect={(variable) => {
                      setVariableValue(variable.actualValue);
                      eventHelper.trackEvent(
                        TargetPlatform.Amplitude,
                        AmplitudeKeys.service_used,
                        {
                          step: AmplitudeEventStep.variable_used,
                          variableName: variable.displayName,
                          screen: "journey_builder",
                        }
                      );
                    }}
                  />
                </div>
              }
            />
          </div>
        )}
      </div>
    </RichTextEditorStyled>
  );
};

export default RichTextEditor;
