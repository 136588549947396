import { Icon } from "./icons-props";

const ReceiptIcon: Icon = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2026_5321)">
        <path
          d="M8 5C7.44772 5 7 5.44772 7 6C7 6.55228 7.44772 7 8 7H16C16.5523 7 17 6.55228 17 6C17 5.44772 16.5523 5 16 5H8Z"
          fill="white"
        />
        <path
          d="M7 10C7 9.44772 7.44772 9 8 9H14C14.5523 9 15 9.44772 15 10C15 10.5523 14.5523 11 14 11H8C7.44772 11 7 10.5523 7 10Z"
          fill="white"
        />
        <path
          d="M8 13C7.44772 13 7 13.4477 7 14C7 14.5523 7.44772 15 8 15H12C12.5523 15 13 14.5523 13 14C13 13.4477 12.5523 13 12 13H8Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6 1C4.34315 1 3 2.34315 3 4V23C3 23.4045 3.24364 23.7691 3.61732 23.9239C3.99099 24.0787 4.42111 23.9931 4.70711 23.7071L8 20.4142L11.2929 23.7071C11.6834 24.0976 12.3166 24.0976 12.7071 23.7071L16 20.4142L19.2929 23.7071C19.5789 23.9931 20.009 24.0787 20.3827 23.9239C20.7564 23.7691 21 23.4045 21 23V4C21 2.34315 19.6569 1 18 1H6ZM5 4C5 3.44772 5.44772 3 6 3H18C18.5523 3 19 3.44772 19 4V20.5858L16.7071 18.2929C16.3166 17.9024 15.6834 17.9024 15.2929 18.2929L12 21.5858L8.70711 18.2929C8.31658 17.9024 7.68342 17.9024 7.29289 18.2929L5 20.5858V4Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2026_5321">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ReceiptIcon;
