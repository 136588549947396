import { Icon } from "./icons-props";

const ImageIcon: Icon = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 21 20"
      fill={color || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.41618 5.00008C6.26559 5.00008 5.33285 5.93282 5.33285 7.08342C5.33285 8.23401 6.26559 9.16675 7.41618 9.16675C8.56677 9.16675 9.49951 8.23401 9.49951 7.08342C9.49951 5.93282 8.56677 5.00008 7.41618 5.00008ZM6.99951 7.08342C6.99951 6.8533 7.18606 6.66675 7.41618 6.66675C7.6463 6.66675 7.83285 6.8533 7.83285 7.08342C7.83285 7.31353 7.6463 7.50008 7.41618 7.50008C7.18606 7.50008 6.99951 7.31353 6.99951 7.08342Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6662 15.8334C18.6662 17.2141 17.5469 18.3334 16.1662 18.3334H4.49852C3.11826 18.3329 1.99951 17.2138 1.99951 15.8334V4.16675C1.99951 2.78604 3.1188 1.66675 4.49951 1.66675H16.1662C17.5469 1.66675 18.6662 2.78604 18.6662 4.16675V15.8334ZM4.49951 3.33341C4.03927 3.33341 3.66618 3.70651 3.66618 4.16675V15.8334C3.66618 16.1908 3.8911 16.4956 4.20709 16.614L13.0769 7.74416C13.4024 7.41872 13.93 7.41872 14.2554 7.74416L16.9995 10.4882V4.16675C16.9995 3.70651 16.6264 3.33341 16.1662 3.33341H4.49951ZM16.9995 15.8334V12.8453L13.6662 9.51193L6.51136 16.6667H16.1662C16.6264 16.6667 16.9995 16.2937 16.9995 15.8334Z"
      />
    </svg>
  );
};

export default ImageIcon;
