import styled from "styled-components";

export const VariableStyle = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  flex-wrap: wrap;

  .page_title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }

  .var_list_title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
  }

  .cards-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }

  .card-table {
    border-collapse: collapse;
    border-spacing: 0 8px;
    width: 100%;
  }

  .variable-card {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
  }

  .variable_name {
    padding: 22px 12px 22px 12px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .variable_type {
    padding: 22px 12px 22px 12px;
  }

  .variable_value {
    padding: 22px 12px 22px 12px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .variable_actions {
    flex-basis: 18%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 12px;

    &&__icon_wrapper {
    }

    svg {
      outline: 1px solid #e0e0e0;
      padding: 5px;
    }
  }
`;
