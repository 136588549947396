import { Viewport } from "reactflow";

/**
 * Getter for Viewport in local storage.
 */
export const getViewPortFromLocalStorage = (
  storeId: string,
  flowId: string,
  defaultViewport = { x: 10, y: 15, zoom: 1 }
) => {
  const viewPort = localStorage.getItem(`${storeId}_${flowId}_viewport`);
  return viewPort ? (JSON.parse(viewPort) as Viewport) : defaultViewport;
};

/**
 * Setter for Viewport in local storage
 */
export const setViewPortToLocalStorage = (
  storeId: string,
  flowId: string,
  viewport: Viewport
) => {
  localStorage.setItem(
    `${storeId}_${flowId}_viewport`,
    JSON.stringify(viewport)
  );
};

/**
 * Getter for Do not remind me for Escape to close in local storage.
 */
export const getDoNotRemindMeForEscapeToCloseFromLocalStorage = (
  storeId: string,
  flowId: string
) => {
  return localStorage.getItem(`${storeId}_${flowId}_doNotRemindMe}`);
};

/**
 * Setter for Do not remind me for Escape to close in local storage.
 * */

export const setDoNotRemindMeForEscapeToCloseFromLocalStorage = (
  storeId: string,
  flowId: string,
  doNotRemindMe: string
) => {
  localStorage.setItem(`${storeId}_${flowId}_doNotRemindMe}`, doNotRemindMe);
};
