import { cloneDeep } from "lodash";

export const populate_microflow_conf = (template: any, data_obj: any) => {
  const templateCopy = cloneDeep(template);
  Object.entries(data_obj).forEach(([path, data]) => {
    const pathSegments = path.split(".");
    let tail = templateCopy;
    for (let i = 0; i < pathSegments.length; i++) {
      let segment: any = pathSegments[i];
      if (segment.includes("[") && segment.includes("]")) {
        segment = Number(segment.substring(1, segment.length - 1));
      }
      // console.log("segment", segment);
      if (i == pathSegments.length - 1) {
        tail[segment] = data;
      } else {
        tail = tail[segment];
      }
      // console.log("tail", tail);
    }
  });
  return templateCopy;
};

export const getNodeIdFromPath = (template: any, path: string) => {
  const templateCopy = cloneDeep(template);
  const pathSegments = path.split(".");
  let tail = templateCopy;
  for (let i = 0; i < pathSegments.length; i++) {
    let segment: any = pathSegments[i];
    if (segment.includes("[") && segment.includes("]")) {
      segment = Number(segment.substring(1, segment.length - 1));
    }
    if (i == pathSegments.length - 1) {
      return tail[segment];
    } else {
      tail = tail[segment];
    }
  }
};

export const reparseMicroFlowConf = (
  template: any,
  configurablePaths: string[],
  withKeys: boolean = false
) => {
  let parsedData: any = [];
  if (withKeys) {
    parsedData = {};
  }
  for (let path of configurablePaths) {
    const pathSegments = path.split(".");
    let tail = template;
    for (let i = 0; i < pathSegments.length; i++) {
      let segment: any = pathSegments[i];
      if (segment.includes("[") && segment.includes("]")) {
        segment = Number(segment.substring(1, segment.length - 1));
      }
      tail = tail[segment];
    }
    if (withKeys) {
      parsedData[path] = tail;
    } else {
      parsedData.push(tail);
    }
  }
  return parsedData;
};
