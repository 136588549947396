const TearsOfJoy = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M15.9281 29.9128C9.07084 29.9128 1.67285 25.6117 1.67285 16.1737C1.67285 6.73573 9.07084 2.43457 15.9281 2.43457C19.7377 2.43457 23.2524 3.68805 25.8577 5.97381C28.6841 8.48077 30.1834 12.02 30.1834 16.1737C30.1834 20.3274 28.6841 23.8421 25.8577 26.349C23.2524 28.6348 19.7131 29.9128 15.9281 29.9128Z"
        fill="url(#paint0_radial_1584_28432)"
      />
      <path
        d="M27.6984 8.00684C29.0084 10.1205 29.6917 12.6373 29.6917 15.4368C29.6917 19.5905 28.1924 23.1051 25.366 25.6121C22.7607 27.8979 19.2215 29.1759 15.4364 29.1759C10.9976 29.1759 6.34255 27.3694 3.59473 23.5377C6.23933 27.8782 11.2139 29.9133 15.928 29.9133C19.713 29.9133 23.2523 28.6352 25.8575 26.3494C28.684 23.8425 30.1833 20.3278 30.1833 16.1741C30.1833 13.0429 29.3304 10.2606 27.6984 8.00684Z"
        fill="#EB8F00"
      />
      <path
        d="M9.99002 8.38518C9.88187 8.12466 9.58694 7.99193 9.32149 8.08779C8.92333 8.21805 8.53991 8.38764 8.1737 8.59164C7.20041 9.12744 6.36721 9.8869 5.74293 10.8037C5.57088 11.0372 5.61512 11.364 5.8437 11.541C5.96413 11.6418 6.12143 11.6909 6.27873 11.6713C6.39671 11.6541 6.50485 11.5975 6.58841 11.5115C7.53221 10.5874 8.58907 9.78613 9.73195 9.12744L9.75898 9.11269C10.0195 8.9726 10.1227 8.65063 9.99002 8.38518Z"
        fill="#422B0D"
      />
      <path
        d="M22.5863 8.13909C22.6944 7.87856 22.9893 7.74584 23.2548 7.84169C23.6505 7.9695 24.0339 8.13417 24.4001 8.33326C25.3734 8.86906 26.2066 9.62606 26.8333 10.5453C27.0029 10.7788 26.9587 11.1032 26.7326 11.2826C26.6121 11.3834 26.4548 11.4301 26.2975 11.4129C26.1796 11.3957 26.0714 11.3392 25.9879 11.2531C25.0441 10.329 23.9872 9.52775 22.8443 8.86906L22.8173 8.85431C22.5641 8.71422 22.4609 8.40207 22.5863 8.13909Z"
        fill="#422B0D"
      />
      <path
        d="M25.3833 18.317C25.0392 17.6952 24.292 17.4224 23.6284 17.6731C21.1067 18.408 18.4916 18.7742 15.8642 18.7619C13.2368 18.7742 10.6217 18.408 8.09997 17.6731C7.43882 17.4224 6.69165 17.6928 6.34756 18.3121C6.0133 18.929 6.25416 19.6443 6.53681 20.2612C8.11472 23.7267 11.5925 25.8011 15.8445 25.8133H15.8838C20.1358 25.8133 23.6136 23.7267 25.194 20.2612C25.4742 19.6393 25.7175 18.9339 25.3833 18.317Z"
        fill="#422B0D"
      />
      <path
        d="M19.799 24.8353C19.7081 24.7518 19.6147 24.6756 19.5213 24.5896C18.5185 23.7072 17.2208 23.2328 15.8837 23.2599C14.5074 23.2378 13.1679 23.6949 12.0938 24.5551C12.0004 24.6313 11.9021 24.7051 11.8111 24.8009C11.7202 24.8968 11.671 24.9656 11.6121 25.0467C12.9639 25.5751 14.4041 25.8455 15.8567 25.8381H15.896C17.2871 25.8381 18.666 25.5899 19.9686 25.1008C19.9194 25.0074 19.8629 24.9189 19.799 24.8353Z"
        fill="#ED7770"
      />
      <path
        d="M23.6283 17.6732C21.1066 18.408 18.4914 18.7743 15.8641 18.762C13.2367 18.7743 10.6216 18.408 8.09985 17.6732C7.4387 17.4225 6.69153 17.6928 6.34743 18.3122C6.29828 18.4056 6.25895 18.5039 6.23438 18.6047C6.31548 18.6464 6.40888 18.6882 6.51948 18.7325C9.47868 20.2145 12.75 20.9666 16.0582 20.9273C19.2312 20.9642 22.3674 20.2735 25.2332 18.9119C25.3487 18.8627 25.4446 18.816 25.5306 18.7718C25.5109 18.612 25.4593 18.4547 25.3831 18.3122C25.039 17.6928 24.2919 17.42 23.6283 17.6732Z"
        fill="white"
      />
      <path
        d="M25.3931 18.3195C25.0441 17.6977 24.2945 17.4224 23.6259 17.6731C21.1042 18.408 18.4891 18.7742 15.8642 18.7619C13.2368 18.7742 10.6217 18.408 8.09997 17.6731C7.43882 17.4224 6.69165 17.6928 6.34756 18.3121C6.0133 18.929 6.25416 19.6443 6.53681 20.2612C6.66216 20.5389 6.80225 20.8117 6.95709 21.0747C6.95709 21.0747 6.44095 19.1625 6.88828 18.6071C7.04066 18.3711 7.29627 18.2236 7.57646 18.2089C7.69198 18.2089 7.80504 18.2286 7.91564 18.263C10.4816 19.0175 13.1434 19.3985 15.8199 19.3936H15.906C18.5825 19.3985 21.2443 19.0175 23.8103 18.263C23.9209 18.2286 24.0339 18.2089 24.1494 18.2089C24.4296 18.2236 24.6877 18.3711 24.8401 18.6071C25.2948 19.1625 24.7713 21.0821 24.7713 21.0821C24.9236 20.8191 25.076 20.5487 25.2038 20.2685C25.484 19.6516 25.7274 18.9389 25.3931 18.3195Z"
        fill="#EB8F00"
      />
      <path
        d="M25.0439 14.7461C25.0439 14.7461 29.2222 14.8616 30.8911 17.9412C31.5891 19.234 31.6284 21.3822 30.1832 22.1662C28.4357 23.1272 26.7964 21.7631 26.5874 20.3081C26.0246 16.3609 25.0439 14.7461 25.0439 14.7461Z"
        fill="#29B6F6"
      />
      <path
        d="M30.5912 20.6884C30.4266 20.9882 30.0653 21.1185 29.7482 20.9932C29.4656 20.8432 29.3894 20.4819 29.3451 20.1649L29.21 19.1818C29.1534 18.791 28.7553 17.5252 29.1731 17.3187C29.5049 17.1565 30.1415 17.6038 30.3283 17.8619C30.7387 18.4469 30.9329 20.1133 30.5912 20.6884Z"
        fill="#81D4FA"
      />
      <path
        d="M29.1731 17.3314C29.5049 17.1692 30.1415 17.6165 30.3283 17.8746C30.7387 18.4473 30.9329 20.1137 30.5913 20.6888C30.4266 20.9886 30.0653 21.1189 29.7482 20.9936C29.4656 20.8436 29.3894 20.4823 29.3451 20.1653L29.21 19.1822"
        fill="#81D4FA"
      />
      <path
        d="M7.00866 14.7461C7.00866 14.7461 2.83039 14.8616 1.16154 17.9412C0.463523 19.2218 0.436487 21.36 1.86939 22.1539C3.61689 23.1149 5.25624 21.7508 5.46516 20.2958C6.02799 16.3609 7.00866 14.7461 7.00866 14.7461Z"
        fill="#29B6F6"
      />
      <path
        d="M1.46146 20.6884C1.62613 20.9882 1.98743 21.1185 2.30448 20.9932C2.58713 20.8432 2.66332 20.4819 2.70756 20.1649L2.84274 19.1818C2.89927 18.791 3.29744 17.5252 2.87961 17.3187C2.54781 17.1565 1.91123 17.6038 1.72444 17.8619C1.31399 18.4469 1.11982 20.1133 1.46146 20.6884Z"
        fill="#81D4FA"
      />
      <path
        d="M12.1308 14.4459L12.0939 14.3919L12.0423 14.3255C12.0226 14.2985 12.003 14.2764 11.9784 14.2542L11.8285 14.1018C11.7645 14.0379 11.6933 13.974 11.6146 13.9077C11.4524 13.7676 11.2705 13.6545 11.0739 13.566C10.8847 13.4825 10.6782 13.4382 10.4717 13.4382H10.5135H10.3734C10.3489 13.4358 10.3267 13.4358 10.3022 13.4382C10.2579 13.4432 10.2137 13.4505 10.1694 13.4604C10.0687 13.4849 9.96789 13.5169 9.87204 13.5562C9.67542 13.6422 9.49354 13.7578 9.33132 13.8978C9.1986 14.0084 9.07325 14.1289 8.95774 14.2591L8.81764 14.4164L8.74391 14.5C8.44651 14.8392 7.93283 14.8736 7.59365 14.5787C7.3405 14.3575 7.24956 14.0011 7.36262 13.684L7.38966 13.6054C7.41178 13.5538 7.43144 13.4751 7.47814 13.3817C7.5912 13.1163 7.73867 12.8631 7.91563 12.6345C8.34574 12.0668 8.95036 11.6539 9.63609 11.4572C9.77619 11.4204 9.91874 11.3933 10.0637 11.3737C10.1375 11.3737 10.2456 11.3516 10.2776 11.3516H10.4644C10.7421 11.3565 11.0198 11.3958 11.2902 11.4646C11.5483 11.5359 11.794 11.6391 12.0275 11.7718C12.4085 11.9955 12.7428 12.2904 13.0107 12.6419C13.1925 12.8803 13.3474 13.1384 13.4678 13.4137C13.4875 13.453 13.5047 13.4923 13.5145 13.5341C13.5268 13.566 13.5342 13.5931 13.5415 13.6152L13.5637 13.6816C13.7038 14.1166 13.4654 14.5836 13.0303 14.7237C12.7034 14.8294 12.3421 14.7212 12.1283 14.4533L12.1308 14.4459Z"
        fill="#422B0D"
      />
      <path
        d="M23.272 14.4459L23.2278 14.3919L23.1762 14.3255C23.1565 14.2985 23.1368 14.2764 23.1122 14.2542L22.9599 14.1018C22.8935 14.033 22.8222 13.9691 22.7485 13.9077C22.5863 13.7676 22.4044 13.6545 22.2078 13.566C22.0185 13.4825 21.8121 13.4382 21.6056 13.4382H21.6474H21.5048C21.4803 13.4358 21.4581 13.4358 21.4336 13.4382C21.3893 13.4432 21.3451 13.4505 21.3008 13.4604C21.2001 13.4849 21.0993 13.5169 21.0035 13.5562C20.8044 13.6472 20.6176 13.7651 20.4504 13.9077C20.3177 14.0207 20.1948 14.1412 20.0793 14.269L19.9392 14.4263L19.8655 14.5098C19.5681 14.849 19.0544 14.8834 18.7152 14.5885C18.4621 14.3673 18.3711 14.0109 18.4842 13.6939L18.5112 13.6152C18.5334 13.5636 18.553 13.4849 18.5973 13.3915C18.7152 13.1212 18.8652 12.868 19.047 12.6345C19.4772 12.0668 20.0818 11.6539 20.7675 11.4572C20.9076 11.4204 21.0502 11.3933 21.1952 11.3737C21.2689 11.3737 21.377 11.3516 21.409 11.3516H21.5958C21.8735 11.3565 22.1512 11.3958 22.4216 11.4646C22.6772 11.5384 22.923 11.6416 23.154 11.7743C23.535 11.9955 23.8693 12.2929 24.1372 12.6444C24.2404 12.7771 24.3313 12.9172 24.4149 13.0622C24.4812 13.1777 24.5402 13.2957 24.5943 13.4161C24.614 13.4554 24.6287 13.4948 24.641 13.5366C24.641 13.5685 24.6607 13.5955 24.668 13.6177L24.6902 13.684C24.8303 14.1191 24.5918 14.586 24.1568 14.7261C23.8299 14.8318 23.4686 14.7237 23.2548 14.4558L23.272 14.4459Z"
        fill="#422B0D"
      />
      <defs>
        <radialGradient
          id="paint0_radial_1584_28432"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(15.9281 16.1737) scale(13.9996)"
        >
          <stop offset="0.5" stop-color="#FDE030" />
          <stop offset="0.92" stop-color="#F7C02B" />
          <stop offset="1" stop-color="#F4A223" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default TearsOfJoy;
