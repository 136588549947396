const AlertTriangle = (props: any) => {
  const { width, height, color } = props;
  return (
    <svg
      width={width || "41"}
      height={height || "40"}
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.047 3.80518C18.7958 3.38359 19.6406 3.16211 20.5 3.16211C21.3593 3.16211 22.2041 3.38359 22.9529 3.80518C23.7018 4.22677 24.3293 4.83424 24.775 5.56897L24.7798 5.57688L38.8964 29.1436L38.9099 29.1666C39.3465 29.9226 39.5775 30.7798 39.58 31.6529C39.5824 32.5259 39.3562 33.3844 38.9239 34.1429C38.4915 34.9014 37.8681 35.5335 37.1157 35.9762C36.3632 36.419 35.5079 36.657 34.6349 36.6666L34.6166 36.6668L6.36497 36.6667C5.49196 36.6571 4.63669 36.419 3.88424 35.9762C3.13178 35.5335 2.50837 34.9014 2.07604 34.1429C1.6437 33.3844 1.4175 32.5259 1.41994 31.6529C1.42239 30.7798 1.65339 29.9226 2.08997 29.1666L2.10351 29.1436L16.2202 5.5769L16.225 5.56897C16.6706 4.83424 17.2981 4.22677 18.047 3.80518ZM19.077 7.29441L4.9711 30.8431C4.82914 31.0927 4.75407 31.3749 4.75326 31.6622C4.75245 31.9532 4.82785 32.2394 4.97196 32.4922C5.11607 32.7451 5.32388 32.9557 5.57469 33.1033C5.82332 33.2496 6.10561 33.3289 6.39395 33.3334H34.606C34.8943 33.3289 35.1766 33.2496 35.4252 33.1033C35.676 32.9557 35.8838 32.745 36.0279 32.4922C36.1721 32.2394 36.2475 31.9532 36.2466 31.6622C36.2458 31.3749 36.1708 31.0928 36.0289 30.8431L21.925 7.29773L21.9229 7.29441C21.7745 7.05099 21.5661 6.84969 21.3176 6.7098C21.068 6.56927 20.7864 6.49544 20.5 6.49544C20.2135 6.49544 19.9319 6.56927 19.6823 6.7098C19.4338 6.84969 19.2254 7.05099 19.077 7.29441ZM20.5003 13.3334C21.4208 13.3334 22.167 14.0796 22.167 15V21.6667C22.167 22.5872 21.4208 23.3334 20.5003 23.3334C19.5798 23.3334 18.8337 22.5872 18.8337 21.6667V15C18.8337 14.0796 19.5798 13.3334 20.5003 13.3334ZM20.5003 26.6667C19.5798 26.6667 18.8337 27.4129 18.8337 28.3334C18.8337 29.2538 19.5798 30 20.5003 30H20.517C21.4375 30 22.1837 29.2538 22.1837 28.3334C22.1837 27.4129 21.4375 26.6667 20.517 26.6667H20.5003Z"
        fill={color || "#9E9E9E"}
      />
    </svg>
  );
};

export default AlertTriangle;
