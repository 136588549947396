import { Icon } from "./icons-props";

const AnalyticsIcon: Icon = (props) => {
  const { width, height, color, backgroundColor } = props;
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_517_20440)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.25001 3.75C3.25001 3.33579 2.91422 3 2.50001 3C2.0858 3 1.75001 3.33579 1.75001 3.75V12.4964C1.75 12.499 1.75 12.5016 1.75001 12.5042V16.25C1.75001 16.6642 2.0858 17 2.50001 17H17.5C17.9142 17 18.25 16.6642 18.25 16.25C18.25 15.8358 17.9142 15.5 17.5 15.5H3.25001V12.8403L7.53637 9.08977L12.05 12.475C12.3335 12.6876 12.7272 12.6728 12.9939 12.4394L17.9939 8.06443C18.3056 7.79167 18.3372 7.31785 18.0644 7.00612C17.7917 6.69439 17.3179 6.66281 17.0061 6.93557L12.4637 10.9102L7.95001 7.525C7.6665 7.31236 7.27284 7.3272 7.00613 7.56057L3.25001 10.8472V3.75Z"
          fill="#731DCF"
        />
      </g>
      <defs>
        <clipPath id="clip0_517_20440">
          <rect
            width={width || "20"}
            height={height || "20"}
            fill={color || "white"}
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AnalyticsIcon;
