import DebugRuns from "../../components/analytics/runs/DebugRuns";
import CampaignDashboard from "../campaign/CampaignDashboard";
import ScopesDashboard from "../growth/ScopesDashboard";
import LogSearch from "../integrations/LogSearch";
import ProductsDashboard from "../integrations/ProductsDashboard";
import OnboardingDashboard from "../growth/OnboardingDashboard";

export interface FeatureProps {
  redirectLink: string;
  podName: string;
  color: string;
  searchKeys?: string[];
  optionalKeys?: string;
  POD_COMPONENT?: any;
}

export const BASE_ROUTES: FeatureProps[] = [
  {
    redirectLink: "/v2/admin/campaign",
    podName: "Campaign",
    color: "darkred",
  },
  {
    redirectLink: "/v2/admin/crm",
    podName: "CRM",
    color: "darkred",
  },
  {
    redirectLink: "/v2/admin/chatbot",
    podName: "Chatter bot",
    color: "blue",
  },
  {
    redirectLink: "/v2/admin/growth",
    podName: "Growth / PLG ",
    color: "darkred",
  },
  {
    redirectLink: "/v2/admin/integrations",
    podName: "Integrations",
    color: "blue",
  },
  {
    redirectLink: "/v2/admin/widgets",
    podName: "Widgets",
    color: "blue",
  },
];

const EMPTY_HONME = () => {
  return "EMPTY";
};

export const GROWTH_FEATURE_BOXES: FeatureProps[] = [
  {
    redirectLink: "/v2/admin/growth/onboarding",
    podName: "Health dashboard",
    color: "darkred",
    POD_COMPONENT: OnboardingDashboard,
  },
  {
    redirectLink: "/v2/admin/growth/scopes",
    podName: "Scope addition",
    color: "darkred",
    POD_COMPONENT: ScopesDashboard,
  },
  {
    redirectLink: "/v2/admin/growth",
    podName: "Home page",
    color: "darkred",
    POD_COMPONENT: ScopesDashboard,
  },
];

export const CAMPAIGN_FEATURE_BOXES: FeatureProps[] = [
  {
    redirectLink: "/v2/admin/campaign",
    podName: "Customer Search",
    color: "blue",
    POD_COMPONENT: CampaignDashboard,
  },
];

export const CRM_FEATURE_BOXES: FeatureProps[] = [];

export const CHATBOT_FEATURE_BOXES: FeatureProps[] = [
  {
    redirectLink: "/v2/admin/chatbot",
    podName: "Chatter bot",
    color: "blue",
    POD_COMPONENT: DebugRuns,
  },
];

export const INTEGRATIONS_FEATURE_BOXES: FeatureProps[] = [
  {
    redirectLink: "/v2/admin/integrations/log_search",
    podName: "Log Search",
    color: "darkred",
    POD_COMPONENT: LogSearch,
  },
  {
    redirectLink: "/v2/admin/integrations",
    podName: "Home page",
    color: "darkred",
    POD_COMPONENT: LogSearch,
  },
  {
    redirectLink: "/v2/admin/integrations/internal_products",
    podName: "Internal products",
    color: "darkred",
    POD_COMPONENT: ProductsDashboard,
  },
];

export const WIDGETS_FEATURE_BOXES: FeatureProps[] = [
  {
    redirectLink: "/v2/admin/widgets",
    podName: "Cache Disabling",
    color: "darkred",
    POD_COMPONENT: EMPTY_HONME,
  },
];

export const LINK_FEATURE_MAP = {
  growth: GROWTH_FEATURE_BOXES,
  campaign: CAMPAIGN_FEATURE_BOXES,
  crm: CRM_FEATURE_BOXES,
  chatbot: CHATBOT_FEATURE_BOXES,
  integrations: INTEGRATIONS_FEATURE_BOXES,
  widgets: WIDGETS_FEATURE_BOXES,
};

export const FEATURE_BOXES: FeatureProps[] = [
  ...INTEGRATIONS_FEATURE_BOXES,
  ...CAMPAIGN_FEATURE_BOXES,
  ...CHATBOT_FEATURE_BOXES,
  ...CRM_FEATURE_BOXES,
  ...GROWTH_FEATURE_BOXES,
  ...WIDGETS_FEATURE_BOXES,
];
