import { Icon } from "./icons-props";

const AtIcon: Icon = (props) => {
  const { height, width, color } = props;
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      fill={color || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="mingcute:at-line" clip-path="url(#clip0_1556_19280)">
        <g id="Group">
          <path
            id="Vector"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4 12C4 9.87827 4.84285 7.84344 6.34315 6.34315C7.84344 4.84285 9.87827 4 12 4C17.367 4 20.445 8.445 20.006 12.39C19.886 13.476 19.568 14.113 19.286 14.485C19.005 14.858 18.715 15.025 18.553 15.105C17.8 15.482 17.42 15.317 17.27 15.198C17.08 15.048 16.898 14.695 16.986 14.164L16.992 14.132L17.495 9.1C17.5181 8.87135 17.4618 8.6417 17.3355 8.44971C17.2091 8.25772 17.0205 8.1151 16.8014 8.04585C16.5823 7.9766 16.346 7.98494 16.1322 8.06948C15.9185 8.15401 15.7405 8.30958 15.628 8.51C15.1593 8.03112 14.5995 7.65082 13.9816 7.3915C13.3636 7.13218 12.7001 6.99907 12.03 7C9.279 7 7 9.229 7 12C7 14.774 9.288 17 12.038 17C13.25 17 14.388 16.564 15.275 15.824C15.45 16.184 15.7 16.506 16.028 16.766C16.945 17.492 18.2 17.518 19.448 16.894C19.785 16.726 20.358 16.384 20.882 15.691C21.406 14.997 21.838 14.009 21.994 12.611C22.556 7.554 18.633 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C13.5422 22.002 15.0638 21.646 16.445 20.96C16.6805 20.8408 16.8593 20.6333 16.9424 20.3828C17.0256 20.1324 17.0064 19.8591 16.8889 19.6228C16.7714 19.3864 16.5653 19.2061 16.3154 19.1211C16.0656 19.0361 15.7922 19.0533 15.555 19.169C14.3351 19.7741 12.981 20.0578 11.6208 19.9933C10.2607 19.9287 8.93949 19.5181 7.7824 18.8002C6.62531 18.0823 5.67061 17.081 5.00871 15.891C4.34681 14.701 3.99961 13.3617 4 12ZM9 12C9 10.353 10.364 9 12.03 9C13.95 9 15.394 10.767 15.004 12.62C14.713 13.998 13.465 15 12.038 15C10.368 15 9 13.645 9 12Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1556_19280">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AtIcon;
