import { COLORS } from "@bikdotai/bik-component-library";
import styled from "styled-components";

const StyledCard = styled.div<{ hasError?: boolean; hasWarning?: boolean }>`
  display: flex;
  background-color: ${COLORS.surface.standard};
  border: 1px solid
    ${(props) =>
      props.hasError
        ? `${COLORS.content.negative}`
        : props.hasWarning
          ? `#FEC02D`
          : `${COLORS.stroke.primary}`};
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
  position: relative;
  padding: 8px 13px;
  text-align: start;
  margin: 8px 0px;
  position: relative;
  .cross-icon {
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -10px;
    right: -5px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: ${COLORS.surface.subdued};
    border: 1px solid ${COLORS.content.inactive};
  }
  &:hover {
    border: 1px solid ${COLORS.content.inactive};
    .cross-icon {
      display: flex;
    }
  }
`;

const StyledMediaCard = styled.div<{
  imageUrl: string;
  index: number;
  remainingCount: number;
  mediaType?: "post" | "story" | "reel";
}>`
  width: ${(props) => (props.mediaType === "post" ? 48 : 32)}px;
  height: 48px;
  background-image: url(${(props) => props.imageUrl});
  background-size: cover;
  border-radius: 4px;
  margin-left: ${(props) => (props.index === 0 ? " 0px" : "6px")};
  border: 1px solid ${COLORS.stroke.primary};
  position: relative;
  ${(props) =>
    props.index === (props.mediaType === "post" ? 5 : 7) && props.remainingCount
      ? `
      &::before {
        content: '+${props.remainingCount.toString()}';
        position: absolute;
        
        width: ${props.mediaType === "post" ? 46 : 30}px;
        height: 46px;
        border-radius: 3px;
        background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        color: white;
        z-index: 1;
      }
    `
      : ""}
`;

const MediaContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 6px;
`;

export { StyledCard, StyledMediaCard, MediaContainer };
