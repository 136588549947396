import { useEffect, useState } from "react";
import { BodyCaption, TitleRegular } from "../../TypographyStyle";
import { ListItem } from "@bikdotai/bik-component-library";
import DynamicIcon from "../../icons/dynamicIcon";
import { ParentData, PickerData } from ".";
import CaratDown from "../../icons/caratDown";
import CheckIcon from "../../icons/checkIcon";
import { RowCardStyle, ColumnCardStyle, CheckBoxStyle } from "./style";

export interface RowCardInterface {
  leftElement?: any;
  rightElement?: any;
  elements?: Array<any>;
  width: string;
  paddingRight?: string;
  paddingLeft?: string;
  paddingTop?: string;
  paddingBottom?: string;
  onClickHandler?: () => void;
  backgroundColor?: string;
  justifyContent?: string;
  alignItems?: string;
  border?: boolean;
  height: string;
}

export interface ColumnCardInterface {
  elements: Array<any>;
  height: string;
  paddingRight?: string;
  paddingLeft?: string;
  paddingTop?: string;
  paddingBottom?: string;
  onClickHandler?: () => void;
}

export const truncate = (str: string, n: number, useWordBoundary: boolean) => {
  if (str.length <= n) {
    return str;
  }
  const subString = str.slice(0, n - 1); // the original check
  return (
    (useWordBoundary
      ? subString.slice(0, subString.lastIndexOf(" "))
      : subString) + "..."
  );
};

export const RowCard = (props: RowCardInterface) => {
  const {
    width,
    leftElement,
    rightElement,
    elements,
    paddingRight,
    paddingLeft,
    paddingBottom,
    paddingTop,
    backgroundColor,
    onClickHandler,
    justifyContent,
    alignItems,
    border,
    height,
  } = props;

  return (
    <RowCardStyle
      height={height}
      width={width}
      paddingBottom={paddingBottom}
      paddingRight={paddingRight}
      paddingLeft={paddingLeft}
      paddingTop={paddingTop}
      onClick={onClickHandler}
      backgroundColor={backgroundColor}
      justifyContent={justifyContent}
      alignItems={alignItems}
      border={border}
    >
      {leftElement && <div id="left">{leftElement}</div>}
      {elements?.map((element) => {
        return <div className="card_elements">{element}</div>;
      })}
      {rightElement && <div id="right">{rightElement}</div>}
    </RowCardStyle>
  );
};

export const ColumnCard = (props: ColumnCardInterface) => {
  const {
    height,
    elements,
    paddingRight,
    paddingLeft,
    paddingBottom,
    paddingTop,
  } = props;
  return (
    <ColumnCardStyle
      height={height}
      paddingBottom={paddingBottom}
      paddingRight={paddingRight}
      paddingLeft={paddingLeft}
      paddingTop={paddingTop}
    >
      {elements.map((element) => {
        return <div className="card_elements">{element}</div>;
      })}
    </ColumnCardStyle>
  );
};

export const CheckBox = (props: any) => {
  const { isSelected } = props;
  return (
    <CheckBoxStyle selected={isSelected}>
      {isSelected && <CheckIcon height={12} width={12} color="#FFFFFF" />}
    </CheckBoxStyle>
  );
};

export interface PickerSectionInterface {
  width: string;
  onItemsPicked: (groupData: any, varaintId: string) => void;
  data: PickerData;
  isItemPicked: (parentId: string, varaintId: string) => boolean;
  canSelect: () => boolean;
}

export const PickerSection = (props: PickerSectionInterface) => {
  const { width, onItemsPicked, data, isItemPicked, canSelect } = props;

  return (
    <div>
      <ListItem
        style={{
          cursor: "default",
        }}
        listItem={{
          label: "",
          value: "",
        }}
        variant={"separator"}
        subText="Or select products from the list"
      />
      <div>
        {Object.keys(data).map((parentId) => {
          return (
            <div key={parentId}>
              <CardDropdown
                data={data[parentId]}
                onItemsPicked={onItemsPicked}
                isItemPicked={isItemPicked}
                canSelect={canSelect}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export interface CardDropdownInterface {
  onItemsPicked: (groupData: any, varaintId: string) => void;
  data: ParentData;
  isItemPicked: (parentId: string, varaintId: string) => boolean;
  canSelect: () => boolean;
}

export const CardDropdown = (props: CardDropdownInterface) => {
  const { onItemsPicked, data, isItemPicked, canSelect } = props;
  const [enabled, setEnabled] = useState<boolean>(false);
  const [imageAvailable, setImageAvailable] = useState<boolean>(true);

  const onCardChange = (variantId: string) => {
    onItemsPicked(data, variantId);
  };

  return (
    <div>
      <div onClick={() => setEnabled(!enabled)}>
        <RowCard
          width={"100%"}
          height={"92px"}
          leftElement={
            imageAvailable && data.imageUrl ? (
              <img
                style={{
                  width: "40px",
                  height: "40px",
                }}
                onError={() => setImageAvailable(false)}
                src={data.imageUrl}
              />
            ) : (
              <DynamicIcon />
            )
          }
          rightElement={<CaratDown />}
          paddingBottom="12px"
          paddingTop="12px"
          paddingRight="16px"
          paddingLeft="16px"
          justifyContent="center"
          alignItems="center"
          elements={[
            <ColumnCard
              height={"40px"}
              elements={[
                <div>
                  <TitleRegular>{truncate(data.title, 50, true)}</TitleRegular>
                  <BodyCaption>{`${
                    Object.keys(data.variants).length
                  } products`}</BodyCaption>
                </div>,
              ]}
            />,
          ]}
        />
      </div>
      {enabled &&
        Object.entries(data.variants).map(([variantId, value]) => {
          return (
            <div key={variantId}>
              <CheckboxCard
                width={"100%"}
                isSelected={isItemPicked(data.uniqueId, variantId)}
                cardData={value as PickerItemInterface}
                onCardChange={onCardChange}
                canSelect={canSelect}
              />
            </div>
          );
        })}
    </div>
  );
};

export interface CheckboxCardInterface {
  width: string;
  isSelected: boolean;
  onCardChange: (cardId: string) => void;
  cardData: PickerItemInterface;
  canSelect: () => boolean;
}

export interface PickerItemInterface {
  uniqueId: string;
  title: string;
  subText: string;
  imageUrl: string;
  [index: string]: string;
}

export const CheckboxCard = (props: CheckboxCardInterface) => {
  const { width, onCardChange, cardData, canSelect } = props;
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [imageAvailable, setImageAvailable] = useState<boolean>(true);

  useEffect(() => {
    setIsSelected(props.isSelected);
  });

  return (
    <RowCard
      width={width}
      height={"78px"}
      leftElement={
        imageAvailable && cardData.imageUrl ? (
          <img
            style={{
              width: "40px",
              height: "40px",
            }}
            onError={() => setImageAvailable(false)}
            src={cardData.imageUrl}
          />
        ) : (
          <DynamicIcon />
        )
      }
      onClickHandler={() => {
        if (isSelected) {
          setIsSelected(!isSelected);
          onCardChange(cardData.uniqueId);
        } else {
          if (canSelect()) {
            setIsSelected(!isSelected);
            onCardChange(cardData.uniqueId);
          }
        }
      }}
      rightElement={
        <div style={{ cursor: "pointer" }}>
          <CheckBox isSelected={isSelected} />
        </div>
      }
      paddingBottom="12px"
      paddingTop="12px"
      paddingRight="16px"
      paddingLeft="40px"
      backgroundColor={isSelected ? "#E6F4E7" : "#FFFFFF"}
      justifyContent="center"
      alignItems="center"
      elements={[
        <ColumnCard
          height={"40px"}
          elements={[
            <div>
              <TitleRegular>
                {truncate(cardData.subText || "", 50, true)}
              </TitleRegular>
            </div>,
          ]}
        />,
      ]}
    />
  );
};
