import { Icon } from "./icons-props";

const OrderDetailsIcon: Icon = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      width={width || "32"}
      height={height || "32"}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="4" fill={"#E6F4E7"} />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13 5C11.8954 5 11 5.89543 11 7H10C9.20435 7 8.44129 7.31607 7.87868 7.87868C7.31607 8.44129 7 9.20435 7 10V24C7 24.7957 7.31607 25.5587 7.87868 26.1213C8.44129 26.6839 9.20435 27 10 27H16C16.5523 27 17 26.5523 17 26C17 25.4477 16.5523 25 16 25H10C9.73478 25 9.48043 24.8946 9.29289 24.7071C9.10536 24.5196 9 24.2652 9 24V10C9 9.73478 9.10536 9.48043 9.29289 9.29289C9.48043 9.10536 9.73478 9 10 9H11C11 10.1046 11.8954 11 13 11H19C20.1046 11 21 10.1046 21 9H22C22.2652 9 22.5196 9.10536 22.7071 9.29289C22.8946 9.48043 23 9.73478 23 10V13C23 13.5523 23.4477 14 24 14C24.5523 14 25 13.5523 25 13V10C25 9.20435 24.6839 8.44129 24.1213 7.87868C23.5587 7.31607 22.7956 7 22 7H21C21 5.89543 20.1046 5 19 5H13ZM19 7H13V9H19V7Z"
        fill="#00580E"
      />
      <path
        d="M21.25 18C20.8358 18 20.5 18.3358 20.5 18.75C20.5 19.1642 20.8358 19.5 21.25 19.5H24.75C25.1642 19.5 25.5 19.1642 25.5 18.75C25.5 18.3358 25.1642 18 24.75 18H21.25Z"
        fill="#00580E"
      />
      <path
        d="M20.5 21.25C20.5 20.8358 20.8358 20.5 21.25 20.5H22.75C23.1642 20.5 23.5 20.8358 23.5 21.25C23.5 21.6642 23.1642 22 22.75 22H21.25C20.8358 22 20.5 21.6642 20.5 21.25Z"
        fill="#00580E"
      />
      <path
        d="M21.25 23C20.8358 23 20.5 23.3358 20.5 23.75C20.5 24.1642 20.8358 24.5 21.25 24.5H22.75C23.1642 24.5 23.5 24.1642 23.5 23.75C23.5 23.3358 23.1642 23 22.75 23H21.25Z"
        fill="#00580E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.25 17C18.25 16.0335 19.0335 15.25 20 15.25H26C26.9665 15.25 27.75 16.0335 27.75 17V25.5C27.75 26.4665 26.9665 27.25 26 27.25H20C19.0335 27.25 18.25 26.4665 18.25 25.5V17ZM20 16.75C19.8619 16.75 19.75 16.8619 19.75 17V25.5C19.75 25.6381 19.8619 25.75 20 25.75H26C26.1381 25.75 26.25 25.6381 26.25 25.5V17C26.25 16.8619 26.1381 16.75 26 16.75H20Z"
        fill={color || "#00580E"}
      />
    </svg>
  );
};
export default OrderDetailsIcon;
