import React, { useState } from "react";
import { StyledMediaCard, StyledCard, MediaContainer } from "./styled";
import GreyCircle from "../grey-circle";
import {
  BodyCaption,
  BodyPrimary,
  BodyTiny,
  COLORS,
} from "@bikdotai/bik-component-library";
import CrossTagIcon from "../../icons/crossTagIcon";
import WhatsappIcon from "../../icons/whatsappIcon";
import InstagramIcon from "../../icons/instagramIcon";
import EmailIcon from "../../icons/email";

export interface Media {
  imageUrl: string;
  redirectionUrl: string;
  overlayCaption: string;
  id: string;
}

export interface AutomationCardProps {
  header: string;
  subHeader: string;
  onClick: Function;
  onCross: Function;
  icon?: React.ReactNode;
  media?: Media[];
  mediaType?: "post" | "story" | "reel";
  channelIdName?: string;
  channel: string;
  hasError: boolean;
  hasWarning: boolean;
}

const renderMediaCards = (
  media: Media[],
  mediaType?: "post" | "story" | "reel"
) => {
  const slice = mediaType === "post" ? 6 : 8;
  const slicedMedia = media.slice(0, slice);
  const remainingCount = media.length - slice;
  return slicedMedia.map((item, index) => {
    return (
      <StyledMediaCard
        key={item.id}
        mediaType={mediaType}
        imageUrl={item.imageUrl}
        index={index}
        remainingCount={remainingCount}
      ></StyledMediaCard>
    );
  });
};

const AutomationCard: React.FC<AutomationCardProps> = ({
  header,
  subHeader,
  icon,
  onClick,
  media,
  mediaType,
  onCross,
  channel,
  channelIdName,
  hasError,
  hasWarning,
}) => {
  const getChannelIcon = () => {
    if (channel === "whatsapp") {
      return <WhatsappIcon width={12} height={12} />;
    } else if (channel === "instagram") {
      return <InstagramIcon width={12} height={12} />;
    } else {
      return <EmailIcon width={12} height={12} />;
    }
  };
  return (
    <div>
      {channelIdName && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: 16,
          }}
        >
          {getChannelIcon()}
          <BodyTiny
            style={{
              marginLeft: 4,
              color: hasError ? COLORS.content.negative : undefined,
            }}
          >
            {channelIdName}
          </BodyTiny>
        </div>
      )}
      <StyledCard
        hasError={hasError}
        onClick={() => onClick()}
        hasWarning={hasWarning}
      >
        <div
          className="cross-icon"
          onClick={(e) => {
            e.stopPropagation();
            onCross();
          }}
        >
          <CrossTagIcon
            width={12}
            height={12}
            color={COLORS.content.secondary}
          />
        </div>
        <GreyCircle
          size={32}
          icon={icon}
          backgroundColor={
            channel === "whatsapp"
              ? "#E6F4E7"
              : channel === "instagram"
                ? "#F4E6F1"
                : undefined
          }
          borderColor={
            channel === "whatsapp"
              ? "#C3E4C4"
              : channel === "instagram"
                ? "#EFD9EA"
                : undefined
          }
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: 8,
            justifyContent: "center",
            flex: 1,
          }}
        >
          <BodyPrimary color={COLORS.content.primary}>{header}</BodyPrimary>
          {!!subHeader && (
            <BodyCaption
              color={COLORS.content.secondary}
              style={{
                maxWidth: "320px",
                overflow: "hidden",
                display: "inline-block",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: "1",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {subHeader}
            </BodyCaption>
          )}
          {!!media && media.length > 0 && (
            <MediaContainer>
              <>{renderMediaCards(media, mediaType)}</>
            </MediaContainer>
          )}
        </div>
      </StyledCard>
    </div>
  );
};

export default AutomationCard;
