import { ChildNodeProps } from "../../../utilities/flowBuilder.utility";
import { Handle, Position } from "reactflow";
import { ChildNodeContainer, ChildNodeText } from "../style";
import { useAppSelector } from "../../../state/store";
import { CTRRenderer } from "../customNode";
import { getCtrPercentageText } from "../../../services/helpers/AnalyticsHelper";

export const ChildNodeWithoutButton = (props: {
  childNode: ChildNodeProps;
  nodeId: string;
  isAnalytics: boolean;
}) => {
  const homeState = useAppSelector((state) => state.homeState);

  const { isAnalytics, childNode, nodeId } = props;

  const renderAnalytics = () => {
    const analyticsData = homeState?.analyticsData?.nodeAnalytics?.[nodeId];
    const operandCTR = analyticsData?.operands?.[childNode.buttonId];
    const triggeredCount = analyticsData?.triggered_count;
    if (operandCTR) {
      return CTRRenderer(
        getCtrPercentageText(operandCTR, triggeredCount),
        true
      );
    }

    return CTRRenderer("CTR 0%", true);
  };

  return (
    <ChildNodeContainer>
      {isAnalytics && renderAnalytics()}
      <ChildNodeText>{props.childNode.name}</ChildNodeText>
      <div>
        <Handle
          type="source"
          position={Position.Right}
          id={props.childNode.buttonId}
          style={{
            background: "#616161",
            width: "12px",
            height: "12px",
            right: "-6px",
            zIndex: 100,
            boxShadow: "0px 0px 0px 5px #E0E0E0",
            border: "none",
          }}
          isConnectable={props.childNode.isConnectable && !props.isAnalytics}
        />
      </div>
    </ChildNodeContainer>
  );
};
