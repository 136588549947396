import { FirebaseApp, getApp, initializeApp } from "firebase/app";
import {
  collection,
  doc,
  deleteDoc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  runTransaction,
  setDoc,
  where,
  writeBatch,
  limit,
  documentId,
  Unsubscribe,
  onSnapshot,
} from "firebase/firestore";
import { BikStore } from "@bikdotai/bik-models/growth/models/store";
import { firebaseConfig } from "../../constants/FirebaseConfig";
import {
  deleteObject,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import { ADMIN_STORE_ID, isProd } from "../../config";
import { SlackNotification } from "../../utilities/slackNotification";
import { compileFlow, updateKeywordCache } from "../helpers/StoreHelper";
import { generateRandomNumber } from "../../app/action-block/ActionBlockHelper";
import { StickyNotesInterface } from "../../utilities/flowBuilder.utility";
import { ButtonValues } from "../../ui-components/button-settings/buttonSettings";
import { cloneDeep } from "lodash";
import { captureErrorToSentry } from "../../utilities/sentryHelper";
import { FeatureSubKeyToPlanMappingInterface } from "@bikdotai/bik-models/growth/models/feature";

export enum PAGES {
  NLA_ANALYTICS = "analytics",
  JOURNEY_BUILDER_HOME = "journeyBuilderHome",
}

export enum PartnerStoreSource {
  SHOPIFY = "shopify",
  CUSTOM = "custom",
}
export interface PRODUCT_TOUR_META {
  productTourCompletion: {
    [key in PAGES]?: boolean;
  };
}

export class FirebaseService {
  static isInitialized = false;
  private slackNotification = new SlackNotification();
  successFulMsg: (str?: string) => void;
  failureMsg: (str?: string) => void;

  constructor(successFulMsg: any, failureMsg: any) {
    this.successFulMsg = successFulMsg;
    this.failureMsg = failureMsg;
  }

  static initializeApp() {
    this.isInitialized = true;
    initializeApp(
      isProd ? firebaseConfig.bikayiChatApp : firebaseConfig.stagingApp
    );
  }

  getDatabase() {
    const app = getApp();
    return getFirestore(app);
  }

  getFirebaseStorage() {
    const app = getApp();
    return getStorage(app);
  }

  static async fetchCurrentUser(): Promise<User | null> {
    const app = getApp();
    const auth = getAuth(app);
    if (!auth) {
      return null;
    }
    return new Promise((resolve) => {
      onAuthStateChanged(auth, (state) => {
        resolve(state);
      });
    });
  }

  static async fetchIdToken(): Promise<string | undefined> {
    const user = await this.fetchCurrentUser();
    if (!user) {
      return;
    }
    return await user.getIdToken(true);
  }

  alertError(msg: string, methodName: string) {
    this.failureMsg(msg);
    this.slackNotification.reportError(methodName, msg).then();
  }

  // Getters

  async getNode(nodeId: string, storeId: string, flowId: string) {
    let result = {};
    if (!nodeId) return result;
    try {
      const database = this.getDatabase();
      const docRef = doc(
        database,
        "chatflows",
        storeId,
        "flows",
        flowId,
        "chat-nodes",
        nodeId
      );
      const baseRef = await getDoc(docRef);
      result = baseRef.data() || {};
    } catch (error: any) {
      this.alertError(`Error in getting node => ${error?.message}`, "getNode");
      captureErrorToSentry(error, `Error in getting node: getNode`);
    } finally {
      return result;
    }
  }

  subscribeToShopifySync(
    storeId: string,
    cf: (unsubscribe: Unsubscribe, latestData: BikStore) => void
  ) {
    try {
      const database = this.getDatabase();
      const onboardRef = doc(database, "bik-store", storeId);
      const unsubscribe = onSnapshot(onboardRef, (doc) => {
        const latestData = doc.data() as BikStore;
        cf(unsubscribe, latestData);
      });
      return unsubscribe;
    } catch (error: any) {
      console.log(error);
    }
  }

  async fetchProductTourMeta(storeId: string, agentId: string) {
    try {
      const database = this.getDatabase();
      const docRef = doc(
        database,
        "notification-token",
        storeId,
        "agentInfo",
        agentId
      );
      const baseRef = await getDoc(docRef);
      let productTourMeta = baseRef.data() || {};
      if (!productTourMeta) {
        productTourMeta = {};
      }

      return productTourMeta as PRODUCT_TOUR_META;
    } catch (error: any) {
      captureErrorToSentry(
        error,
        `Error in fetching product tour meta with storeid ${storeId} and agentId ${JSON.stringify(
          agentId
        )}`
      );
    }
  }

  async setProductTour(storeId: string, agentId: string, page: PAGES) {
    try {
      const productTourCompletion = {
        [page]: true,
      };
      const database = this.getDatabase();
      const docRef = doc(
        database,
        "notification-token",
        storeId,
        "agentInfo",
        agentId
      );
      await setDoc(docRef, { productTourCompletion }, { merge: true });
      return true;
    } catch (err) {
      return false;
    }
  }

  async saveWebhookTypeTree(storeId: string, flowId: string, typeTree: object) {
    try {
      const database = this.getDatabase();
      const docRef = doc(
        database,
        "chatflows",
        storeId,
        "flows",
        flowId,
        "react-flow",
        flowId
      );

      const baseRef = await getDoc(docRef);
      let result = baseRef.data() || {};
      result["webhookTypeTree"] = typeTree;
      await setDoc(docRef, result);
    } catch (error: any) {
      this.alertError(
        `Error in saving webhook type tree => ${error?.message}`,
        "saveWebhookTypeTree"
      );
      captureErrorToSentry(error, `Error in saving webhook type tree`);
    }
  }

  async saveTypeTree(storeId: string, flowId: string, typeTree: object) {
    let result: any = {};
    try {
      const database = this.getDatabase();
      const docRef = doc(
        database,
        "chatflows",
        storeId,
        "flows",
        flowId,
        "react-flow",
        flowId
      );
      const baseRef = await getDoc(docRef);
      result = baseRef.data() || {};

      function mergeJSONObjects(obj1: any, obj2: any) {
        if (typeof obj1 !== "object" || typeof obj2 !== "object") {
          return obj2;
        }

        const merged: any = {};

        // Merge obj1 keys
        for (const key in obj1) {
          if (obj1.hasOwnProperty(key)) {
            merged[key] = obj1[key];
          }
        }

        // Merge obj2 keys, giving precedence to obj2 values
        for (const key in obj2) {
          if (obj2.hasOwnProperty(key)) {
            if (
              typeof obj2[key] === "object" &&
              obj1.hasOwnProperty(key) &&
              typeof obj1[key] === "object"
            ) {
              // If both values are objects, recursively merge them
              merged[key] = mergeJSONObjects(obj1[key], obj2[key]);
            } else {
              // Otherwise, assign obj2 value to the merged object
              merged[key] = obj2[key];
            }
          }
        }

        return merged;
      }

      if (Object.keys(result).length && Object.keys(typeTree).length) {
        if (
          result?.["payloadTypeTree"] &&
          Object.keys(result["payloadTypeTree"]).length
        ) {
          result["payloadTypeTree"] = mergeJSONObjects(
            result["payloadTypeTree"],
            typeTree
          );
        } else {
          result["payloadTypeTree"] = typeTree;
        }
        await setDoc(docRef, result);
      }
    } catch (error: any) {
      this.alertError(
        `Error in saveTypeTree=> ${error?.message}`,
        "saveTypeTree"
      );
      captureErrorToSentry(error, `Error in saveTypeTree`);
    } finally {
      return result;
    }
  }

  async getGlobalVariablesV2(storeId: string) {
    let result = {};
    try {
      const database = this.getDatabase();
      const docRef = doc(
        database,
        "chatflows",
        storeId,
        "global-collection",
        "global-variable-list"
      );
      const baseRef = await getDoc(docRef);
      result = baseRef.data() || {};
    } catch (error: any) {
      this.alertError(
        `Error in getting global-variable-list => ${error?.message}`,
        "getGlobalVariablesV2"
      );
      captureErrorToSentry(error, `Error in getting global-variable-list`);
    } finally {
      return result;
    }
  }

  async getReactFlow(flowId: string, storeId: string) {
    let result = {};
    try {
      const database = this.getDatabase();
      const docRef = doc(
        database,
        "chatflows",
        storeId,
        "flows",
        flowId,
        "react-flow",
        flowId
      );
      const baseRef = await getDoc(docRef);
      result = baseRef.data() || {};
    } catch (error: any) {
      this.alertError(
        `Error in getting react flows => ${error?.message}`,
        "getReactFlow"
      );
      captureErrorToSentry(error, `Error in getting react flows`);
    } finally {
      return result;
    }
  }

  async getAllNodes(flowId: string, storeId: string) {
    let result: any = [];
    try {
      const database = this.getDatabase();
      const docRef = await getDocs(
        collection(
          database,
          "chatflows",
          storeId,
          "flows",
          flowId,
          "chat-nodes"
        )
      );

      for (const nodeDoc of docRef.docs) {
        const nodeData = nodeDoc.data();

        if (nodeData.isDynamicMicroflow) {
          const mDocRef = await getDocs(
            collection(
              database,
              "chatflows",
              storeId,
              "flows",
              flowId,
              "chat-nodes",
              nodeData.nodeId,
              "microflow-nodes"
            )
          );

          const template: any = {};

          for (const mDoc of mDocRef.docs) {
            const mDocData = mDoc.data();
            template[mDocData.nodeId] = mDocData;
          }

          template.start_node = nodeData.start_node;
          delete nodeData.start_node;
          nodeData.template = template;
          result.push(nodeData);
        } else {
          result.push(nodeData);
        }
      }
    } catch (error: any) {
      this.alertError(
        `Error in getting all nodes => ${error?.message}`,
        "getAllNodes"
      );
      captureErrorToSentry(error, `Error in getting all nodes`);
    }
    return this.arrayToObject(result);
  }

  async getFlowEditHistory(storeId: string, flowId: string) {
    let result: any = {};
    try {
      const database = this.getDatabase();
      const docRef = doc(
        database,
        "chatflows",
        storeId,
        "flows",
        flowId,
        "last-update-details",
        flowId
      );
      const baseRef = await getDoc(docRef);
      result = baseRef.data() || {};
    } catch (error: any) {
      this.alertError(
        `Error in getFlowEditHistory => ${error?.message}`,
        "getFlowEditHistory"
      );
      captureErrorToSentry(error, `Error in getFlowEditHistory`);
    } finally {
      return result;
    }
  }

  async getFlowKeywordsByFlowId(storeId: string, flowId: string) {
    let result: any = {};
    try {
      const database = this.getDatabase();
      const docRef = doc(
        database,
        "chatflows",
        storeId,
        "flow-keywords",
        flowId
      );
      const keywordDoc = await getDoc(docRef);
      result = keywordDoc.data() || {};
    } catch (error: any) {
      this.alertError(
        `Error in getFlowKeywordsByFlowId => ${error?.message}`,
        "getFlowKeywordsByFlowId"
      );
      captureErrorToSentry(error, `Error in getFlowKeywordsByFlowId`);
    } finally {
      return result;
    }
  }

  async getCompilationReportByFlowId(storeId: string, flowId: string) {
    let result: any = {};
    try {
      const database = this.getDatabase();
      const docRef = doc(
        database,
        "chatflows",
        storeId,
        "compilation-reports",
        flowId
      );
      const keywordDoc = await getDoc(docRef);
      result = keywordDoc.data() || {};
    } catch (error: any) {
      captureErrorToSentry(error, `Error in getCompilationReportByFlowId`);
    } finally {
      return result;
    }
  }

  async getStoreInfo(storeId: string) {
    try {
      const database = this.getDatabase();
      const docRef = doc(database, "bik-store", storeId);
      const docData = await getDoc(docRef);
      if (docData.exists()) {
        const {
          countryCode,
          currency,
          storeIntegrationStatus,
          attributionDetails,
          shopifyStats,
          partnerStore,
        } = docData.data() as any;
        return {
          countryCode,
          currency,
          storeIntegrationStatus,
          attributionDetails,
          shopifyStats,
          partnerStore,
        };
      } else {
        if (storeId !== ADMIN_STORE_ID) {
          captureErrorToSentry(`Document does not exist`);
        }
        return {};
      }
    } catch (error: any) {
      captureErrorToSentry(error, `Error in getStoreInfo`);
      return {};
    }
  }

  async getStickyNotes(
    storeId: string,
    flowId: string
  ): Promise<{ [key: string]: StickyNotesInterface }> {
    let result: any = [];
    try {
      const database = this.getDatabase();
      const docRef = await getDocs(
        collection(
          database,
          "chatflows",
          storeId,
          "flows",
          flowId,
          "sticky-notes"
        )
      );
      docRef.forEach((nodeDoc) => {
        result.push(nodeDoc.data());
      });
    } catch (error: any) {
      this.alertError(
        `Error in fetching notes => ${error?.message}`,
        "getStickyNotes"
      );
      captureErrorToSentry(error, `Error in fetching notes`);
    } finally {
      return this.arrayToObject(result);
    }
  }

  // Setters

  async setFlow(flow: any, flowId: string, storeId: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const convertedFlow = this.objectToArray(flow);
        const database = this.getDatabase();
        const batch = writeBatch(database);
        for (const index in convertedFlow) {
          const nodeRef = doc(
            database,
            "chatflows",
            storeId,
            "flows",
            flowId,
            "chat-nodes",
            convertedFlow[index].nodeId
          );
          batch.set(nodeRef, convertedFlow[index]);
        }
        await batch.commit();
        resolve(true);
      } catch (error: any) {
        this.alertError(
          `Error in setting flow => ${error?.message}`,
          "setFlow"
        );
        captureErrorToSentry(error, `Error in setting flow`);
        reject(error?.message);
      }
    });
  }

  async setUpdateDetails(flow: any, storeId: string) {
    return new Promise(async (resolve, reject) => {
      if (!isProd) return resolve(true);
      try {
        const user = await FirebaseService.fetchCurrentUser();
        const database = this.getDatabase();
        const email = user ? user.email : "";
        await setDoc(
          doc(
            collection(
              database,
              "chatflows",
              storeId,
              "flows",
              flow.flowId,
              "last-update-details"
            ),
            flow.flowId
          ),
          JSON.parse(JSON.stringify({ lastUpdatedTime: Date.now(), email }))
        );
        resolve(true);
      } catch (error: any) {
        this.alertError(
          `Error in updating details => ${error?.message}`,
          "setUpdateDetails"
        );
        captureErrorToSentry(error, `Error in updating details`);
        reject(error?.message);
      }
    });
  }

  async setGlobalVariablesV2(storeId: string, list: any) {
    return new Promise(async (resolve, reject) => {
      try {
        const database = this.getDatabase();
        const nodeRef = collection(
          database,
          "chatflows",
          storeId,
          "global-collection"
        );
        await setDoc(doc(nodeRef, "global-variable-list"), list);
        this.successFulMsg("Successfully set global-variable-list");
        resolve(true);
      } catch (error: any) {
        this.alertError(
          `Error in setting global-variable-list => ${error?.message}`,
          "setGlobalVariablesV2"
        );
        reject(error?.message);
      }
    });
  }

  async saveMediaMetaData(flowData: any, flowId: string, storeId: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const database = this.getDatabase();
        const keywordRef = doc(
          database,
          "chatflows",
          storeId,
          "flow-keywords",
          flowId
        );
        await runTransaction(database, async (transaction) => {
          transaction.set(keywordRef, flowData, { merge: true });
        });
        this.successFulMsg("Successfully set save media meta data");
        resolve(true);
      } catch (error: any) {
        this.alertError(
          `Error in setting save media meta data => ${error?.message}`,
          "saveMediaMetaData"
        );
        captureErrorToSentry(error, `Error in setting save media meta data`);
        reject(error?.message);
      }
    });
  }

  async getFlowsByTrigger(storeId: string, trigger: string) {
    const database = this.getDatabase();
    const keywordRef = collection(
      database,
      "chatflows",
      storeId,
      "flow-keywords"
    );
    const queryRef = query(keywordRef, where("trigger", "==", trigger));
    const querySnapshot = await getDocs(queryRef);
    if (!querySnapshot.empty) {
      return querySnapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id } as any;
      });
    }
  }

  fetchAllFeatures = async () => {
    const database = this.getDatabase();
    const allFeatures = await getDocs(collection(database, "releaseJet"));
    const results = allFeatures.docs.reduce((acc: any, doc) => {
      acc[doc.id] = { ...doc.data(), docId: doc.id };
      return acc;
    }, {});
    return results;
  };

  fetchAllProducts = async (storeId: string) => {
    const database = this.getDatabase();
    const storeRef = collection(database, "store-new", storeId, "items");

    const products: any = [];

    const itemsDocs = await getDocs(storeRef);
    itemsDocs.forEach((document) => {
      if (document.exists()) {
        const productData = document.data();

        const product = {
          uniqueId: productData.id.toString(),
          imageUrl: productData?.image || "",
          title: productData.name,
          subText: productData?.combinations?.length || 0,
          variants: {} as any,
          collectionId: "",
        };

        if (
          typeof productData?.catalogs !== "undefined" &&
          productData?.catalogs?.length > 0
        ) {
          product["collectionId"] = productData?.catalogs[0]?.id;
        }

        const combinations = productData.combinations;
        if (combinations) {
          combinations.forEach((combination: any) => {
            const variantId: string = combination.id.toString();
            const varaint: any = {};
            varaint["uniqueId"] = variantId;
            varaint["title"] =
              (combination.custom === "Default Title"
                ? productData.name
                : combination.custom) || productData.name;
            varaint["subText"] =
              (combination.custom === "Default Title"
                ? productData.name
                : combination.custom) || productData.name;
            varaint["imageUrl"] = combination.image || productData.image || "";
            varaint["price"] = combination?.price || "";
            product.variants[variantId] = varaint;
          });
        }
        products.push(product);
      }
    });

    return products;
  };

  getProductsByIds(storeId: string, ids: string[]) {
    // don't run if there aren't any ids or a path for the collection
    if (!ids || !ids.length) return [];
    const database = this.getDatabase();

    const collectionPath = collection(database, "store-new", storeId, "items");
    const batches = [];

    while (ids.length) {
      // firestore limits batches to 10
      const batch = ids.splice(0, 10);
      const queryRef = query(
        collectionPath,
        where(documentId(), "in", [...batch])
      );

      // add the batch request to to a queue
      batches.push(
        getDocs(queryRef).then((results) =>
          results.docs.map((result) => ({ ...result.data() }))
        )
      );
    }

    // after all of the data is fetched, return it
    return Promise.all(batches).then((content) => content.flat());
  }

  private async updateFlowStatus(
    storeId: string,
    flowId: string,
    status: boolean
  ) {
    const database = this.getDatabase();
    const keywordRef = doc(
      database,
      "chatflows",
      storeId,
      "flow-keywords",
      flowId
    );
    await runTransaction(database, async (transaction) => {
      transaction.update(keywordRef, { status: status });
    });
  }

  async updateTestModeStatus(storeId: string, flowId: string, status: boolean) {
    const database = this.getDatabase();
    const keywordRef = doc(
      database,
      "chatflows",
      storeId,
      "flow-keywords",
      flowId
    );
    await runTransaction(database, async (transaction) => {
      transaction.update(keywordRef, { isTestMode: status });
    });
    await updateKeywordCache(storeId, { [flowId]: { isTestMode: status } });
    return true;
  }

  async saveFlow(
    storeId: string,
    flow: any,
    storeStateFlow: any,
    deletedNodes: string[],
    saveType: string,
    flowData: any,
    notes: { [key: string]: StickyNotesInterface },
    deletedStickyNotes: string[],
    forcePublish = false,
    sunset = false,
    testMode = false,
    status = false,
    agentId: number | string | null = null,
    saveOnly?: boolean,
    flowMeta?: any,
    triggerType?: string,
    dndEnabled?: boolean
  ) {
    return new Promise(async (resolve, reject) => {
      try {
        const database = this.getDatabase();
        const batch = writeBatch(database);

        // delete chat-nodes
        const chatNodeRef = collection(
          database,
          "chatflows",
          storeId,
          "flows",
          flow.flowId,
          "chat-nodes"
        );
        const nodeIdsData = await getDocs(chatNodeRef);

        const microflowNodesMap: any = {};
        const customCollNodesMap: any = {};

        for (let document of nodeIdsData.docs) {
          if (document.data()?.isDynamicMicroflow) {
            microflowNodesMap[document.id] = [];
            customCollNodesMap[document.id] = [];

            const microRef = collection(
              database,
              "chatflows",
              storeId,
              "flows",
              flow.flowId,
              "chat-nodes",
              document.id,
              "microflow-nodes"
            );
            const microData = await getDocs(microRef);

            for await (let mDoc of microData.docs) {
              microflowNodesMap[document.id].push(mDoc.id);

              const toDeleteIds: string[] = [];
              const mData = mDoc.data();

              mData?.message_template?.buttonsList?.forEach(
                (btn: ButtonValues) => {
                  if (
                    btn.value?.itemIdx &&
                    btn.buttonId === "CustomCatalog Id"
                  ) {
                    if (deletedNodes.includes(document.id)) {
                      toDeleteIds.push(btn.value.itemIdx);
                    }

                    customCollNodesMap[document.id].push(btn.value.itemIdx);
                  }
                }
              );

              if (deletedNodes.includes(document.id)) {
                for await (let id of toDeleteIds) {
                  let customCollRef = collection(
                    database,
                    "chatflows",
                    storeId,
                    "custom-collection",
                    id,
                    "variants"
                  );

                  const variants = await getDocs(customCollRef);
                  for (let variant of variants.docs) {
                    batch.delete(
                      doc(
                        database,
                        "chatflows",
                        storeId,
                        "custom-collection",
                        id,
                        "variants",
                        variant.id
                      )
                    );
                  }

                  batch.delete(
                    doc(database, "chatflows", storeId, "custom-collection", id)
                  );
                }

                const microflowDoc = doc(
                  database,
                  "chatflows",
                  storeId,
                  "flows",
                  flow.flowId,
                  "chat-nodes",
                  document.id,
                  "microflow-nodes",
                  mDoc.id
                );
                batch.delete(microflowDoc);
              }
            }
          }
        }

        if (deletedNodes && deletedNodes.length) {
          for await (let document of nodeIdsData.docs) {
            if (deletedNodes.includes(document.id)) {
              const nodeDoc = doc(
                database,
                "chatflows",
                storeId,
                "flows",
                flow.flowId,
                "chat-nodes",
                document.id
              );
              batch.delete(nodeDoc);
            }
          }
        }

        // set chat-nodes
        const convertedFlow = this.objectToArray(storeStateFlow);

        for await (const conf of convertedFlow) {
          if (!deletedNodes.includes(conf.nodeId)) {
            //const conf = convertedFlow[index];
            const isDynamicMicroflow = conf.isDynamicMicroflow;
            const curNodeId = conf.nodeId;

            if (isDynamicMicroflow) {
              let toDeleteMNodes = [];

              if (curNodeId) {
                toDeleteMNodes = (microflowNodesMap[curNodeId] || []).filter(
                  (id: string) => !Object.keys(conf.template).includes(id)
                );
              }

              toDeleteMNodes.forEach((mNode: string) => {
                const chatNodeDoc = doc(
                  database,
                  "chatflows",
                  storeId,
                  "flows",
                  flow.flowId,
                  "chat-nodes",
                  conf.nodeId,
                  "microflow-nodes",
                  mNode
                );
                batch.delete(chatNodeDoc);
              });

              const curCustomCollList: string[] = [];

              for (let mNodeId in conf.template) {
                if (mNodeId === "start_node") {
                  continue;
                }

                conf.template[mNodeId]?.message_template?.buttonsList?.forEach(
                  (btn: ButtonValues) => {
                    if (
                      btn.buttonId === "CustomCatalog Id" &&
                      btn.value?.itemIdx
                    ) {
                      curCustomCollList.push(btn.value.itemIdx);
                    }
                  }
                );

                const chatNodeDoc = doc(
                  database,
                  "chatflows",
                  storeId,
                  "flows",
                  flow.flowId,
                  "chat-nodes",
                  conf.nodeId,
                  "microflow-nodes",
                  mNodeId
                );

                batch.set(chatNodeDoc, conf.template[mNodeId]);
              }
              conf.start_node = conf.template.start_node;
              delete conf["template"];
              batch.set(
                doc(
                  database,
                  "chatflows",
                  storeId,
                  "flows",
                  flow.flowId,
                  "chat-nodes",
                  conf.nodeId
                ),
                conf
              );

              const deletetedCusColl = (
                customCollNodesMap[curNodeId] || []
              ).filter((id: string) => !curCustomCollList.includes(id));

              for (let id of deletetedCusColl) {
                let customCollRef = collection(
                  database,
                  "chatflows",
                  storeId,
                  "custom-collection",
                  id,
                  "variants"
                );

                const variants = await getDocs(customCollRef);
                for (let variant of variants.docs) {
                  batch.delete(
                    doc(
                      database,
                      "chatflows",
                      storeId,
                      "custom-collection",
                      id,
                      "variants",
                      variant.id
                    )
                  );
                }

                batch.delete(
                  doc(database, "chatflows", storeId, "custom-collection", id)
                );
              }
            } else {
              const chatNodeDoc = doc(
                database,
                "chatflows",
                storeId,
                "flows",
                flow.flowId,
                "chat-nodes",
                conf.nodeId
              );
              batch.set(chatNodeDoc, conf);
            }
          }
        }

        // set react-flow
        const reactFlowCol = collection(
          database,
          "chatflows",
          storeId,
          "flows",
          flow.flowId,
          "react-flow"
        );

        const reactFlowDoc = doc(reactFlowCol, flow.flowId);
        const oldReactFlowDoc = (await getDoc(reactFlowDoc)).data() || {};
        if (oldReactFlowDoc["payloadTypeTree"]) {
          flow["payloadTypeTree"] = cloneDeep(
            oldReactFlowDoc["payloadTypeTree"]
          );
        }
        if (oldReactFlowDoc["webhookTypeTree"]) {
          flow["webhookTypeTree"] = cloneDeep(
            oldReactFlowDoc["webhookTypeTree"]
          );
        }
        batch.set(reactFlowDoc, JSON.parse(JSON.stringify(flow)));

        // set last-update-details
        const user = await FirebaseService.fetchCurrentUser();
        const email = user ? user.email : "";
        const lastUpdateref = collection(
          database,
          "chatflows",
          storeId,
          "flows",
          flow.flowId,
          "last-update-details"
        );
        const lastupdateDoc = doc(lastUpdateref, flow.flowId);
        const lastupdateDetails = JSON.parse(
          JSON.stringify({ lastUpdatedTime: Date.now(), email })
        );
        batch.set(lastupdateDoc, lastupdateDetails);

        // deleting sticky notes
        const stickyNoteRef = collection(
          database,
          "chatflows",
          storeId,
          "flows",
          flow.flowId,
          "sticky-notes"
        );
        if (deletedStickyNotes && deletedStickyNotes.length) {
          const notesIdsData = await getDocs(stickyNoteRef);
          notesIdsData.forEach((document) => {
            if (deletedStickyNotes.includes(document.id)) {
              const notesDoc = doc(
                database,
                "chatflows",
                storeId,
                "flows",
                flow.flowId,
                "sticky-notes",
                document.id
              );
              batch.delete(notesDoc);
            }
          });
        }

        // updating sticky notes
        const convertedNotes = this.objectToArray(notes);
        for (const index in convertedNotes) {
          const notesDoc = doc(
            database,
            "chatflows",
            storeId,
            "flows",
            flow.flowId,
            "sticky-notes",
            convertedNotes[index].nodeId
          );
          batch.set(notesDoc, convertedNotes[index]);
        }

        await batch.commit();
        let errorMessage = "";
        let successMessage = "";
        let keywordUpdates: any = { status: false, lastupdateDetails };
        let response;

        // disable/enable and add lastupdateDetails in flow-keywords
        let finalMessage = "Successfully saved and disabled the flow!";
        // if publish, check for if flow is trigger then there should be only one trigger can be published

        //start a flow data in flow-keywords while saving the flow
        let startAFlow: { [key: string]: string } = {};
        Object.keys(storeStateFlow).map((item: any) => {
          if (storeStateFlow?.[item]?.sub_type === "start_flow") {
            const flowId = storeStateFlow?.[item]?.actions?.start_flow?.flow_id;
            const flowName =
              storeStateFlow?.[item]?.actions?.start_flow?.flow_header;
            startAFlow[flowId] = flowName;
          }
        });

        if (saveType === "Publish") {
          if (forcePublish) {
            keywordUpdates = {
              startAFlow: startAFlow,
              status: true,
              isSunset: false,
              lastUpdated: new Date().getTime(),
            };
          } else {
            response = await this.getCompilationReport(
              storeId,
              flow.flowId,
              flow,
              storeStateFlow,
              testMode,
              status,
              sunset,
              startAFlow,
              agentId,
              flowMeta,
              triggerType,
              dndEnabled
            );
            // Something unknown broke in backend. Common cause could be because of load.
            if (!response) {
              errorMessage = "Something went wrong while publishing flow";
            }
            if (
              response &&
              response.compilation_status === "FAILED" &&
              !response.flow_status
            ) {
              errorMessage =
                "Could not publish the flow. Please review the errors below and retry again";
            }
          }
        }

        if ((saveType === "Save" || forcePublish) && saveOnly === undefined) {
          response = await this.getCompilationReport(
            storeId,
            flow.flowId,
            flow,
            storeStateFlow,
            testMode,
            status,
            sunset,
            startAFlow,
            agentId,
            flowMeta,
            triggerType,
            dndEnabled
          );

          if (!response) {
            errorMessage = "Unable to deactive flow!";
            reject(errorMessage);
          }
        }
        if (errorMessage) {
          this.alertError(
            `Error in publish flow => ${errorMessage}`,
            "publishflow"
          );
        } else {
          this.successFulMsg(successMessage);
        }
        deletedNodes.length = 0;
        deletedStickyNotes.length = 0;
        resolve({ completionStatus: true, response: response });
      } catch (error: any) {
        this.alertError(`Error in save flow => ${error?.message}`, "saveFlow");
        captureErrorToSentry(error, `Error in save flow`);
        reject(error?.message);
      }
    });
  }

  async saveEventTriggerData(storeId: string, eventData: any) {
    return new Promise(async (resolve, reject) => {
      try {
        const database = this.getDatabase();
        const triggerEventId = eventData.triggerEventId;
        const eventTriggerRef = collection(
          database,
          "chatflows",
          storeId,
          "event-trigger"
        );
        await setDoc(
          doc(eventTriggerRef, triggerEventId),
          JSON.parse(JSON.stringify(eventData))
        );
        this.successFulMsg("Successfully saved the trigger event");
        resolve(true);
      } catch (error: any) {
        this.alertError(
          `Error in setting trigger event => ${error?.message}`,
          "saveEventTriggerData"
        );
        captureErrorToSentry(error, `Error in setting trigger event`);
        reject(error?.message);
      }
    });
  }

  async saveProductsToFirestore(
    storeId: string,
    uniqueId: string,
    data: any,
    catalogData: any
  ) {
    try {
      const database = this.getDatabase();
      const batch = writeBatch(database);

      Object.keys(data).forEach((variantId: string) => {
        const docRef = doc(
          database,
          "chatflows",
          storeId,
          "custom-collection",
          uniqueId,
          "variants",
          variantId
        );
        batch.set(docRef, data[variantId]);
      });

      if (catalogData) {
        const docRef = doc(
          database,
          "chatflows",
          storeId,
          "custom-collection",
          uniqueId
        );
        batch.set(docRef, catalogData);
      }

      await batch.commit();
    } catch (error: any) {
      captureErrorToSentry(
        error,
        `Error in setting save products to fire store. Additional info: ${JSON.stringify(
          {
            storeId,
            uniqueId,
            data: JSON.stringify(data),
            catalogData: JSON.stringify(catalogData),
          }
        )}`
      );
    }
  }

  async fetchProductsFromFirestore(storeId: string, uniqueId: string) {
    const database = this.getDatabase();

    const colRef = collection(
      database,
      "chatflows",
      storeId,
      "custom-collection",
      uniqueId,
      "variants"
    );

    const docRef = doc(
      database,
      "chatflows",
      storeId,
      "custom-collection",
      uniqueId
    );

    const docs = await getDocs(colRef);
    const docData = await getDoc(docRef);
    const catalogData = docData.data();

    const data: any = {};

    const variantsMap: any = {};

    docs.forEach((doc) => {
      if (doc.exists()) {
        const variantData = doc.data();
        const variantId = doc.id;
        const collectionId = variantData.collectionId;
        const productId = variantData.productId;
        const productImage = variantData.productImage;
        const collectionImage = variantData.collectionImage;
        const productName = variantData.productName;
        const collectionName = variantData.collectionName;
        const name = variantData.name;
        const image = variantData.image;
        const price = variantData.price;
        const isSmartCollection = variantData.isSmartCollection;

        variantsMap[variantId] = {
          name,
          image,
          price,
          productId,
          collectionId,
          productName,
          productImage,
          collectionName,
          collectionImage,
          isSmartCollection,
          id: doc.id,
        };

        if (!data?.[collectionId]) {
          data[collectionId] = {
            name: collectionName,
            image: collectionImage,
          };
        }

        if (!data?.[collectionId]?.["products"]) {
          data[collectionId]["products"] = {};
        }

        if (!data?.[collectionId]?.["products"]?.[productId]) {
          data[collectionId]["products"][productId] = {
            name: productName,
            image: productImage,
          };
        }

        if (!data?.[collectionId]?.["products"]?.[productId]?.["variants"]) {
          data[collectionId]["products"][productId]["variants"] = {};
        }

        data[collectionId]["products"][productId]["variants"][variantId] = {
          name,
          image,
          price,
        };
      }
    });

    return {
      productsRaw: data,
      productsProcessed: variantsMap,
      catalogData: catalogData,
    };
  }

  async deleteFlowsById(storeId: string, flowIds: Array<string>) {
    return new Promise(async (resolve, reject) => {
      try {
        const database = this.getDatabase();
        const batch = writeBatch(database);
        const colRef = collection(
          database,
          "chatflows",
          storeId,
          "event-trigger"
        );

        const queryRef = query(colRef, where("flowId", "in", flowIds));
        const querySnapshot = await getDocs(queryRef);
        querySnapshot.docs.forEach((document) => {
          const flowDoc = doc(
            database,
            "chatflows",
            storeId,
            "event-trigger",
            document.id
          );
          batch.delete(flowDoc);
        });
        await batch.commit();
        resolve(true);
      } catch (error: any) {
        captureErrorToSentry(error, `Error in deleteFlowsById`);
        reject(error?.message);
      }
    });
  }

  // Utilities

  async getCompilationReport(
    storeId: string,
    flowId: string,
    reactFlow?: any,
    flowConfig?: any,
    testMode?: boolean,
    status?: boolean,
    sunset?: boolean,
    startAFlow?: any,
    agentId?: number | string | null,
    flowMeta?: any,
    triggerType?: string,
    dndEnabled?: boolean
  ): Promise<any> {
    const compilationReport = await compileFlow(
      storeId,
      flowId,
      reactFlow,
      flowConfig,
      testMode,
      status,
      sunset,
      startAFlow,
      agentId,
      flowMeta,
      triggerType,
      dndEnabled
    );
    return compilationReport;
  }

  async getBikStoreData(storeId: string) {
    const database = this.getDatabase();
    const docRef = doc(database, "bik-store", storeId);

    const baseRef = await getDoc(docRef);
    const result = baseRef.data() || {};

    return result;
  }

  objectToArray(nodesObject: any) {
    const nodesArray: any[] = [];
    Object.keys(nodesObject).forEach((key) => {
      const node = { ...nodesObject[key], nodeId: key };
      nodesArray.push(node);
    });
    return nodesArray;
  }

  arrayToObject(nodesArray: any) {
    const nodesObject: any = {};
    for (const node of nodesArray) {
      nodesObject[node.nodeId] = node;
    }
    return nodesObject;
  }

  arrayToObjectCarousal(childNodes: any) {
    let obj: any = {};
    childNodes.map((node: any, idx: number) => {
      let key = generateRandomNumber();
      obj[`cr${idx}_${key}`] = node;
    });
    return obj;
  }

  objectToArrayCarousal(childNodes: any) {
    const keys = Object.keys(childNodes).sort();
    const array = keys.map((key) => childNodes[key]);
    return array;
  }

  getUploadRef(file: File, path: string) {
    const storage = this.getFirebaseStorage();
    const storageRef = ref(storage, path);
    const upload = uploadBytesResumable(storageRef, file);
    return upload;
  }

  deleteFile(url: string) {
    const storage = this.getFirebaseStorage();
    const storageRef = ref(storage, url);
    deleteObject(storageRef)
      .then(() => {})
      .catch((error) => {
        captureErrorToSentry(error, `Error in deleteFile`);
      });
  }

  async getShopifyBaseUrl(storeId: string) {
    const database = this.getDatabase();
    const keywordRef = collection(database, "shopify-store");
    const queryRef = query(keywordRef, where("storeId", "==", storeId));
    const querySnapshot = await getDocs(queryRef);
    return querySnapshot.docs[0].id;
  }

  async saveWebhookTrigger(storeId: string, docId: string, conf: any) {
    const database = this.getDatabase();

    const docRef = doc(database, "chatflows", storeId, "webhooks", docId);

    await setDoc(docRef, conf);
  }

  async getWebhookTrigger(storeId: string, flowId: string) {
    const database = this.getDatabase();

    const colRef = collection(database, "chatflows", storeId, "webhooks");

    const queryRef = query(
      colRef,
      where("storeId", "==", storeId),
      where("flowId", "==", flowId)
    );
    const querySnapshot = await getDocs(queryRef);

    if (!querySnapshot.empty) {
      return querySnapshot.docs[0].data();
    }
  }

  async getWebhookByDocId(storeId: string, docId: string) {
    const database = this.getDatabase();

    const docRef = doc(database, "chatflows", storeId, "webhooks", docId);

    const baseRef = await getDoc(docRef);
    const result = baseRef.data() || {};

    return result;
  }

  /**
   * Gets already added past profile details from Database.
   */
  async getPastProfileDetails(storeId: string, flowId: string) {
    const database = this.getDatabase();

    const docRef = doc(database, "chatflows", storeId, "flows", flowId);
    const baseRef = await getDoc(docRef);
    const result = baseRef.data() || {};

    return result?.pastProfileData;
  }

  /* fetchRestriction  */
  async fetchBikFeatureRestrictionData(
    storeId: string
  ): Promise<FeatureSubKeyToPlanMappingInterface> {
    const database = this.getDatabase();
    const docRef = doc(database, "restricted-features", storeId); // Collection name + document ID
    const docData = await getDoc(docRef);
    try {
      let data = docData.data() as FeatureSubKeyToPlanMappingInterface;
      if (!data) {
        data = {} as FeatureSubKeyToPlanMappingInterface;
      }
      return data;
    } catch (e) {
      captureErrorToSentry(e, "Error in fetching feature restriction data");
      return {} as FeatureSubKeyToPlanMappingInterface;
    }
  }
}
