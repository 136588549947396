import { FirebaseService } from "../../services/firebase/FirebaseService";
import { voidFunction } from "../../app/messageBlock/Constants";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useAppSelector } from "../../state/store";
import { getDownloadURL } from "firebase/storage";
import {
  FileUploadStyles,
  LoaderContainer,
  Progress,
  ProgressModalStyles,
} from "./style";
import Upload from "../../icons/upload";
import Modal from "../modal";
import VideoIcon from "../../icons/video";
import ImageIcon from "../../icons/img";
import DocumentIcon from "../../icons/documentIcon";
import AlertIcon from "../../icons/alertIcon";
import { CrossIcon } from "../../icons";
import BikayiButton from "../button";
import { ImageService } from "@bikdotai/bik-component-library";

interface ProgressModalProps {
  file: any;
  onBackDropPress: () => void;
  progress: number;
  type: string;
}

const ProgressModal: FunctionComponent<ProgressModalProps> = (props) => {
  const [icon, setIcon] = useState<any>();
  const [header, setHeader] = useState<string>("");
  const [subText, setSubText] = useState<string>("");

  useEffect(() => {
    if (props.type === "Video") {
      setIcon(<VideoIcon width={40} height={40} color={"#616161"} />);
      setHeader("Video upload in progress...");
      setSubText(
        "Your video is getting loaded. This may take a couple of minutes."
      );
    } else if (props.type === "Image") {
      setIcon(<ImageIcon width={40} height={40} color={"#616161"} />);
      setHeader("Image upload in progress...");
      setSubText(
        "Your image is getting loaded. This may take a couple of minutes."
      );
    } else if (props.type === "Document") {
      setIcon(<DocumentIcon width={40} height={40} color={"#616161"} />);
      setHeader("Document upload in progress...");
      setSubText(
        "Your document is getting loaded. This may take a couple of minutes."
      );
    }
  }, [props.type]);

  return (
    <Modal onBackdropPress={props.onBackDropPress}>
      <ProgressModalStyles width={440} height={432}>
        <LoaderContainer>
          <div className="m-auto">
            <div className="icon-holder mx-auto">
              <div className="mx-auto d-flex">{icon}</div>
            </div>
            <div
              style={{ paddingTop: "16px", color: "#212121" }}
              className="title-medium"
            >
              {header}
            </div>
            <div
              style={{ padding: "8px 0 32px 0", color: "#616161" }}
              className="body-secondary"
            >
              {subText}
            </div>
            <div
              className="mx-auto"
              style={{ width: "344px", background: "#E6F4E7", borderRadius: 8 }}
            >
              <Progress progress={props.progress || 5} />
            </div>
            <div
              style={{
                padding: "38px 0 0 0",
                color: "#616161",
                cursor: "pointer",
              }}
              className="button-regular"
              onClick={props.onBackDropPress}
            >
              Cancel
            </div>
          </div>
        </LoaderContainer>
      </ProgressModalStyles>
    </Modal>
  );
};

interface ErrorModalProps {
  header: string;
  subText: string;
  onBackDropPress: () => void;
  onRetry: () => void;
}

export const ErrorModal: FunctionComponent<ErrorModalProps> = (props) => {
  return (
    <Modal onBackdropPress={props.onBackDropPress}>
      <ProgressModalStyles width={400} height={264}>
        <div className="d-flex">
          <div
            className="icon-holder"
            style={{
              background: "#FFEBEF",
              width: 60,
              height: 60,
              marginLeft: 144,
            }}
          >
            <div className="mx-auto d-flex">
              <AlertIcon />
            </div>
          </div>
          <div
            className="ml-auto d-flex"
            style={{ cursor: "pointer" }}
            onClick={props.onBackDropPress}
          >
            <CrossIcon width={24} height={24} />
          </div>
        </div>
        <LoaderContainer background={"#FFFFFF"} padding={1}>
          <div>
            <div
              style={{ color: "#212121", paddingTop: 8 }}
              className="title-medium"
            >
              {props.header}
            </div>
            <div
              style={{ padding: "8px 0 24px 0", color: "#616161" }}
              className="body-secondary"
            >
              {props.subText}
            </div>
            <div className="d-flex" style={{ flexDirection: "row" }}>
              {/*@ts-ignore*/}
              <BikayiButton
                buttonName={"Cancel"}
                buttonStyle={"outline"}
                buttonWidth={"172px"}
                buttonHeight={"32px"}
                click={props.onBackDropPress}
              ></BikayiButton>
              <div style={{ marginLeft: 8 }}>
                {/*@ts-ignore*/}
                <BikayiButton
                  buttonName={"Retry"}
                  buttonStyle={"primary"}
                  buttonWidth={"172px"}
                  buttonHeight={"32px"}
                  click={props.onRetry}
                ></BikayiButton>
              </div>
            </div>
          </div>
        </LoaderContainer>
      </ProgressModalStyles>
    </Modal>
  );
};

interface UploadProps {
  onFileUpload: (fileMetaData: any) => void;
  type: "Video" | "Image" | "Document";
  channel: string;
  setFileType?: (fileType: string) => void;
}

export const UploadFile = (props: UploadProps) => {
  const firebaseService = new FirebaseService(voidFunction, voidFunction);
  const [error, setError] = useState<string>("");
  const homeState = useAppSelector((state) => state.homeState);
  const [uploadState, setUploadState] = useState<number>(0);
  const [showProgressBar, setShowProgressBar] = useState<boolean>(false);
  const [file, setFile] = useState<any>();
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [errorHeader, setErrorHeader] = useState<string>("");
  const [acceptedFormat, setAcceptedFormat] = useState<string>("");

  const handleFileUpload = (target: any) => {
    setError("");
    const file = target?.files?.[0];
    let error = "";
    if (props.channel === "whatsapp") {
      error = validateFile(file);
    } else {
      error = validateInstagramFile(file);
    }
    setError(error);
    if (!error) {
      setFile(file);
      uploadMedia(file);
      setShowProgressBar(true);
    } else {
      setShowErrorModal(true);
    }
  };

  const validateInstagramFile = (file: File) => {
    if (!file) return "Please upload an media before saving!";
    if (file.type.match(/image\/(jpeg|png)/)) {
      props?.setFileType?.("Image");
      if (file.size > 8000000) {
        setErrorHeader("Maximum size limit exceeded");
        return "Maximum image size limit that can be added is 8MB. Please add another file";
      }
    } else if (file.type.match("video/mp4")) {
      props?.setFileType?.("Video");
      if (file.size > 25000000) {
        setErrorHeader("Maximum size limit exceeded");
        return "Maximum video size limit that can be added is 25MB. Please add another file";
      }
    } else if (file.type.match("audio.*")) {
      props?.setFileType?.("Audio");
      if (file.size > 25000000) {
        setErrorHeader("Maximum size limit exceeded");
        return "Maximum video size limit that can be added is 25MB. Please add another file";
      }
    } else {
      setErrorHeader("Format not supported");
      return "Uploaded file format is not supported.";
    }
    return "";
  };

  const validateFile = (file: File) => {
    if (!file) return "Please upload an media before saving!";
    else if (props.type === "Image") {
      if (!!!file.type.match(/image\/(jpeg|png)/)) {
        setErrorHeader("Format not supported");
        return "Uploaded file format is not supported. Only JPEG and PNG formats are allowed.";
      } else if (file.size > 5000000) {
        setErrorHeader("Maximum size limit exceeded");
        return "Maximum image size limit that can be added is 5MB. Please add another file";
      }
    } else if (props.type === "Video") {
      if (!!!file.type.match("video/mp4")) {
        setErrorHeader("Format not supported");
        return "Uploaded file format is not supported. Only MP4 formats are allowed.";
      } else if (file.size > 16000000) {
        setErrorHeader("Maximum size limit exceeded");
        return "Maximum video size limit that can be added is 15MB. Please add another file";
      }
    } else if (
      props.type === "Document" &&
      !!!file.type.match("application/pdf")
    ) {
      if (!!!file.type.match("application/pdf")) {
        setErrorHeader("Format not supported");
        return "Uploaded file format is not supported. Only PDF formats are allowed.";
      } else if (file.size > 100000000) {
        setErrorHeader("Maximum size limit exceeded");
        return "Maximum file size limit that can be added is 100MB. Please add another file";
      }
    }
    return "";
  };

  const getFileName = (fileName: string) => {
    const extension = fileName.split(".").pop();
    const date = new Date();
    return date.getTime().toString() + "." + extension;
  };

  const getMediaPath = (path: string) => {
    return `chatbot/${homeState.storeId}/${path}`;
  };

  const formatFileSize = (bytes: number) => {
    if (!bytes) return "0 Bytes";
    const k = 1024;
    const dm = 2;
    const sizes = ["Bytes", "KB", "MB"];
    const power = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, power)).toFixed(dm))} ${sizes[power]}`;
  };

  const getMetaData = (file: File, url: string, fileName: string) => {
    return {
      mediaUrl: url,
      mediaSize: formatFileSize(file.size) || "0 Bytes",
      mediaName: fileName,
    };
  };

  const uploadMedia = async (file: File) => {
    if (file.type.includes("image")) {
      //image compression
      await ImageService.compressImage(file).then(
        (response: { compressedFile: File }) => {
          file = response.compressedFile;
        }
      );
    }
    if (!error) {
      const fileName = getFileName(file!.name);
      const uploadRef = firebaseService.getUploadRef(
        file!,
        getMediaPath(fileName)
      );
      uploadRef.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          if (percent === 100) {
            setUploadState(percent);
          }
        },
        (error) => {
          setError("An unknown technical error occured while uploading file.");
          setErrorHeader("Unknown error occurred");
        },
        () => {
          getDownloadURL(uploadRef.snapshot.ref).then((url) => {
            const meta = getMetaData(file!, url, fileName);
            setShowErrorModal(false);
            setShowProgressBar(false);
            props.onFileUpload(meta);
          });
        }
      );
    }
  };

  useEffect(() => {
    if (props.type === "Video") {
      setAcceptedFormat("video/mp4, video/x-m4v");
    } else if (props.type === "Image") {
      setAcceptedFormat("image/png, image/jpeg");
    } else if (props.type === "Document") {
      setAcceptedFormat("application/pdf");
    }
  }, [props.type]);

  return (
    <>
      <FileUploadStyles>
        <label htmlFor={"file_upload"} style={{ width: "100%" }}>
          <div className="upload-button">
            <div className="mx-auto d-flex">
              <div className="my-auto d-flex">
                <Upload color={"#731DCF"} width={20} height={20} />
              </div>
              <div className="btn-text">Upload</div>
            </div>
          </div>
        </label>
        <input
          id="file_upload"
          type="file"
          accept={acceptedFormat}
          style={{ opacity: 0, position: "absolute", zIndex: -1 }}
          onChange={(event: React.FormEvent) => handleFileUpload(event.target)}
          onClick={(event) => {
            const element = event.target as HTMLInputElement;
            element.value = "";
          }}
        />
      </FileUploadStyles>
      {showProgressBar && (
        <ProgressModal
          type={props.type}
          progress={uploadState}
          file={file}
          onBackDropPress={() => setShowProgressBar(false)}
        />
      )}

      {showErrorModal && (
        <ErrorModal
          header={errorHeader}
          subText={error}
          onBackDropPress={() => {
            setShowErrorModal(false);
            setError("");
          }}
          onRetry={() => {
            document.getElementById("file_upload")?.click();
            setShowErrorModal(false);
            setError("");
          }}
        />
      )}
    </>
  );
};
