import React, { FunctionComponent, useEffect, useState } from "react";
import { useAppSelector } from "../../state/store";
import { BlockContainer, IconContainer, SideModalStyles } from "./style";
import { Beta } from "../header-component/headerComponent";
import { Tooltip } from "@bikdotai/bik-component-library";
import { enabledStoreIds } from "../../config";
import { RecordEventHelper } from "../../utilities/RecordEventHelpers";
import {
  AmplitudeEventStep,
  AmplitudeKeys,
  TargetPlatform,
} from "../../utilities/AnalyticsTypes";
import { NodeSubType } from "../../models/nodeSubType";

export interface SubSections {
  subHeader: string;
  listOfBlocks: {
    blockName: string;
    blockIcon: any;
    blockId: string;
    automationChannel?: string;
    isBeta?: boolean;
    description?: string;
  }[];
}

export interface SideModalProps {
  storeId: string;
  header: string;
  caption: string;
  subSections: SubSections[];
  setNewNodeData: (
    blockId: string,
    blockName: string,
    blockChannel?: string
  ) => void;
  onClick?: () => void;
  closeModal?: () => void;
  isOpen: boolean;
}
type Props = SideModalProps;

export interface SubBlockHeaderProps {
  subBlockName: string;
}

const SubBlockHeader: React.FC<SubBlockHeaderProps> = (props) => {
  return (
    <div className="section__header-container">
      <div className="section__header">{props.subBlockName}</div>
      <div className="section__bar" />
    </div>
  );
};

export interface BlockProps {
  channel: string;
  blockName: string;
  blockIcon: any;
  blockId: string;
  blockChannel?: string;
  click: (blockId: string, blockName: string, blockChannel: string) => void;
  isBeta?: boolean;
}

const ONLY_SSO_BLOCKS = [
  NodeSubType.STORE_VARIABLE,
  NodeSubType.MANIFEST_SEARCH,
  NodeSubType.MANIFEST_LLM_CALL,
  NodeSubType.MANIFEST_EXIT,
  NodeSubType.MANIFEST_BUILD_LLM_INPUT,
];

const Block: React.FC<BlockProps> = (props) => {
  const { blockName, blockIcon, blockId, blockChannel, click, channel } = props;

  return (
    <BlockContainer
      channel={channel}
      subType={blockId}
      onClick={() => click(blockId, blockName, blockChannel || "")}
    >
      {props.isBeta && (
        <div
          style={{
            position: "absolute",
            top: -10,
            left: -10,
          }}
        >
          <Beta />
        </div>
      )}
      <IconContainer channel={channel} subType={blockId}>
        <div className="d-flex m-auto">{blockIcon}</div>
      </IconContainer>
      <div className="block__caption">{blockName}</div>
    </BlockContainer>
  );
};

const SideModal: FunctionComponent<Props> = (props) => {
  const homeState = useAppSelector((state) => state.homeState);
  const [isSSO, setIsSSO] = useState<boolean>(false);
  const eventHelper = RecordEventHelper.getInstance();

  useEffect(() => {
    if (homeState.userData && homeState.userData?.loginType === "sso") {
      setIsSSO(true);
    }
  }, [homeState?.flowId]);

  const onBlockClick = (
    blockId: string,
    blockName: string,
    blockChannel?: string
  ) => {
    props.setNewNodeData(blockId, blockName, blockChannel);
    eventHelper.trackEvent(
      TargetPlatform.Amplitude,
      AmplitudeKeys.service_used,
      {
        step: AmplitudeEventStep.block_used,
        blockName: blockName,
        screen: "journey_builder",
      }
    );
  };

  return (
    <>
      <SideModalStyles isOpen={props.isOpen}>
        <div className="sticky-top">
          <div className="header">{props.header}</div>
          <div className="caption">{props.caption}</div>
        </div>
        {props.subSections &&
          props.subSections.map((section) => (
            <div className="section">
              <SubBlockHeader subBlockName={section.subHeader} />
              {
                <div className="block__outer-container">
                  {section.listOfBlocks.map((block) =>
                    // @ts-ignore:
                    ONLY_SSO_BLOCKS.includes(block.ONLY_blockId) &&
                    !isSSO &&
                    !enabledStoreIds.includes(homeState.storeId) ? null : (
                      <>
                        <Tooltip
                          delay={[400, 0] as unknown as number}
                          body={block.description || ""}
                          placement="bottom"
                        >
                          <div>
                            <Block
                              channel={homeState.channel || "whatsapp"}
                              blockName={block.blockName}
                              blockIcon={block.blockIcon}
                              blockId={block.blockId}
                              blockChannel={block?.automationChannel}
                              click={onBlockClick}
                              isBeta={block?.isBeta || false}
                            />
                          </div>
                        </Tooltip>
                      </>
                    )
                  )}
                </div>
              }
            </div>
          ))}
      </SideModalStyles>
    </>
  );
};
export default SideModal;
