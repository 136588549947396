import { StoreDataState } from "../../state/storeDataStateType";
import { RootState } from "../../state/storeType";
import { cloneDeep } from "lodash";
import { SlackNotification } from "../../utilities/slackNotification";
import {
  populate_microflow_conf,
  reparseMicroFlowConf,
} from "../../services/helpers/MicroflowHelper";
import { NodeSubType } from "../../models/nodeSubType";
import { NodeType } from "../../models/nodeType";
import {
  ExitManifestNodeData,
  ManifestSearchProductsData,
} from "../../components/manifest/models";

export class NodeManager {
  private slackNotification = new SlackNotification();

  handleSideBarActions(node: any, store: RootState) {
    const nodeData = cloneDeep(store.nodeClickState?.data);
    const childNodes: any = [];
    const dripNodes: any = [];
    let childNodesArray: any = nodeData?.childNodes;

    if (
      nodeData?.subType === NodeSubType.CAROUSEL ||
      nodeData?.subType === NodeSubType.WA_CAROUSEL
    ) {
      childNodesArray &&
        childNodesArray.forEach((elements: any) => {
          if (!Array.isArray(elements)) {
            for (let [k, v] of Object.entries(elements)) {
              childNodes.push({
                buttonId: k,
                name: (v as any)["key"],
                isConnectable: true,
                type: (v as any)["type"] ?? "",
              });
            }
          } else {
            let temp: any = [];
            elements.forEach((node: any) => {
              if (!Object.keys(node.operator_values).length) {
                temp.push({
                  title: node.title,
                  imageUrl: node.imgMeta.mediaUrl,
                });
              } else {
                for (const [key, values] of Object.entries(
                  node.operator_values as {
                    [key: string]: any;
                  }
                )) {
                  temp.push({
                    buttonId: key.substring(1),
                    name: values.key,
                    isConnectable:
                      typeof values?.isConnectable !== "boolean"
                        ? true
                        : values.isConnectable,
                    title: node.title,
                    imageUrl: node.imgMeta.mediaUrl,
                    value: values.value ? values.value : "",
                    ctaValue: values.ctaValue ? values.ctaValue : "",
                    type: values?.type ?? "",
                  });
                }
              }
            });
            childNodes.push(temp);
          }
        });
    }

    if (nodeData?.subType === NodeSubType.PRODUCT_CAROUSEL) {
      for (let [k, v] of Object.entries(nodeData?.childNodes?.dripChildNodes)) {
        childNodes.push({
          buttonId: k,
          name: (v as any)["key"],
          isConnectable: true,
        });
      }

      for (const [key, values] of Object.entries(
        (nodeData?.childNodes?.operator_values as { [key: string]: any }) || {}
      )) {
        childNodes.push({
          buttonId: key,
          name: values.key,
          isConnectable:
            typeof values?.isConnectable !== "boolean"
              ? true
              : values.isConnectable,
          title: nodeData.childNodes?.title,
          subTitle: nodeData.childNodes?.subTitle,
          image: nodeData.childNodes?.image,
          ctaValue: values.ctaValue ? values.ctaValue : "",
        });
      }
    }

    if (
      nodeData?.subType !== NodeSubType.CAROUSEL &&
      nodeData?.subType !== NodeSubType.PRODUCT_CAROUSEL &&
      nodeData?.subType !== NodeSubType.WA_CAROUSEL
    ) {
      for (const [key, values] of Object.entries(nodeData?.childNodes || {})) {
        childNodes.push({
          buttonId: [
            NodeSubType.INSTAGRAM_TEXT,
            NodeSubType.MESSAGE,
            NodeSubType.LIST,
            NodeSubType.CSAT_NPS,
            NodeSubType.PRODUCT_CARD,
          ].includes((nodeData?.subType as NodeSubType) || "")
            ? key.substring(1)
            : key,
          name: values.key,
          description: values.description,
          isConnectable:
            typeof values?.isConnectable !== "boolean"
              ? true
              : values.isConnectable,
        });
      }
    }

    delete nodeData?.childNodes;
    delete node?.data?.nodeData?.icon;
    node = {
      ...node,
      ...{
        data: {
          ...node.data,
          ...{
            nodeData: {
              ...node.data.nodeData,
              ...nodeData,
              ...{ childNodes: childNodes },
              ...{ dripNodes },
            },
          },
        },
      },
    };
    return node;
  }

  getEdgeConnections(edge: any) {
    const fromNodeId = edge?.source;
    const toNodeId = edge?.target;
    const sourceHandler = edge?.sourceHandle;
    const targetHandler = edge?.targetHandle;
    return {
      fromNodeId,
      toNodeId,
      sourceHandler,
      targetHandler,
    };
  }

  handleEdgeConnections(editableNode: any, edgeData: any, oldEdge: any = null) {
    let error = "";
    try {
      editableNode = cloneDeep(editableNode);
      switch (editableNode?.type?.toLowerCase()) {
        case NodeType.TRIGGER: {
          const index = edgeData?.sourceHandler;
          if (index === "fallback") {
            editableNode.conditions[0].operator_values["fallback"] = {
              key: "fallback",
              value: edgeData?.toNodeId,
            };
          } else {
            editableNode.conditions[0].operator_values[index]["value"] =
              edgeData?.toNodeId;
          }
          break;
        }
        case NodeType.SPM.toLowerCase(): {
          // handling edge update from one node to another
          if (oldEdge) {
            const found = editableNode.relations.indexOf(oldEdge?.target);
            if (found !== -1) {
              editableNode.relations.splice(found, 1);
            }
          }

          editableNode.relation = edgeData?.toNodeId;
          break;
        }
        case NodeType.MPM.toLowerCase(): {
          if (oldEdge) {
            const found = editableNode.relations.indexOf(oldEdge?.target);
            if (found !== -1) {
              editableNode.relations.splice(found, 1);
            }
          }

          editableNode.relation = edgeData?.toNodeId;
          break;
        }
        case NodeType.CONDITION: {
          const index = edgeData?.sourceHandler;
          editableNode.conditions[0].operator_values[index]["value"] =
            edgeData?.toNodeId;
          break;
        }
        case NodeType.AI.toLowerCase(): {
          switch (editableNode.sub_type) {
            case "ai_classify": {
              const index = edgeData?.sourceHandler;
              editableNode.connections[index]["value"] = edgeData?.toNodeId;
              break;
            }
            case NodeSubType.AI_ASK: {
              const index = edgeData?.sourceHandler;
              editableNode.connections[index]["value"] = edgeData?.toNodeId;
              break;
            }
            case "ai_product_recommend": {
              const index = edgeData?.sourceHandler;
              editableNode.connections[index]["value"] = edgeData?.toNodeId;
              break;
            }
            case "ai_reply": {
              const index = edgeData?.sourceHandler;
              editableNode.connections[index]["value"] = edgeData?.toNodeId;
              break;
            }
          }
          break;
        }
        case NodeType.AI_ASSISTANT: {
          switch (editableNode.sub_type) {
            case NodeSubType.AI_GENERAL_PURPOSE_ASSISTANT: {
              const index = edgeData?.sourceHandler;
              editableNode.conditions[0]["operator_values"][index]["value"] =
                edgeData?.toNodeId;
              break;
            }
          }
          break;
        }
        case NodeType.ACTION: {
          const keys = Object.keys(editableNode.actions);
          switch (keys[0]) {
            case NodeSubType.START_FLOW: {
              editableNode.actions.start_flow.node_id = edgeData?.toNodeId;
              break;
            }
            case NodeSubType.CSAT_NPS:
            case "csat_nps": {
              const index = edgeData?.sourceHandler;
              if (index === "fallback") {
                editableNode.actions.csat_nps.fallback.fallbackNode =
                  edgeData?.toNodeId;
                editableNode.actions.csat_nps.fallback_node =
                  edgeData?.toNodeId;
              } else if (index !== "source") {
                editableNode.actions.csat_nps.conditions[0].operator_values[
                  index
                ]["value"] = edgeData?.toNodeId;
              } else {
                editableNode.actions.csat_nps.default_node = edgeData?.toNodeId;
              }
              break;
            }
            case NodeSubType.EMAIL_CSAT: {
              const index = edgeData?.sourceHandler;
              if (index === "fallback") {
                editableNode.actions.email_csat.fallback.fallbackNode =
                  edgeData?.toNodeId;
                editableNode.actions.email_csat.fallback_node =
                  edgeData?.toNodeId;
              } else if (index !== "source") {
                editableNode.actions.email_csat.conditions[0].operator_values[
                  index
                ]["value"] = edgeData?.toNodeId;
              } else {
                editableNode.actions.email_csat.default_node =
                  edgeData?.toNodeId;
              }
              break;
            }
            case NodeSubType.SHOPIFY_ADD_TAGS: {
              editableNode.actions.shopify_add_tags.relation =
                edgeData?.toNodeId;
              break;
            }
            case NodeSubType.ADD_CUSTOMER_TAGS: {
              editableNode.actions.add_customer_tags.relation =
                edgeData?.toNodeId;
              break;
            }
            case NodeSubType.SHOPIFY_ADD_NOTES: {
              editableNode.actions.shopify_add_notes.relation =
                edgeData?.toNodeId;
              break;
            }
            case NodeSubType.STORE_VARIABLE:
            case "store_value": {
              editableNode.actions.store_value.relation = edgeData?.toNodeId;
              break;
            }
            case NodeSubType.STORE_VALUE_EVALUATION: {
              editableNode.actions.store_value_evaluation.relation =
                edgeData?.toNodeId;
              break;
            }
            case NodeSubType.DISCOUNT_CODE: {
              editableNode.actions.discount_code.relation = edgeData?.toNodeId;
              break;
            }
            case NodeSubType.CALL_API: {
              editableNode.actions.call_api.relation = edgeData?.toNodeId;
              break;
            }
            case NodeSubType.MANIFEST_LLM_CALL: {
              const index = edgeData?.sourceHandler;
              editableNode.actions.manifest_llm_call.conditions[0].operator_values[
                index
              ]["value"] = edgeData?.toNodeId;
              break;
            }
            case NodeSubType.MANIFEST_EXIT:
            case NodeSubType.MANIFEST_BUILD_LLM_INPUT:
            case NodeSubType.MANIFEST_SEARCH:
            case NodeSubType.CALL_CUSTOM_API: {
              let actionObject: any = "";
              if (editableNode.sub_type === NodeSubType.MANIFEST_SEARCH) {
                actionObject = editableNode.actions.manifest_search;
              } else if (editableNode.sub_type === NodeSubType.MANIFEST_EXIT) {
                actionObject = editableNode.actions.manifest_exit;
              } else if (
                editableNode.sub_type === NodeSubType.MANIFEST_BUILD_LLM_INPUT
              ) {
                actionObject = editableNode.actions.manifest_build_llm_input;
              } else {
                actionObject = editableNode.actions.call_custom_api;
              }
              const index = edgeData?.sourceHandler;
              actionObject.conditions[0].operator_values[index]["value"] =
                edgeData?.toNodeId;
              break;
            }
            case NodeSubType.REST_API: {
              const index = edgeData?.sourceHandler;
              editableNode.actions.rest_api.conditions[0].operator_values[
                index
              ]["value"] = edgeData?.toNodeId;
              break;
            }
            case NodeSubType.BROADCAST_EVENTS: {
              editableNode.actions.broadcast_events.relation =
                edgeData?.toNodeId;
              break;
            }
            case NodeSubType.CALL_EXTERNAL_API: {
              const index = edgeData?.sourceHandler;
              editableNode.actions.call_external_api.conditions[0].operator_values[
                index
              ]["value"] = edgeData?.toNodeId;
              break;
            }
            case NodeSubType.ADD_EVENT: {
              editableNode.actions.add_event.relation = edgeData?.toNodeId;
              break;
            }
            case NodeSubType.FETCH_PRODUCT: {
              editableNode.actions.fetch_product.relation = edgeData?.toNodeId;
              break;
            }
            case NodeSubType.FETCH_CATALOG: {
              editableNode.actions.fetch_catalog.relation = edgeData?.toNodeId;
              break;
            }
            case NodeSubType.ADD_TICKET_LABEL: {
              editableNode.actions.add_ticket_label.relation =
                edgeData?.toNodeId;
              break;
            }
            case NodeSubType.CHANGE_TICKET_STAGE: {
              editableNode.actions.change_ticket_stage.relation =
                edgeData?.toNodeId;
              break;
            }
            case NodeSubType.ASSIGN_TICKET: {
              const index = edgeData?.sourceHandler;
              editableNode.actions.assign_ticket.conditions[0].operator_values[
                index
              ]["value"] = edgeData?.toNodeId;
              break;
            }
            case NodeSubType.AB_TEST: {
              const index = edgeData?.sourceHandler;
              editableNode.actions.ab_test.conditions[0].operator_values[index][
                "value"
              ] = edgeData?.toNodeId;
              break;
            }
            case NodeSubType.ASSIGN_TO_AGENT: {
              const index = edgeData?.sourceHandler;
              editableNode.actions.assign_to_agent.conditions[0].operator_values[
                index
              ]["value"] = edgeData?.toNodeId;
              break;
            }
            case NodeSubType.CHECK_WORKING_HOURS: {
              const index = edgeData?.sourceHandler;
              editableNode.actions.check_working_hours.conditions[0].operator_values[
                index
              ]["value"] = edgeData?.toNodeId;
              break;
            }
            case NodeSubType.FILTER: {
              const index = edgeData?.sourceHandler;
              editableNode.actions.filter.conditions[0].operator_values[index][
                "value"
              ] = edgeData?.toNodeId;
              break;
            }
            case NodeSubType.ORDER_FILTER: {
              const index = edgeData?.sourceHandler;
              editableNode.actions.order_filter.conditions[0].operator_values[
                index
              ]["value"] = edgeData?.toNodeId;
              break;
            }
            case NodeSubType.EVALUATE: {
              editableNode.actions.store_value_evaluation.relation =
                edgeData?.toNodeId;
              break;
            }
            case NodeSubType.PREVENT_BACKTRACKING: {
              editableNode.actions.prevent_backtracking.relation =
                edgeData?.toNodeId;
              break;
            }
            case NodeSubType.DELAY: {
              editableNode.actions.delay.relation = edgeData?.toNodeId;
              break;
            }
          }
          break;
        }
        case "instagram_message":
        case "message":
          if (editableNode.message_template?.carousel) {
            const index = edgeData?.sourceHandler;
            if (index === "fallback") {
              editableNode.fallback_node = edgeData?.toNodeId;
              editableNode.fallback.fallbackNode = edgeData?.toNodeId;
            } else if (index === "source") {
              editableNode.default_node = edgeData?.toNodeId;
            } else if (index.startsWith("drip-")) {
              editableNode.dripConf.nodeConnections[index]["value"] =
                edgeData?.toNodeId;
            } else {
              const conditions = editableNode.conditions;
              for (let i = 0; i < conditions.length; i++) {
                for (let key in conditions[i].operator_values) {
                  if (key === index) {
                    editableNode.conditions[i].operator_values[key]["value"] =
                      edgeData?.toNodeId;
                  }
                }
              }
            }
            break;
          } else {
            if (editableNode.isMicroflow) {
              const sourceHandler = edgeData?.sourceHandler;
              const nextNodeId = edgeData?.toNodeId;
              if (sourceHandler === "fallback") {
                switch (editableNode.sub_type) {
                  case NodeSubType.CATALOG_LIST:
                  case NodeSubType.ORDER_LIST:
                    if (editableNode?.v2) {
                      editableNode.template.M1.fallback.fallbackNode =
                        nextNodeId;
                      editableNode.fallback_node = nextNodeId;
                    } else {
                      editableNode.template.M2.fallback.fallbackNode =
                        nextNodeId;
                      editableNode.fallback_node = nextNodeId;
                    }
                    break;

                  case NodeSubType.ASK_NAME:
                  case NodeSubType.ASK_EMAIL:
                  case NodeSubType.ASK_PHONE:
                    editableNode.template.M1.fallback.fallbackNode = nextNodeId;
                    editableNode.fallback_node = nextNodeId;
                    break;
                  default:
                    break;
                }
              } else if (sourceHandler === "source") {
                editableNode.next_step_node = nextNodeId;
                if (editableNode.sub_type === "product_card") {
                  editableNode.template.M1.default_node = nextNodeId;
                }
              } else {
                const path = editableNode.danglingNodes[sourceHandler];
                editableNode.template = populate_microflow_conf(
                  editableNode.template,
                  {
                    [path]: nextNodeId,
                  }
                );
              }
            } else {
              const index = edgeData?.sourceHandler;
              // handling edge update from one node to another
              if (oldEdge) {
                const found = editableNode.relations.indexOf(oldEdge?.target);
                if (found !== -1) {
                  editableNode.relations.splice(found, 1);
                }
              }
              if (index === "fallback") {
                editableNode.fallback_node = edgeData?.toNodeId;
                editableNode.fallback.fallbackNode = edgeData?.toNodeId;
              } else if (index !== "source") {
                if (index.startsWith("drip-")) {
                  editableNode.dripConf.nodeConnections[index]["value"] =
                    edgeData?.toNodeId;
                } else {
                  editableNode.conditions[0].operator_values[index]["value"] =
                    edgeData?.toNodeId;
                }
              } else {
                editableNode.default_node = edgeData?.toNodeId;
              }
            }
            break;
          }
        case NodeType.ORDER.toLowerCase(): {
          const conditions = editableNode.conditions;
          const index = edgeData?.sourceHandler;
          // For default node | Next step
          if (index === "source") {
            editableNode.default_node = edgeData?.toNodeId;
          }
          for (let i = 0; i < conditions.length; i++) {
            for (let key in conditions[i].operator_values) {
              if (key === index) {
                editableNode.conditions[i].operator_values[key]["value"] =
                  edgeData?.toNodeId;
              }
            }
          }
          break;
        }
        case "run":
          const index = edgeData?.sourceHandler;
          editableNode.actions.run.conditions[0].operator_values[index][
            "value"
          ] = edgeData?.toNodeId;
          break;
      }
    } catch (err: any) {
      error = err?.message || "error";
    } finally {
      return { editableNode, error };
    }
  }

  handleNodesDeletion(
    nodes: any,
    storeState: StoreDataState,
    deleteNodeIds: string[],
    toDeleteEdges: any
  ) {
    try {
      const flow: any = cloneDeep(storeState);
      for (const edge of toDeleteEdges) {
        for (let deleteNodeId of deleteNodeIds) {
          const nodeId =
            edge.source === deleteNodeId ? edge.target : edge.source;
          if (nodeId) {
            const { nodeData, error } = this.removeReferences(
              flow[nodeId],
              deleteNodeId,
              edge.sourceHandle
            );
            if (error) {
              return { newNodes: nodes, flow: storeState, error };
            }
            flow[nodeId] = nodeData;
          }
        }
      }
      deleteNodeIds.forEach((nodeId) => {
        delete flow[nodeId];
      });
      const newNodes =
        nodes.filter((node: any) => !deleteNodeIds.includes(node.id)) || nodes;
      return { newNodes, flow, error: "" };
    } catch (error: any) {
      const msg = `handleNodesDeletion: ${error?.message}`;
      this.slackNotification.reportError("handleNodesDeletion", msg).then();
      return { newNodes: nodes, flow: storeState, error: msg };
    }
  }

  handleEdgesDeletion(
    edges: any,
    toDeleteEdge: any,
    storeState?: StoreDataState
  ) {
    try {
      const flow: any = cloneDeep(storeState);
      const { nodeData, error } = this.removeReferences(
        flow[toDeleteEdge.source],
        toDeleteEdge.target,
        toDeleteEdge.sourceHandleId
      );
      if (error) {
        return { storeState, edges, error };
      }
      flow[toDeleteEdge.source] = nodeData;
      const newEdges =
        edges.filter((edge: any) => toDeleteEdge.id !== edge.id) || edges;
      return { flow, newEdges, error: "" };
    } catch (error: any) {
      const msg = `handleEdgeDeletion: ${error?.message}`;
      this.slackNotification.reportError("handleNodesDeletion", msg).then();
      return { storeState, edges, error: msg };
    }
  }

  removeReferences(
    node: any,
    deleteId: string,
    sourceId?: string,
    forceEmpty: boolean = false
  ) {
    let error = "";
    try {
      switch (node.type?.toLowerCase()) {
        case NodeType.TRIGGER: {
          for (const [key, values] of Object.entries(
            node?.conditions[0]?.operator_values
          )) {
            if (
              forceEmpty ||
              ((values as unknown as any)["value"] === deleteId &&
                key === sourceId)
            ) {
              node.conditions[0].operator_values[key]["value"] = "";
            }
          }
          break;
        }
        case NodeType.CONDITION: {
          for (const [key, values] of Object.entries(
            node?.conditions[0]?.operator_values
          )) {
            if (
              forceEmpty ||
              ((values as unknown as any)["value"] === deleteId &&
                key === sourceId)
            ) {
              node.conditions[0].operator_values[key]["value"] = "";
            }
          }
          break;
        }

        case NodeType.AI.toLowerCase(): {
          switch (node.sub_type) {
            case "ai_classify": {
              for (const [key, values] of Object.entries(node?.connections)) {
                if (
                  forceEmpty ||
                  ((values as unknown as any)["value"] === deleteId &&
                    key === sourceId)
                ) {
                  node.connections[key]["value"] = "";
                }
              }
              break;
            }

            case NodeSubType.AI_ASK: {
              for (const [key, values] of Object.entries(node?.connections)) {
                if (
                  forceEmpty ||
                  ((values as unknown as any)["value"] === deleteId &&
                    key === sourceId)
                ) {
                  node.connections[key]["value"] = "";
                }
              }
              break;
            }

            case "ai_product_recommend": {
              for (const [key, values] of Object.entries(node?.connections)) {
                if (
                  forceEmpty ||
                  ((values as unknown as any)["value"] === deleteId &&
                    key === sourceId)
                ) {
                  node.connections[key]["value"] = "";
                }
              }
              break;
            }

            case "ai_reply": {
              for (const [key, values] of Object.entries(node?.connections)) {
                if (
                  forceEmpty ||
                  ((values as unknown as any)["value"] === deleteId &&
                    key === sourceId)
                ) {
                  node.connections[key]["value"] = "";
                }
              }
              break;
            }
          }
          break;
        }
        case NodeType.AI_ASSISTANT: {
          switch (node.sub_type) {
            case NodeSubType.AI_GENERAL_PURPOSE_ASSISTANT: {
              for (const [key, values] of Object.entries(
                node?.conditions?.[0]?.operator_values ?? {}
              )) {
                if (
                  forceEmpty ||
                  ((values as unknown as any)["value"] === deleteId &&
                    key === sourceId)
                ) {
                  node.conditions[0]["operator_values"][key]["value"] = "";
                }
              }
              break;
            }
          }

          break;
        }

        case NodeType.ACTION: {
          const keys = Object.keys(node.actions);
          switch (keys[0]) {
            case NodeSubType.START_FLOW: {
              if (forceEmpty || node.actions.start_flow.node_id === deleteId) {
                node.actions.start_flow.node_id = "";
              }
              break;
            }
            case NodeSubType.CSAT_NPS:
            case "csat_nps": {
              if (
                forceEmpty ||
                (node.actions.csat_nps.fallback?.fallbackNode === deleteId &&
                  sourceId === "fallback")
              ) {
                if (node.actions.csat_nps?.fallback?.fallbackNode) {
                  node.actions.csat_nps.fallback.fallbackNode = "";
                }
                if (node.actions.csat_nps?.fallback_node) {
                  node.actions.csat_nps.fallback_node = "";
                }
              }
              if (
                forceEmpty ||
                (node.actions.csat_nps.default_node === deleteId &&
                  sourceId === "source")
              ) {
                node.actions.csat_nps.default_node = "";
              } else {
                for (const [key, values] of Object.entries(
                  node?.actions.csat_nps.conditions[0]?.operator_values
                )) {
                  if (
                    forceEmpty ||
                    ((values as unknown as any)["value"] === deleteId &&
                      key === sourceId)
                  ) {
                    node.actions.csat_nps.conditions[0].operator_values[key][
                      "value"
                    ] = "";
                  }
                }
              }
              break;
            }
            case NodeSubType.EMAIL_CSAT: {
              if (
                forceEmpty ||
                (node.actions.email_csat.fallback?.fallbackNode === deleteId &&
                  sourceId === "fallback")
              ) {
                if (node.actions.email_csat?.fallback?.fallbackNode) {
                  node.actions.email_csat.fallback.fallbackNode = "";
                }
                if (node.actions.email_csat?.fallback_node) {
                  node.actions.email_csat.fallback_node = "";
                }
              }
              if (
                forceEmpty ||
                (node.actions.email_csat.default_node === deleteId &&
                  sourceId === "source")
              ) {
                node.actions.email_csat.default_node = "";
              } else {
                for (const [key, values] of Object.entries(
                  node?.actions.email_csat.conditions[0]?.operator_values
                )) {
                  if (
                    forceEmpty ||
                    ((values as unknown as any)["value"] === deleteId &&
                      key === sourceId)
                  ) {
                    node.actions.email_csat.conditions[0].operator_values[key][
                      "value"
                    ] = "";
                  }
                }
              }
              break;
            }
            case NodeSubType.STORE_VARIABLE:
            case "store_value": {
              if (
                forceEmpty ||
                node.actions.store_value.relation === deleteId
              ) {
                node.actions.store_value.relation = "";
              }
              break;
            }
            case NodeSubType.CALL_API: {
              if (forceEmpty || node.actions.call_api.relation === deleteId) {
                node.actions.call_api.relation = "";
              }
              break;
            }
            case NodeSubType.MANIFEST_SEARCH: {
              const typedNode = node as ManifestSearchProductsData;
              if (
                forceEmpty ||
                typedNode.actions.manifest_search.relation === deleteId
              ) {
                typedNode.actions.manifest_search.relation = "";
              }
              break;
            }
            case NodeSubType.MANIFEST_EXIT: {
              const typedNode = node as ExitManifestNodeData;
              if (
                forceEmpty ||
                typedNode.actions.manifest_exit.relation === deleteId
              ) {
                typedNode.actions.manifest_exit.relation = "";
              }
              break;
            }
            case NodeSubType.SHOPIFY_ADD_TAGS: {
              if (
                forceEmpty ||
                node.actions.shopify_add_tags.relation === deleteId
              ) {
                node.actions.shopify_add_tags.relation = "";
              }
              break;
            }
            case NodeSubType.DISCOUNT_CODE: {
              if (
                forceEmpty ||
                node.actions.discount_code.relation === deleteId
              ) {
                node.actions.discount_code.relation = "";
              }
              break;
            }
            case NodeSubType.SHOPIFY_ADD_NOTES: {
              if (
                forceEmpty ||
                node.actions.shopify_add_notes.relation === deleteId
              ) {
                node.actions.shopify_add_notes.relation = "";
              }
              break;
            }
            case NodeSubType.ADD_EVENT: {
              if (forceEmpty || node.actions.add_event.relation === deleteId) {
                node.actions.add_event.relation = "";
              }
              break;
            }

            case NodeSubType.FETCH_PRODUCT: {
              if (
                forceEmpty ||
                node.actions.fetch_product.relation === deleteId
              ) {
                node.actions.fetch_product.relation = "";
              }
              break;
            }

            case NodeSubType.FETCH_CATALOG: {
              if (
                forceEmpty ||
                node.actions.fetch_catalog.relation === deleteId
              ) {
                node.actions.fetch_catalog.relation = "";
              }
              break;
            }
            case NodeSubType.AB_TEST: {
              for (const [key, values] of Object.entries(
                node?.actions?.ab_test?.conditions[0]?.operator_values
              )) {
                if (
                  forceEmpty ||
                  ((values as unknown as any)["value"] === deleteId &&
                    key === sourceId)
                ) {
                  node.actions.ab_test.conditions[0].operator_values[key][
                    "value"
                  ] = "";
                }
              }
              break;
            }

            case NodeSubType.FILTER: {
              for (const [key, values] of Object.entries(
                node?.actions?.filter?.conditions[0]?.operator_values
              )) {
                if (
                  forceEmpty ||
                  ((values as unknown as any)["value"] === deleteId &&
                    key === sourceId)
                ) {
                  node.actions.filter.conditions[0].operator_values[key][
                    "value"
                  ] = "";
                }
              }
              break;
            }

            case NodeSubType.ORDER_FILTER: {
              for (const [key, values] of Object.entries(
                node?.actions?.order_filter?.conditions[0]?.operator_values
              )) {
                if (
                  forceEmpty ||
                  ((values as unknown as any)["value"] === deleteId &&
                    key === sourceId)
                ) {
                  node.actions.order_filter.conditions[0].operator_values[key][
                    "value"
                  ] = "";
                }
              }
              break;
            }

            case NodeSubType.ASSIGN_TO_AGENT: {
              for (const [key, values] of Object.entries(
                node?.actions?.assign_to_agent?.conditions[0]?.operator_values
              )) {
                if (
                  forceEmpty ||
                  ((values as unknown as any)["value"] === deleteId &&
                    key === sourceId)
                ) {
                  node.actions.assign_to_agent.conditions[0].operator_values[
                    key
                  ]["value"] = "";
                }
              }
              break;
            }
            case NodeSubType.EVALUATE: {
              if (
                forceEmpty ||
                node.actions.store_value_evaluation.relation === deleteId
              ) {
                node.actions.store_value_evaluation.relation = "";
              }
              break;
            }
            case NodeSubType.PREVENT_BACKTRACKING: {
              if (
                forceEmpty ||
                node.actions.prevent_backtracking.relation === deleteId
              ) {
                node.actions.prevent_backtracking.relation = "";
              }
              break;
            }
            case NodeSubType.CALL_CUSTOM_API: {
              if (forceEmpty) {
                node.actions.call_custom_api.relation = "";
              }
              for (const [key, values] of Object.entries(
                node?.actions?.call_custom_api?.conditions[0]?.operator_values
              )) {
                if (
                  forceEmpty ||
                  ((values as unknown as any)["value"] === deleteId &&
                    key === sourceId)
                ) {
                  node.actions.call_custom_api.conditions[0].operator_values[
                    key
                  ]["value"] = "";
                }
              }
              break;
            }
            case NodeSubType.MANIFEST_BUILD_LLM_INPUT:
            case NodeSubType.MANIFEST_SEARCH:
            case NodeSubType.MANIFEST_EXIT: {
              let actionObject: any = "";
              if (node.sub_type === NodeSubType.MANIFEST_SEARCH) {
                actionObject = node.actions.manifest_search;
              } else if (node.sub_type === NodeSubType.MANIFEST_EXIT) {
                actionObject = node.actions.manifest_exit;
              } else if (
                node.sub_type === NodeSubType.MANIFEST_BUILD_LLM_INPUT
              ) {
                actionObject = node.actions.manifest_build_llm_input;
              }

              if (forceEmpty) {
                actionObject.relation = "";
              }

              for (const [key, values] of Object.entries(
                actionObject?.conditions[0]?.operator_values
              )) {
                if (
                  forceEmpty ||
                  ((values as unknown as any)["value"] === deleteId &&
                    key === sourceId)
                ) {
                  actionObject.conditions[0].operator_values[key]["value"] = "";
                }
              }
              break;
            }
            case NodeSubType.BROADCAST_EVENTS: {
              if (
                forceEmpty ||
                node.actions.broadcast_events?.relation === deleteId
              ) {
                node.actions.broadcast_events.relation = "";
              }
              break;
            }
            case NodeSubType.REST_API: {
              for (const [key, values] of Object.entries(
                node?.actions?.rest_api?.conditions[0]?.operator_values
              )) {
                if (
                  forceEmpty ||
                  ((values as unknown as any)["value"] === deleteId &&
                    key === sourceId)
                ) {
                  node.actions.rest_api.conditions[0].operator_values[key][
                    "value"
                  ] = "";
                }
              }
              break;
            }
            case NodeSubType.CALL_EXTERNAL_API: {
              for (const [key, values] of Object.entries(
                node?.actions?.call_external_api?.conditions[0]?.operator_values
              )) {
                if (
                  forceEmpty ||
                  ((values as unknown as any)["value"] === deleteId &&
                    key === sourceId)
                ) {
                  node.actions.call_external_api.conditions[0].operator_values[
                    key
                  ]["value"] = "";
                }
              }
              break;
            }
            case NodeSubType.CHANGE_TICKET_STAGE: {
              if (node.actions.change_ticket_stage.relation === deleteId) {
                node.actions.change_ticket_stage.relation = "";
              }
              break;
            }
            case NodeSubType.ADD_TICKET_LABEL: {
              if (node.actions.add_ticket_label.relation === deleteId) {
                node.actions.add_ticket_label.relation = "";
              }
              break;
            }
            case NodeSubType.CHECK_WORKING_HOURS: {
              for (const [key, values] of Object.entries(
                node?.actions?.check_working_hours?.conditions[0]
                  ?.operator_values
              )) {
                if (
                  forceEmpty ||
                  ((values as unknown as any)["value"] === deleteId &&
                    key === sourceId)
                ) {
                  node.actions.check_working_hours.conditions[0].operator_values[
                    key
                  ]["value"] = "";
                }
              }
              break;
            }
            case NodeSubType.DELAY: {
              if (forceEmpty || node.actions.delay.relation === deleteId) {
                node.actions.delay.relation = "";
              }
              break;
            }
            case NodeSubType.ASSIGN_TICKET: {
              for (const [key, values] of Object.entries(
                node?.actions?.assign_ticket?.conditions[0]?.operator_values
              )) {
                if (
                  forceEmpty ||
                  ((values as unknown as any)["value"] === deleteId &&
                    key === sourceId)
                ) {
                  node.actions.assign_ticket.conditions[0].operator_values[key][
                    "value"
                  ] = "";
                }
              }
              break;
            }
          }
          break;
        }
        case NodeType.INSTAGRAM_MESSAGE:
        case NodeType.MESSAGE: {
          if (node?.sub_type === "product_carousel") {
            const conditions = node.conditions;
            for (let i = 0; i < conditions.length; i++) {
              for (let key in conditions[i].operator_values) {
                if (forceEmpty || key === sourceId) {
                  node.conditions[i].operator_values[key]["value"] = "";
                }
              }
            }

            // delete node connection entries
            const dripConnections = node?.dripConf?.nodeConnections || {};
            Object.entries(dripConnections).forEach(([k, v]: [string, any]) => {
              if ((k === sourceId && v["value"] === deleteId) || forceEmpty) {
                node.dripConf.nodeConnections[k]["value"] = "";
              }
            });

            if (
              forceEmpty ||
              (node?.default_node === deleteId && sourceId === "source")
            ) {
              node.default_node = "";
            }
            if (
              forceEmpty ||
              (node?.fallback?.fallbackNode === deleteId &&
                sourceId === "fallback")
            ) {
              if (node?.fallback?.fallbackNode) {
                node.fallback.fallbackNode = "";
              }
              if (node?.fallback_node) {
                node.fallback_node = "";
              }
            }
            break;
          } else if (node.message_template?.carousel) {
            const conditions = node.conditions;
            for (let i = 0; i < conditions.length; i++) {
              for (let key in conditions[i].operator_values) {
                if (key === sourceId) {
                  node.conditions[i].operator_values[key]["value"] = "";
                }
              }
            }

            // delete node connection entries
            const dripConnections = node?.dripConf?.nodeConnections || {};
            Object.entries(dripConnections).forEach(([k, v]: [string, any]) => {
              if ((k === sourceId && v["value"] === deleteId) || forceEmpty) {
                node.dripConf.nodeConnections[k]["value"] = "";
              }
            });

            if (
              forceEmpty ||
              (node?.default_node === deleteId && sourceId === "source")
            ) {
              node.default_node = "";
            }
            break;
          } else {
            if (node.isMicroflow) {
              if (forceEmpty) {
                node.next_step_node = "";
                if (node.sub_type === "product_card") {
                  node.template.M1.default_node = "";
                }
              }
              if (sourceId === "fallback") {
                switch (node.sub_type) {
                  case NodeSubType.CATALOG_LIST:
                  case NodeSubType.ORDER_LIST:
                    if (
                      forceEmpty ||
                      node.template.M2.fallback.fallbackNode === deleteId
                    ) {
                      if (node.template.M2?.fallback?.fallbackNode) {
                        node.template.M2.fallback.fallbackNode = "";
                      }
                      if (node?.fallback_node) {
                        node.fallback_node = "";
                      }
                    }
                    break;
                  case NodeSubType.ASK_NAME:
                  case NodeSubType.ASK_EMAIL:
                  case NodeSubType.ASK_PHONE:
                    if (
                      forceEmpty ||
                      node.template.M1.fallback.fallbackNode === deleteId
                    ) {
                      if (node.template.M1?.fallback?.fallbackNode) {
                        node.template.M1.fallback.fallbackNode = "";
                      }
                      if (node?.fallback_node) {
                        node.fallback_node = "";
                      }
                    }
                    break;
                  default:
                    break;
                }
              }
              if (node.next_step_node === deleteId && sourceId === "source") {
                node.next_step_node = "";
                if (node.sub_type === "product_card") {
                  node.template.M1.default_node = "";
                }
              } else {
                const danglingNodePaths: any = [];
                const danglingNodes = node.danglingNodes;
                Object.keys(danglingNodes).forEach((key) => {
                  if (forceEmpty || key === sourceId) {
                    danglingNodePaths.push(danglingNodes?.[key]);
                  }
                });
                const connectionData = reparseMicroFlowConf(
                  node.template,
                  danglingNodePaths,
                  true
                );
                const toPopulateData: any = {};
                Object.entries(connectionData).forEach(([key, val]) => {
                  if (forceEmpty || val === deleteId) {
                    if (key.includes("drip-")) {
                      toPopulateData[key] = "";
                    } else {
                      toPopulateData[key] = "E";
                    }
                  }
                });
                node.template = populate_microflow_conf(
                  node.template,
                  toPopulateData
                );
              }
            } else {
              for (const [key, values] of Object.entries(
                node?.conditions?.[0]?.operator_values || {}
              )) {
                if (
                  forceEmpty ||
                  ((values as unknown as any)["value"] === deleteId &&
                    key === sourceId)
                ) {
                  node.conditions[0].operator_values[key]["value"] = "";
                }
              }

              const dripConnections = node?.dripConf?.nodeConnections || {};
              Object.entries(dripConnections).forEach(
                ([k, v]: [string, any]) => {
                  if (
                    (k === sourceId && v["value"] === deleteId) ||
                    forceEmpty
                  ) {
                    node.dripConf.nodeConnections[k]["value"] = "";
                  }
                }
              );

              if (
                forceEmpty ||
                (node?.default_node === deleteId && sourceId === "source")
              ) {
                node.default_node = "";
              }
              if (
                forceEmpty ||
                (node?.fallback?.fallbackNode === deleteId &&
                  sourceId === "fallback")
              ) {
                if (node?.fallback?.fallbackNode) {
                  node.fallback.fallbackNode = "";
                }
                if (node?.fallback_node) {
                  node.fallback_node = "";
                }
              }
            }
            break;
          }
        }
        case NodeType.SPM.toLowerCase(): {
          if (forceEmpty || node.relation === deleteId) {
            node.relation = "";
          }
          break;
        }
        case NodeType.MPM.toLowerCase(): {
          if (forceEmpty || node.relation === deleteId) {
            node.relation = "";
          }
          break;
        }
        case NodeType.ORDER.toLowerCase(): {
          for (const [key, values] of Object.entries(
            node?.conditions?.[0]?.operator_values || {}
          )) {
            if (
              forceEmpty ||
              ((values as unknown as any)["value"] === deleteId &&
                key === sourceId)
            ) {
              node.conditions[0].operator_values[key]["value"] = "";
            }
          }
          // For default node | Next step
          if (forceEmpty || sourceId === "source") {
            node.default_node = "";
          }
          break;
        }
        case "run": {
          for (const [key, values] of Object.entries(
            node?.actions?.run?.conditions[0]?.operator_values
          )) {
            if (
              forceEmpty ||
              ((values as unknown as any)["value"] === deleteId &&
                key === sourceId)
            ) {
              node.actions.run.conditions[0].operator_values[key]["value"] = "";
            }
          }
          break;
        }
      }
    } catch (err: any) {
      error = err?.message || "error";
    } finally {
      return { nodeData: node, error };
    }
  }
}
