import { useEffect, useState } from "react";
import { FirebaseAuthHelper } from "../../services/firebase/auth/firebaseAuth";
import { FirebaseService } from "../../services/firebase/FirebaseService";
import Modal from "../../ui-components/modal";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import { isValidChannel } from "../../utilities/login.utility";
import { captureErrorToSentry } from "../../utilities/sentryHelper";
import { Button } from "@bikdotai/bik-component-library";
import { userDataState } from "../../state/storeDataStateType";

export const LoginModal = styled.div`
  width: 250px;
  height: 150px;
  background: #ffffff;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 24px;
  .m-auto {
    margin: auto;
  }

  .modal {
    &__heading {
      font-family: "Inter", serif;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: #212121;
      padding-bottom: 8px;
    }
    &__text {
      font-family: "Inter", serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #616161;
    }
    &__footer {
      display: flex;
      padding-top: 8px;
    }
  }
  .discard__text {
    font-family: "Inter", serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #616161;
    padding-right: 12px;
    padding-top: 10px;
    cursor: pointer;
  }
`;

interface LoginProps {
  onLogin: (val: boolean) => void;
  requestingEntryPath?: boolean;
  redirectTo404: (val: boolean) => void;
  validatingUser: boolean;
  mode?: string;
  handleRedirectionWhenNotAuthenticated?: () => void;
  setUserData?: (val: userDataState) => void;
}

function Login(props: LoginProps) {
  const firebaseAuth = new FirebaseAuthHelper();
  const { storeId, flowId, token, channel, nodeId } = useParams();
  const [showSSOPopup, setShowSSOPopup] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!token && !props.validatingUser) {
      setShowSSOPopup(true);
    }
  }, [flowId, token, props.validatingUser]);

  if (storeId && flowId && !isValidChannel(channel!)) {
    props.redirectTo404(true);
    return null;
  }
  if (storeId && token && !props?.requestingEntryPath) {
    firebaseAuth
      .signInWithToken(token)
      .then(
        (userCreads) => {
          userCreads?.user
            .getIdTokenResult()
            .then(async (token) => {
              if (
                userCreads?.user?.uid === storeId ||
                !!token.claims[storeId] === true
              ) {
                const userDetails = {
                  uid: userCreads.user.uid || "",
                  email: userCreads.user.email || "",
                  loginType: "token",
                  agentId: NaN,
                };
                props.setUserData?.(userDetails);
                if (nodeId) {
                  navigate(
                    `/${storeId}/${flowId}/${channel}/${props.mode}/${nodeId}`
                  );
                } else {
                  navigate(`/${storeId}/${flowId}/${channel}/${props.mode}`);
                }
                props.onLogin(true);
              } else {
                firebaseAuth.signOut().then();
                props.onLogin(false);
                props.handleRedirectionWhenNotAuthenticated &&
                  props.handleRedirectionWhenNotAuthenticated();
              }
            })
            .catch((error) => {
              captureErrorToSentry(error, "getIdTokenResult exception");
              props.handleRedirectionWhenNotAuthenticated &&
                props.handleRedirectionWhenNotAuthenticated();
            });
        },
        (err) => {
          captureErrorToSentry(err, "FireBaseAuth SignInWithToken Exception");
          firebaseAuth.signOut().then();
          props.handleRedirectionWhenNotAuthenticated &&
            props.handleRedirectionWhenNotAuthenticated();
          props.onLogin(false);
        }
      )
      .catch((error) => {
        captureErrorToSentry(error, "SigninWithToken exception");
        props.handleRedirectionWhenNotAuthenticated &&
          props.handleRedirectionWhenNotAuthenticated();
      });
  }

  const loginWithGoogle = () => {
    firebaseAuth
      .loginWithFirebaseAppGoogle()
      .then(async () => {
        const user = await FirebaseService.fetchCurrentUser();

        if (!user) {
          props.onLogin(false);
        } else {
          if (storeId && flowId && channel) {
            navigate(`/${storeId}/${flowId}/${channel}/analytics`);
          } else {
            navigate(`/v2/bik-chatbot/flows`);
          }
          props.onLogin(true);
          window.location.reload();
        }
      })
      .catch(() => {
        firebaseAuth.signOut().then();
        props.onLogin(false);
        setShowSSOPopup(true);
      });
  };

  return (
    <>
      {showSSOPopup && (
        <Modal>
          <LoginModal>
            <div className="modal__heading">Login</div>
            <div className="modal__footer">
              <Button
                size={"large"}
                buttonType={"primary"}
                buttonText={"Login with Google"}
                onClick={loginWithGoogle}
                className={"m-auto"}
              />
            </div>
          </LoginModal>
        </Modal>
      )}
    </>
  );
}

export default Login;
