import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StickyNotesState } from "./storeDataStateType";

const initialState: StickyNotesState = {
  notes: {},
  id: "",
  data: {
    content: "",
    createdDate: null,
    updatedDate: null,
    nodeId: "",
  },
};

const stickyNotesSlice = createSlice({
  name: "stickyNotes",
  initialState: initialState,
  reducers: {
    updateInsertNode: (state, action: PayloadAction<StickyNotesState>) => {
      const { id, data } = action.payload;
      if (id && data && Object.keys(data).length) state.notes[id] = data;
      return state;
    },
    restoreNotes: (state, action: PayloadAction<StickyNotesState>) => {
      state.notes = {
        ...action.payload.notes,
      };
      return state;
    },
  },
});

export const stickyNotesActions = stickyNotesSlice.actions;
export default stickyNotesSlice;
