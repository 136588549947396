const StepOne = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="35" height="35" rx="17.5" stroke="#616161" />
      <path
        d="M13.0341 23.1392C12.285 23.1392 11.647 22.9354 11.12 22.5277C10.593 22.1167 10.1903 21.5218 9.91193 20.7429C9.63352 19.9607 9.49432 19.0161 9.49432 17.9091C9.49432 16.8087 9.63352 15.8691 9.91193 15.0902C10.1937 14.308 10.598 13.7114 11.125 13.3004C11.6553 12.8861 12.2917 12.679 13.0341 12.679C13.7765 12.679 14.4112 12.8861 14.9382 13.3004C15.4685 13.7114 15.8729 14.308 16.1513 15.0902C16.433 15.8691 16.5739 16.8087 16.5739 17.9091C16.5739 19.0161 16.4347 19.9607 16.1562 20.7429C15.8778 21.5218 15.4751 22.1167 14.9482 22.5277C14.4212 22.9354 13.7831 23.1392 13.0341 23.1392ZM13.0341 22.0455C13.7765 22.0455 14.3532 21.6875 14.7642 20.9716C15.1752 20.2557 15.3807 19.2348 15.3807 17.9091C15.3807 17.0275 15.2862 16.2768 15.0973 15.657C14.9117 15.0372 14.6432 14.5649 14.2919 14.2401C13.9439 13.9152 13.5246 13.7528 13.0341 13.7528C12.2983 13.7528 11.7232 14.1158 11.3089 14.8416C10.8946 15.5642 10.6875 16.5866 10.6875 17.9091C10.6875 18.7907 10.7803 19.5398 10.9659 20.1562C11.1515 20.7727 11.4183 21.2417 11.7663 21.5632C12.1177 21.8847 12.5402 22.0455 13.0341 22.0455ZM23.4707 12.8182V23H22.2377V14.1108H22.1781L19.6923 15.7614V14.5085L22.2377 12.8182H23.4707Z"
        fill="#616161"
      />
    </svg>
  );
};

export default StepOne;
