import { CustomNodeIconProps } from "../utilities/flowBuilder.utility";

const TablerTicketIcon = (props: CustomNodeIconProps) => {
  const { backgroundColor, color, height, width, style, showBorder } = props;
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="tabler:ticket">
        <path
          id="Vector (Stroke)"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.81484 3.33333C2.60489 3.33333 2.39961 3.41894 2.24527 3.5777C2.0903 3.73709 2.00002 3.95718 2.00002 4.19048V5.91765C2.32224 6.02493 2.61724 6.20921 2.85892 6.45779C3.25961 6.86993 3.4815 7.42501 3.4815 8C3.4815 8.57499 3.25961 9.13007 2.85892 9.54221C2.61724 9.79079 2.32224 9.97507 2.00002 10.0823V11.8095C2.00002 12.0428 2.0903 12.2629 2.24527 12.4223C2.39961 12.5811 2.60489 12.6667 2.81484 12.6667H13.1852C13.3952 12.6667 13.6004 12.5811 13.7548 12.4223C13.9097 12.2629 14 12.0428 14 11.8095V10.0823C13.6778 9.97507 13.3828 9.79079 13.1411 9.54221C12.7404 9.13007 12.5185 8.57499 12.5185 8C12.5185 7.42501 12.7404 6.86993 13.1411 6.45779C13.3828 6.20921 13.6778 6.02493 14 5.91765V4.19048C14 3.95718 13.9097 3.7371 13.7548 3.5777C13.6004 3.41894 13.3952 3.33333 13.1852 3.33333H2.81484ZM1.28927 2.64826C1.69059 2.23548 2.23895 2 2.81484 2H13.1852C13.7611 2 14.3095 2.23548 14.7108 2.64826C15.1115 3.0604 15.3334 3.61549 15.3334 4.19048V6.47619C15.3334 6.84438 15.0349 7.14286 14.6667 7.14286C14.4567 7.14286 14.2515 7.22847 14.0971 7.38722C13.9422 7.54662 13.8519 7.76671 13.8519 8C13.8519 8.23329 13.9422 8.45338 14.0971 8.61278C14.2515 8.77154 14.4567 8.85714 14.6667 8.85714C15.0349 8.85714 15.3334 9.15562 15.3334 9.52381V11.8095C15.3334 12.3845 15.1115 12.9396 14.7108 13.3517C14.3095 13.7645 13.7611 14 13.1852 14H2.81484C2.23895 14 1.69059 13.7645 1.28927 13.3517C0.888578 12.9396 0.666687 12.3845 0.666687 11.8095V9.52381C0.666687 9.15562 0.965164 8.85714 1.33335 8.85714C1.5433 8.85714 1.74857 8.77154 1.90292 8.61278C2.05789 8.45338 2.14817 8.23329 2.14817 8C2.14817 7.76671 2.05789 7.54662 1.90292 7.38722C1.74857 7.22847 1.5433 7.14286 1.33335 7.14286C0.965164 7.14286 0.666687 6.84438 0.666687 6.47619V4.19048C0.666687 3.61549 0.888578 3.0604 1.28927 2.64826Z"
          fill="#616161"
        />
        <path
          id="Star 1"
          d="M7.27346 6.23607C7.50214 5.53225 8.49786 5.53225 8.72654 6.23607C8.82881 6.55083 9.12213 6.76393 9.45308 6.76393C10.1931 6.76393 10.5008 7.71091 9.90211 8.1459C9.63436 8.34043 9.52233 8.68524 9.6246 9C9.85328 9.70382 9.04773 10.2891 8.44903 9.8541C8.18128 9.65957 7.81872 9.65957 7.55097 9.8541C6.95227 10.2891 6.14672 9.70382 6.3754 9C6.47767 8.68524 6.36564 8.34043 6.09789 8.1459C5.49918 7.71091 5.80688 6.76393 6.54691 6.76393C6.87787 6.76393 7.17119 6.55083 7.27346 6.23607Z"
          fill="#616161"
        />
      </g>
    </svg>
  );
};

export default TablerTicketIcon;
