import { CustomNodeIconProps } from "../utilities/flowBuilder.utility";

const AudioIcon = (props: CustomNodeIconProps) => {
  const { backgroundColor, color, height, width } = props;
  return (
    <svg
      width={width || "22"}
      height={height || "32"}
      viewBox="0 0 22 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.0002 0C9.58568 0 8.22912 0.561903 7.22893 1.5621C6.22873 2.56229 5.66683 3.91885 5.66683 5.33333V16C5.66683 17.4145 6.22873 18.771 7.22893 19.7712C8.22912 20.7714 9.58568 21.3333 11.0002 21.3333C12.4146 21.3333 13.7712 20.7714 14.7714 19.7712C15.7716 18.771 16.3335 17.4145 16.3335 16V5.33333C16.3335 3.91885 15.7716 2.56229 14.7714 1.5621C13.7712 0.561903 12.4147 0 11.0002 0ZM9.11454 3.44772C9.61464 2.94762 10.2929 2.66667 11.0002 2.66667C11.7074 2.66667 12.3857 2.94762 12.8858 3.44772C13.3859 3.94781 13.6668 4.62609 13.6668 5.33333V16C13.6668 16.7072 13.3859 17.3855 12.8858 17.8856C12.3857 18.3857 11.7074 18.6667 11.0002 18.6667C10.2929 18.6667 9.61464 18.3857 9.11454 17.8856C8.61445 17.3855 8.3335 16.7072 8.3335 16V5.33333C8.3335 4.62609 8.61445 3.94781 9.11454 3.44772ZM3.00016 13.3333C3.00016 12.597 2.40321 12 1.66683 12C0.93045 12 0.333496 12.597 0.333496 13.3333V16C0.333496 18.829 1.4573 21.5421 3.45769 23.5425C5.14119 25.226 7.32949 26.2886 9.66683 26.583V29.3333H5.66683C4.93045 29.3333 4.3335 29.9303 4.3335 30.6667C4.3335 31.403 4.93045 32 5.66683 32H11.0002H16.3335C17.0699 32 17.6668 31.403 17.6668 30.6667C17.6668 29.9303 17.0699 29.3333 16.3335 29.3333H12.3335V26.583C14.6708 26.2886 16.8591 25.226 18.5426 23.5425C20.543 21.5421 21.6668 18.829 21.6668 16V13.3333C21.6668 12.597 21.0699 12 20.3335 12C19.5971 12 19.0002 12.597 19.0002 13.3333V16C19.0002 18.1217 18.1573 20.1566 16.657 21.6569C15.1567 23.1571 13.1219 24 11.0002 24C8.87843 24 6.8436 23.1571 5.34331 21.6569C3.84302 20.1566 3.00016 18.1217 3.00016 16V13.3333Z"
        fill={color || "#9E9E9E"}
      />
    </svg>
  );
};

export default AudioIcon;
