const WhatsappIcon = (props: any) => {
  const { color } = props;
  const height = props.height || 20;
  const width = props.width || 20;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill={color || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_962_31609)">
        <path
          d="M0.426843 9.88016C0.426374 11.5606 0.868874 13.2013 1.71028 14.6475L0.346375 19.5888L5.44262 18.2629C6.85218 19.0243 8.43148 19.4232 10.0364 19.4233H10.0406C15.3386 19.4233 19.6514 15.1456 19.6536 9.88768C19.6547 7.33985 18.6556 4.94404 16.8404 3.14156C15.0256 1.33923 12.6119 0.346133 10.0402 0.344971C4.74153 0.344971 0.429109 4.62249 0.426921 9.88016"
          fill="url(#paint0_linear_962_31609)"
        />
        <path
          d="M0.0835938 9.87721C0.0830469 11.6181 0.541406 13.3175 1.41281 14.8155L0 19.9339L5.27898 18.5605C6.73352 19.3474 8.37117 19.7622 10.0376 19.7629H10.0419C15.53 19.7629 19.9977 15.3312 20 9.88512C20.0009 7.24574 18.9659 4.7638 17.0859 2.89674C15.2057 1.02992 12.7057 0.00108527 10.0419 0C4.55281 0 0.0858594 4.43101 0.0836719 9.87721H0.0835938ZM3.22734 14.5575L3.03023 14.2471C2.20164 12.9398 1.7643 11.4291 1.76492 9.87783C1.76672 5.35109 5.47953 1.66822 10.045 1.66822C12.2559 1.66915 14.3337 2.52434 15.8966 4.07597C17.4593 5.62775 18.3192 7.69054 18.3187 9.8845C18.3166 14.4112 14.6037 18.0946 10.0419 18.0946H10.0386C8.5532 18.0938 7.09641 17.698 5.82594 16.95L5.52359 16.7721L2.39094 17.5871L3.22734 14.5574V14.5575Z"
          fill="url(#paint1_linear_962_31609)"
        />
        <path
          d="M7.55298 5.74801C7.36658 5.33692 7.1704 5.32863 6.99314 5.32142C6.84798 5.31522 6.68204 5.31568 6.51626 5.31568C6.35032 5.31568 6.08072 5.37762 5.85283 5.62452C5.6247 5.87165 4.98189 6.46886 4.98189 7.68351C4.98189 8.89824 5.87353 10.0721 5.99783 10.237C6.12228 10.4016 7.71915 12.974 10.2482 13.9636C12.3501 14.786 12.7778 14.6224 13.234 14.5812C13.6902 14.5401 14.7062 13.9841 14.9135 13.4076C15.1209 12.8312 15.1209 12.3371 15.0587 12.2338C14.9965 12.131 14.8306 12.0692 14.5817 11.9458C14.3328 11.8223 13.1095 11.225 12.8815 11.1426C12.6534 11.0603 12.4875 11.0192 12.3216 11.2664C12.1556 11.5132 11.6792 12.0692 11.5339 12.2338C11.3888 12.3989 11.2436 12.4194 10.9949 12.2959C10.7459 12.172 9.94454 11.9117 8.99384 11.0706C8.25415 10.4162 7.75478 9.60808 7.60962 9.36088C7.46447 9.11405 7.59408 8.98026 7.71884 8.85723C7.83064 8.74661 7.96775 8.56894 8.09228 8.42483C8.21634 8.28064 8.25775 8.17777 8.34072 8.01312C8.42376 7.84832 8.3822 7.70413 8.32009 7.58064C8.25775 7.45715 7.77423 6.23622 7.55298 5.74809"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_962_31609"
          x1="10"
          y1="19.5888"
          x2="10"
          y2="0.344971"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1FAF38" />
          <stop offset="1" stop-color="#60D669" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_962_31609"
          x1="10"
          y1="19.9339"
          x2="10"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={"#F9F9F9"} />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <clipPath id="clip0_962_31609">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WhatsappIcon;
