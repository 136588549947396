import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NodeItemDataState } from "./storeDataStateType";

const initialState: any = {
  nodeItemList: {},
};

const NodeItemDataSlice = createSlice({
  name: "NodeItemData",
  initialState: initialState,
  reducers: {
    createNodeItemDataState: (state, action: PayloadAction<any>) => {
      state = action.payload;
      return state;
    },
    resetState: (state, action: PayloadAction<any>) => {
      return initialState;
    },
  },
});

export const nodeItemDataActions = NodeItemDataSlice.actions;
export default NodeItemDataSlice;
