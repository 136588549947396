import React from 'react';
import { COLORS } from '@bikdotai/bik-component-library';
import styled, { css } from 'styled-components';

export interface CommonTableI {
  tableHeadData: string[];
  tableData: CommonTableMainDataI[];
  rowStyles?: { index: number; styles: React.CSSProperties }[];
  isBik?: boolean;
  appplyAnalyticsStyle?: boolean;
  tdEllipsisMaxWidth?: string;
}

export type CommonTableMainDataI = {
  [key: string]: JSX.Element | string;
};

const CommonTable: React.FC<CommonTableI> = ({
  tableHeadData,
  tableData,
  rowStyles,
  isBik,
  appplyAnalyticsStyle,
  tdEllipsisMaxWidth,
}) => {
  const eachKeyOfTableData = Object.keys(tableData[0]);
  return (
    <CommonTableContainer
      isBik={isBik}
      appplyAnalyticsStyle={appplyAnalyticsStyle}
      tdEllipsisMaxWidth={tdEllipsisMaxWidth}
    >
      {tableHeadData && tableHeadData.length ? (
        <thead>
          <tr>
            {tableHeadData.map((item, index) => (
              <th key={index}>{item}</th>
            ))}
          </tr>
        </thead>
      ) : (
        <></>
      )}
      <tbody>
        {tableData.map((item, rowIndex) => (
          <tr key={rowIndex} style={getRowStyle(rowIndex)}>
            {eachKeyOfTableData.map((key, colIndex) => (
              <td
                key={colIndex}
                className="td-class"
                style={getRowStyle(rowIndex)}
                title={
                  typeof item[key] === 'object' ? '' : item[key]?.toString()
                }
              >
                <div
                  className={`${isBik ? 'left--style' : ''} ${appplyAnalyticsStyle ? 'ellipsis__td' : ''}`}
                  title={
                    typeof item[key] === 'object' ? '' : item[key]?.toString()
                  }
                >
                  {item[key]}
                </div>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </CommonTableContainer>
  );

  function getRowStyle(rowIndex: number): React.CSSProperties | undefined {
    if (rowStyles) {
      const styleObject = rowStyles.find(style => style.index === rowIndex);
      return styleObject?.styles;
    }
    return undefined;
  }
};

export default CommonTable;

export const CommonTableContainer = styled.table<{
  isBik?: boolean;
  appplyAnalyticsStyle?: boolean;
  tdEllipsisMaxWidth?: string;
}>`
  width: 100%;
  background-color: ${({ appplyAnalyticsStyle }) =>
    appplyAnalyticsStyle ? '#ffffff' : ''};
  border-radius: 8px;

  thead {
    height: 40px;
    background: ${({ appplyAnalyticsStyle }) =>
      appplyAnalyticsStyle
        ? COLORS.surface.subdued
        : COLORS.background.brandLight};
    flex: none;
    order: 2;
    flex-grow: 0;
    th {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: ${({ appplyAnalyticsStyle }) =>
        appplyAnalyticsStyle
          ? COLORS.content.primary
          : COLORS.background.brand};
      padding: 16px 12px;
      border-bottom: ${({ appplyAnalyticsStyle }) =>
        appplyAnalyticsStyle ? `1px solid ${COLORS.stroke.primary}` : 'none'};
    }
  }
  tr {
    border-bottom: 1px solid ${COLORS.stroke.primary};
    td {
      padding: 16px 12px;
    }
  }
  .columnTable {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .rowTable {
    display: flex;
    flex-direction: row;
    > * {
      margin-right: 24px;
    }
  }
  .alignRight {
    text-align: right;
  }
  th:first-child {
    border-radius: 8px 0px 0px 0px;
  }
  th:last-child {
    border-radius: 0px 8px 0px 0px;
  }
  td:first-child {
    ${({ isBik, appplyAnalyticsStyle }) => css`
      min-width: ${isBik && !appplyAnalyticsStyle ? '339px' : 'unset'};
      max-width: ${isBik && !appplyAnalyticsStyle ? '283px' : 'unset'};
    `}
    .left--style {
      display: flex;
      justify-content: flex-start;
    }
  }

  td {
    ${({ isBik }) => css`
      min-width: ${isBik ? '230px' : '130px'};
      max-width: ${isBik ? '230px' : '130px'};
    `}
    .left--style {
      display: flex;
      justify-content: center;
    }
  }
  tr:last-child {
    border-bottom: none;
  }
  .td-class {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: ${COLORS.content.secondary};
  }
  .ellipsis__td {
    max-width: 200px !important;
    max-width: ${({ tdEllipsisMaxWidth }) =>
      tdEllipsisMaxWidth ?? '200px'}!important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
