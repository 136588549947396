const WrapTextIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="action icon">
        <path
          id="Vector"
          d="M12.9222 16.1742C12.5772 16.4608 12.0781 16.4651 11.7283 16.1845L9.38434 14.3045C8.88525 13.9042 8.88525 13.1446 9.38434 12.7443L11.7283 10.8643C12.0781 10.5837 12.5772 10.588 12.9222 10.8746C13.3817 11.2563 13.3756 11.9634 12.9096 12.3372L12.4882 12.6751H15.0294C15.5412 12.6751 15.9781 12.5299 16.3402 12.2395C16.7024 11.949 16.8831 11.5988 16.8824 11.1889C16.8824 10.7785 16.7013 10.4283 16.3392 10.1384C15.9771 9.84853 15.5405 9.70331 15.0294 9.70274H1.84926C1.38022 9.70274 1 9.32251 1 8.85348C1 8.38445 1.38023 8.00423 1.84926 8.00423H15.0294C16.1412 8.00423 17.0811 8.31222 17.8491 8.92822C18.6171 9.54421 19.0007 10.2978 19 11.1889C19 12.0807 18.616 12.8345 17.848 13.4505C17.08 14.0665 16.1405 14.3742 15.0294 14.3737H12.4882L12.9096 14.7116C13.3756 15.0854 13.3817 15.7925 12.9222 16.1742ZM1.84926 14.3737C1.38023 14.3737 1 13.9934 1 13.5244C1 13.0554 1.38023 12.6751 1.84926 12.6751H5.44486C5.91389 12.6751 6.29412 13.0554 6.29412 13.5244C6.29412 13.9934 5.91389 14.3737 5.44486 14.3737H1.84926ZM1.84926 5.03183C1.38022 5.03183 1 4.6516 1 4.18257C1 3.71354 1.38023 3.33331 1.84926 3.33331H17.0919C17.561 3.33331 17.9412 3.71354 17.9412 4.18257C17.9412 4.6516 17.5609 5.03183 17.0919 5.03183H1.84926Z"
          fill="black"
        />
      </g>
    </svg>
  );
};

export default WrapTextIcon;
