import { Icon } from "./icons-props";

const CheckIconGreenCopy: Icon = (props) => {
  const { width, height, color } = props;
  return (
    <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="72" height="72" rx="36" fill="#1A872C"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M50.5118 24.8215C51.1627 25.4723 51.1627 26.5276 50.5118 27.1785L32.1785 45.5118C31.5276 46.1627 30.4724 46.1627 29.8215 45.5118L21.4882 37.1785C20.8373 36.5276 20.8373 35.4723 21.4882 34.8215C22.139 34.1706 23.1943 34.1706 23.8452 34.8215L31 41.9763L48.1548 24.8215C48.8057 24.1706 49.861 24.1706 50.5118 24.8215Z" fill="white"/>
    </svg>
    
  );
};

export default CheckIconGreenCopy;
