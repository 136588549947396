// @ts-nocheck
const POSITIVE_VAL_ERROR = "Please enter a positive integer";
const NON_ZERO_VAL = "Please enter a non-zero value";
const INTEGER_ERROR = "Please enter an integer";
const REQUIRED_VALUE = "Required field";
const NON_DECIMAL_NUMBER = "Please enter a non-decimal number";
const COMMA_SEPERATED_VALUES = "Please enter values seperated by commas";
const GENERIC_ERROR_MSG = "Some inputs are missing or incorrect. Please check.";

export class QueryValidator {
  validate(stateArg: any) {
    const state = { ...stateArg };

    let hasErr = "";

    const includeNodes = (state.include?.nodes ?? []).map((node) => {
      const metas = (node.metas ?? []).map((meta) =>
        this.validateMeta(meta, () => {
          hasErr = GENERIC_ERROR_MSG;
        })
      );

      const properties = (node.properties ?? []).map((property) =>
        this.validateProperty(property, () => {
          hasErr = GENERIC_ERROR_MSG;
        })
      );

      // evnt trigger,
      if (
        !metas.length &&
        (node.type === "EVENTS_TRIGGER" || node.type === "IG_TRIGGER")
      ) {
        hasErr = GENERIC_ERROR_MSG;
        return {
          ...node,
          metas: [
            {
              key: "triggerName",
              error: {
                key: REQUIRED_VALUE,
              },
            },
          ],
          properties,
        };
      }

      if (!properties.length && node.type !== "EVENTS_TRIGGER") {
        hasErr = GENERIC_ERROR_MSG;
        return {
          ...node,
          properties: [],
        };
      }

      if (node.type === "IG_TRIGGER") {
        if (node.metas[0].value === "Mentions") {
          const isAnyStory = state.include?.nodes[0].properties.find(
            (property) => property.name === "anyStory"
          );
          if (isAnyStory) {
            if (properties.length === 1) {
              return {
                ...node,
              };
            } else if (properties.length > 1) {
              hasErr = "Activity not allowed with Mentions + Any Story";
              return {
                ...node,
              };
            }
          }
        }
        if (properties.length > 2) {
          hasErr = "Only 1 Activity type & 1 Media type allowed";
          return {
            ...node,
          };
        }
        if (properties.length < 2) {
          hasErr = "1 Activity type & 1 Media type required";
          return {
            ...node,
          };
        }
        if (state.include?.nodes[0].PropertyOperator === "OR") {
          hasErr = "OR operator not allowed";
          return {
            ...node,
          };
        }
        // check for IG activity
        const activityStrings = state.include?.nodes[0].properties.filter(
          (item) => item.dataType === "activityString"
        );
        const reactionType = state.include?.nodes[0].properties.filter(
          (item) => item.dataType === "reaction"
        );
        if (activityStrings.length > 1 || reactionType.length > 1) {
          hasErr = "Only 1 activity type allowed";
          return {
            ...node,
          };
        }

        // check for IG media
        const medias = state.include?.nodes[0].properties.filter(
          (item) => item.operator === "in"
        );
        if (medias.length > 1) {
          hasErr = "Only 1 media type allowed";
          return {
            ...node,
          };
        }
      }

      return {
        ...node,
        metas,
        properties,
      };
    });
    const include: any = {
      nodes: includeNodes,
      operator: state.include.operator,
    };

    return [hasErr, { ...state, include }];
  }

  validateCtwa(stateArg: any) {
    const state = { ...stateArg };

    let hasErr = "";

    const includeNodes = (state.include?.nodes ?? []).map((node) => {
      const metas = (node.metas ?? []).map((meta) =>
        this.validateMeta(meta, () => {
          hasErr = GENERIC_ERROR_MSG;
        })
      );

      const properties = (node.properties ?? []).map((property) =>
        this.validateProperty(property, () => {
          hasErr = GENERIC_ERROR_MSG;
        })
      );

      // evnt trigger,
      if (!metas.length) {
        hasErr = GENERIC_ERROR_MSG;
        return {
          ...node,
          metas: [
            {
              key: "triggerName",
              error: {
                key: REQUIRED_VALUE,
              },
            },
          ],
          properties,
        };
      }

      if (!properties.length) {
        hasErr = GENERIC_ERROR_MSG;
        return {
          ...node,
          properties: [],
        };
      }

      if (properties.length > 2) {
        hasErr = "Only 1 Activity type & 1 Media type allowed";
        return {
          ...node,
        };
      }

      if (properties.length < 2) {
        hasErr = "1 Activity type & 1 Media type required";
        return {
          ...node,
        };
      }

      if (state.include?.nodes[0].PropertyOperator === "OR") {
        hasErr = "OR operator not allowed";
        return {
          ...node,
        };
      }

      // check for Ad activity
      const activity = state.include?.nodes[0].properties.filter(
        (item) => item.name === "custom" || item.name === "icebreakers"
      );
      if (activity.length > 1) {
        hasErr = "Only 1 activity type allowed";
        return {
          ...node,
        };
      }

      if (
        activity?.[0].name === "icebreakers" &&
        activity?.[0].value &&
        !activity?.[0].value.length
      ) {
        hasErr = "Please select atleast one icebreaker";
        return {
          ...node,
        };
      }

      // check for Ad media
      const medias = state.include?.nodes[0].properties.filter(
        (item) => item.dataType === "anyAd" || item.dataType === "specificAd"
      );
      if (medias.length > 1) {
        hasErr = "Only 1 media type allowed";
        return {
          ...node,
        };
      }

      return {
        ...node,
        metas,
        properties,
      };
    });
    const include: any = {
      nodes: includeNodes,
      operator: state.include.operator,
    };

    return [hasErr, { ...state, include }];
  }

  validateMeta(meta: any, onErr?: () => void) {
    if (meta.key === "frequency") {
      if (!meta.value.value) {
        onErr?.();

        return {
          ...meta,
          error: {
            value: {
              value: REQUIRED_VALUE,
            },
          },
        };
      } else if (isNaN(meta.value.value)) {
        onErr?.();
        return {
          ...meta,
          error: {
            value: {
              value: POSITIVE_VAL_ERROR,
            },
          },
        };
      } else {
        const intVal = parseFloat(meta.value.value.toString());

        if (intVal < 1) {
          onErr?.();
          return {
            ...meta,
            error: {
              value: {
                value: NON_ZERO_VAL,
              },
            },
          };
        }

        if (intVal % 1 !== 0) {
          onErr?.();
          return {
            ...meta,
            error: {
              value: {
                value: NON_DECIMAL_NUMBER,
              },
            },
          };
        }
      }
    }
    if (meta.key === "date" && meta.value?.operator === "in the last") {
      if (
        !meta.value?.value ||
        (typeof meta.value?.value === "string" && !meta.value?.value.trim())
      ) {
        onErr?.();
        return {
          ...meta,
          error: {
            value: {
              value: REQUIRED_VALUE,
            },
          },
        };
      } else if (isNaN(meta.value?.value)) {
        onErr?.();
        return {
          ...meta,
          error: {
            value: {
              value: INTEGER_ERROR,
            },
          },
        };
      } else if (!isNaN(meta.value?.value) && meta.value.value < 0) {
        onErr?.();
        return {
          ...meta,
          error: {
            value: {
              value: POSITIVE_VAL_ERROR,
            },
          },
        };
      }
    }

    if (meta.key === "eventName" && !meta.value) {
      onErr?.();
      return {
        ...meta,
        error: {
          value: REQUIRED_VALUE,
        },
      };
    }

    return { ...meta, error: {} };
  }

  validateProperty(property: any, onErr?: () => void) {
    if (!property.name) {
      onErr?.();
      return { ...property, error: { name: REQUIRED_VALUE } };
    }

    if (property.operator && property.dataType) {
      if (property.dataType === "integer") {
        if (
          property.operator === "lessThan" ||
          property.operator === "lessThanOrEqualTo" ||
          property.operator === "greaterThan" ||
          property.operator === "greaterThanOrEqualTo"
        ) {
          const val = parseInt(property.value?.toString());
          if (isNaN(val)) {
            onErr?.();
            return { ...property, error: { value: INTEGER_ERROR } };
          } else if (val < 0) {
            onErr?.();
            return { ...property, error: { value: POSITIVE_VAL_ERROR } };
          }
        }
      }

      if (property.dataType === "frequency") {
        if (
          property.operator === "exactly" ||
          property.operator === "atleast" ||
          property.operator === "atMost"
        ) {
          /** Single positive number */
          const val = parseInt(property.value?.toString());
          if (isNaN(val)) {
            onErr?.();
            return { ...property, error: { value: INTEGER_ERROR } };
          } else if (val < 0) {
            onErr?.();
            return { ...property, error: { value: POSITIVE_VAL_ERROR } };
          }
        }
      }

      if (
        !(
          property.operator === "exists" ||
          property.operator === "doesntExist" ||
          property.operator === "today" ||
          property.operator === "yesterday" ||
          property.operator === "last month" ||
          property.operator === "last quarter" ||
          property.operator === "last week" ||
          property.operator === "isFalse" ||
          property.operator === "isTrue" ||
          property.dataType === "anyIg" ||
          property.dataType === "anyAd" ||
          (property.dataType === "activityString" &&
            property.operator === "any")
        )
      ) {
        /** not noInput */
        if (!property.value) {
          onErr?.();
          return { ...property, error: { value: REQUIRED_VALUE } };
        }
      }

      if (
        (property.dataType === "integer" &&
          (property.operator === "is" || property.operator === "isNot")) ||
        (property.dataType === "string" &&
          (property.operator === "contains" ||
            property.operator === "doesntContain" ||
            property.operator === "startsWith" ||
            property.operator === "endsWith")) ||
        (property.dataType === "activityString" &&
          (property.operator === "is" ||
            property.operator === "contains" ||
            property.operator === "startsWith" ||
            property.operator === "endsWith")) ||
        (property.dataType === "reaction" && property.operator === "contains")
      ) {
        if (!property.value) {
          onErr?.();
          return { ...property, error: { value: COMMA_SEPERATED_VALUES } };
        } else {
          if (
            typeof property.value === "object" &&
            Array.isArray(property.value) &&
            (property.value as string[]).length === 0
          ) {
            onErr?.();
            return { ...property, error: { value: REQUIRED_VALUE } };
          }
          let isError = false;
          property.value.forEach((item) => {
            if (parseInt(item?.toString()) < 0) {
              isError = true;
            }
          });
          if (isError) {
            onErr?.();
            return { ...property, error: { value: POSITIVE_VAL_ERROR } };
          }
        }
      }

      if (
        property.dataType === "string" &&
        (property.operator === "is" || property.operator === "isNot")
      ) {
        if (
          !property.value ||
          (Array.isArray(property.value) && property.value.length === 0)
        ) {
          onErr?.();
          return { ...property, error: { value: REQUIRED_VALUE } };
        }
      }
    }

    return { ...property, error: {} };
  }
}
