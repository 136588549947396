import { FunctionComponent } from "react";
import { TagManagerStyle } from "./TagManager.style";
import SmallCrossIcon from "../../../icons/smallCrossIcon";

export interface TagProps {
  textColor: string;
  tagColor: string;
  text: string;
  borderColor: string;
  showCross?: boolean;
  ClickCross?: () => void;
}

const TagManager: FunctionComponent<TagProps> = (props) => {
  return (
    <TagManagerStyle {...props}>
      {props.text}
      {props.showCross && (
        <span className="icon-style" onClick={props.ClickCross}>
          <SmallCrossIcon />
        </span>
      )}
    </TagManagerStyle>
  );
};
export default TagManager;
