import { Icon } from "./icons-props";

const DoubleMessageIcon: Icon = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2026_4927)">
        <path
          d="M7.5 13.5002V17.2502C7.5 17.4492 7.57902 17.6399 7.71967 17.7806C7.86032 17.9212 8.05109 18.0002 8.25 18.0002H17.2875L21 21.0002V9.00024C21 8.80133 20.921 8.61057 20.7803 8.46991C20.6397 8.32926 20.4489 8.25024 20.25 8.25024H16.5M6.7125 13.5002L3 16.5002V4.50024C3 4.30133 3.07902 4.11057 3.21967 3.96991C3.36032 3.82926 3.55109 3.75024 3.75 3.75024H15.75C15.9489 3.75024 16.1397 3.82926 16.2803 3.96991C16.421 4.11057 16.5 4.30133 16.5 4.50024V12.7502C16.5 12.9492 16.421 13.1399 16.2803 13.2806C16.1397 13.4212 15.9489 13.5002 15.75 13.5002H6.7125Z"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2026_4927">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DoubleMessageIcon;
