import {
  HTTPMethods,
  RequestExecutor,
} from "../../api-helpers/RequestExecutor";
import { getChatbotEndpoints } from "../../config";

export type AnalyticsPayload = {
  storeId: string;
  flowId: string;
  startDate: number;
  endDate: number;
  channel: string;
  basedOnTriggeredDate: boolean;
  csvReport?: boolean;
};

export const getAnalyticsData = async (payload: any) => {
  const response: any = await RequestExecutor.fetch({
    apiPath: `${getChatbotEndpoints()}/get-flow-analytics`,
    apiMethod: HTTPMethods.POST,
    requestBody: payload,
  });
  if (response?.data && response?.data?.success) {
    const data = response.data.data;
    return data;
  }
  return {};
};

export const getSummaryAnalyticsData = async (payload: any) => {
  const response: any = await RequestExecutor.fetch({
    apiPath: `${getChatbotEndpoints()}/get-summary-analytics`,
    apiMethod: HTTPMethods.POST,
    requestBody: payload,
  });
  if (response?.data && response?.data?.success) {
    const data = response.data.data;
    return data;
  }
  return {};
};

export const getUserResponseReport = async (payload: any) => {
  const response: any = await RequestExecutor.fetch({
    apiPath: `${getChatbotEndpoints()}/get-user-response-analytics`,
    apiMethod: HTTPMethods.POST,
    requestBody: payload,
  });
  if (response?.data && response?.data?.success) {
    const data = response.data.data;
    return data;
  }
  return {};
};

export const downloadNodeLevelAnalytics = async (payload: any) => {
  const response: any = await RequestExecutor.fetch({
    apiPath: `${getChatbotEndpoints()}/get-node-analytics`,
    apiMethod: HTTPMethods.POST,
    requestBody: payload,
  });
  if (response?.data && response?.data?.success) {
    const data = response.data.data;
    return data;
  }
  return false;
};

export const retryFromNode = async (payload: any) => {
  const response: any = await RequestExecutor.fetch({
    apiPath: `${getChatbotEndpoints()}/retry-from-node`,
    apiMethod: HTTPMethods.POST,
    requestBody: payload,
  });
  if (response?.data && response?.data?.success) {
    return response.data.success;
  }
  return false;
};

export const AnalyticsToPercentageConverter = (
  value1: number,
  value2: number
) => {
  return Math.round((value1 / value2) * 100 * 100) / 100;
};

/**
 Returns 0% in string when numerator or denominator is 0.
 Calculates and returns the percentage string in other cases.
 */
export const getCtrPercentageText = (
  numerator: number,
  denominator: number
) => {
  if (denominator <= 0 || numerator === 0) return "CTR 0%";

  return `CTR ${Math.round((numerator / denominator) * 100 * 100) / 100}%`;
};
