import { Provider } from "react-redux";
import botStore from "./state/store";
import { ReactNode } from "react";
import { ThemeProvider } from "styled-components";
import { DEFAULT_THEME } from "./Theme";

export const AppProviders = (props: { children: ReactNode }) => {
  return (
    <ThemeProvider theme={DEFAULT_THEME}>
      <Provider store={botStore}>{props.children}</Provider>
    </ThemeProvider>
  );
};

export default AppProviders;
