import { ReactFlowProvider } from "reactflow";
import { NodeProps } from "./model";
import Node from "./node";

const NodeProvider = (props: NodeProps) => {
  const {
    newNodeData,
    storeId,
    flowId,
    type,
    setIndex,
    getNodeId,
    index,
    resetViewPort,
  } = props;

  return (
    <ReactFlowProvider>
      <Node
        forceFetch={props.forceFetch}
        newNodeData={newNodeData}
        storeId={storeId}
        flowId={flowId}
        type={type}
        setIndex={setIndex}
        getNodeId={getNodeId}
        index={index}
        resetViewPort={resetViewPort}
      />
    </ReactFlowProvider>
  );
};

export default NodeProvider;
