export const firebaseConfig = {
  bikayiAppUsa: {
    apiKey: "AIzaSyC9UXTV6SbHEhz6IsH1juvkq_yHEmOf8PE",
    authDomain: "bikai-d5ee5.firebaseapp.com",
    databaseURL: "https://bikai-d5ee5.firebaseio.com",
    projectId: "bikai-d5ee5",
    storageBucket: "bikai-d5ee5.appspot.com",
    messagingSenderId: "960183660605",
    appId: "1:960183660605:web:b93c3b654d17c9e53d7a54",
  },
  bikayiAppIndia: {
    apiKey: "AIzaSyA6Mi0Zii5CIimu2ogFH8wTLUYkCiDJE0E",
    authDomain: "bikayi-chat.firebaseapp.com",
    databaseURL: "https://bikayi-chat.firebaseio.com",
    projectId: "bikayi-chat",
    storageBucket: "bikayi-chat.appspot.com",
    messagingSenderId: "663068353804",
    appId: "1:663068353804:web:23c7b6577a1054f95e1529",
  },
  functions: {
    CLOUD_FUNCTION_URL: "https://us-central1-bikai-d5ee5.cloudfunctions.net",
    CLOUD_FUNCTION_URL_INDIA:
      "https://asia-south1-bikai-d5ee5.cloudfunctions.net",
    FASITY_FUNCTION: "https://fastify-6nwaonxexq-el.a.run.app",
    NEW_FASITY_FUNCTION: "https://api.bikayi.app",
    FIREBASE_URL: "https://bikai-d5ee5.firebaseio.com",
  },
  stagingApp: {
    apiKey: "AIzaSyD1PQW26YUKNpXWMEpj60czm1ZMfCOPl0M",
    authDomain: "staging-bikayi.firebaseapp.com",
    databaseURL:
      "https://staging-bikayi-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "staging-bikayi",
    storageBucket: "staging-bikayi.appspot.com",
    messagingSenderId: "99091183732",
    appId: "1:99091183732:web:d8f4b5df42e8d313dcb921",
    measurementId: "G-ST13VY8JYW",
  },
  stagingFunctionEndpoints: {
    CLOUD_FUNCTION_URL: "https://us-central1-staging-bikayi.cloudfunctions.net",
    CLOUD_FUNCTION_URL_INDIA:
      "https://asia-south1-staging-bikayi.cloudfunctions.net",
    FIREBASE_URL:
      "https://staging-bikayi-default-rtdb.asia-southeast1.firebasedatabase.app",
    FASITY_FUNCTION: "https://fastify-2adcfj52ea-el.a.run.app",
  },
  bikayiChatApp: {
    apiKey: "AIzaSyA6Mi0Zii5CIimu2ogFH8wTLUYkCiDJE0E",
    authDomain: "bikayi-chat.firebaseapp.com",
    databaseURL: "https://bikayi-chat.firebaseio.com",
    projectId: "bikayi-chat",
    storageBucket: "bikayi-chat.appspot.com",
    messagingSenderId: "663068353804",
    appId: "1:663068353804:web:23c7b6577a1054f95e1529",
  },
  firebaseImageEndpoint:
    "https://firebasestorage.googleapis.com/v0/b/bikayi-chat.appspot.com/o/",
};
