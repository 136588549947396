import React from "react";
import StyledCircle from "./styled";

export interface GreyCircleProps {
  icon: React.ReactNode;
  size: number;
  borderColor?: string;
  backgroundColor?: string;
}

const GreyCircle = ({
  icon,
  size,
  borderColor,
  backgroundColor,
}: GreyCircleProps) => {
  return (
    <StyledCircle
      size={size}
      icon={icon}
      borderColor={borderColor}
      backgroundColor={backgroundColor}
    >
      {icon}
    </StyledCircle>
  );
};

export default GreyCircle;
