import React, { ChangeEventHandler, FocusEventHandler, LegacyRef } from "react";
import { InputWrapper, SpanStyle } from "../BotInput.style";
import { InputDataProps } from "../InputModel";

export interface TextInputDataProps extends InputDataProps {
  inputEl?: LegacyRef<HTMLInputElement>;
  onChangeHandle: ChangeEventHandler<HTMLInputElement>;
}

/**
 For Type 'number' - input is read as type 'text' and validated as number in onChange().
 **/

const TextInput = (props: TextInputDataProps) => {
  const handleFocusEvent = (e: React.FocusEvent<HTMLInputElement>) => {
    // Do something
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.type === "number") {
      const inputNumber = e.target.value.toString().replace(/[^0-9]/gi, "");
      e.target.value = inputNumber;
    }
    props.onChangeHandle(e);
  };

  return (
    <React.Fragment>
      <InputWrapper {...props}>
        <input
          name={props.name}
          ref={props.inputEl}
          onKeyDown={props.onKeyDown}
          type={props.type === "number" ? "text" : props.type}
          value={props.value}
          onChange={handleOnChange}
          disabled={props.isDisabled}
          onBlur={props.onBlur}
          maxLength={props.wordCount}
        />
        {!props.value && (!!props.placeHolder || !!props.isRequired) && (
          <SpanStyle>
            {props.placeHolder}
            {props.isRequired ? "*" : null}
          </SpanStyle>
        )}
      </InputWrapper>
    </React.Fragment>
  );
};
export default TextInput;
