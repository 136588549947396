import { Icon } from "./icons-props";

const CatalogList: Icon = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 18H3.09091C2.76456 18 2.5 17.7354 2.5 17.4091V2.59091C2.5 2.26456 2.76456 2 3.09091 2H16.4091C16.7354 2 17 2.26456 17 2.59091V5.5"
        stroke={color || "#212121"}
        stroke-width="2.18"
        stroke-linecap="round"
      />
      <path
        d="M9 6V21.5"
        stroke={color || "#212121"}
        stroke-width="2.18"
        stroke-linecap="round"
      />
      <path
        d="M17 2.5L9 6"
        stroke={color || "#212121"}
        stroke-width="2.18"
        stroke-linecap="round"
      />
      <path
        d="M9 6H21.4091C21.7354 6 22 6.26456 22 6.59091V21.4091C22 21.7354 21.7354 22 21.4091 22H9"
        stroke={color || "#212121"}
        stroke-width="2.18"
        stroke-linecap="round"
      />
      <path
        d="M15 10L19 10"
        stroke={color || "#212121"}
        stroke-width="2.18"
        stroke-linecap="round"
      />
      <path
        d="M15 14H17"
        stroke={color || "#212121"}
        stroke-width="2.18"
        stroke-linecap="round"
      />
      <path
        d="M15 18H19"
        stroke={color || "#212121"}
        stroke-width="2.18"
        stroke-linecap="round"
      />
      <circle cx="12" cy="10" r="1" fill={color || "#212121"} />
      <circle cx="12" cy="14" r="1" fill={color || "#212121"} />
      <circle cx="12" cy="18" r="1" fill={color || "#212121"} />
    </svg>
  );
};

export default CatalogList;
