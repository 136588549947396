import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProductsDataState } from "./storeDataStateType";

const initialState = {
  products: [] as any,
};

const productsDataSlice = createSlice({
  name: "productsDataState",
  initialState: initialState,
  reducers: {
    setProducts: (state, action: PayloadAction<ProductsDataState>) => {
      const { products } = action.payload;
      state.products = products;
      return state;
    },
  },
});

export const productsDataAction = productsDataSlice.actions;
export default productsDataSlice;
