import { Icon } from "./icons-props";

const Box2: Icon = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      width={width || "16"}
      height={height || "16"}
      viewBox="0 0 16 16"
      fill={color || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="box_2">
        <path
          id="shape"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.67622 0.749911C7.87757 0.638051 8.12239 0.638051 8.32374 0.749911L14.3237 4.08324C14.5354 4.20082 14.6666 4.4239 14.6666 4.66602V11.3327C14.6666 11.5748 14.5354 11.7979 14.3237 11.9155L8.32374 15.2488C8.12239 15.3606 7.87757 15.3606 7.67622 15.2488L1.67622 11.9155C1.46457 11.7979 1.33331 11.5748 1.33331 11.3327V4.66602C1.33331 4.4239 1.46457 4.20082 1.67622 4.08324L7.67622 0.749911ZM6.37273 2.99935L11 5.57004L12.6272 4.66602L7.99998 2.09532L6.37273 2.99935ZM13.3333 5.79903L8.66665 8.39162V13.533L13.3333 10.9404V5.79903ZM7.33331 13.533V8.39162L2.66665 5.79903V10.9404L7.33331 13.533ZM3.37273 4.66602L7.99998 7.23671L9.62723 6.33268L4.99998 3.76199L3.37273 4.66602Z"
          fill={color || "#616161"}
        />
      </g>
    </svg>
  );
};

export default Box2;
