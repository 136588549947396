import { FunctionComponent, useState } from "react";
import ArrowDown from "../../../../icons/arrowDown";
import ArrowUp from "../../../../icons/arrowUp";
import BikayiButton from "../../../../ui-components/button";
import BotInput from "../../../../ui-components/input";
import SearchBar from "../../../../ui-components/searchBar";
import { VariableData, VariableDataTypesEnum } from "../common";
import { VariableEditorStyle } from "./style";

interface EditorProps {
  mode?: string;
  variableData?: VariableData;
  setEditModes?: any;
  storeId: string;
  onUpdate: any;
  globalVariableList: VariableData[];
}

export const VariableDataTypes: any[] = [
  { id: 1, text: VariableDataTypesEnum.STRING },
  { id: 2, text: VariableDataTypesEnum.NUMBER },
  { id: 3, text: VariableDataTypesEnum.INTEGER },
  { id: 4, text: VariableDataTypesEnum.BOOLEAN },
];

export const VariableEditor: FunctionComponent<EditorProps> = (props) => {
  const [showPopup, setShowPopup] = useState<string>("");
  const [mode, setMode] = useState<string>(props.mode || "add");
  const [variableData, setVariableData] = useState<VariableData>(
    props.variableData || {
      name: "",
      type: VariableDataTypesEnum.NULL,
      defaultValue: "",
    }
  );
  const [errors, setErrors] = useState<any>({});

  const handlePopup = (popupName: string) => {
    if (showPopup) setShowPopup("");
    else setShowPopup(popupName);
  };

  const onChangeVariableData = (newData: VariableData) => {
    setVariableData(newData);
  };

  const onSave = async () => {
    const errorsCopy: any = {};
    setErrors({});

    if (!variableData.name) {
      errorsCopy["name"] = "Variable name cannot be empty";
    } else if (
      variableData.name.trim().startsWith("{{") &&
      variableData.name.trim().endsWith("}}")
    ) {
      errorsCopy["name"] = "Name should not contain {{}}";
    }

    if (!variableData.type) {
      errorsCopy["type"] = "Variable type cannot be empty";
    }

    if (!variableData.defaultValue.toString()) {
      errorsCopy["defaultValue"] = "Default value cannot be empty";
    }

    if (Object.keys(errorsCopy).length) {
      setErrors(errorsCopy);
      return;
    }

    let defValue = variableData.defaultValue;
    if (variableData.type === VariableDataTypesEnum.NUMBER) {
      defValue = parseFloat(defValue);
      if (Number.isNaN(defValue)) {
        errorsCopy["defaultValue"] = "Please enter a valid number";
      }
    } else if (variableData.type === VariableDataTypesEnum.INTEGER) {
      defValue = parseInt(defValue);
      if (Number.isNaN(defValue)) {
        errorsCopy["defaultValue"] = "Please enter a valid integer";
      }
    } else if (variableData.type === VariableDataTypesEnum.BOOLEAN) {
      if (defValue.toString() === ("true" || "True")) {
        defValue = true;
      } else if (defValue.toString() === ("false" || "False")) {
        defValue = false;
      } else {
        errorsCopy["defaultValue"] = "Please enter the value as true or false";
      }
    }

    if (Object.keys(errorsCopy).length) {
      setErrors(errorsCopy);
      return;
    }

    if (mode === "add") {
      const idx = props.globalVariableList.findIndex(
        (item) => item.name === variableData.name
      );
      if (idx > -1) {
        errorsCopy["name"] = "Duplicate variable name";
        setErrors(errorsCopy);
        return;
      }

      props.onUpdate([
        ...props.globalVariableList,
        {
          type: variableData.type,
          name: variableData.name,
          defaultValue: defValue,
        },
      ]);
      setVariableData({
        name: "",
        type: VariableDataTypesEnum.NULL,
        defaultValue: "",
      });
    } else if (mode === "edit") {
      if (props.variableData && variableData.name !== props.variableData.name) {
        const idx = props.globalVariableList.findIndex(
          (item) => item.name === variableData.name
        );
        if (idx > -1) {
          errorsCopy["name"] = "Duplicate variable name";
          setErrors(errorsCopy);
          return;
        }
      }

      const oldKey = props.variableData ? props.variableData.name : "";
      const idx = props.globalVariableList.findIndex((item) => {
        return item.name === oldKey;
      });
      const listCopy = [...props.globalVariableList];
      listCopy.splice(idx, 1, {
        name: variableData.name,
        type: variableData.type,
        defaultValue: defValue,
      });
      props.onUpdate(listCopy);
      props.setEditModes((prevState: any) => {
        return { ...prevState, [variableData.name]: false };
      });
    }
  };

  return (
    <VariableEditorStyle>
      {props.mode === "add" && (
        <div className="editor_heading">Add new variable</div>
      )}
      <div className={"variable_editor"}>
        <div className={"variable_editor__body"}>
          <div className={"variable_editor__body__name_container"}>
            <BotInput
              name={"name-editor"}
              label={"Variable name*"}
              placeHolder={"variable.name"}
              subText={
                errors["name"]
                  ? errors["name"]
                  : "Name should not conatain {{}}"
              }
              error={errors["name"]}
              height={"48px"}
              type={"text"}
              isDisabled={false}
              value={variableData.name}
              onChange={(e) =>
                onChangeVariableData({
                  name: e.target.value,
                  type: variableData.type,
                  defaultValue: variableData.defaultValue,
                })
              }
            ></BotInput>
          </div>
          <div
            className={"variable_editor__body__type_container"}
            onClick={() => handlePopup("type_dropdown")}
          >
            <BotInput
              name={"type-dopdown"}
              label={"Type*"}
              placeHolder={"Select Type"}
              height={"48px"}
              type={"text"}
              background={"#FFFFFF"}
              isDisabled={true}
              iconRight={true}
              value={variableData.type}
              children={showPopup ? <ArrowDown /> : <ArrowUp />}
              subText={errors["type"]}
              error={errors["type"]}
            ></BotInput>
            {showPopup === "type_dropdown" && (
              <div className="variable_editor__body__type_container__searchbar">
                <SearchBar
                  data={VariableDataTypes}
                  placeholder={"Variable data types"}
                  onClickHandle={(e) =>
                    onChangeVariableData({
                      name: variableData.name,
                      type: e.text as VariableDataTypesEnum,
                      defaultValue: variableData.defaultValue,
                    })
                  }
                />
              </div>
            )}
          </div>
          <div className={"variable_editor__body__default_value_container"}>
            <BotInput
              name={"type-editor"}
              label={"Default value*"}
              placeHolder={"Enter value"}
              height={"48px"}
              type={"text"}
              isDisabled={false}
              onChange={(e) =>
                onChangeVariableData({
                  name: variableData.name,
                  type: variableData.type,
                  defaultValue: e.target.value,
                })
              }
              value={variableData.defaultValue.toString()}
              subText={errors["defaultValue"]}
              error={errors["defaultValue"]}
            ></BotInput>
          </div>
          <div className={"variable_editor__body__button"}>
            <BikayiButton
              buttonSize="large"
              buttonName={props.variableData ? "Save" : "Add"}
              buttonStyle="primary"
              buttonWidth="100%"
              buttonHeight={"60px"}
              style={{
                "font-family": "Inter",
                "font-style": "normal",
                "font-weight": "600",
                "font-size": "16px",
                "line-height": "20px",
              }}
              click={onSave}
            />
          </div>
        </div>
      </div>
    </VariableEditorStyle>
  );
};
