import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProximityConnectState } from "./storeDataStateType";

const initialState: ProximityConnectState = {
  listenToHover: false,
};

const proximityConnectSlice = createSlice({
  name: "ProximityConnect",
  initialState: initialState,
  reducers: {
    setState: (state, action: PayloadAction<ProximityConnectState>) => {
      const { listenToHover } = action.payload;
      state = {
        ...state,
        listenToHover,
      };
      return state;
    },
  },
});

export const proximityConnectActions = proximityConnectSlice.actions;
export default proximityConnectSlice;
