import { Icon } from "./icons-props";

const SmallCrossIcon: Icon = (props) => {
  const { height, width, color } = props;
  return (
    <>
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.47157 1.47141C9.73192 1.21107 9.73192 0.788955 9.47157 0.528606C9.21122 0.268256 8.78911 0.268256 8.52876 0.528606L5.00016 4.0572L1.47157 0.528606C1.21122 0.268256 0.789108 0.268256 0.528758 0.528606C0.268409 0.788955 0.268409 1.21107 0.528758 1.47141L4.05735 5.00001L0.528758 8.52861C0.268409 8.78896 0.268409 9.21107 0.528758 9.47141C0.789108 9.73176 1.21122 9.73176 1.47157 9.47141L5.00016 5.94282L8.52876 9.47141C8.78911 9.73176 9.21122 9.73176 9.47157 9.47141C9.73192 9.21107 9.73192 8.78896 9.47157 8.52861L5.94297 5.00001L9.47157 1.47141Z"
          fill="#616161"
        />
      </svg>
    </>
  );
};

export default SmallCrossIcon;
