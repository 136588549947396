import React, {
  ChangeEventHandler,
  FunctionComponent,
  KeyboardEventHandler,
} from "react";
import { SearchInputStyle } from "./BotInput.style";
import SearchIcon from "../../icons/searchIcon";

interface SearchInputProps {
  onChangeHandle: ChangeEventHandler<HTMLInputElement>;
  value?: string;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  placeHolder?: string;
}

type Props = SearchInputProps;

const SearchInput: FunctionComponent<Props> = (props) => {
  return (
    <SearchInputStyle>
      <div className="search__icon my-auto d-flex">
        <SearchIcon />
      </div>
      <input
        type="text"
        onKeyDown={props?.onKeyDown}
        name="SearchInput"
        placeholder={props.placeHolder || "Enter your string here"}
        value={props?.value}
        onChange={props.onChangeHandle}
        autoComplete={"off"}
        style={{ width: "100%", fontSize: "14px", lineHeight: "20px" }}
      />
    </SearchInputStyle>
  );
};

export default SearchInput;
