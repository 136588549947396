import React, { FunctionComponent, useEffect, useState } from "react";
import InternalProductsDashboard from "./InternalProducts";
import ShopifyProductsDashboard from "./ShopifyProducts";
import FacebookProductsDashboard from "./FacebookProducts";
import { IntegrationService } from "./IntegrationsService";
import AdminSearchBar from "../core/AdminSearchBar";
import styled from "styled-components";

interface OwnProps {}

type Props = OwnProps;

const ProductsDashboard: FunctionComponent<Props> = (props) => {
  const [storeId, setStoreId] = useState<string>("");
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [isFetched, setIsFetched] = useState<boolean>(false);

  const [productList, setProductList] = useState<any[]>([]);
  const [shopifyProductList, setShopifyProductList] = useState<any[]>([]);

  const repo = new IntegrationService();

  const handleSubmitClick = async () => {
    const { products, absentProducts } = await repo.fetchData(storeId);

    setProductList(products);
    setShopifyProductList(absentProducts);

    setIsFetched(true);
    setShowSpinner(false);
    let startIndex = 0;
    let endIndex = 0;
    for (let length = products.length; length > 0; length -= 25) {
      endIndex = length > 25 ? startIndex + 25 : startIndex + length;
      const subProductList = products.slice(startIndex, endIndex);
      startIndex = endIndex;
      const productErrors = await repo.fetchFbProductErrors(
        storeId,
        subProductList
      );
      setProductList((prevState) =>
        prevState.map((product: any) => {
          const hasProduct = productErrors.find(
            (fbProduct: any) =>
              fbProduct.combinations[0].id === product.combinations[0].id
          );
          if (hasProduct) {
            return hasProduct;
          } else {
            return product;
          }
        })
      );
    }
  };

  useEffect(() => {
    if (storeId.length > 0) {
      handleSubmitClick();
    }
  }, [storeId]);

  return (
    <div>
      <AdminSearchBar
        mandatoryKeys={["storeId"]}
        optionalKeys={[]}
        onSubmit={(id, values) => {
          if (storeId !== id) {
            setShowSpinner(true);
            setStoreId(id);
            setIsFetched(false);
            setShopifyProductList([]);
            setProductList([]);
          }
        }}
      />
      {storeId.length > 0 && isFetched && (
        <div>
          <InternalProductsDashboard productList={productList} />
          <ShopifyProductsDashboard
            storeId={storeId}
            shopifyProductList={shopifyProductList}
          />
          <FacebookProductsDashboard
            storeId={storeId}
            productList={productList}
          />
        </div>
      )}
      {showSpinner && (
        <InternalProducts>
          <div className={"loader"} />
        </InternalProducts>
      )}
    </div>
  );
};

export default ProductsDashboard;

const InternalProducts = styled.div`
  .loader {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #731dcf;
    max-width: 40px;
    height: 40px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    margin: auto;
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;
