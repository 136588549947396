import { Icon } from "./icons-props";

const MoneyIcon: Icon = (props) => {
  const { height, width, color } = props;
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      fill={color || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 4C1.34315 4 0 5.34315 0 7V17C0 18.6569 1.34315 20 3 20H11C11.5523 20 12 19.5523 12 19C12 18.4477 11.5523 18 11 18H3C2.44772 18 2 17.5523 2 17V7C2 6.44772 2.44772 6 3 6H21C21.5523 6 22 6.44772 22 7V9C22 9.55228 22.4477 10 23 10C23.5523 10 24 9.55228 24 9V7C24 5.34315 22.6569 4 21 4H3Z"
        fill="white"
      />
      <path
        d="M17 18C16.4477 18 16 18.4477 16 19C16 19.5523 16.4477 20 17 20H23C23.5523 20 24 19.5523 24 19C24 18.4477 23.5523 18 23 18H17Z"
        fill="white"
      />
      <path
        d="M16 16C16 15.4477 16.4477 15 17 15H23C23.5523 15 24 15.4477 24 16C24 16.5523 23.5523 17 23 17H17C16.4477 17 16 16.5523 16 16Z"
        fill="white"
      />
      <path
        d="M17 12C16.4477 12 16 12.4477 16 13C16 13.5523 16.4477 14 17 14H23C23.5523 14 24 13.5523 24 13C24 12.4477 23.5523 12 23 12H17Z"
        fill="white"
      />
      <path
        d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z"
        fill="white"
      />
    </svg>
  );
};

export default MoneyIcon;
