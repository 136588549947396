import { useState } from "react";
import { userDataState } from "../../state/storeDataStateType";
import { GrowthContainer } from "./style";
import { Menu, SimpleSidebar } from "@bikdotai/bik-component-library";
import CustomizedJourneys from "./CustomizedJourneys";
import { SidebarEnums } from "./type";
import WhatsNewPanel from "./WhatsNew";

export const MenuItems: Menu[] = [
  {
    key: SidebarEnums["tailored-section"],
    displayName: "Tailored for you",
  },
  {
    key: SidebarEnums["recommended-section"],
    displayName: "Recommended for you",
  },
  {
    key: SidebarEnums["whats-new-section"],
    displayName: "What's new",
  },
];

const GrowthBlock: React.FC<{ userData: userDataState }> = ({ userData }) => {
  const [currentMenu, setCurrentMenu] = useState(MenuItems[0]);

  return (
    <>
      <GrowthContainer>
        <div className="growth-global-container">
          <SimpleSidebar
            menuList={MenuItems}
            activeMenu={currentMenu}
            theme={"white"}
            onMenuClick={function (menuSelected: Menu): void {
              setCurrentMenu(menuSelected);
            }}
            router={undefined}
          />
          <div className="growth-component-container">
            <CustomizedJourneys currentMenu={currentMenu} />
            {currentMenu.key === SidebarEnums["whats-new-section"] && (
              <WhatsNewPanel />
            )}
          </div>
        </div>
      </GrowthContainer>
    </>
  );
};

export default GrowthBlock;
