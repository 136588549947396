import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StoreDataState } from "./storeDataStateType";

const initialState: StoreDataState = {
  flow: {},
  nodeId: "",
  data: {},
};

const storeDataSlice = createSlice({
  name: "storeData",
  initialState: initialState,
  reducers: {
    updateInsertNode: (state, action: PayloadAction<StoreDataState>) => {
      const { nodeId, data } = action.payload;
      if (nodeId && data && Object.keys(data).length) {
        state.flow[nodeId] = data;
        state.nodeId = nodeId;
      }
      return state;
    },
    restoreFlow: (state, action: PayloadAction<StoreDataState>) => {
      state.flow = {
        ...action.payload.flow,
      };
      return state;
    },
  },
});

export const storeDataActions = storeDataSlice.actions;
export default storeDataSlice;
