import {
  BodySecondary,
  Button,
  COLORS,
  StyledModal,
  TitleMedium,
} from "@bikdotai/bik-component-library";
import React from "react";

type Props = {
  onClose: () => void;
  onSunset: () => void;
  onInactive: () => void;
};

export const SunsetModal: React.FC<Props> = ({
  onSunset,
  onInactive,
  onClose,
}) => {
  return (
    <StyledModal
      open
      zIndex={200}
      centralContainerStyles={{ padding: 24, width: 400 }}
      onClose={onClose}
    >
      <TitleMedium style={{ marginBottom: 8 }}>
        Do you want to sunset this journey?
      </TitleMedium>
      <>
        <BodySecondary style={{ color: COLORS.content.secondary }}>
          <b>The Sunset :</b> Journey will not run for new customers. It will
          only run for customers for whom journey is already initiated and will
          be deactivated gradually.
        </BodySecondary>
        <div style={{ height: 12 }} />
        <BodySecondary style={{ color: COLORS.content.secondary }}>
          <b>Turn off completely:</b> Journey will stop for all customers.
        </BodySecondary>
      </>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 32,
          gap: 12,
        }}
      >
        <div style={{ display: "flex", flex: 1 }}>
          <Button
            buttonText="Turn off completely"
            buttonType="secondary"
            matchParentWidth
            onClick={onInactive}
          />
        </div>
        <div style={{ display: "flex", flex: 1 }}>
          <Button
            buttonText="Sunset"
            buttonType="primary"
            matchParentWidth
            onClick={onSunset}
          />
        </div>
      </div>
    </StyledModal>
  );
};
