import { HTTPMethods, RequestExecutor } from "../api-helpers/RequestExecutor";
import { getGrowthEndpoints } from "../config";
import { captureErrorToSentry } from "./sentryHelper";

export class SlackNotification {
  private endpoint =
    "https://hooks.slack.com/services/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";

  public async reportError(name: string, msg: string) {
    try {
      await RequestExecutor.fetch({
        apiMethod: HTTPMethods.POST,
        apiPath: this.endpoint,
        requestBody: { username: name, text: msg },
      });
    } catch (error: any) {}
  }

  public async sendSlackMessageRequest(message: string, channelId?: string) {
    const url =
      getGrowthEndpoints() + "/bikStoreSettingsApiFunctions-sendMessageToSlack";
    const body = {
      channelId: channelId ? channelId : "C05V24XC9KQ", //bik_scheduling_calls
      message,
    };
    try {
      const response = await RequestExecutor.sendData({
        apiPath: url,
        requestBody: body,
        apiMethod: HTTPMethods.POST,
      });
      if (response.status !== 200) {
        return [response.error as Error, undefined];
      } else {
        return [false, response.data];
      }
    } catch (error) {
      captureErrorToSentry(error, "Error in sending slack message");
      return [error as Error, undefined];
    }
  }
}
