import { BikShimmer } from "@bikdotai/bik-component-library";
import { ShimmerStyled } from "./style";
import { getChatbotEndpoints, getManifestEndpoints } from "../../config";
import {
  HTTPMethods,
  RequestExecutor,
} from "../../api-helpers/RequestExecutor";

export const getTestModeUsers = async (storeId: string) => {
  const path = `${getChatbotEndpoints()}/get-testmode-users`;
  const res = (await RequestExecutor.fetch({
    apiPath: path,
    apiMethod: HTTPMethods.POST,
    requestBody: {
      store_id: storeId,
    },
  })) as any;
  return res.data;
};

export const getGlobalSpamSettings = async (storeId: string) => {
  const url = `${getChatbotEndpoints()}/get-dnd-settings`;

  try {
    const response = await RequestExecutor.sendData({
      apiPath: url,
      requestBody: {
        storeId: storeId,
      },
      apiMethod: HTTPMethods.POST,
    });
    if (response.status !== 200) {
      return {};
    } else {
      if ((response.data as any).success) {
        return (response.data as any).data;
      } else {
        return {};
      }
    }
  } catch (error) {
    return {};
  }
};

export const getAiEnablemnetStatus = async (storeId: string) => {
  const url = `${getManifestEndpoints()}/bik-ai/ai-enable-info`;
  try {
    const response = await RequestExecutor.sendData({
      apiPath: url,
      requestBody: {
        storeId: storeId,
      },
      apiMethod: HTTPMethods.POST,
    });
    if (response.status !== 200) {
      return {};
    } else {
      if ((response.data as any).success) {
        return (response.data as any).data;
      } else {
        return {};
      }
    }
  } catch (error) {
    return {};
  }
};

export const getFlowDndSettings = async (storeId: string, flowId: string) => {
  const url = `${getChatbotEndpoints()}/get-flow-dnd-status`;

  try {
    const response = await RequestExecutor.sendData({
      apiPath: url,
      requestBody: {
        storeId: storeId,
        flowId: flowId,
      },
      apiMethod: HTTPMethods.POST,
    });
    if (response.status !== 200) {
      return {};
    } else {
      if ((response.data as any).success) {
        return (response.data as any).data;
      } else {
        return {};
      }
    }
  } catch (error) {
    return {};
  }
};

export const setDndSettings = async (
  storeId: string,
  flowId: string,
  enabled: boolean
) => {
  const url = `${getChatbotEndpoints()}/set-flow-dnd-status`;

  try {
    const response = await RequestExecutor.sendData({
      apiPath: url,
      requestBody: {
        storeId: storeId,
        flowId: flowId,
        status: enabled,
      },
      apiMethod: HTTPMethods.POST,
    });
    if (response.status !== 200) {
      return false;
    } else {
      if ((response.data as any).success) {
        return true;
      } else {
        return false;
      }
    }
  } catch (error) {
    return false;
  }
};

export const setFlowFreqCapStatus = async (
  storeId: string,
  flowId: string,
  enabled: boolean,
) => {
  const url = `${getChatbotEndpoints()}/set-flow-freq-cap`;

  try {
    const response = await RequestExecutor.sendData({
      apiPath: url,
      requestBody: {
        storeId: storeId,
        flowId: flowId,
        isGlobalFreqCapEnabled: enabled,
      },
      apiMethod: HTTPMethods.POST,
    });
    if (response.status !== 200) {
      return false;
    } else {
      if ((response.data as any).success) {
        return true;
      } else {
        return false;
      }
    }
  } catch (error) {
    return false;
  }
};

export const GetLastUpdatedMessage = (lastUpdated: string) => {
  const current = new Date().getTime();
  const diff = current - Number(lastUpdated);
  const mins = Math.round(diff / 1000 / 60);
  const hrs = Math.round(mins / 60);
  const days = Math.round(hrs / 24);
  if (days === 1) {
    return "Last saved 1 day ago";
  } else if (days > 1) {
    return `Last saved ${days} days ago`;
  } else if (hrs === 1) {
    return "Last saved 1 hr ago";
  } else if (hrs > 1) {
    return `Last saved ${hrs} hrs ago`;
  } else if (mins < 1) {
    return "Last saved less than a min ago";
  } else if (mins === 1) {
    return "Last saved 1 min ago";
  } else if (mins > 1) {
    return `Last saved ${mins} mins ago`;
  }
};

export const SummaryShimmers = () => {
  return (
    <ShimmerStyled>
      <div className="shimmer__card">
        <BikShimmer
          boxes={[
            { height: "20px", width: "64px" },
            { height: "28px", width: "120px" },
          ]}
        />
      </div>
      <div className="shimmer__card">
        <BikShimmer
          boxes={[
            { height: "20px", width: "64px" },
            { height: "28px", width: "120px" },
          ]}
        />
      </div>
      <div className="shimmer__card">
        <BikShimmer
          boxes={[
            { height: "20px", width: "64px" },
            { height: "28px", width: "120px" },
          ]}
        />
      </div>
      <div className="shimmer__card">
        <BikShimmer
          boxes={[
            { height: "20px", width: "64px" },
            { height: "28px", width: "120px" },
          ]}
        />
      </div>
      <div className="shimmer__card">
        <BikShimmer
          boxes={[
            { height: "20px", width: "64px" },
            { height: "28px", width: "120px" },
          ]}
        />
      </div>
    </ShimmerStyled>
  );
};
