import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { FirebaseService } from "../services/firebase/FirebaseService";
import { getIntegrationsEndpoints, isProd } from "../config";
import { captureErrorToSentry } from "../utilities/sentryHelper";

interface AppID {
  appId: string;
  signupPhoneNumber: string;
  storeId: string;
}

export const integrationsCheckApi: any = createApi({
  reducerPath: "integrationsCheckApi",
  keepUnusedDataFor: 21600,
  baseQuery: fetchBaseQuery({
    baseUrl: getIntegrationsEndpoints(),
    prepareHeaders: async (headers, { getState }) => {
      const idToken = await FirebaseService.fetchIdToken();
      if (idToken) {
        headers.set("Authorization", idToken);
      }
      headers.set("content-type", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getIntegrationsByStore: builder.query<[string], string>({
      query: (storeId) => {
        return {
          url: "bikAppsInstallationFunctions-fetchBikAppInstallations",
          method: "POST",
          body: {
            storeId,
          },
          responseHandler: async (response) => {
            const apiResponse: {
              status: number;
              apps: AppID[];
            } = await response.json();

            if (apiResponse.status != 200) {
              if (
                (apiResponse as any)?.detail?.indexOf("invalid user token") !==
                -1
              ) {
                const user = await FirebaseService.fetchCurrentUser();
                const projectId = (user as any)?.auth.app._options.projectId;
                console.error(
                  "Invalid auth",
                  JSON.stringify({ env: isProd, projectId: projectId })
                );
                captureErrorToSentry(
                  `Invalid auth, ${isProd} and ${projectId} and ${window.location}`
                );
              }
              return [];
            }
            const appIds: string[] = [];
            apiResponse.apps.forEach((app: AppID) => {
              appIds.push(app?.appId);
            });
            return appIds;
          },
        };
      },
    }),
  }),
});

export const { useGetIntegrationsByStoreQuery } = integrationsCheckApi;
