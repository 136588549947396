import { useDispatch } from "react-redux";
import { FirebaseService } from "../services/firebase/FirebaseService";
import { notificationDataStateActions } from "../state/notificationDataState";

export const useFirebaseHelperHook = () => {
  const dispatcher = useDispatch();

  const getFirebaseObject = () => {
    const firebaseService = new FirebaseService(
      (successMsg: string) => {
        dispatcher(
          notificationDataStateActions.setNotifications({
            successMessage: successMsg,
            errorMessage: "",
          })
        );

        setTimeout(() => {
          dispatcher(
            notificationDataStateActions.setNotifications({
              errorMessage: "",
              successMessage: "",
            })
          );
        }, 3500);
      },
      (failureMsg: string) => {
        dispatcher(
          notificationDataStateActions.setNotifications({
            errorMessage: failureMsg,
            successMessage: "",
          })
        );

        setTimeout(() => {
          dispatcher(
            notificationDataStateActions.setNotifications({
              errorMessage: "",
              successMessage: "",
            })
          );
        }, 3500);
      }
    );
    return firebaseService;
  };

  return {
    getFirebaseObject,
  };
};
