import { FunctionComponent } from "react";
import styled from "styled-components";
import { COLORS, BodyCaption } from "@bikdotai/bik-component-library";
import BulbIcon from "../../icons/bulbIcon";
import { HelpLinks } from "./links";

interface HelpSectionProps {
  subType: string;
}

type Props = HelpSectionProps;
const HelpSection: FunctionComponent<Props> = (props) => {
  if (typeof props.subType === "undefined") return <></>;
  const helpLink: { visible: boolean; link: string; info?: string[] } =
    HelpLinks[props.subType];
  const linkMaps = HelpLinks[props.subType]?.linkMaps;

  if (typeof helpLink === "undefined") return <></>;
  return (
    <Container>
      <>
        <ul style={{ marginBottom: 0 }}>
          {helpLink?.info?.map((info, idx) => (
            <li>
              <BodyCaption style={{ lineHeight: "18px" }}>
                {info}
                {linkMaps?.[idx.toString()] && (
                  <a
                    rel="noreferrer"
                    href={linkMaps?.[idx.toString()][1]}
                    target="_blank"
                  >
                    {linkMaps?.[idx.toString()][0]}
                  </a>
                )}
              </BodyCaption>
            </li>
          ))}
        </ul>
        {helpLink?.link && (
          <ul style={{ marginBottom: 0 }}>
            <li>
              <BodyCaption>
                {props.subType === "wa_carousel"
                  ? "Create a carousel template "
                  : "To know more about how to use this block,"}
                <a rel="noreferrer" href={helpLink?.link} target="_blank">
                  {props.subType === "wa_carousel" ? "here" : "click here"}
                </a>
                .
              </BodyCaption>
            </li>
          </ul>
        )}
      </>
      <BulbWrapper>
        <BulbIcon />
      </BulbWrapper>
    </Container>
  );
};

export default HelpSection;

const Container = styled.div`
  position: relative;
  border: 1px solid ${COLORS.stroke.primary};
  border-radius: 4px;
  margin-top: 50px;
  padding: 16px;
  background-color: ${COLORS.surface.hovered};
  a {
    color: ${COLORS.content.brand} !important;
    text-decoration: underline !important;
    cursor: pointer;
  }
`;

const BulbWrapper = styled.div`
  width: 40px;
  height: 40px;
  position: absolute;
  top: -26px;
  cursor: default !important;
`;
