import { firebaseConfig } from "../../../constants/FirebaseConfig";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signInWithCustomToken,
  FacebookAuthProvider,
  signOut,
  SAMLAuthProvider,
} from "firebase/auth";
import { initializeApp, getApp } from "firebase/app";
import { httpsCallable, getFunctions } from "firebase/functions";
import { isProd } from "../../../config";

export class FirebaseAuthHelper {
  bikayiAppUsa = initializeApp(firebaseConfig.bikayiAppUsa, "bikayiAppUsa");
  prodProvider = new SAMLAuthProvider("saml.bikayi-admin");
  stagingProvider = new SAMLAuthProvider("saml.bikayi-staging-admin");
  loginWithSecondaryApp = () => {
    const auth = getAuth(this.bikayiAppUsa);
    if (isProd) {
      return signInWithPopup(auth, this.prodProvider);
    } else {
      return signInWithPopup(auth, this.stagingProvider);
    }
  };
  loginWithFirebaseAppGoogle = () => {
    const app = getApp();
    const auth = getAuth(app);
    if (isProd) {
      return signInWithPopup(auth, this.prodProvider);
    } else {
      return signInWithPopup(auth, this.stagingProvider);
    }
  };
  loginWithFirebaseFacebook = () => {
    const app = getApp();
    const auth = getAuth(app);
    return signInWithPopup(auth, new FacebookAuthProvider());
  };
  getToken = async () => {
    const functions = getFunctions(this.bikayiAppUsa);
    return await httpsCallable(functions, "signIntoDifferentProvider")({});
  };
  signInWithToken = async (token: string) => {
    const app = getApp();
    const auth = getAuth(app);
    return signInWithCustomToken(auth, token);
  };
  signOut = async () => {
    const app = getApp();
    const auth = getAuth(app);
    await signOut(auth);
  };
}
