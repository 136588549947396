import { keyboardEventDataActions } from "../../state/keyboardEventState";
import { cloneDeep } from "lodash";
import { useAppSelector } from "../../state/store";
import { Node, Edge, XYPosition } from "reactflow";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { captureErrorToSentry } from "../sentryHelper";

/**
 * useCopy Hook can be used when selected list of node Id's are available and can be copied to clipboard.
 *
 * @param selectedNodeIds - Returned from useMultipleSelection hook
 * @param selectionEndPosition - Returned from useMultipleSelection hook
 * @param nodes - Nodes list which contains UI configuration, to be passed by caller
 * @param edges - Edges list which contains UI configuration, to be passed by caller
 */
const useCopy = (
  selectedNodeIds: Array<string>,
  selectionEndPosition: XYPosition,
  nodes: Node<any>[],
  edges: Edge<any>[]
) => {
  const dispatcher = useDispatch();
  const { type, channel } = useParams();

  //Redux store variables
  const keyboardEventDataState = useAppSelector(
    (state) => state.keyboardEventState
  );
  const storeState = useAppSelector((state: any) => state.storeState);
  const customApiResponses = useAppSelector(
    (state) => state.apiDataState.apiData
  );
  const discountResponses = useAppSelector(
    (state) => state.apiDataState.discountData
  );
  const externalApiDataState = useAppSelector(
    (state) => state.apiDataState.externalApiData
  );
  const stickyNotes = useAppSelector((state) => state.stickyNotesState.notes);
  const localVariableList = useAppSelector(
    (state) => state.localVariablesState.localVariablesList
  );
  const aiVariablesList = useAppSelector(
    (state) => state.aiVariablesState.aiVariablesList
  );
  const flowId = useAppSelector((state) => state.homeState.flowId);
  //Types and subtypes to be excluded from Copy
  const typeExclusions = ["trigger"];
  const subTypeExclusions = ["browse_collection"];

  //Object structure which will be copied to clipboard
  const selectedNodesAndEdges: ClipboardConfigType = {
    "nodes-schema": {},
    edges: {},
    "nodes-ui": {},
    "api-data": {},
    "local-variables": {},
    "ai-variables": {},
    "discount-variables": {},
    "external-api-variables": {},
    "sticky-notes": {},
    "offset-position": {},
    channel: channel,
    flowId: flowId,
  };

  const isModalOpen =
    document.getElementById("cmpModal") ||
    document.getElementById("chatbot-builder-modal") ||
    document.getElementById("bik-modal");

  const isCopyAllowed =
    keyboardEventDataState.keyPressAction === "copy" &&
    selectedNodeIds.length > 0 &&
    !isModalOpen;

  if (isCopyAllowed) {
    //Object which will be copied as a string to clipboard.
    for (let nodeId of selectedNodeIds) {
      try {
        const selectedNodeUI = cloneDeep(
          nodes.filter((node: any) => node.id === nodeId)?.[0]
        );

        // calculates offset position of the node w.r.t to end position of rectangle selection
        selectedNodesAndEdges["offset-position"][nodeId] = {
          x: selectionEndPosition.x - selectedNodeUI.position.x,
          y: selectionEndPosition.y - selectedNodeUI.position.y,
        };

        //handle sticky notes
        if (nodeId.startsWith("S")) {
          selectedNodesAndEdges["sticky-notes"][nodeId] = stickyNotes[nodeId];
          selectedNodesAndEdges["nodes-ui"][nodeId] = selectedNodeUI;
          continue;
        }

        const selectedNode = storeState.flow?.[nodeId];

        //empty nodes are not allowed to be copied
        if (!selectedNode) continue;

        // specific types and subtypes are not allowed to copy
        if (
          typeExclusions.includes(selectedNode.type) ||
          subTypeExclusions.includes(selectedNode.sub_type)
        )
          continue;

        // for call custom api blocks, if version does not exist, not allowed to copy
        if (
          selectedNode?.actions?.call_custom_api &&
          !selectedNode?.actions?.call_custom_api?.version
        )
          continue;

        //populating nodes schema and nodes ui
        selectedNodesAndEdges["nodes-schema"][nodeId] = selectedNode;
        selectedNodesAndEdges["nodes-ui"][nodeId] = selectedNodeUI;

        //copying api data state
        const customApiKeyList = Object.keys(customApiResponses);
        for (let customApiKey of customApiKeyList) {
          if (customApiKey.startsWith(`${nodeId} -`)) {
            selectedNodesAndEdges["api-data"][nodeId] =
              customApiResponses[customApiKey];
            break;
          }
        }

        //copying local variables state
        const localVariableValuesList = Object.values(localVariableList);
        for (let localVariableValue of localVariableValuesList) {
          if (localVariableValue.startsWith(`${nodeId}_`)) {
            selectedNodesAndEdges["local-variables"][nodeId] =
              localVariableValue;
            break;
          }
        }

        //copying ai variables state
        const aiVariableKeysList = Object.keys(aiVariablesList);
        for (let aiVariableKey of aiVariableKeysList) {
          if (aiVariableKey === nodeId) {
            selectedNodesAndEdges["ai-variables"][nodeId] =
              aiVariablesList[aiVariableKey];
            break;
          }
        }

        //copying discount data state
        const discountResponseKeyList = Object.keys(discountResponses);
        for (let discountResponseKey of discountResponseKeyList) {
          if (discountResponseKey.startsWith(`${nodeId} -`)) {
            selectedNodesAndEdges["discount-variables"][nodeId] =
              discountResponses[discountResponseKey];
            break;
          }
        }

        //copying external variables state
        if (selectedNode.sub_type === "call_external_api") {
          const externalApiResponses =
            selectedNode?.actions?.call_external_api.response;

          const variables: any[] = []
            .concat(...externalApiResponses.map((subArray: any) => subArray))
            .map((response: any) => {
              return response.variable;
            });

          selectedNodesAndEdges["external-api-variables"][nodeId] = variables;
        }
      } catch (e) {
        captureErrorToSentry(e, `Error in copying node ${nodeId} to clipboard`);
      }
    }

    try {
      const copiedNodeIds = Object.keys(selectedNodesAndEdges["nodes-schema"]);

      //copying edges - copy edges only if both source and target nodes are copied
      for (let edge of edges) {
        if (
          copiedNodeIds.includes(edge.source) &&
          copiedNodeIds.includes(edge.target)
        ) {
          // populating eligible edges
          selectedNodesAndEdges["edges"][edge.id] = edge;
        }
      }
    } catch (e) {
      captureErrorToSentry(e, `Error in copying edges to clipboard`);
    }

    try {
      //Writing data to clipboard
      navigator.clipboard.writeText(JSON.stringify(selectedNodesAndEdges));
    } catch (e) {
      captureErrorToSentry(e, `Error in writing to clipboard`);
    }

    //reset keyboard event state
    dispatcher(
      keyboardEventDataActions.keyPressed({
        keyPressAction: "",
      })
    );
  }
};

export type ClipboardConfigType = {
  "nodes-schema": any;
  edges: any;
  "nodes-ui": any;
  "api-data": any;
  "local-variables": any;
  "ai-variables": any;
  "discount-variables": any;
  "external-api-variables": any;
  "sticky-notes": any;
  "offset-position": any;
  channel: string | undefined;
  flowId: string;
};

export default useCopy;
