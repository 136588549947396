import { Icon } from "./icons-props";

export const CsatIcon: Icon = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.00494 17.3444L6.1639 16.6727L5.67455 16.258L6.32921 16.2024L6.61413 15.5435L6.89904 16.2024L7.5537 16.258L7.06436 16.6727L7.22332 17.3444L6.61413 16.9852L6.00494 17.3444Z"
        stroke={color || "#1A872C"}
        stroke-width="2.98945"
      />
      <path
        d="M16.4678 17.3444L16.6268 16.6727L16.1374 16.258L16.7921 16.2024L17.077 15.5435L17.3619 16.2024L18.0166 16.258L17.5272 16.6727L17.6862 17.3444L17.077 16.9852L16.4678 17.3444Z"
        stroke={color || "#1A872C"}
        stroke-width="2.98945"
      />
      <path
        d="M10.2923 9.58287L10.5075 8.67337L10.6487 8.07662L10.1809 7.68017L9.48252 7.08835L10.4327 7.00773L11.0287 6.95716L11.2661 6.40819L11.6503 5.51964L12.0345 6.40819L12.2718 6.95716L12.8678 7.00773L13.818 7.08835L13.1196 7.68017L12.6518 8.07662L12.793 8.67337L13.0082 9.58287L12.1582 9.08164L11.6503 8.78216L11.1423 9.08164L10.2923 9.58287Z"
        stroke={color || "#1A872C"}
        stroke-width="2"
      />
    </svg>
  );
};
