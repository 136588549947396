import React, { memo, useEffect, useState } from "react";
import {
  ChildNodeStyle,
  CTRRenderStyle,
  RedirectButtonContainer,
  StyledAnalyticsCard,
} from "./style";
import { useDispatch } from "react-redux";
import { nodeClickDataActions } from "../../state/nodeClickDataState";
import {
  ChildNodeProps,
  CREATE_NODE_DATA,
  CustomNodeProps,
} from "../../utilities/flowBuilder.utility";
import MessageBlockNode from "./messageNode";
import ConditionBlockNode from "./conditionNode";
import ActionBlockNode from "./actionNode";
import TriggerBlockNode from "./triggerNode";
import { NodeActionActions } from "../../state/nodeActionState";
import { useAppSelector } from "../../state/store";
import RedirectIcon from "../../icons/redirect";
import { WarnIcon } from "../../icons/warnIcon";
import { errorDataActions } from "../../state/errorDataState";
import AnalyticsNodeWrapper from "../../components/analytics/AnalyticsNodeWrapper";
import { useDebugRuns } from "../../components/analytics/runs/useDebugRuns";
import StickyNotesNode from "./stickyNotes";
import CustomNodeOptions from "./customNodeOptions";
import {
  AnalyticsCard,
  BodyTiny,
  COLORS,
} from "@bikdotai/bik-component-library";
import { useDetectAnalytics } from "./useDetectAnalytics";
import {
  Handle,
  NodeProps,
  Position,
  useReactFlow,
  useUpdateNodeInternals,
} from "reactflow";
import { getCtrPercentageText } from "../../services/helpers/AnalyticsHelper";
import AINode from "./aiNode";
import PhoneIcon from "../../icons/phoneIcon";
import Reply from "../../icons/reply";
import { NodeType } from "../../models/nodeType";
import { OrderDetailsButton } from "../../components/order-blocks/models";

const actionTypesForAnalytics: string[] = [
  "evaluate",
  "call_external_api",
  "rest_api",
  "call_custom_api",
  "filter",
  "order_filter",
  "CSAT/NPS",
  "email_csat",
  "create_payment_link",
  "freshdesk",
  "google_sheet_add_row",
  "google_sheet_update_row",
  "google_sheet_get_row_data",
  "ab_test",
];
const AiTypesForAnalytics: string[] = ["ai_classify"];
const subTypesToExcludeForAnalytics: string[] = ["catalog_list", "order_list"];
const subTypesForRedirectButton: string[] = [
  "product_carousel",
  "carousel",
  "wa_carousel",
];

const CustomNode = (props: NodeProps) => {
  const { data, isConnectable, id } = props;
  const isAnalytics = useDetectAnalytics();
  const leftHandlerStyle = {
    top: "36px",
    background: data.nodeData?.connectorColor || "#616161",
    width: "12px",
    height: "12px",
    zIndex: 100,
    left: "-6px",
    boxShadow: "0px 0px 0px 5px #E0E0E0",
    border: "none",
  };
  const rightHandlerStyle = {
    bottom: "15px",
    left: "0 !important",
    right: data.nodeData?.type === "Trigger" ? "" : "-6px",
  };
  const dispatcher = useDispatch();
  const [LocalComponent, setLocalComponent] =
    useState<React.FunctionComponentElement<CustomNodeProps>>();
  const listenToHover = useAppSelector(
    (state) => state.proximityConnectState.listenToHover
  );
  const errorState = useAppSelector((state) => state.errorDataState.errors);
  const errorDataState = useAppSelector((state) => state.errorDataState);
  const nodeFlow = useAppSelector((state) => state.storeState.flow);
  const currentFlow = useAppSelector((state) => state.homeState.flowId);
  const [isWarningExisting, setWarningExitsing] = useState(false);
  const [isErrorExisting, setIsErrorExisting] = useState(false);
  const [nodeOptionsDimension, setNodeOptionsDimension] = useState<{
    width: number;
    height: number;
  }>({ width: 20, height: 20 });
  const { getZoom } = useReactFlow();
  const [hoveredNodeId, setHoveredNodeId] = useState<string | null>(null);
  const runsHook = useDebugRuns();

  const updateNodeInternals = useUpdateNodeInternals();
  useEffect(() => {
    updateNodeInternals(data.nodeData.nodeId);
  }, [data.nodeData.childNodes]);

  const getIcon = (nodeName: string) => {
    return CREATE_NODE_DATA.filter((node) => node?.name === nodeName).map(
      (el) => el?.icon
    )?.[0];
  };

  const editNode = (
    nodeType: string,
    nodeId: string,
    nodeSubType: string,
    nodeIndex: number,
    automationChannel: string
  ) => {
    dispatcher(
      nodeClickDataActions.setEditNode({
        nodeType,
        nodeId,
        nodeSubType,
        nodeIndex,
        automationChannel,
      })
    );
  };

  const setNodeId = (nodeId: string | null, subType: string | undefined) => {
    if (isAnalytics) return; // Don't show hover affect in analytics mode
    dispatcher(
      NodeActionActions.updateState({
        action: "hover",
        type: "node",
        nodeId: nodeId ? nodeId : null,
        subType: subType ? subType : null,
      })
    );
  };

  useEffect(() => {
    const zoomLevel = getZoom();
    setNodeOptionsDimension({
      width: zoomLevel < 1 ? (2 - zoomLevel) * 20 : 20,
      height: zoomLevel < 1 ? (2 - zoomLevel) * 20 : 20,
    });
  }, [hoveredNodeId]);

  useEffect(() => {
    const propsItem = {
      id,
      nodeData: data.nodeData || data,
      isConnectable,
      leftHandlerStyle,
      rightHandlerStyle,
      getIcon,
      editNode,
      onHover: setNodeId,
    };
    switch (data?.nodeData?.type) {
      case "Trigger":
        setLocalComponent(<TriggerBlockNode {...propsItem} />);
        break;
      case "Message":
      case "Message (Instagram)":
        setLocalComponent(<MessageBlockNode {...propsItem} />);
        break;
      case "Condition":
        setLocalComponent(<ConditionBlockNode {...propsItem} />);
        break;
      case "Action":
        setLocalComponent(<ActionBlockNode {...propsItem} />);
        break;
      case "Sticky notes":
        setLocalComponent(<StickyNotesNode {...propsItem} />);
        break;
      case "AI":
      case NodeType.AI_ASSISTANT:
        setLocalComponent(<AINode {...propsItem} />);
        break;
    }
  }, [props?.data?.nodeData, listenToHover, isAnalytics]);

  useEffect(() => {
    if (
      props.data?.nodeData?.subType === "start_flow" &&
      errorState &&
      errorState[currentFlow] &&
      errorState[currentFlow]["child_reports"]
    ) {
      const nodeData = nodeFlow[id];
      if (nodeData && nodeData.actions.start_flow.flow_id) {
        const flowId = nodeData.actions.start_flow.flow_id;
        const nodeErrorState =
          errorState[currentFlow]["child_reports"][`${flowId}`];
        let errors = false;
        let warnings = false;
        if (nodeErrorState) {
          Object.keys(nodeErrorState).forEach((nodeId) => {
            const nodeLevelData = nodeErrorState[nodeId];
            if (errors && warnings) {
              return;
            }
            if (
              nodeLevelData &&
              nodeLevelData.errors &&
              nodeLevelData.errors.length
            ) {
              errors = true;
            }
            if (
              nodeLevelData &&
              nodeLevelData.warnings &&
              nodeLevelData.warnings.length
            ) {
              warnings = true;
            }
          });
        }
        setIsErrorExisting(errors);
        setWarningExitsing(warnings);
      }
    } else if (
      errorState &&
      errorState[currentFlow] &&
      errorState[currentFlow][`${id}`]
    ) {
      const nodeErrorState = errorState[currentFlow][`${id}`];
      if (nodeErrorState.errors?.length) {
        setIsErrorExisting(true);
      } else {
        setIsErrorExisting(false);
      }
      if (nodeErrorState.warnings?.length) {
        setWarningExitsing(true);
      } else {
        setWarningExitsing(false);
      }
    } else {
      setIsErrorExisting(false);
      setWarningExitsing(false);
    }
  }, [errorState, props?.data?.nodeData]);

  const showErrorModal = () => {
    dispatcher(
      errorDataActions.updateErrorState({
        ...errorDataState,
        errorModalOpenStatus: true,
        selectedNode: id,
      })
    );
  };

  if (runsHook.isDebugAnalyticsSetup) {
    return (
      <AnalyticsNodeWrapper
        nodeId={props.id}
        LocalComponent={LocalComponent!}
      />
    );
  }

  return (
    <div
      onMouseEnter={() => setHoveredNodeId(props.id)}
      onMouseLeave={() =>
        setTimeout(() => {
          setHoveredNodeId(null);
        }, 180)
      }
    >
      {isErrorExisting ? (
        <div
          style={{
            color: "#B92321",
            position: "absolute",
            top: "-30px",
            display: "flex",
            marginLeft: 16,
          }}
        >
          <div onClick={() => showErrorModal()}>
            <WarnIcon width={20} height={20} color={"#B92321"} />
          </div>
          <span style={{ marginLeft: 16 }}>Block defective</span>
        </div>
      ) : isWarningExisting && !isAnalytics ? (
        <div
          style={{
            color: "#99631B",
            position: "absolute",
            top: "-30px",
            display: "flex",
            marginLeft: 16,
          }}
        >
          <div onClick={() => showErrorModal()}>
            <WarnIcon width={20} height={20} color={"#99631B"} />
          </div>
          <span style={{ marginLeft: 16 }}>Block Has Warnings</span>
        </div>
      ) : null}
      {LocalComponent}
      {!isAnalytics && hoveredNodeId === props.id && props.id !== "N1" && (
        <CustomNodeOptions
          nodeId={props.id}
          width={nodeOptionsDimension!.width}
          height={nodeOptionsDimension!.height}
          nodeType={props.data?.nodeData?.type}
          nodeIndex={props.data?.nodeData?.nodeIndex?.toString()}
          subType={props.data?.nodeData?.subType}
        />
      )}
    </div>
  );
};

export const AnalyticsCardRenderer = (
  analytics: { [key: string]: string },
  width: string,
  loader?: boolean
) => {
  return (
    <StyledAnalyticsCard id="nla">
      <AnalyticsCard
        cardInfo={analytics}
        width={width}
        isLoading={
          loader ? loader : Object.keys(analytics).length > 0 ? false : true
        }
      />
    </StyledAnalyticsCard>
  );
};

export const CTRRenderer = (value: string, secondaryType?: boolean) => {
  return (
    <CTRRenderStyle secondaryType={secondaryType || false}>
      {value}
    </CTRRenderStyle>
  );
};

export const ChildNodes = (props: ChildNodeProps) => {
  const homeState = useAppSelector((state) => state.homeState);
  const id = `handler_${props.index}`;
  const isAnalytics = useDetectAnalytics();

  const getHandlerPosition = () => {
    switch (props?.nodeType) {
      case "Trigger":
        return `-4px`;
      case "Message":
        return `10px`;
      case "Condition":
        return `-4px`;
      case "Action":
        return `-4px`;
    }
  };

  const HandlerStyle = {
    bottom: getHandlerPosition(),
    background: props?.connectorColor || "#555555",
    width: "12px",
    height: "12px",
    boxShadow: "0px 0px 0px 5px #E0E0E0",
    zIndex: 100,
    right: "-24px",
    border: "none",
  };

  const isMessageNode = props.nodeType === "Message";

  const isConditionOrActionOrAi =
    (props.nodeType === "Condition" ||
      (props.nodeType === "Action" &&
        actionTypesForAnalytics.includes(props.subType!)) ||
      (props.nodeType === "AI" &&
        AiTypesForAnalytics.includes(props.subType!))) &&
    !subTypesToExcludeForAnalytics.includes(props.subType!);

  const handleConditonOrActionOrAi = (
    nodeId: string,
    buttonId: string,
    name: string
  ) => {
    const analyticsData = homeState?.analyticsData?.nodeAnalytics?.[nodeId];
    const operandButtonCTR = analyticsData?.operands?.[buttonId];
    const operandCTR = analyticsData?.operands?.[name];
    const classificationCTR = analyticsData?.classifications?.[name];
    const buttonCTR = analyticsData?.buttons?.[buttonId];
    const triggeredCount = analyticsData?.triggered_count;
    if (operandButtonCTR) {
      return CTRRenderer(
        getCtrPercentageText(operandButtonCTR, triggeredCount),
        true
      );
    } else if (operandCTR) {
      return CTRRenderer(
        getCtrPercentageText(operandCTR, triggeredCount),
        true
      );
    } else if (classificationCTR) {
      return CTRRenderer(
        getCtrPercentageText(classificationCTR, triggeredCount),
        true
      );
    } else if (buttonCTR) {
      return CTRRenderer(getCtrPercentageText(buttonCTR, triggeredCount), true);
    } else {
      return CTRRenderer("CTR 0%", true);
    }
  };

  const handleMessageButton = (nodeId: string, buttonId: string) => {
    const analyticsData = homeState?.analyticsData?.nodeAnalytics?.[nodeId];
    const buttonCTR = analyticsData?.buttons?.[buttonId];
    const linkClicks = analyticsData?.link_clicks;
    const triggeredCount = analyticsData?.triggered_count;
    const noCTRButtonIds = [
      "copy_code",
      "phone",
      OrderDetailsButton.REVIEW_AND_PAY,
      OrderDetailsButton.PAY_NOW,
    ];

    if (
      noCTRButtonIds.some((noCTRButtonId) => buttonId.includes(noCTRButtonId))
    ) {
      return <></>;
    }

    /**
     * First, we check if there is any button, if there is no button then old CTA flow.
     *
     * If there is button, there is either 2 CTA scenario or not, for 2 CTA scenario we take analytics fron button Ids.
     * For other scenario, i.e only one CTA present, or any qrb present, we take from link_clicks (CTA),
     * for QRB we take from buttons.
     *
     */

    //TODO: To remove after 1 month, if condition is written for backward compatibility

    if (analyticsData?.buttons) {
      // 2 cta scenario
      const ctaButtons = Object.keys(analyticsData?.buttons ?? {})?.filter(
        (buttonId: any) => buttonId.startsWith("website-")
      );
      if (ctaButtons && ctaButtons?.length >= 2) {
        if (buttonCTR) {
          return CTRRenderer(getCtrPercentageText(buttonCTR, triggeredCount));
        } else {
          return CTRRenderer("CTR 0%");
        }
      } else {
        if (linkClicks > buttonCTR) {
          return CTRRenderer(getCtrPercentageText(linkClicks, triggeredCount));
        } else if (buttonCTR) {
          return CTRRenderer(getCtrPercentageText(buttonCTR, triggeredCount));
        } else {
          return CTRRenderer("CTR 0%");
        }
      }
    } else {
      if (buttonCTR) {
        return CTRRenderer(getCtrPercentageText(buttonCTR, triggeredCount));
      } else if (linkClicks) {
        return CTRRenderer(getCtrPercentageText(linkClicks, triggeredCount));
      } else {
        return CTRRenderer("CTR 0%");
      }
    }
  };

  const getNodeIcon = (type: string) => {
    if (type === "URL") {
      return <RedirectIcon height={20} width={20} color={"#9E9E9E"} />;
    } else if (type === "PHONE_NUMBER") {
      return <PhoneIcon height={20} width={20} color={"#9E9E9E"} />;
    } else if (type === "QUICK_REPLY") {
      return <Reply height={20} width={20} color={"#9E9E9E"} />;
    } else {
      return <></>;
    }
  };

  return (
    <div id={id}>
      <ChildNodeStyle
        nodeType={props?.nodeType}
        subType={props?.subType}
        isAnalytics={isAnalytics}
      >
        {props.subType &&
        subTypesForRedirectButton.includes(props.subType) &&
        !!props?.ctaValue?.length ? (
          <RedirectButtonContainer>
            <RedirectIcon height={20} width={20} color={"#9E9E9E"} />
          </RedirectButtonContainer>
        ) : (
          <RedirectButtonContainer>
            {props.type ? (
              <div style={{ marginRight: 4 }}>{getNodeIcon(props.type)}</div>
            ) : (
              <></>
            )}
          </RedirectButtonContainer>
        )}

        {isAnalytics &&
          props.nodeId &&
          isConditionOrActionOrAi &&
          handleConditonOrActionOrAi(props.nodeId, props.buttonId, props.name)}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="button__text">{props?.name} </div>
          {props.description && !isAnalytics && (
            <BodyTiny
              style={{ color: COLORS.content.placeholder }}
              className="single-line"
            >
              {props.description}
            </BodyTiny>
          )}
        </div>

        {isAnalytics &&
          props.nodeId &&
          isMessageNode &&
          !subTypesToExcludeForAnalytics.includes(props.subType!) &&
          handleMessageButton(props.nodeId, props.buttonId)}
      </ChildNodeStyle>
      {props?.isConnectable && (
        <Handle
          type="source"
          position={Position.Right}
          id={props?.buttonId}
          isConnectable={!isAnalytics}
          style={HandlerStyle}
        />
      )}
    </div>
  );
};

export default memo(CustomNode);
