import styled, { keyframes } from "styled-components";

const keyframesAnimation = keyframes`
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
`;

export const ShimmerStyled = styled.div`
  display: flex;
  flex-direction: column;
  .shine {
    margin-top: 16px;
    background: #f6f7f8;
    background-image: linear-gradient(
      to right,
      #f6f7f8 0%,
      #edeef1 20%,
      #f6f7f8 40%,
      #f6f7f8 100%
    );
    background-repeat: no-repeat;
    background-size: 800px 304px;
    display: inline-block;
    position: relative;

    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: ${keyframesAnimation};
    -webkit-animation-timing-function: linear;
  }
`;
