import { Icon } from "./icons-props";

const Catalog: Icon = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      width={width || "22"}
      height={height || "22"}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 3C0 1.34315 1.34315 0 3 0H7C8.65685 0 10 1.34315 10 3V7C10 8.65685 8.65685 10 7 10H3C1.34315 10 0 8.65685 0 7V3ZM3 2C2.44772 2 2 2.44772 2 3V7C2 7.55228 2.44772 8 3 8H7C7.55228 8 8 7.55228 8 7V3C8 2.44772 7.55228 2 7 2H3Z"
        fill={color || "#212121"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 15C0 13.3431 1.34315 12 3 12H7C8.65685 12 10 13.3431 10 15V19C10 20.6569 8.65685 22 7 22H3C1.34315 22 0 20.6569 0 19V15ZM3 14C2.44772 14 2 14.4477 2 15V19C2 19.5523 2.44772 20 3 20H7C7.55228 20 8 19.5523 8 19V15C8 14.4477 7.55228 14 7 14H3Z"
        fill={color || "#212121"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15 12C13.3431 12 12 13.3431 12 15V19C12 20.6569 13.3431 22 15 22H19C20.6569 22 22 20.6569 22 19V15C22 13.3431 20.6569 12 19 12H15ZM14 15C14 14.4477 14.4477 14 15 14H19C19.5523 14 20 14.4477 20 15V19C20 19.5523 19.5523 20 19 20H15C14.4477 20 14 19.5523 14 19V15Z"
        fill={color || "#212121"}
      />
      <path
        d="M17 1C17.5523 1 18 1.44772 18 2V4H20C20.5523 4 21 4.44772 21 5C21 5.55228 20.5523 6 20 6H18V8C18 8.55228 17.5523 9 17 9C16.4477 9 16 8.55228 16 8V6H14C13.4477 6 13 5.55228 13 5C13 4.44772 13.4477 4 14 4H16V2C16 1.44772 16.4477 1 17 1Z"
        fill={color || "#212121"}
      />
    </svg>
  );
};

export default Catalog;
