import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AllChannels,
  AllFeatureSubKey,
  AllModules,
  FeatureCategory,
  FeatureSubKeyToPlanMappingInterface,
} from "@bikdotai/bik-models/growth/models/feature";

import { useAppSelector } from "../state/store";
import { FirebaseService } from "../services/firebase/FirebaseService";
import {
  BikFeatureLocalRequest,
  BikFeatureRestrictionPropsCompulsory,
  BikRestrictedFeaturesI,
  DEFAULT_FR_BUTTON_TEXT,
  DEFAULT_FR_SUBTITLE,
  DEFAULT_FR_TITLE,
  FEATURE_DETAILS_MAPPING,
  FEATURE_LOCK_IMAGE,
  FeatureDetailsKeys,
  bikRestrictedFeatureSliceActions,
} from "../state/bikFeatureRestrictState";

export const useFetchRestrictedFeature = () => {
  const [isFeatureRestrictingLoading, setIsFeatureRestrictingLoading] =
    useState(true);
  const dispatch = useDispatch();
  const bikFeatureState = useSelector<
    {
      bikRestrictedFeature: BikRestrictedFeaturesI;
    },
    BikRestrictedFeaturesI
  >((state) => state.bikRestrictedFeature);

  const homeState = useAppSelector((state) => state.homeState);
  const storeId = homeState?.storeId;

  const fetchAndCacheFeatureDoc = async () => {
    setIsFeatureRestrictingLoading(true);
    const firebaseService = new FirebaseService(
      () => {},
      () => {}
    );
    if (!storeId) {
      return;
    }
    const resp = await firebaseService.fetchBikFeatureRestrictionData(storeId);
    if (resp) {
      dispatch(
        bikRestrictedFeatureSliceActions.setFeatureRestrictedState(resp)
      );
    }
    setIsFeatureRestrictingLoading(false);
    return resp;
  };

  useEffect(() => {
    if (!storeId) {
      return;
    }
    if (!bikFeatureState.featureDoc) {
      fetchAndCacheFeatureDoc();
      return;
    }
    setIsFeatureRestrictingLoading(false);
  }, [storeId]);

  const hasAccessToBikFeatures = async (
    props: BikFeatureLocalRequest
  ): Promise<boolean> => {
    const featureDoc =
      bikFeatureState.featureDoc || (await fetchAndCacheFeatureDoc());
    if (!featureDoc || Object.keys(featureDoc).length === 0) {
      return true;
    }
    return checkAccess(featureDoc, props);
  };

  const checkAccess = (
    featureDoc: FeatureSubKeyToPlanMappingInterface,
    props: BikFeatureLocalRequest
  ) => {
    let hasAccess = true;
    const mainCategory = !props.subFeatureName
      ? FeatureCategory.channel
      : props.category || FeatureCategory.module;

    if (!props.subFeatureName) {
      hasAccess =
        featureDoc[FeatureCategory.channel]?.[
          props.featureName as keyof (typeof featureDoc)[FeatureCategory.channel]
        ]?.isDisabled !== true;
    } else {
      const categoryDoc = featureDoc[mainCategory as keyof typeof featureDoc];
      const featureDocEntry =
        categoryDoc?.[props.featureName as keyof typeof categoryDoc];
      const subFeatureDocEntry = featureDocEntry?.[
        props.subFeatureName as keyof typeof featureDocEntry
      ] as { isDisabled: boolean };

      hasAccess = subFeatureDocEntry?.isDisabled !== true;
    }

    if (!hasAccess && !props.skipPopup) {
      showRestrictionPopupOrDetails({ ...props, category: mainCategory });
    }

    return hasAccess;
  };

  const showRestrictionPopupOrDetails = (
    props: BikFeatureLocalRequest
  ): BikFeatureRestrictionPropsCompulsory => {
    const key =
      props.subFeatureName ||
      (props.featureName as AllModules | AllChannels | AllFeatureSubKey);
    const {
      title = DEFAULT_FR_TITLE,
      subTitle = DEFAULT_FR_SUBTITLE,
      primaryButtonText = DEFAULT_FR_BUTTON_TEXT,
      icon,
      featureImage = FEATURE_LOCK_IMAGE,
    } = FEATURE_DETAILS_MAPPING[key as FeatureDetailsKeys] || {};
    const restrictionProps = {
      isEnabled: true,
      title,
      subTitle,
      primaryButtonText,
      icon,
      category: props.category,
      featureImage,
      redirect: props.redirect || false,
      uniqueIdAsFeatureOrSubFeatureName:
        props.subFeatureName || props.featureName,
    };

    if (props.returnOnlyValues) {
      return restrictionProps as BikFeatureRestrictionPropsCompulsory;
    }
    dispatch(
      bikRestrictedFeatureSliceActions.setBikRestrictionProps({
        ...restrictionProps,
      })
    );
    return restrictionProps;
  };

  return {
    bikFeatureState,
    featureDoc: bikFeatureState?.featureDoc,
    isLoading: isFeatureRestrictingLoading,
    hasAccessToBikFeatures,
    bikRestrictionProps: bikFeatureState?.bikFeatureRestrictionProps,
    isBlockingThankYouModalOpen: bikFeatureState?.isBlockingThankYouModalOpen,
  };
};
