import React, { FunctionComponent, useState } from "react";
import BotInput from "../../ui-components/input";
import BikayiButton from "../../ui-components/button";
import { CampaignService } from "../services/CampaignService";

interface OwnProps {}

type Props = OwnProps;

const CustomerSearch: FunctionComponent<Props> = (props) => {
  const [storeId, setStoreId] = useState<string>("");
  const [customerId, setCustomerId] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [details, setDetails] = useState<string>("");
  const search = () => {
    const campaignService = new CampaignService();
    campaignService.getCustomerDetails(customerId, storeId).then((details) => {
      setDetails(JSON.stringify(details));
    });
  };
  return (
    <div>
      <div className="title-regular">Customer Search By Id</div>
      <div
        style={{ display: "flex", columnGap: 20, width: 800, marginTop: 16 }}
      >
        <BotInput
          id={"storeId"}
          height={"20px"}
          value={storeId}
          placeHolder={"Enter store id"}
          type={"text"}
          background={"#FFFFFF"}
          onChange={(event) => {
            setStoreId(event.target.value);
          }}
        ></BotInput>
        <BotInput
          id={"customer_id"}
          height={"20px"}
          value={customerId}
          placeHolder={"Enter customer id"}
          type={"text"}
          background={"#FFFFFF"}
          onChange={(event) => {
            setCustomerId(event.target.value);
          }}
        ></BotInput>
        <BotInput
          id={"phone_number"}
          height={"20px"}
          value={phoneNumber}
          placeHolder={"Enter phone number"}
          type={"text"}
          background={"#FFFFFF"}
          onChange={(event) => {
            setPhoneNumber(event.target.value);
          }}
        ></BotInput>
      </div>
      <BikayiButton
        style={{ marginTop: "16px", marginLeft: 0 }}
        buttonName={"Submit"}
        buttonStyle="primary"
        buttonWidth="75px"
        buttonPadding={"6px 12px"}
        buttonHeight="32px"
        click={() => {
          search();
        }}
      />
      <div style={{ marginTop: 8 }}>Details collected : {details}</div>
    </div>
  );
};

export default CustomerSearch;
