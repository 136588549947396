import { cloneDeep } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { TriggerNodeIcon } from "../../../icons";
import { useAppSelector } from "../../../state/store";
import {
  CustomNodeProps,
  NODE_COLOR,
} from "../../../utilities/flowBuilder.utility";
import { AnalyticsCardRenderer, ChildNodes } from "../customNode";
import { NodeStyle } from "../style";
import { TriggerNodeStyle, AutomationTrigger } from "./style";
import {
  BodyCaption,
  BodySecondary,
  BodyTiny,
  COLORS,
  IconButton,
  Spinner,
  Tag,
  Tooltip,
} from "@bikdotai/bik-component-library";
import GreyCircle from "../../grey-circle";
import AddEvent from "../../../icons/addEvent";
import InstagramIcon from "../../../icons/instagramIcon";
import { unCamelCase } from "../../../utilities/unCamelCase";
import WhatsappIcon from "../../../icons/whatsappIcon";
import SpecialDateIcon from "../../../icons/calendar";

import HashIcon from "../../../icons/hashIcon";
import {
  keyWordCounter,
  keyWordsStringify,
} from "../../../components/AutomationTrigger/AutomationTrigger";
import { useDetectAnalytics } from "../useDetectAnalytics";
import DownloadIcon from "../../../icons/DownloadIcon";
import { downloadNodeLevelAnalytics } from "../../../services/helpers/AnalyticsHelper";
import { generateCSV } from "../../../utilities/fileDownload";
import WebhookIcon from "../../../icons/webhookIcon";
import InstagramSendIcon from "../../../icons/instagramSendIcon";
import InstagramCommentIcon from "../../../icons/instagramCommentIcon";
import InstagramReplyIcon from "../../../icons/instagramReplyIcon";
import EmailIcon from "../../../icons/email";
import { flowMetaStateActions } from "../../../state/flowMetaState";
import { useDispatch } from "react-redux";
import {
  getChannelIdName,
  hasChannelError,
} from "../../../components/query-builder/utils";
import ErrorReport from "../../../icons/errorReport";
import { SummaryAnalyticsViewTabName } from "../../../components/header/SummaryAnalytics";
import BackInStockIcon from "../../../icons/backInStockIcon";
import PriceDropIcon from "../../../icons/priceDropIcon";
import { BUSINESS_EVENTS } from "../../../components/business-events/models";
import PromocodeIcon from "../../../icons/promocodeIcon";
import { RecordEventHelper } from "../../../utilities/RecordEventHelpers";
import {
  AmplitudeEventStep,
  AmplitudeKeys,
  TargetPlatform,
} from "../../../utilities/AnalyticsTypes";

const TriggerBlockNode = (props: CustomNodeProps) => {
  const { nodeData, editNode, onHover } = props;
  const { flowId } = useParams();
  const flowMeta: { [key: string]: any } = useAppSelector(
    (state) => state.flowMetaState.flowMeta
  );
  const channels: { [key: string]: any } = useAppSelector(
    (state) => state.flowMetaState.channels!
  );
  const updateFlowMeta: boolean | undefined = useAppSelector(
    (state) => state.flowMetaState.updateFlowMeta
  );
  const homeState = useAppSelector((state) => state.homeState);
  const itemList = cloneDeep(flowMeta);
  const homeStateChannel = useAppSelector((state) => state.homeState?.channel);
  const isAnalytics = useDetectAnalytics();
  const [messageAnalytics, setMessageAnalytics] = useState<{
    [key: string]: string;
  }>({});
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isErrorDownloading, setIsErrorDownloading] = useState<boolean>(false);
  const triggerNodeRef = useRef<HTMLDivElement>();
  const [triggers, setTriggers] = useState<any>([]);
  const dispatcher = useDispatch();

  useEffect(() => {
    triggerNodeRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  }, []);

  useEffect(() => {
    if (
      homeState?.analyticsData?.nodeAnalytics &&
      homeState?.analyticsData?.nodeAnalytics["N1"]
    ) {
      populateAnalytics();
    }
  }, [homeState]);

  const populateAnalytics = () => {
    const nodeAnalytics = homeState?.analyticsData?.nodeAnalytics;
    const triggered = nodeAnalytics?.["N1"]?.flow_triggered ?? "--";
    const stopped = nodeAnalytics?.["N1"]?.flow_stopped ?? "--";
    setMessageAnalytics({
      Triggered: triggered,
      Stopped: stopped,
    });
  };

  const onClickTrigger = () => {
    editNode(
      nodeData?.type,
      props?.id,
      "starting_point",
      nodeData?.nodeIndex,
      nodeData?.automationChannel
    );
  };

  const renderAutomationIcon = (triggerType: string, trigger: any) => {
    if (triggerType === "ig") {
      if (trigger?.header?.includes("message")) {
        return <InstagramSendIcon width={16} height={16} />;
      } else if (trigger?.header?.includes("Comments")) {
        return <InstagramCommentIcon width={16} height={16} />;
      } else if (trigger?.tag === "Any Story") {
        return <InstagramReplyIcon width={16} height={16} />;
      } else {
        return <InstagramIcon width={16} height={16} />;
      }
    } else if (triggerType === "ctwa") {
      return <WhatsappIcon width={16} height={16} />;
    } else if (triggerType === "relay") {
      return (
        <SpecialDateIcon
          width={16}
          height={16}
          color={
            itemList?.[flowId!]?.channel === "whatsapp"
              ? "#1A872C"
              : itemList?.[flowId!]?.channel === "instagram"
                ? "#510058"
                : COLORS.content.secondary
          }
        />
      );
    } else if (triggerType === "webhook") {
      return (
        <WebhookIcon
          width={16}
          height={16}
          color={"#1A872C"}
          backgroundColor={"#E6F4E7"}
          borderColor={"#C3E4C4"}
        />
      );
    } else if (triggerType === "businessEvents") {
      const subType = trigger.businessEventType;
      if (subType === BUSINESS_EVENTS.BACK_IN_STOCK) {
        return (
          <BackInStockIcon color={"#1A872C"} width={"16px"} height={"16px"} />
        );
      } else {
        return (
          <PromocodeIcon color={"#1A872C"} width={"16px"} height={"16px"} />
        );
      }
    }
    return (
      <AddEvent
        width={16}
        height={16}
        color={
          itemList?.[flowId!]?.channel === "whatsapp"
            ? "#1A872C"
            : itemList?.[flowId!]?.channel === "instagram"
              ? "#510058"
              : COLORS.content.secondary
        }
      />
    );
  };

  const handleNLADownload = async (e: any, isError = false) => {
    e.stopPropagation();
    if (isError) {
      setIsErrorDownloading(true);
    } else {
      setIsDownloading(true);
    }
    const buttons: { [key: string]: string } = {};
    const payload: any = {
      storeId: homeState.storeId,
      flowId: homeState.flowId,
      startDate: homeState.analyticsData?.startDate,
      endDate: homeState.analyticsData?.endDate,
      channel: homeState.channel,
      nodeId: "N1",
      nodeSubType: nodeData.type,
      nodeType: nodeData.type,
      buttons: buttons,
      basedOnTriggeredDate:
        homeState?.analyticsData?.selectedView ===
        SummaryAnalyticsViewTabName.FUNNEL_VIEW,
    };
    if (isError) {
      payload.onlyErrorReport = true;
    }
    const response = await downloadNodeLevelAnalytics(payload);
    if (response) {
      generateCSV(
        response,
        `${isError ? "error_" : ""}${homeState.flowId}_N1_${nodeData.type}`
      );
      const eventHelper = RecordEventHelper.getInstance();
      eventHelper.trackEvent(
        TargetPlatform.Amplitude,
        AmplitudeKeys.service_used,
        {
          step: isError
            ? AmplitudeEventStep.error_report_downloaded
            : AmplitudeEventStep.node_analytics_downloaded,
          screen: "journey_builder_analytics",
        }
      );
    }
    if (isError) {
      setIsErrorDownloading(false);
    } else {
      setIsDownloading(false);
    }
  };

  useEffect(() => {
    if (!itemList?.[flowId!]?.triggers?.length) {
      return;
    }
    if (itemList?.[flowId!]?.triggers?.length && updateFlowMeta) {
      const sortedTriggers = [...itemList?.[flowId!]?.triggers].sort((a, b) => {
        if (itemList?.[flowId!]?.triggerType == "ig") {
          return a?.instaId?.localeCompare(b?.instaId);
        } else {
          return a?.channelId?.localeCompare(b?.channelId);
        }
      });
      const sortedTriggerClone = cloneDeep(sortedTriggers);
      const updatedTriggers = sortedTriggerClone.map((trigger) => {
        delete trigger.isDuplicateChannelId;
        return trigger;
      });
      // Remove duplicate channel IDs
      const uniqueTriggers = [];
      const uniqueChannelIds = new Set();

      for (const trigger of updatedTriggers) {
        if (itemList?.[flowId!]?.triggerType == "ig") {
          if (!uniqueChannelIds.has(trigger.instaId)) {
            // Add the trigger to the updated array
            uniqueTriggers.push(trigger);
            uniqueChannelIds.add(trigger.instaId);
          } else {
            trigger.isDuplicateChannelId = true;
            uniqueTriggers.push(trigger);
          }
        } else {
          if (!uniqueChannelIds.has(trigger.channelId)) {
            // Add the trigger to the updated array
            uniqueTriggers.push(trigger);
            uniqueChannelIds.add(trigger.channelId);
          } else {
            trigger.isDuplicateChannelId = true;
            uniqueTriggers.push(trigger);
          }
        }
      }
      setTriggers(uniqueTriggers);
      if (updateFlowMeta) {
        dispatcher(
          flowMetaStateActions.updateFlowMetaState({
            updateFlowMeta: false,
          } as any)
        );
      }
    }
  }, [itemList?.[flowId!]?.triggers?.length, updateFlowMeta]);

  const getChannelIcon = (channel: string) => {
    if (channel === "whatsapp") {
      return <WhatsappIcon width={12} height={12} />;
    } else if (channel === "instagram") {
      return <InstagramIcon width={12} height={12} />;
    } else {
      return <EmailIcon width={12} height={12} />;
    }
  };

  return (
    <>
      <div ref={triggerNodeRef as any}>
        <TriggerNodeStyle
          id="trigger-node"
          onMouseEnter={() => onHover("N1", "trigger_node")}
          onMouseLeave={() => onHover(null, undefined)}
        >
          <NodeStyle
            className="customNode flex--column"
            nodeColor={NODE_COLOR[nodeData?.type] || "#C3E4C4"}
          >
            <div className="customNode__header flex justify-content-between align-items-center">
              <div className="flex">
                <div className="customNode__header__icon icon-wrapper">
                  <TriggerNodeIcon color={"#731DCF"} />
                </div>
                <div style={{ width: 15 }}></div>
                <span className="customNode__header__title">
                  Starting Point
                </span>
              </div>
              {isAnalytics && (
                <div className={"ml-auto d-flex"}>
                  <div>
                    {isDownloading ? (
                      <div style={{ width: 32 }}>
                        <Spinner size="small" color={COLORS.content.primary} />
                      </div>
                    ) : (
                      <Tooltip
                        /* @ts-ignore */
                        delay={[200, 0]}
                        body="Download node analytics"
                        placement="right"
                      >
                        <IconButton
                          Icon={DownloadIcon}
                          style={{ color: "#919191", display: "flex" }}
                          onClick={handleNLADownload}
                        />
                      </Tooltip>
                    )}
                  </div>
                  <div>
                    {isErrorDownloading ? (
                      <div style={{ width: 32 }}>
                        <Spinner size="small" color={COLORS.content.primary} />
                      </div>
                    ) : (
                      <Tooltip
                        /* @ts-ignore */
                        delay={[200, 0]}
                        body="Download error report"
                        placement="right"
                      >
                        <IconButton
                          Icon={ErrorReport}
                          style={{ color: "#919191", display: "flex" }}
                          onClick={(e) => handleNLADownload(e, true)}
                        />
                      </Tooltip>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div
              className="flex--column"
              onClick={isAnalytics ? () => {} : onClickTrigger}
            >
              {(!flowMeta?.[flowId!]?.keywordsNew ||
                !Object.keys(flowMeta?.[flowId!]?.keywordsNew)?.length) &&
                !itemList?.[flowId!]?.triggers?.length && (
                  <div className="customNode__body__no-content flex">
                    {`Click to add trigger`}
                  </div>
                )}
              {itemList?.[flowId!]?.triggerType === "keyword" &&
                flowMeta?.[flowId!]?.keywordsNew &&
                Object.keys(flowMeta?.[flowId!]?.keywordsNew).length > 0 && (
                  <>
                    <BodySecondary
                      style={{ marginTop: 16 }}
                      color={COLORS.content.primary}
                    >
                      Whenever customer says
                    </BodySecondary>
                    {Object.keys(flowMeta[flowId!].keywordsNew).map((key) => {
                      let displayName = undefined;
                      if (itemList?.[flowId!]?.channel === "instagram") {
                        displayName = channels?.instagram?.find(
                          (channel: any) => channel.accountId === key
                        )?.accountName;
                      } else if (itemList?.[flowId!]?.channel === "whatsapp") {
                        displayName = channels?.multiWhatsappInfo?.find(
                          (channel: any) => channel.phoneNumberId === key
                        )?.accountNumber;
                      }
                      const hasError = hasChannelError(
                        itemList?.[flowId!]?.channel,
                        key,
                        channels
                      );
                      return (
                        <div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              marginTop: 16,
                            }}
                          >
                            {getChannelIcon(itemList?.[flowId!]?.channel)}
                            {displayName ? (
                              <BodyTiny style={{ marginLeft: 4 }}>
                                {displayName}
                              </BodyTiny>
                            ) : (
                              <BodyTiny
                                style={{
                                  marginLeft: 4,
                                  color: COLORS.content.negative,
                                }}
                              >
                                Connect an account
                              </BodyTiny>
                            )}
                          </div>
                          <div className="flex--column">
                            <AutomationTrigger hasError={hasError}>
                              <GreyCircle
                                size={32}
                                icon={
                                  itemList?.[flowId!]?.channel ===
                                  "whatsapp" ? (
                                    <HashIcon color={"#1A872C"} />
                                  ) : itemList?.[flowId!]?.channel ===
                                    "instagram" ? (
                                    <InstagramSendIcon />
                                  ) : (
                                    <HashIcon />
                                  )
                                }
                                backgroundColor={
                                  itemList?.[flowId!]?.channel === "whatsapp"
                                    ? "#E6F4E7"
                                    : itemList?.[flowId!]?.channel ===
                                        "instagram"
                                      ? "#F4E6F1"
                                      : undefined
                                }
                                borderColor={
                                  itemList?.[flowId!]?.channel === "whatsapp"
                                    ? "#C3E4C4"
                                    : itemList?.[flowId!]?.channel ===
                                        "instagram"
                                      ? "#EFD9EA"
                                      : undefined
                                }
                              />
                              <div className="content-wrapper">
                                <BodySecondary
                                  className="single-line"
                                  color={COLORS.content.primary}
                                >
                                  {keyWordsStringify(
                                    flowMeta[flowId!].keywordsNew[key]
                                  )}
                                </BodySecondary>
                              </div>
                            </AutomationTrigger>
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              {!!itemList?.[flowId!]?.triggers?.length && (
                <>
                  <BodySecondary
                    style={{ marginTop: 16 }}
                    color={COLORS.content.primary}
                  >
                    Whenever this happens
                  </BodySecondary>
                  {triggers?.map((item: any, idx: number) => {
                    const displayName = getChannelIdName(
                      itemList?.[flowId!]?.channel,
                      channels,
                      item
                    );
                    const hasError = hasChannelError(
                      itemList?.[flowId!]?.channel,
                      item?.channelId ?? item?.instaId,
                      channels
                    );
                    return (
                      <div>
                        {((item?.channelId && !item?.isDuplicateChannelId) ||
                          (item?.instaId && !item.isDuplicateChannelId)) && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              marginTop: 16,
                            }}
                          >
                            {getChannelIcon(itemList?.[flowId!]?.channel)}
                            {displayName ? (
                              <BodyTiny style={{ marginLeft: 4 }}>
                                {displayName}
                              </BodyTiny>
                            ) : Object.keys(channels).length > 0 ? (
                              <BodyTiny
                                style={{
                                  marginLeft: 4,
                                  color: COLORS.content.negative,
                                }}
                              >
                                Connect an account
                              </BodyTiny>
                            ) : (
                              <></>
                            )}
                          </div>
                        )}
                        <div className="flex--column">
                          <AutomationTrigger hasError={hasError}>
                            <div id={`edge-conn-${idx}`}>
                              <GreyCircle
                                size={32}
                                icon={renderAutomationIcon(
                                  itemList?.[flowId!].triggerType,
                                  item
                                )}
                                backgroundColor={
                                  itemList?.[flowId!]?.channel === "whatsapp"
                                    ? "#E6F4E7"
                                    : itemList?.[flowId!]?.channel ===
                                        "instagram"
                                      ? "#F4E6F1"
                                      : undefined
                                }
                                borderColor={
                                  itemList?.[flowId!]?.channel === "whatsapp"
                                    ? "#C3E4C4"
                                    : itemList?.[flowId!]?.channel ===
                                        "instagram"
                                      ? "#EFD9EA"
                                      : undefined
                                }
                              />
                            </div>

                            <div className="content-wrapper">
                              <BodySecondary
                                className="single-line"
                                color={COLORS.content.primary}
                              >
                                {unCamelCase(item.header)}
                              </BodySecondary>
                              <BodyCaption
                                className="single-line"
                                style={{ marginTop: 2 }}
                                color={COLORS.content.secondary}
                              >
                                {item.subHeader}
                              </BodyCaption>
                              {!!item.tag && (
                                <div
                                  style={{ marginTop: 8, width: "fit-content" }}
                                >
                                  <Tag
                                    tagText={item.tag}
                                    theme="light"
                                    type="white"
                                  />
                                </div>
                              )}
                            </div>
                          </AutomationTrigger>
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
            </div>
            <div className="customNode__footer">
              <div className="customNode__child-nodes flex--column">
                <div className="customNode__childNodes">
                  <ChildNodes
                    key={homeStateChannel}
                    name={homeStateChannel as string}
                    buttonId={homeStateChannel as string}
                    index={0}
                    connectorColor={nodeData?.connectorColor}
                    nodeType={"Trigger"}
                    count={nodeData?.childNodes?.length}
                    isConnectable={true}
                  />
                </div>
              </div>
            </div>
            {itemList?.[flowId!]?.triggerType === "keyword" &&
              itemList?.[flowId!]?.["frequencyCapping"]?.threshold && (
                <div className="customNode__footer">
                  <div className="customNode__child-nodes flex--column">
                    <div className="customNode__childNodes">
                      <ChildNodes
                        key={"fallback"}
                        name={"fallback"}
                        buttonId={"fallback"}
                        index={1}
                        connectorColor={nodeData?.connectorColor}
                        nodeType={"Trigger"}
                        count={nodeData?.childNodes?.length}
                        isConnectable={true}
                      />
                    </div>
                  </div>
                </div>
              )}
          </NodeStyle>
          {isAnalytics && (
            <div>
              {AnalyticsCardRenderer(
                messageAnalytics,
                "100%",
                homeState?.analyticsData?.shimmer
              )}
            </div>
          )}
        </TriggerNodeStyle>
      </div>
    </>
  );
};

export default TriggerBlockNode;
