import {
  BodySecondary,
  Button,
  COLORS,
  Caption,
  Dropdown,
  DropdownOptions,
  Menu,
  Tabs,
} from "@bikdotai/bik-component-library";
import {
  ChipButtonProps,
  EachTailoredSectionI,
  JourneyLevels,
  LocalIndicesI,
  PostOnboardingJourney,
  PostOnboardingJourneyRawI,
  SidebarEnums,
  UiMeta,
} from "./type";
import { AllModules } from "@bikdotai/bik-models/growth/models/feature";
import { useState, useMemo, useEffect, FunctionComponent } from "react";
import { GrowthRepo } from "./GrowthRepo";
import { CustomizedSection, TabContent, TailoredTopSection } from "./style";
import {
  DropdownOption,
  SingleOption,
} from "@bikdotai/bik-component-library/dist/esm/components/dropdown/type";
import EditIcon from "../../icons/editIcon";
import CommonTable from "./common/ComonTable";
import AddOrEditFeatureModal from "./common/AddOrEditFeatureModal";
import { cloneDeep } from "lodash";
const DefaultIndices = {
  automations: {
    basic: [],
    advanced: [],
    intermediate: [],
  },
  campaigns: {
    basic: [],
    advanced: [],
    intermediate: [],
  },
  helpdesk: {
    basic: [],
    advanced: [],
    intermediate: [],
  },
  popup: {
    basic: [],
    advanced: [],
    intermediate: [],
  },
};
const TabNames = Object.values(AllModules).map((key) => ({
  key: key,
  title: key.charAt(0).toUpperCase() + key.slice(1).toLowerCase(),
}));

const TailoredColumns = [
  "Feature Title",
  "Feature Description",
  "Feature level",
  "Actions",
];
export const RecomendedColumns = [
  "Feature Title",
  "Feature Description",
  "Actions",
];
const MainTabs = [
  { key: "list", title: "Feature Lists" },
  { key: "customize", title: "Customize Features" },
];
const LevelTabs = Object.values(JourneyLevels).map((key) => ({
  key,
  title: key.charAt(0).toUpperCase() + key.slice(1).toLowerCase(),
}));
interface CustomizedJourneysProps {
  currentMenu: Menu;
}

const CustomizedJourneys: FunctionComponent<CustomizedJourneysProps> = (
  props
) => {
  const [recommendedData, setRecommendedData] = useState<UiMeta[]>([]);
  const [tailoredData, setTailoredData] = useState<PostOnboardingJourney>();
  const growthRepo = useMemo(() => new GrowthRepo(), []);
  const [mainTab, setMainTab] = useState<string>(MainTabs[0].key);
  const [currentTab, setCurrentTab] = useState<string>(TabNames[0].key);
  const [levelTab, setLevelTab] = useState<string>(LevelTabs[0].key);
  const [moduleSelected, setModuleSelected] = useState<string>(TabNames[0].key);
  const [localIndices, setLocalIndices] = useState<LocalIndicesI>();
  const [changedIndices, setChangedIndices] = useState<LocalIndicesI>();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [itemSelectedForEditing, setItemSelectedForEditing] = useState<
    EachTailoredSectionI | UiMeta
  >();
  const [indexToUpdate, setIndexToUpdate] = useState<number>(0);
  const [operationtype, setOperationType] = useState<"add" | "edit">("add");
  const [indicesLoaded, setIndicesLoaded] = useState(false);

  useEffect(() => {
    setUpJourneys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.currentMenu.key === SidebarEnums["tailored-section"]) {
      setMainTab(MainTabs[0].key);
      setLevelTab(LevelTabs[0].key);
      setModuleSelected(TabNames[0].key);
    }
  }, [props.currentMenu]);

  useEffect(() => {
    setCurrentTab(TabNames[0].key);
    setLevelTab(LevelTabs[0].key);
    setModuleSelected(TabNames[0].key);
  }, [mainTab]);

  const setUpJourneys = async () => {
    setLoading(true);
    const allData = await growthRepo.fetchRecommendedAndTailoredData();
    await setFetchTailoredAndRecommendedData(allData || []);
    setLoading(false);
  };

  const setFetchTailoredAndRecommendedData = async (
    allData: PostOnboardingJourneyRawI[]
  ) => {
    const tailored = allData!.filter((data: any) => data.id !== "also-try");
    const { journey, indices } = await sortPODocsBasedModule(tailored);
    // Set localIndices once, only during the initial load
    setTailoredData(journey);
    if (!localIndices) {
      setLocalIndices(indices); // Set only once, not every time
    }
    const clonedIndices = cloneDeep(indices);

    setChangedIndices(clonedIndices); // Set changedIndices to keep track of modifications
    /* ============ recomended is okay ============== */
    const recommended = allData!
      .filter((data: any) => data.id === "also-try")
      .flatMap((data: any) => data.features);
    setRecommendedData(recommended);
  };

  const sortPODocsBasedModule = async (
    allPODocs: PostOnboardingJourneyRawI[]
  ) => {
    const journey: PostOnboardingJourney = {} as PostOnboardingJourney;
    const indices: LocalIndicesI = {} as LocalIndicesI;
    if (!allPODocs || allPODocs.length === 0) return { journey, indices };

    allPODocs.forEach((doc) => {
      const moduleName = doc.id as AllModules;

      if (!journey[moduleName]) {
        journey[moduleName] = { features: [] };
      }
      if (!indices[moduleName]) {
        indices[moduleName] = {
          basic: [],
          intermediate: [],
          advanced: [],
        };
      }

      indices[moduleName] = doc.indicesToShow || {
        basic: [],
        intermediate: [],
        advanced: [],
      };
      journey[moduleName] = {
        features: doc.features.map((feature) => ({
          ...feature,
          isSelected: !!doc.selectedIndices?.includes(
            doc.features.indexOf(feature)
          ),
        })),
        selectedIndices: doc.selectedIndices || [],
      };
    });

    return { journey, indices };
  };

  const editIconClicked = (
    each: EachTailoredSectionI | UiMeta,
    index: number
  ) => {
    setItemSelectedForEditing(each);
    setOperationType("edit");
    setIndexToUpdate(index);
    setOpenModal(true);
  };

  const formTableData = (
    data: PostOnboardingJourney,
    moduleName: AllModules
  ) => {
    return data?.[moduleName].features.map((each, index) => ({
      title: each.uiMeta.title,
      desciption: each.uiMeta.desc,
      level:
        each.level.charAt(0).toUpperCase() + each.level.slice(1).toLowerCase(),
      icons: (
        <div onClick={() => editIconClicked(each, index)}>
          <EditIcon height={24} width={24} />
        </div>
      ),
    }));
  };

  const formRecommendedTableData = (data: UiMeta[]) => {
    return data.map((each, index) => ({
      title: each.title,
      desciption: each.desc,
      icons: (
        <div onClick={() => editIconClicked(each, index)}>
          <EditIcon height={24} width={24} />
        </div>
      ),
    }));
  };
  const [dropdownOptions, setDropdownOptions] = useState<DropdownOptions[]>([]);

  useEffect(() => {
    const options = formDropdownBasedModule();
    setDropdownOptions(options as DropdownOptions[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tailoredData, changedIndices, levelTab, moduleSelected]);

  const formDropdownBasedModule = () => {
    const currentLevel = levelTab as JourneyLevels;
    const curentModule = moduleSelected as AllModules;
    const allDataBasedModule =
      tailoredData &&
      tailoredData[curentModule] &&
      tailoredData[curentModule].features;
    if (!allDataBasedModule) return [] as DropdownOptions[];
    if (!tailoredData || !changedIndices) return [];
    const indicesToShow = changedIndices![curentModule]![currentLevel] || [];
    const tailoredDataBasedOnLevel = allDataBasedModule.filter(
      (each) => each.level === currentLevel
    );

    return tailoredDataBasedOnLevel.map((each) => {
      const originalIndex = allDataBasedModule.indexOf(each);
      return {
        label: each.uiMeta.title,
        value: originalIndex.toString(),
        selected: indicesToShow.includes(originalIndex),
      };
    });
  };

  const handleDropdownSelect = (option: DropdownOption | DropdownOption[]) => {
    const originalIndex = parseInt((option as SingleOption).value, 10);
    const currentLevel = levelTab as JourneyLevels;
    const currentModule = moduleSelected as AllModules;

    setChangedIndices((prevIndices) => {
      const updatedIndices: LocalIndicesI = {
        ...DefaultIndices,
        ...prevIndices,
      };

      const indicesToShow = updatedIndices[currentModule]?.[currentLevel] || [];

      if (indicesToShow.length >= 4 && !indicesToShow.includes(originalIndex)) {
        alert("Maximum of 4 selections allowed.");
        return prevIndices;
      }

      if (!indicesToShow.includes(originalIndex)) {
        updatedIndices[currentModule][currentLevel] = [
          ...indicesToShow,
          originalIndex,
        ];
      } else {
        updatedIndices[currentModule][currentLevel] = indicesToShow.filter(
          (idx) => idx !== originalIndex
        );
      }

      return updatedIndices;
    });
  };

  const HandleSubmitIndices = async () => {
    setIndicesLoaded(true);
    await growthRepo.updateSelectedIndices(
      moduleSelected,
      changedIndices?.[moduleSelected as AllModules]?.[
        levelTab as JourneyLevels
      ] || [],
      levelTab as JourneyLevels
    );
    setIndicesLoaded(false);
    window.location.reload();
  };
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {props.currentMenu.key === SidebarEnums["tailored-section"] ? (
        <>
          <div id={SidebarEnums["tailored-section"]}>
            <TailoredTopSection>
              <Tabs
                tabs={MainTabs}
                onTabSelected={(tabKey) => {
                  setMainTab(tabKey);
                  if (tabKey === "list") {
                    setChangedIndices(cloneDeep(localIndices));
                  }
                }}
                isSquared={true}
                containerStyles={{
                  width: "fit-content",
                  background: COLORS.surface.subdued,
                }}
              ></Tabs>
            </TailoredTopSection>
            {mainTab === "list" ? (
              <>
                <Tabs
                  tabs={TabNames}
                  onTabSelected={(tabKey) => {
                    setCurrentTab(tabKey);
                  }}
                ></Tabs>
                <Button
                  style={{ margin: "16px 24px 0px 24px" }}
                  buttonText="Add a feature"
                  onClick={() => {
                    setOperationType("add");
                    setOpenModal(true);
                  }}
                ></Button>
                <TabContent>
                  {tailoredData &&
                  tailoredData[currentTab as AllModules] &&
                  tailoredData[currentTab as AllModules].features ? (
                    <>
                      <CommonTable
                        tableHeadData={TailoredColumns}
                        tableData={formTableData(
                          tailoredData,
                          currentTab as AllModules
                        )}
                        appplyAnalyticsStyle={true}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </TabContent>
              </>
            ) : (
              <CustomizedSection>
                <Tabs
                  tabs={LevelTabs}
                  onTabSelected={(tabKey) => {
                    setLevelTab(tabKey);
                    setChangedIndices(cloneDeep(localIndices));
                  }}
                  containerStyles={{
                    width: "100%",
                  }}
                ></Tabs>
                <div className="all-journey-wrapper">
                  <div className="chip--wrapper">
                    {TabNames.map((module) => (
                      <ChipButton
                        onClick={() => {
                          setModuleSelected(module.key);
                        }}
                        buttonText={module.title}
                        isSelected={moduleSelected === module.key}
                        key={"chip__" + module.key}
                      />
                    ))}
                  </div>
                  <div style={{ width: "40%" }}>
                    {loading ? (
                      <></>
                    ) : (
                      <>
                        <Dropdown
                          options={dropdownOptions}
                          isSearchable
                          onSelect={(option) => handleDropdownSelect(option)}
                        />
                        <Button
                          buttonText="Submit"
                          style={{ marginTop: 10 }}
                          onClick={HandleSubmitIndices}
                          isLoading={indicesLoaded}
                        />
                      </>
                    )}
                  </div>
                  <div className="feature--wrapper">
                    {changedIndices &&
                    changedIndices![moduleSelected as AllModules] ? (
                      changedIndices![moduleSelected as AllModules]![
                        levelTab as JourneyLevels
                      ]?.map((index) => (
                        <div className="selected--feature--container">
                          <Caption className="feature--text">
                            {" "}
                            {
                              tailoredData![moduleSelected as AllModules]
                                .features[index].uiMeta.title
                            }
                          </Caption>
                        </div>
                      ))
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </CustomizedSection>
            )}
          </div>
        </>
      ) : (
        <></>
      )}

      {props.currentMenu.key === SidebarEnums["recommended-section"] ? (
        <>
          <div id={SidebarEnums["recommended-section"]}>
            <>
              <Button
                style={{ margin: "16px 24px 0px 24px" }}
                buttonText="Add a feature"
                onClick={() => {
                  setOperationType("add");
                  setOpenModal(true);
                }}
              ></Button>
              <TabContent>
                {recommendedData ? (
                  <>
                    <CommonTable
                      tableHeadData={RecomendedColumns}
                      tableData={formRecommendedTableData(recommendedData)}
                      appplyAnalyticsStyle={true}
                    />
                  </>
                ) : (
                  <></>
                )}
              </TabContent>
            </>
          </div>
        </>
      ) : (
        <></>
      )}
      {openModal && (
        <AddOrEditFeatureModal
          isOpen={openModal}
          onCloseModal={(reload) => {
            if (!!reload) {
              setUpJourneys();
            }
            setOpenModal(false);
          }}
          curentMenu={String(props.currentMenu.key)}
          operationtype={operationtype}
          moduleMenu={currentTab}
          menuItemToEdit={
            operationtype === "edit" ? itemSelectedForEditing : undefined
          }
          indexToUpdate={indexToUpdate}
        />
      )}
    </>
  );
};
export default CustomizedJourneys;

export const ChipButton: React.FC<ChipButtonProps> = ({
  onClick,
  buttonText,
  isSelected,
}) => {
  return (
    <button
      onClick={onClick}
      style={{
        padding: "8px 16px",
        border: `1px solid ${COLORS.stroke.primary}`,
        borderRadius: "8px",
        ...(isSelected
          ? {
              borderColor: COLORS.stroke.brand,
              background: COLORS.background.brandLight,
              color: COLORS.content.brand,
            }
          : {
              borderColor: COLORS.stroke.primary,
              background: COLORS.surface.subdued,
            }),
      }}
    >
      <BodySecondary color={isSelected ? COLORS.content.brand : ""}>
        {buttonText}
      </BodySecondary>
    </button>
  );
};
