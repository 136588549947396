import { Icon } from "./icons-props";

const MultiProductIcon: Icon = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      width={width || "22"}
      height={height || "24"}
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1426 19.4271V14.8556C12.1424 14.6552 12.0895 14.4584 11.9892 14.2849C11.8889 14.1113 11.7448 13.9673 11.5712 13.8671L7.57122 11.5813C7.39748 11.481 7.2004 11.4282 6.99979 11.4282C6.79918 11.4282 6.6021 11.481 6.42836 11.5813L2.42836 13.8671C2.2548 13.9673 2.11064 14.1113 2.01034 14.2849C1.91004 14.4584 1.85714 14.6552 1.85693 14.8556V19.4271C1.85714 19.6275 1.91004 19.8243 2.01034 19.9978C2.11064 20.1713 2.2548 20.3154 2.42836 20.4156L6.42836 22.7013C6.6021 22.8016 6.79918 22.8545 6.99979 22.8545C7.2004 22.8545 7.39748 22.8016 7.57122 22.7013L11.5712 20.4156C11.7448 20.3154 11.8889 20.1713 11.9892 19.9978C12.0895 19.8243 12.1424 19.6275 12.1426 19.4271Z"
        stroke={color || "#212121"}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.01123 14.2617L6.9998 17.1474L11.9884 14.2617"
        stroke={color || "#212121"}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7 22.9016V17.1416"
        stroke={color || "#212121"}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.85693 12.8555V9.71256C9.85714 9.51214 9.91004 9.3153 10.0103 9.14179C10.1106 8.96828 10.2548 8.82419 10.4284 8.72398L14.4284 6.43827C14.6021 6.33796 14.7992 6.28516 14.9998 6.28516C15.2004 6.28516 15.3975 6.33796 15.5712 6.43827L19.5712 8.72398C19.7448 8.82419 19.8889 8.96828 19.9892 9.14179C20.0895 9.3153 20.1424 9.51214 20.1426 9.71256V14.284C20.1424 14.4844 20.0895 14.6812 19.9892 14.8547C19.8889 15.0283 19.7448 15.1723 19.5712 15.2726L15.5712 17.5583C15.3975 17.6586 15.2004 17.7114 14.9998 17.7114C14.7992 17.7114 14.6021 17.6586 14.4284 17.5583L12.1426 16.284"
        stroke={color || "#212121"}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.0117 9.11816L15.0003 12.0039L19.9889 9.11816"
        stroke={color || "#212121"}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15 17.7133V11.999"
        stroke={color || "#212121"}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.1426 7.71289V4.56998C12.1424 4.36956 12.0895 4.17273 11.9892 3.99921C11.8889 3.8257 11.7448 3.68161 11.5712 3.58141L7.57122 1.29569C7.39748 1.19539 7.2004 1.14258 6.99979 1.14258C6.79918 1.14258 6.6021 1.19539 6.42836 1.29569L2.42836 3.58141C2.2548 3.68161 2.11064 3.8257 2.01034 3.99921C1.91004 4.17273 1.85714 4.36956 1.85693 4.56998V9.14141C1.85714 9.34182 1.91004 9.53866 2.01034 9.71217C2.11064 9.88568 2.2548 10.0298 2.42836 10.13C2.71408 10.2949 5.57122 11.9986 5.57122 11.9986"
        stroke={color || "#212121"}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.01123 3.97559L6.9998 6.8613L11.9884 3.97559"
        stroke={color || "#212121"}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7 11.4269V6.85547"
        stroke={color || "#212121"}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default MultiProductIcon;
