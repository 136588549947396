/**
 * Returns
 * startDate - Today - 6 days (Last 7th day) 0h0m0s
 * endDate - Today 23:59:59
 */
export const getLast7DaysStartAndEndDate = () => {
  const today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  const last7Days = new Date(today.getTime());
  last7Days.setDate(last7Days.getDate() - 6);
  const startDate = last7Days.getTime();
  const endDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
    23,
    59,
    59
  ).getTime();
  return { startDate, endDate };
};
