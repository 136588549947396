import {
  HTTPMethods,
  RequestExecutor,
} from "../../api-helpers/RequestExecutor";
import { getCampaignEndPoints } from "../../config";

export class CampaignService {
  async fetchOnboardingDetails(storeId: string) {
    const path = `${getCampaignEndPoints()}/eventsApiFunctions-fetchOnboardingDetails`;
    const res = (await RequestExecutor.fetch({
      apiPath: path,
      apiMethod: HTTPMethods.POST,
      requestBody: {
        storeId: storeId,
      },
    })) as any;
    console.log(res.data);
    return res.data;
  }

  async getCustomerDetails(customerId: string, storeId: string) {
    const path = `${getCampaignEndPoints()}/postgresCustomerApiFunctions-fetchCustomerDetails`;
    const res = (await RequestExecutor.fetch({
      apiPath: path,
      apiMethod: HTTPMethods.POST,
      requestBody: {
        customerId: customerId,
        storeId: storeId,
      },
    })) as any;
    console.log(res.data);
    const basicData = res.data.data[0].basic;
    return {
      firstName: basicData.firstName,
      lastName: basicData.lastName,
      phoneNumber: basicData.phoneNumber,
      email: basicData.email,
    };
  }
}
