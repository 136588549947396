import { COLORS } from "@bikdotai/bik-component-library";
import styled from "styled-components";

export const Header = styled.div`
  width: 100%;
  height: 64px;
  padding: 20px 24px 20px 24px;
  border-bottom: 1px solid ${COLORS.stroke.primary};
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
`;

export const AdDropdown = styled.div<{ isIcebreaker?: boolean }>`
  margin-bottom: ${(props) => (props.isIcebreaker ? "0px" : "24px")};
  width: ${(props) => (props.isIcebreaker ? "392px" : "328px")};
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Container = styled.div`
  border-radius: 8px;
  background: ${COLORS.surface.standard};
  height: 560px;
  display: flex;
  flex-direction: column;
`;

export const Footer = styled.div`
  width: 100%;
  height: 64px;
  padding: 20px 24px 20px 24px;
  border-top: 1px solid ${COLORS.stroke.primary};
  margin-top: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Body = styled.div`
  width: 100%;
  height: 432px;
  padding: 28px 24px 28px 24px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0 !important;
  }
`;

export const CommentAction = styled.div`
  border-radius: 8px;
  border: 1px solid ${COLORS.content.inactive};
  width: 100%;
`;

export const BtnGrpSelector = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const ExcludeStrip = styled.div`
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 12px 16px 12px 16px;
  background-color: ${COLORS.surface.subdued};
  border-top: 1px solid ${COLORS.stroke.primary};
`;

export const AddButton = styled.div<{ err?: boolean }>`
  display: flex;
  width: 100px;
  height: 100px;
  padding: 4px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 4px;
  cursor: pointer;
  border: ${(props) =>
    !props.err
      ? `1px dashed ${COLORS.content.inactive}`
      : `1px dashed ${COLORS.content.negative}`};
`;

export const ChipStyle = styled.div`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 6px;
  border-radius: 4px;
  border: 1px solid ${COLORS.stroke.primary};
  background: ${COLORS.surface.subdued};
  width: fit-content;
  justify-content: center;
`;

export const SideBarChipStyle = styled.div<{ isActive: boolean }>`
  display: flex;
  padding: 12px 16px;
  gap: 12px;
  background: ${(props) =>
    props.isActive ? COLORS.background.brandLight : COLORS.surface.subdued};
  width: 100%;
  align-items: center;

  &:hover {
    background: ${(props) =>
      props.isActive ? COLORS.background.brandLight : COLORS.surface.hovered};
    cursor: pointer;
  }
`;

export const ActionEventsBlockStyle = styled.div<{
  isActive: boolean;
  disabled: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 16px;
  border-radius: 8px;
  width: 224px;
  height: 224px;
  border: 1px solid ${COLORS.stroke.primary};

  .icon-holder {
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: ${(props) =>
      props.disabled ? COLORS.content.inactive : COLORS.content.brand};
    box-shadow: ${(props) =>
      props.disabled ? "none" : "0px 4px 16px 0px rgba(115, 29, 207, 0.25)"};
  }

  &:hover {
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
    cursor: pointer;
  }
`;

export const VerticalSeparator = styled.div`
  width: 1px;
  height: 24px;
  background: ${COLORS.content.inactive};
  margin-left: 15px;
`;

export const AddCommentReply = styled.div`
  width: 210px;
  display: inline-flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px dashed ${COLORS.content.inactive};
`;

export const CommentReplySection = styled.div`
  border-radius: 5px;
  border: 1px solid ${COLORS.content.inactive};
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ReplyAddStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ModalTitle = styled.div`
  margin-bottom: 8px;
`;

export const ActionStrip = styled.div`
  padding: 22px 16px 22px 16px;
`;

export const ImageBar = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: 18px;
  flex-wrap: wrap;
  align-items: center;
`;

export const CheckBoxBar = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const KeywordContainer = styled.div`
  padding: 22px 16px 22px 16px;
`;

export const ReplyInputSet = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`;

export const VerticalSeparator2 = styled.div`
  width: 1px;
  height: 144px;
  background: #d9d9d9;
`;

export const ReplySecionDelete = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

export const HeartReaction = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: 16px;
  align-items: center;
`;

export const InlineText = styled.div`
  display: inline-flex;
  gap: 4px;
`;

export const ErrorIndicatorStyle = styled.div`
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background-color: #ffebef;
`;
