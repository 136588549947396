import { Icon } from "./icons-props";

const OrderStatusIcon: Icon = (props) => {
  const { width, height, color = "#00580E" } = props;
  return (
    <svg
      width={width || "32"}
      height={height || "32"}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="4" fill="#E6F4E7" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.5484 5.55951C15.44 5.06417 16.5186 5.04073 17.4309 5.49687L19.4874 6.5251C20.0258 6.79432 20.2441 7.44905 19.9748 7.98749C19.7056 8.52593 19.0509 8.74417 18.5124 8.47495L16.456 7.44673C16.1873 7.31239 15.8697 7.31929 15.6071 7.46517L9.24437 11L16.2823 14.91H16.4999C17.1019 14.91 17.5899 15.398 17.5899 16C17.5899 16.3846 17.3907 16.7226 17.0899 16.9167V24.1363L22.446 21.1235C22.7326 20.9623 22.9099 20.6591 22.9099 20.3303V19.5C22.9099 18.898 23.3979 18.41 23.9999 18.41C24.6019 18.41 25.0899 18.898 25.0899 19.5V20.3303C25.0899 21.4467 24.4878 22.4762 23.5148 23.0235L17.5088 26.4019C16.5732 26.9282 15.4315 26.9312 14.4932 26.4099L7.49928 22.5244C6.51831 21.9794 5.90991 20.9454 5.90991 19.8232V12.1768C5.90991 11.0546 6.51831 10.0207 7.49928 9.47569L14.5484 5.55951ZM8.08991 19.8232V12.8525L14.9099 16.6414V24.1476L8.55798 20.6187C8.26908 20.4582 8.08991 20.1537 8.08991 19.8232Z"
        fill={color}
      />
      <path
        d="M21.7143 6H24.5C25.3284 6 26 6.67157 26 7.5V9.64286C26 10.4713 25.3284 11.1429 24.5 11.1429H22.5C21.6716 11.1429 21 11.8144 21 12.6429V15"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M19 14L20.6464 15.6464C20.8417 15.8417 21.1583 15.8417 21.3536 15.6464L23 14"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
};
export default OrderStatusIcon;
