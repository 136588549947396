import React, {
  ChangeEvent,
  FocusEventHandler,
  Fragment,
  FunctionComponent,
  useRef,
} from "react";
import { ErrorMain, InputMain, LabelStyle } from "./BotInput.style";
import FileInput from "./inputTypes/FileInput";
import DateInput from "./inputTypes/DateInput";
import TextInput from "./inputTypes/TextInput";
import { InputDataProps } from "./InputModel";

type Props = InputDataProps;

const BotInput: FunctionComponent<Props> = (props) => {
  const inputEl = useRef<HTMLInputElement>(null);
  const onChangeHandle = ($event: ChangeEvent<HTMLInputElement>) => {
    $event.stopPropagation();
    props.onChange?.($event);
  };

  const onBlurHandle = ($event: React.FocusEvent<HTMLInputElement>) => {
    props.onBlur?.($event);
  };

  const onSVGClick = () => {
    inputEl.current?.click();
  };

  const onClickHandle = () => {
    props?.onClickHandle?.();
  };

  return (
    <Fragment>
      {(props?.label || props?.name) && (
        <LabelStyle>
          <div className="label" onClick={onClickHandle}>
            {(!!props.label && props.label) || (!!props.name && props.name)}
          </div>
        </LabelStyle>
      )}
      <InputMain {...props}>
        {props.type === "file" && (
          <FileInput
            inputEl={inputEl}
            onChangeHandle={onChangeHandle}
            onSVGClick={onSVGClick}
            onBlur={onBlurHandle}
            {...props}
          />
        )}

        {props.type === "date" && (
          <DateInput
            inputEl={inputEl}
            onChange={props.onChange}
            {...props}
            onBlur={onBlurHandle}
          />
        )}

        {(props.type === "text" ||
          props.type === "number" ||
          props.type === "password" ||
          props.type === "tel") && (
          <Fragment>
            <TextInput
              inputEl={inputEl}
              onKeyDown={props.onKeyDown}
              onChangeHandle={onChangeHandle}
              onBlur={onBlurHandle}
              {...props}
              iconLeft={props.iconLeft}
              iconRight={props.iconRight}
            />
            {(props.iconLeft || props.iconRight) && props.children}
          </Fragment>
        )}
      </InputMain>
      {props.subText && <ErrorMain {...props}>{props.subText}</ErrorMain>}
    </Fragment>
  );
};
export default BotInput;

/*
    Examople of sample input
    <BotInput
        name="listInput"
        id={"listInput"}
        height={'24px'}
        label={"Button text"}
        type='text'
        value={text}
        iconRight={true}
        iconLeft={true}
        onChange={(e)=>{setText(e.target.value)}}
        wordCount={20}
        subText={`${text.length}/20`}
        placeHolder={'Enter your text here'}
    >
        <div className="iconLeft" onClick={() => {}}><CrossIcon/></div>
        <div className="iconRight" onClick={() => {}}><CrossIcon/></div>
    </BotInput>
 */
