import { Icon } from "./icons-props";

const DocumentIcon: Icon = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 21 20"
      fill={color || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 2.49992C4.53976 2.49992 4.16667 2.87301 4.16667 3.33325V16.6666C4.16667 17.1268 4.53976 17.4999 5 17.4999H15C15.4602 17.4999 15.8333 17.1268 15.8333 16.6666V8.33325H12.5C11.1193 8.33325 10 7.21396 10 5.83325V2.49992H5ZM11.6667 3.67843L14.6548 6.66658H12.5C12.0398 6.66658 11.6667 6.29349 11.6667 5.83325V3.67843ZM2.5 3.33325C2.5 1.95254 3.61929 0.833252 5 0.833252H11.1785L17.5 7.15474V16.6666C17.5 18.0473 16.3807 19.1666 15 19.1666H5C3.61929 19.1666 2.5 18.0473 2.5 16.6666V3.33325Z"
      />
    </svg>
  );
};

export default DocumentIcon;
