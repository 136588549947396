import { memo } from "react";
import { useAppSelector } from "../../../state/store";
import { CustomNodeProps } from "../../../utilities/flowBuilder.utility";
import { StickyNotesNodeStyle } from "./style";
import "react-quill/dist/quill.snow.css";
import { useDetectAnalytics } from "../useDetectAnalytics";

const StickyNotesNode = (props: CustomNodeProps) => {
  const { nodeData, editNode } = props;
  const notes = useAppSelector((state) => state.stickyNotesState.notes);
  const isAnalytics = useDetectAnalytics();

  return (
    <StickyNotesNodeStyle
      onClick={
        isAnalytics
          ? () => {}
          : () => {
              editNode(
                nodeData?.type,
                props?.id,
                nodeData?.subType,
                nodeData?.nodeIndex,
                nodeData?.automationChannel
              );
            }
      }
    >
      <div className="customNode flex--column">
        <div className="customNode__body flex ql-container">
          {!notes?.[props?.id]?.content && (
            <div className="customNode__body__no-content">
              Click to add notes
            </div>
          )}
          {notes?.[props?.id]?.content && (
            <div
              className="customNode__body__with-content ql-editor"
              dangerouslySetInnerHTML={{
                __html: notes?.[props?.id]?.content || "",
              }}
            />
          )}
        </div>
        {notes?.[props?.id]?.updatedDate && (
          <div className="customNode__footer flex">
            <span>{notes?.[props?.id]?.updatedDate}</span>
          </div>
        )}
      </div>
    </StickyNotesNodeStyle>
  );
};

export default memo(StickyNotesNode);
