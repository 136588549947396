import styled from "styled-components";
import { COLORS } from "@bikdotai/bik-component-library";
import { GreyCircleProps } from ".";

const StyledCircle = styled.div<GreyCircleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : COLORS.background.base};
  border: 1px solid
    ${(props) =>
      props.borderColor ? props.borderColor : COLORS.stroke.primary};
  border-radius: ${(props) => props.size / 2}px;
`;

export default StyledCircle;
