export enum Actions {
  ALL = "all",
  DIRECTMESSAGE = "directMessage",
  COMMENTS = "comments",
  STORIES = "stories",
  ADS = "ads",
  EVENTS = "events",
  SPECIALDATES = "specialDates",
}

export enum WhatsAppActions {
  ALL = "all",
  WHATSAPPMESSAGE = "whatsAppMessage",
  USEREVENT = "userEvent",
  PRODUCTALERTS = "productAlerts",
  ANNIVERSARYDATES = "anniversaryDates",
  INTEGRATIONS = "integrations",
}

export enum ActionEventsList {
  DIRECTMESSAGE = "directMessage",
  DIRECTMESSAGEADS = "directMessageOnAds",
  DIRECTMESSAGEPOSTS = "directMessageOnPosts",
  DIRECTMESSAGEREELS = "directMessageOnReels",
  COMMENTSONPOST = "commentsOnPost",
  COMMENTSONREEL = "commentsOnReel",
  COMMENTSONAD = "commentsOnAd",
  DMONAD = "dmOnAd",
  REACTIONS = "reactions",
  MENTIONS = "mentions",
  REPLIES = "replies",
  EVENTS_ENTER_SEGMENT = "events_enter_segment",
  EVENTS_EXIT_SEGMENT = "events_exit_segment",
  EVENTS_PAYLINK_UPDATE = "events_payment_link_update",
  EVENTS_CHAT_CLOSE = "events_chat_session_closed",
  KEYWORDS = "keywords",
  LAST_ORDER_DATE = "last_order_date",
  FIRST_ORDER_DATE = "first_order_date",
}

export enum WhatsappActionEventsList {
  WHATSAPPMEASSAGE = "whatsAppMessage",
  CLICKONWHATSAPPADS = "clickOnWhatsappAds",
  ECOMMERCE = "eEcommerce",
  ACQUISITIONS = "acquisitions",
  HELPDESK = "helpdesk",
  POSTPURCHASE = "postPurchase",
  INTEGRATIONS = "integrations",
  BACKINSTOCK = "backInStock",
  PRICEDROP = "priceDrop",
  FIRSTORDERDATE = "firstOrderDate",
  LASTORDERDATE = "lastOrderDate",
  LIFETIMEORDERVALUE = "lifetimeOrderValue",
  WEBHOOKS = "webhooks",
  EVENTSEDIT = "eventsEdit",
}
