import { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import BikayiButton from "../../ui-components/button";
import BotInput from "../../ui-components/input";

interface Props {
  mandatoryKeys: string[];
  optionalKeys: string[];
  onSubmit(storeId: string, values: { [key: string]: string }): void;
}

const AdminSearchStyles = styled.div`
  padding: 16px;
  border: 1px solid #b3b2b2;
  margin: 8px;
  border-radius: 8px;
`;

const AdminSearchBar: FunctionComponent<Props> = (props) => {
  const [values, setValues] = useState<{ [key: string]: string }>({});
  const [error, setError] = useState<boolean>(false);
  const [allInputs, setAllInputs] = useState<string[]>([]);

  useEffect(() => {
    setAllInputs(props.mandatoryKeys.concat(props.optionalKeys));
  }, [props.mandatoryKeys, props.optionalKeys]);

  const onSubmit = () => {
    const hasUndefinedValue = props.mandatoryKeys.find((key) => {
      return !values[key];
    });
    if (hasUndefinedValue) {
      setError(true);
      return;
    }
    setError(false);
    props.onSubmit(values.storeId, values);
  };

  return (
    <AdminSearchStyles style={{ padding: 16, border: "1px solid dark" }}>
      <div className="title-regular">Search</div>

      <div
        style={{
          display: "flex",
          columnGap: 20,
          marginTop: 16,
          flexWrap: "wrap",
          rowGap: 20,
        }}
      >
        {allInputs.map((key) => {
          return (
            <div style={{ width: 300 }}>
              <BotInput
                name={"Enter " + key}
                id={key}
                height={"16px"}
                width={"200px"}
                value={values[key]}
                type={"text"}
                onChange={(event) => {
                  setValues({ ...values, [key]: event.target.value });
                }}
              ></BotInput>
            </div>
          );
        })}
      </div>

      {
        <BikayiButton
          style={{ marginTop: "16px", marginLeft: 0 }}
          buttonName={"Submit"}
          buttonStyle="primary"
          buttonWidth="75px"
          buttonPadding={"6px 12px"}
          buttonHeight="32px"
          click={() => {
            onSubmit();
          }}
        />
      }

      {error && (
        <div style={{ color: "red" }}>Please fill all the mandatory fields</div>
      )}
    </AdminSearchStyles>
  );
};

export default AdminSearchBar;
