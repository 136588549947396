export enum AmplitudeKeys {
  added_product = "added_product",
  added_catalog = "added_catalog",
  updated_theme_settings = "updated_theme_settings",
  updated_order_status = "updated_order_status",
  expired = "expired",
  plan_purchased_frontend = "plan_purchased_frontend",
  get_otp_clicked = "get_otp_clicked",
  logged_in_clicked = "logged_in_clicked",
  login_page_opened = "login_page_opened",
  user_logged_in_successfully = "user_logged_in_successfully",
  book_a_demo_clicked = "book_a_demo_clicked",
  calendly_modal_on_onboarding = "calendly_modal_on_onboarding",
  book_a_slot_clicked = "book_a_slot_clicked",
  service_used = "service_used",
}

export enum AmplitudeEventStep {
  template_creation = "template_creation",
  template_filter = "template_filter",
  template = "template",
  saved_success = "saved_success",
  saved_failed = "saved_failed",
  node_analytics_downloaded = "node_analytics_downloaded",
  error_report_downloaded = "error_report_downloaded",
  overall_download = "overall_download",
  user_report_downloaded = "user_report_downloaded",
  filter_changed = "filter_changed",
  variable_used = "variable_used",
  block_used = "block_used",
  trigger_added = "trigger_added",
}

export enum AmplitudeEventSubStep {}

export enum RecordableEvents {
  start_trial = "start_trial",
  premium_open = "premium_open",
  login_start = "login_start",
  login_method = "login_method",
  otp_success = "otp_success",
  login_success = "login_success",
  login_fail = "login_fail",
  registration_start = "registration_start",
  registration_success = "registration_success",
  service_used = "service_used",
  service_open = "service_open",
  share = "share",
  customer_support = "customer_support",
  free_trial_entrypoint = "free_trial_entrypoint",
  free_trial_buy_VIP_button = "free_trial_buy_VIP_button",
  start_free_trial = "start_free_trial",
  service_education_click = "service_education_click",
  premium_nudge = "premium_nudge",
  premium_nudge_click = "premium_nudge",
  premium_attempt = "premium_attempt",
  premium_conversion = "premium_conversion",
  dynamic_link_clicked = "dynamic_link_clicked",
  reports_viewed = "reports viewed",
  manage_refund_clicked = "manage refund clicked",
  refund_via_gateway_clicked = "refund via gateway clicked",
  refund_via_gateway_success = "refund via gateway - success",
  mark_as_refunded_clicked = "mark as refunded clicked",
  order_cancelled = "order cancelled",
  deny_request_clicked = "deny request clicked",
  deny_anyway_clicked = "deny anyway clicked",
  referral_entry_point = "referral_entry_point",
  referral_link_shared = "referral_link_shared",
  return_request_denied = "return request denied",
  inventory_restocked = "inventory restocked",
}

export enum OrderEvents {
  order_filter_applied = "order filter applied",
  order_searched = "order searched",
  order_filter_clicked = "order filter clicked",
  order_filter_cancelled = "order filter cancelled",
  order_viewed = "order viewed",
  alternate_phone_added = "alternate phone added",
  order_details_shared = "order details shared",
  order_status_updated = "order status updated",
  invoice_viewed = "invoice viewed",
  delivery_address_copied = "delivery address copied",
  delivery_address_edited = "delivery address edited",
  tracking_details_added = "tracking details added",
  delivery_partner_selected = "delivery partner selected",
  self_reference_note_added = "self reference note added",
  order_edited = "order edited",
}

export interface RoleData {
  vendorId?: string;
  is_staff?: boolean;
  is_spoofed_user?: boolean;
  staff_permission_level?: string[];
  sub_vendor_permission_level?: string[];
}

export enum BikEvents {
  onboarding_visit = "onboarding visit",
  successful_login = "successful login",
  successful_signup = "bik signup",
  fresh_number = "fresh number",
  integrate_store_click = "integrate store clicked",
  shopify_cta_click = "shopify cta click",
  page_visit = "page visit",
  section_interaction = "section interaction",
  button_click = "button click",
  bik_chat_customer_search = "bik_chat_customer_search",
  bik_store_customer_search = "bik_store_customer_search",
  bik_store_product_search = "bik_store_product_search",
  bik_canned_response_clicked = "bik_canned_response_clicked",
  bik_labels_used_to_close_chat = "bik_labels_used_to_close_chat",
  bik_product_share_click = "bik_product_share_click",
  bik_product_shared = "bik_product_shared",
  bik_chats_reassign_clicked = "bik_chats_reassign_clicked",
  bik_bot_chats_reassign_clicked = "bik_bot_chats _reassign_clicked",
  bik_setting_assignment_tab_clicked = "bik_setting_assignment_tab_clicked",
  bik_setting_staff_management_tab_clicked = "bik_setting_staff_management_tab_clicked",
  bik_profile_availability_change = "bik_profile_availability_change",
  bik_profile_my_conversations_clicked = "bik_profile_my_conversations_clicked",
  bik_edit_profile_clicked = "bik_edit_profile_clicked",
  bik_whatsapp_connect_requested = "bik_whatsapp_connect_requested",
  bik_chat_ai_response_generated = "bik_chat_ai_response_generated",
  bik_chat_ai_feedback_clicked = "bik_chat_ai_feedback_clicked",
  bik_chat_ai_translator_used = "bik_chat_ai_translator_used",
}

export enum TargetPlatform {
  Amplitude = "Amplitude",
  GoogleAnalytics = "GoogleAnalytics",
}
