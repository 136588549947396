import styled from "styled-components";
import { PopupProps } from "./index";

const PopupStyle = styled.div<PopupProps>`
  .popup {
    &__container {
      position: absolute;
      top: ${(props) => props.topPosition ?? 0};
      right: ${(props) => props.rightPosition ?? 0};
      background: #fff;
      border: 1px solid #d6dbe2;
      border-radius: 4px;
      width: 144px;
      z-index: 99;
    }

    &__item {
      padding: 14px 16px;
      cursor: pointer;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #212121;
      border-bottom: 1px solid #e0e0e0;
    }
  }
`;

export default PopupStyle;
