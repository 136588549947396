import * as Sentry from "@sentry/react";

const errorMessagesToExclude = [
  "auth/network-request-failed",
  "client is offline",
  "Missing or insufficient permissions",
];

export const captureErrorToSentry = (error: any, message?: string) => {
  const toExclude = errorMessagesToExclude.some((messageToExclude) => {
    if (typeof error === "string") {
      return error.includes(messageToExclude);
    }
    if (typeof error?.message == "string") {
      return error.message.includes(messageToExclude);
    }
    return false;
  });
  if (toExclude) {
    return;
  }
  Sentry.captureException(new Error(error), {
    extra: {
      message: message,
    },
  });
};
