import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DebugRunState, LocalVariablesState } from "./storeDataStateType";
import {
  RunModel,
  DebugRuns,
  DebugNodeAnalytics,
  DebugRunSetup,
} from "../components/analytics/runs/RunsModel";

const initialState: DebugRunState = {};

const debugRunDataSlice = createSlice({
  name: "debugRunState",
  initialState: initialState,
  reducers: {
    addRuns: (state, action: PayloadAction<DebugRunState>) => {
      state.runs = action.payload.runs;
      state.storeId = action.payload.storeId;
      state.phoneNumber = action.payload.phoneNumber;
      return state;
    },
    addRun: (state, action: PayloadAction<RunModel>) => {
      state.currentRun = action.payload;
      return state;
    },
    addDebugAnalytics: (state, action: PayloadAction<DebugNodeAnalytics>) => {
      state.analytics = action.payload;
      return state;
    },
    addDebugRunState: (
      state,
      action: PayloadAction<DebugRunSetup | undefined>
    ) => {
      console.log("addDebugRunState state ", action.payload);
      state.debugRunStep = action.payload;
      return state;
    },
  },
});

export const debugRunDataAction = debugRunDataSlice.actions;
export default debugRunDataSlice;
