import React, { FunctionComponent } from "react";
import CustomerSearch from "./CustomerSearch";
import LogSearch from "../integrations/LogSearch";

interface OwnProps {}

type Props = OwnProps;

const CampaignDashboard: FunctionComponent<Props> = (props) => {
  return (
    <div style={{ padding: 16 }}>
      <h2>OG POD Dashboard</h2>
      <CustomerSearch />
    </div>
  );
};

export default CampaignDashboard;
