import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  apiData: {},
  sampleData: {},
  externalApiData: {},
  discountData: {},
};

const apiDataStateSlice = createSlice({
  name: "ApiDataManager",
  initialState,
  reducers: {
    addApiData: (state: any, action: PayloadAction<any>) => {
      const { data } = action.payload;
      if (data && Object.keys(data).length) {
        state.apiData = {
          ...state.apiData,
          ...data,
        };
      }
      return state;
    },
    addCustomApiData: (state: any, action: PayloadAction<any>) => {
      const { data, nodeId } = action.payload;
      if (data && Object.keys(data).length) {
        state.apiData = {
          ...state.apiData,
          [nodeId]: data,
        };
      } else {
        delete state.apiData[nodeId];
        state.apiData = {
          ...state.apiData,
        };
      }
      return state;
    },
    addDiscountData: (state: any, action: PayloadAction<any>) => {
      const { data, nodeId } = action.payload;
      if (data && Object.keys(data).length) {
        state.discountData = {
          ...state.discountData,
          [nodeId]: data,
        };
      } else {
        delete state.discountData[nodeId];
        state.discountData = {
          ...state.discountData,
        };
      }
      return state;
    },
    setDiscountData: (state: any, action: PayloadAction<any>) => {
      const { data } = action.payload;
      state.discountData = data;
      return state;
    },
    setCustomApiData: (state: any, action: PayloadAction<any>) => {
      const { data } = action.payload;
      state.apiData = data;
      return state;
    },
    addSampleData: (state: any, action: PayloadAction<any>) => {
      const { data, apiId } = action.payload;
      if (Object.keys(data).length && apiId) {
        state.sampleData[apiId] = data;
      }
      return state;
    },
    addExternalApiData: (state: any, action: PayloadAction<any>) => {
      const { keys, nodeId } = action.payload;
      state.externalApiData = {};
      keys.forEach((key: string) => {
        state.externalApiData[key] = nodeId ?? "";
      });
      return state;
    },
  },
});

export const apiDataStateActions = apiDataStateSlice.actions;
export default apiDataStateSlice;
