import { Icon } from "./icons-props";

const CharmIcon: Icon = (props) => {
  const { height, width, color } = props;
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      fill={color || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="charm:reply">
        <path
          id="Vector"
          d="M21.375 19.875C20.625 10.875 13.125 8.625 9.375 9.375V4.125L2.625 12L9.375 19.875V14.625C13.125 13.875 19.125 15.375 21.375 19.875Z"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
};

export default CharmIcon;
