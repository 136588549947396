import { COLORS } from "@bikdotai/bik-component-library";
import { NodeSubType } from "../../models/nodeSubType";

export const borderColorBasedOnSubType = (
  subType: string,
  channel = "whatsapp"
) => {
  switch (subType) {
    case NodeSubType.SINGLE_PRODUCT:
    case NodeSubType.MESSAGE:
    case NodeSubType.LIST:
    case NodeSubType.CATALOG_LIST:
    case NodeSubType.TEMPLATE:
    case NodeSubType.WA_CAROUSEL:
    case NodeSubType.WHATSAPP_PAYMENT:
    case NodeSubType.ORDER_CONFIRMATION:
    case NodeSubType.ORDER_LIST:
    case NodeSubType.ASK_NAME:
    case NodeSubType.ASK_EMAIL:
    case NodeSubType.ASK_PHONE:
    case NodeSubType.QUESTION:
    case NodeSubType.CSAT_NPS:
    case NodeSubType.PRODUCT_CARD:
    case NodeSubType.MULTI_PRODUCT:
    case NodeSubType.CART_INFO:
    case NodeSubType.BROWSE_COLLECTION:
      return channel === "instagram"
        ? "#D300C5"
        : COLORS.background.positive.vibrant;
    case NodeSubType.ADD_EVENT:
    case NodeSubType.BROADCAST_EVENTS:
    case NodeSubType.ASSIGN_TICKET:
    case NodeSubType.ADD_TICKET_LABEL:
    case NodeSubType.CHANGE_TICKET_STAGE:
    case NodeSubType.CHECK_WORKING_HOURS:
    case NodeSubType.CALL_EXTERNAL_API:
    case NodeSubType.REST_API:
    case NodeSubType.CALL_CUSTOM_API:
    case NodeSubType.STORE_VARIABLE:
    case NodeSubType.CALL_API:
    case NodeSubType.START_FLOW:
      return COLORS.background.negative.vibrant;
    case NodeSubType.SEND_SMS:
      return COLORS.content.blue;
    case NodeSubType.DELAY:
    case NodeSubType.FILTER:
    case NodeSubType.ORDER_FILTER:
    case NodeSubType.AB_TEST:
    case NodeSubType.CONDITION:
    case NodeSubType.EVALUATE:
      return COLORS.stroke.warning.vibrant;
    case NodeSubType.SHOPIFY_ADD_TAGS:
    case NodeSubType.DISCOUNT_CODE:
    case NodeSubType.SHOPIFY_ADD_NOTES:
    case NodeSubType.ADD_CUSTOMER_TAGS:
      return "#008060";
    case NodeSubType.CREATE_TICKET_ON_FRESHDESK:
      return "#25C16F";
    case NodeSubType.CREATE_PAYMENT_LINK_ON_RAZORPAY:
      return "#3395FF";
    case NodeSubType.CREATE_PAYMENT_LINK_ON_GOSWIFT:
      return "#4EAC5B";
    case NodeSubType.JUDGEME:
      return "#308A92";
    case NodeSubType.GOOGLE_SHEET_ADD_ROW:
    case NodeSubType.GOOGLE_SHEET_UPDATE_ROW:
    case NodeSubType.GOOGLE_SHEET_GET_ROW_DATA:
      return "#34A853";
    case NodeSubType.INSTAGRAM_MEDIA:
    case NodeSubType.INSTAGRAM_TEXT:
    case NodeSubType.CAROUSEL:
    case NodeSubType.PRODUCT_CAROUSEL:
    case NodeSubType.AI_CLASSIFY:
    case NodeSubType.AI_PRODUCT_RECOMMEND:
    case NodeSubType.AI_REPLY:
    case NodeSubType.MANIFEST_EXIT:
    case NodeSubType.MANIFEST_BUILD_LLM_INPUT:
    case NodeSubType.MANIFEST_LLM_CALL:
    case NodeSubType.MANIFEST_SEARCH:
    case NodeSubType.AI_ASK:
      return COLORS.stroke.ai.vibrant;
    case NodeSubType.AI_GENERAL_PURPOSE_ASSISTANT:
      return COLORS.stroke.ai.vibrant;
    case NodeSubType.CSAT:
      return "#D300C5";
    case NodeSubType.TRIGGER_NODE:
      return "#EFE6F9";
    case NodeSubType.GMAIL_REPLY:
    case NodeSubType.EMAIL_TEMP_NEW:
    case NodeSubType.EMAIL_CSAT:
      return "#F00";
    default:
      return COLORS.background.brand;
  }
};
export const backgroundBasedOnSubType = (
  subType: string,
  channel = "whatsapp"
) => {
  switch (subType) {
    case NodeSubType.SINGLE_PRODUCT:
    case NodeSubType.MESSAGE:
    case NodeSubType.LIST:
    case NodeSubType.CATALOG_LIST:
    case NodeSubType.TEMPLATE:
    case NodeSubType.WA_CAROUSEL:
    case NodeSubType.WHATSAPP_PAYMENT:
    case NodeSubType.ORDER_CONFIRMATION:
    case NodeSubType.ORDER_LIST:
    case NodeSubType.ASK_NAME:
    case NodeSubType.ASK_EMAIL:
    case NodeSubType.ASK_PHONE:
    case NodeSubType.QUESTION:
    case NodeSubType.CSAT_NPS:
    case NodeSubType.PRODUCT_CARD:
    case NodeSubType.MULTI_PRODUCT:
    case NodeSubType.CART_INFO:
    case NodeSubType.BROWSE_COLLECTION:
      return channel === "instagram"
        ? "#F4E6F1"
        : COLORS.background.positive.light;
    case NodeSubType.ADD_EVENT:
    case NodeSubType.BROADCAST_EVENTS:
    case NodeSubType.ASSIGN_TICKET:
    case NodeSubType.ADD_TICKET_LABEL:
    case NodeSubType.CHANGE_TICKET_STAGE:
    case NodeSubType.CHECK_WORKING_HOURS:
    case NodeSubType.CALL_EXTERNAL_API:
    case NodeSubType.REST_API:
    case NodeSubType.CALL_CUSTOM_API:
    case NodeSubType.STORE_VARIABLE:
    case NodeSubType.CALL_API:
    case NodeSubType.START_FLOW:
      return COLORS.background.negative.light;
    case NodeSubType.SEND_SMS:
      return COLORS.background.blue.light;
    case NodeSubType.SHOPIFY_ADD_TAGS:
    case NodeSubType.DISCOUNT_CODE:
    case NodeSubType.SHOPIFY_ADD_NOTES:
    case NodeSubType.ADD_CUSTOMER_TAGS:
      return "#A5E2B94D";
    case NodeSubType.CREATE_TICKET_ON_FRESHDESK:
      return "#DCEEDE";
    case NodeSubType.CREATE_PAYMENT_LINK_ON_RAZORPAY:
      return "#D6EAFF";
    case NodeSubType.CREATE_PAYMENT_LINK_ON_GOSWIFT:
      return "#A5E2B94D";
    case NodeSubType.JUDGEME:
      return "#DCEBF6";
    case NodeSubType.GOOGLE_SHEET_ADD_ROW:
    case NodeSubType.GOOGLE_SHEET_UPDATE_ROW:
    case NodeSubType.GOOGLE_SHEET_GET_ROW_DATA:
      return "#EDF2FA";
    case NodeSubType.DELAY:
    case NodeSubType.FILTER:
    case NodeSubType.ORDER_FILTER:
    case NodeSubType.AB_TEST:
    case NodeSubType.CONDITION:
    case NodeSubType.EVALUATE:
      return COLORS.background.warning.light;
    case NodeSubType.INSTAGRAM_MEDIA:
    case NodeSubType.INSTAGRAM_TEXT:
    case NodeSubType.CAROUSEL:
    case NodeSubType.PRODUCT_CAROUSEL:
    case NodeSubType.CSAT:
      return "#F4E6F1";
    case NodeSubType.MANIFEST_EXIT:
    case NodeSubType.MANIFEST_BUILD_LLM_INPUT:
    case NodeSubType.MANIFEST_LLM_CALL:
    case NodeSubType.MANIFEST_SEARCH:
    case NodeSubType.AI_CLASSIFY:
    case NodeSubType.AI_PRODUCT_RECOMMEND:
    case NodeSubType.AI_REPLY:
    case NodeSubType.AI_ASK:
      return COLORS.surface.aiLight;
    case NodeSubType.AI_GENERAL_PURPOSE_ASSISTANT:
      return COLORS.surface.aiLight;
    case NodeSubType.GMAIL_REPLY:
    case NodeSubType.EMAIL_TEMP_NEW:
    case NodeSubType.EMAIL_CSAT:
      return COLORS.surface.hovered;
    default:
      return COLORS.background.brandLight;
  }
};
