import React, {
  ChangeEvent,
  ChangeEventHandler,
  FocusEventHandler,
} from "react";

import { ErrorMain } from "../input/BotInput.style";
import {
  TextAreaHeader,
  TextAreaHeading,
  TextAreaLabel,
  TextAreaWrapper,
} from "./TextArea.style";

export interface TextAreaDataProps {
  error?: boolean;
  placeHolder?: string;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  value?: string | number;
  subText?: string;
  name: string;
  isRequired: boolean;
  id: string;
  header?: string;
  onBlur?: (event: FocusEventHandler<HTMLTextAreaElement>) => void;
  borderColor?: string;
  heightInMobileView?: string;
  label?: string;
  wordCount?: number;
}

const BotTextArea = (props: TextAreaDataProps) => {
  const onChangeHandle = ($event: ChangeEvent<HTMLTextAreaElement>) => {
    $event.stopPropagation();
    props.onChange?.($event);
  };

  const onBlurHandle = ($event: FocusEventHandler<HTMLTextAreaElement>) => {
    props.onBlur?.($event);
  };

  return (
    <React.Fragment>
      <TextAreaHeading>{props.label}</TextAreaHeading>
      <TextAreaWrapper
        {...(props as Omit<
          Omit<TextAreaDataProps, "onBlur"> &
            FocusEventHandler<HTMLTextAreaElement>,
          "onChange"
        >)}
      >
        {(props.isRequired || props.header) && (
          <TextAreaHeader>
            <TextAreaLabel {...props}>
              {props.header}
              {props.isRequired ? "*" : null}
            </TextAreaLabel>
          </TextAreaHeader>
        )}
        <textarea
          className="body-small"
          onChange={onChangeHandle}
          value={props.value}
          name={props.name}
          placeholder={props.placeHolder}
          maxLength={props.wordCount}
          onBlur={
            onBlurHandle as unknown as FocusEventHandler<HTMLTextAreaElement>
          }
        />
      </TextAreaWrapper>
      {props.subText && <ErrorMain {...props}>{props.subText}</ErrorMain>}
    </React.Fragment>
  );
};

export default BotTextArea;
