import React, { useEffect } from "react";
import { EditorHeaderStyled } from "./EditorHeader.styled";
import { Separator } from "../../icons/separator";
import EmojiIcon from "../../icons/emojiIcon";
import { MAX_LIMIT_REACHED_ERROR } from "./RichTextEditor";
import { WordCounterRTE } from "./WordCounterRTE";

export interface EditorProps {
  messageText: any;
  showEmoji: boolean;
  setShowEmoji: any;
  showVariableDropdown: boolean;
  setShowVariableDropdown: any;
  error: string;
  setError: any;
  showVariableOnly: boolean;
  hideWordLimit: boolean;
  uniqueId: string;
  errorText?: string;
  wordLimit?: number;
  subText?: string;
  format: string[];
  setFormat: (format: string) => void;
  isCarousel?: boolean;
  showFontSize?: boolean;
  hideAddVariables?: boolean;
  customWordCountPosition?: boolean;
  showEmojiAlso?: boolean;
}

export const EditorHeader: React.FC<EditorProps> = (
  props,
  { errorText = "noError" }
) => {
  let wordCount;
  useEffect(() => {
    if (props?.hideWordLimit) {
      return;
    }
    wordCount = WordCounterRTE(props?.messageText).length;
    const barElement = document.getElementById(
      props?.uniqueId.toString() + "tool-bar"
    );
    const element = barElement?.getElementsByClassName(
      "add_variable"
    )[0] as HTMLElement;
    const list = element?.classList;
    if (props?.wordLimit && wordCount > props?.wordLimit) {
      list?.add("errorText");
      props.setError(MAX_LIMIT_REACHED_ERROR);
    } else {
      list?.remove("errorText");
      props.setError("");
    }
  }, [props.messageText]);

  useEffect(() => {
    const bold = document.getElementById(props.uniqueId + "bold")?.classList;
    const italic = document.getElementById(
      props.uniqueId + "italic"
    )?.classList;
    const strike = document.getElementById(
      props.uniqueId + "strike"
    )?.classList;
    if (props.format.includes("bold")) {
      bold?.add("highlight");
    } else {
      bold?.remove("highlight");
    }
    if (props.format.includes("italic")) {
      italic?.add("highlight");
    } else {
      italic?.remove("highlight");
    }
    if (props.format.includes("strike")) {
      strike?.add("highlight");
    } else {
      strike?.remove("highlight");
    }
  }, [props.format]);

  return (
    <EditorHeaderStyled
      id={props.uniqueId.toString() + "tool-bar"}
      isCarousel={props.isCarousel}
    >
      {errorText && errorText !== "noError" && (
        <div className="error__texty">{errorText}</div>
      )}
      {props.subText && (!errorText || errorText === "noError") && (
        <div className="subText">{props.subText}</div>
      )}
      {!props.hideWordLimit && errorText === "noError" && (
        <div className="add_variable">{`${
          WordCounterRTE(props.messageText).length
        }/${props.wordLimit ? props.wordLimit : 1024}`}</div>
      )}
      <div className="tool__box">
        <div id={"toolbar" + props?.uniqueId} className="toolBar ql-toolbar">
          {!props.showVariableOnly && (
            <>
              <button
                className="insertStar"
                onClick={() => {
                  props.setShowEmoji(!props.showEmoji);
                }}
              >
                <EmojiIcon />
              </button>
              <Separator />
              <button
                onClick={() => {
                  props.setFormat("bold");
                }}
                id={props.uniqueId + "bold"}
                className="ql-bold"
              ></button>
              <Separator />
              <button
                onClick={() => {
                  props.setFormat("italic");
                }}
                id={props.uniqueId + "italic"}
                className="ql-italic"
              ></button>
              <Separator />
              <button
                onClick={() => {
                  props.setFormat("strike");
                }}
                id={props.uniqueId + "strike"}
                className="ql-strike"
              >
                <img src="https://firebasestorage.googleapis.com/v0/b/bikayi-chat.appspot.com/o/images-uid%2Fec77422f-7953-4f9e-a7e3-09eb89994a95?alt=media&token=af388149-fc3e-4e63-8a8e-215dac1fc118"></img>
              </button>
              <Separator />
              {props?.showFontSize && (
                <>
                  <select className="ql-size">
                    <option value="small">small</option>
                    <option value="default" selected>
                      normal
                    </option>
                    <option value="large">large</option>
                    <option value="huge">huge</option>
                  </select>
                  {!props?.hideAddVariables && <Separator />}
                </>
              )}
            </>
          )}
          {!props?.hideAddVariables && (
            <>
              {props.showEmojiAlso && (
                <button
                  className="insertStar"
                  onClick={() => {
                    props.setShowEmoji(!props.showEmoji);
                  }}
                >
                  <EmojiIcon />
                </button>
              )}
              {props.showEmojiAlso && <Separator />}
              <div
                id={props.uniqueId + "variable"}
                className="addVariable"
                onClick={() =>
                  props.setShowVariableDropdown(!props.showVariableDropdown)
                }
              >
                <div className="add">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/bikayi-chat.appspot.com/o/images-uid%2F7ecfbfd3-c513-4331-822b-bc12f934a081?alt=media&token=1cf0e5b3-3462-4cf9-927a-357c1b608e2b"
                    alt="add variable"
                  />
                  <div className="variable">Add variable</div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </EditorHeaderStyled>
  );
};
