import { Icon } from "./icons-props";

const LoaderIcon: Icon = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      id="loader"
      width={width || "44"}
      height={height || "44"}
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 22C2 33.0457 10.9543 42 22 42C33.0457 42 42 33.0457 42 22C42 10.9543 33.0457 2 22 2"
        stroke="#731DCF"
        stroke-width="4"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default LoaderIcon;
