import { useAppSelector } from "../../state/store";
import { useDetectAnalytics } from "../../ui-components/flow-builder/useDetectAnalytics";
import { keyboardEventDataActions } from "../../state/keyboardEventState";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { cloneDeep } from "lodash";
import { storeDataActions } from "../../state/storeDataState";
import { onNodeAdd } from "../../services/helpers/VariableV2Mapper";
import { nodeSubtypeMappingActions } from "../../state/nodeSubtypeMappingState";
import { apiDataStateActions } from "../../state/apiDataState";
import { localVariablesDataAction } from "../../state/localVariablesState";
import { addEdge, XYPosition } from "reactflow";
import { NodeManager } from "../../ui-components/flow-builder/nodeManager";
import { ClipboardConfigType } from "./useCopy";
import { notificationDataStateActions } from "../../state/notificationDataState";
import { getDateString } from "../../components/sticky-notes/utils";
import { snakeToCamel, StickyNotesInterface } from "../flowBuilder.utility";
import { stickyNotesActions } from "../../state/stickyNotesState";
import { Dispatch, SetStateAction } from "react";
import { Edge } from "@reactflow/core/dist/esm/types";
import { captureErrorToSentry } from "../sentryHelper";
import { aiVariablesDataAction } from "../../state/aiVariablesState";

export type PasteProps = {
  index: number;
  setIndex: (idx: number) => void;
  variablesList: any;
  setVariablesList: any;
  setNodes: any;
  setEdges: any;
  edges: any;
  mouseCursorPosition: XYPosition;
};

const usePaste = ({
  index,
  setIndex,
  variablesList,
  setVariablesList,
  setNodes,
  setEdges,
  edges,
  mouseCursorPosition,
}: PasteProps) => {
  const isAnalytics = useDetectAnalytics();
  const dispatcher = useDispatch();
  const { type, channel } = useParams();
  const nodeManager = new NodeManager();

  const isModalOpen =
    document.getElementById("cmpModal") ||
    document.getElementById("chatbot-builder-modal") ||
    document.getElementById("bik-modal");

  const keyboardEventDataState = useAppSelector(
    (state) => state.keyboardEventState
  );

  const subtypeMapping = useAppSelector(
    (state) => state.nodeSubtypeMappingState.data
  );
  const externalApiDataState = useAppSelector(
    (state) => state.apiDataState.externalApiData
  );

  const newNodeSchemas: any = {};
  const newNodeUis: any = {};
  const newEdges: any = {};
  const localVariables: any = {};
  let aiVariableList: any = {};
  const customApiVariables: any = {};
  const discountVariables: any = {};
  let externalApiVariables: any[] = [];

  //Key is old node Id | Value is node Id and node index
  const oldNewMappingData: any = {};

  let currentNodeId = index;
  let currentSubTypeMapping = { ...subtypeMapping };

  const showMessage = (errorMessage: string, successMessage: string) => {
    dispatcher(
      notificationDataStateActions.setNotifications({
        errorMessage,
        successMessage,
      })
    );
  };

  const resetKeyboardEventState = () => {
    dispatcher(
      keyboardEventDataActions.keyPressed({
        keyPressAction: "",
      })
    );
  };

  const prepareNode = (nodeId: string, nodesAndEdges: ClipboardConfigType) => {
    try {
      const oldNodeSchema = nodesAndEdges["nodes-schema"][nodeId];
      const oldNodeUi = nodesAndEdges["nodes-ui"][nodeId];

      const newNodeSchema = cloneDeep(oldNodeSchema);
      const newNodeUI = cloneDeep(oldNodeUi);

      // handles node Id increment
      const newNodeId = `N${currentNodeId + 1}`;
      currentNodeId++;

      newNodeUI.id = newNodeId;
      newNodeUI.data.nodeData.nodeId = newNodeId;

      //handles index increment | if there are no subtype present in the flow, 1 will be the index
      newNodeUI.data.nodeData.nodeIndex =
        (currentSubTypeMapping[newNodeUI.data.nodeData.subType] || 0) + 1;
      currentSubTypeMapping[newNodeUI.data.nodeData.subType] =
        (currentSubTypeMapping[newNodeUI.data.nodeData.subType] || 0) + 1;

      const title =
        newNodeUI.data.nodeData.title.split("#")?.[0] ||
        newNodeUI.data.nodeData.title;
      newNodeUI.data.nodeData.title = `${title}#${newNodeUI.data.nodeData.nodeIndex}`;
      newNodeSchema.nodeId = newNodeId;
      newNodeSchema.node_index = newNodeUI.data.nodeData.nodeIndex;

      const { nodeData: newNodeData, error } = nodeManager.removeReferences(
        newNodeSchema,
        "",
        "",
        true
      );

      const newPosition = {
        x:
          mouseCursorPosition.x -
          nodesAndEdges["offset-position"][oldNodeUi.id].x,
        y:
          mouseCursorPosition.y -
          nodesAndEdges["offset-position"][oldNodeUi.id].y,
      };

      const nodeToBeAddedInState = {
        ...newNodeUI,
        position: newPosition,
        selected: false,
      };

      newNodeSchemas[newNodeId] = newNodeData;
      newNodeUis[newNodeId] = nodeToBeAddedInState;

      oldNewMappingData[nodeId] = {
        nodeId: newNodeId,
        nodeIndex: newNodeData.node_index,
      };

      //Variables handling
      //Custom Api variables
      if (nodesAndEdges["api-data"][oldNodeUi.id]) {
        const customApiKey =
          newNodeSchema.sub_type === "call_custom_api"
            ? `${newNodeId} - ${newNodeSchema.node_index}`
            : `${newNodeId} - ${newNodeSchema.node_index} - ${newNodeSchema.sub_type}`;

        customApiVariables[newNodeId] = {
          key: customApiKey,
          data: nodesAndEdges["api-data"][oldNodeUi.id],
        };
      }

      //local variables
      if (nodesAndEdges["local-variables"][oldNodeUi.id]) {
        localVariables[newNodeId] = {
          key: `${newNodeSchema.sub_type} ${newNodeSchema.node_index}`,
          data: `${newNodeId}_response`,
        };
      }

      //ai variables
      if (nodesAndEdges["ai-variables"][oldNodeUi.id]) {
        aiVariableList = {
          ...aiVariableList,
          [newNodeId]: {
            ...nodesAndEdges["ai-variables"][oldNodeUi.id], // AI Ask will have payload as well
            name: newNodeUis[newNodeId].data.nodeData.title,
            value: `${snakeToCamel(newNodeSchema.sub_type)}${newNodeSchema.node_index}.response`,
            type: nodesAndEdges["ai-variables"][oldNodeUi.id].type,
          },
        };
      }

      //discount variables
      if (nodesAndEdges["discount-variables"][oldNodeUi.id]) {
        discountVariables[newNodeId] = {
          key: `${newNodeId} - ${newNodeSchema.node_index}`,
          data: nodesAndEdges["discount-variables"][oldNodeUi.id],
        };
      }

      //external api variables
      if (nodesAndEdges["external-api-variables"][oldNodeUi.id]) {
        externalApiVariables = [
          ...externalApiVariables,
          ...nodesAndEdges["external-api-variables"][oldNodeUi.id],
        ];
      }
    } catch (e) {
      captureErrorToSentry(e, `Error in preparing node ${nodeId}`);
    }
  };

  const prepareEdge = (edgeId: string, nodesAndEdges: ClipboardConfigType) => {
    try {
      const oldEdge = nodesAndEdges["edges"][edgeId];
      const newEdge = cloneDeep(oldEdge);

      newEdge.source = oldNewMappingData[oldEdge.source].nodeId;
      newEdge.target = oldNewMappingData[oldEdge.target].nodeId;
      newEdge.id = `reactflow__edge-${newEdge.source}${newEdge.sourceHandle}-${newEdge.target}`;

      newEdges[newEdge.id] = newEdge;

      const edge_data = nodeManager.getEdgeConnections(newEdge);

      let fromNode = newNodeSchemas[edge_data.fromNodeId];

      //updating from node
      const { editableNode, error } = nodeManager.handleEdgeConnections(
        fromNode,
        edge_data
      );

      if (error) {
        captureErrorToSentry(
          error,
          "Error in updating node while edge connection"
        );
        return;
      }

      newNodeSchemas[edge_data.fromNodeId] = editableNode;
    } catch (e) {
      captureErrorToSentry(e, `Error in preparing edge ${edgeId}`);
    }
  };

  const pasteNodeAndVariables = (oldNodeId: string) => {
    try {
      const { nodeId: newNodeId, nodeIndex: newNodeIndex } =
        oldNewMappingData[oldNodeId];
      const newNodeSchema = newNodeSchemas[newNodeId];
      const newNodeUI = newNodeUis[newNodeId];

      dispatcher(
        storeDataActions.updateInsertNode({
          flow: {},
          nodeId: newNodeId,
          data: newNodeSchema,
        })
      );

      onNodeAdd(newNodeId, newNodeSchema, setVariablesList, variablesList);

      dispatcher(
        nodeSubtypeMappingActions.updateState({
          data: {},
          type: newNodeUI.data.nodeData.subType,
          count: newNodeUI.data.nodeData.nodeIndex,
        })
      );

      if (customApiVariables[newNodeId]) {
        dispatcher(
          apiDataStateActions.addCustomApiData({
            data: customApiVariables[newNodeId].data,
            nodeId: customApiVariables[newNodeId].key,
          })
        );
      }

      if (localVariables[newNodeId]) {
        dispatcher(
          localVariablesDataAction.addLocalVariables({
            key: localVariables[newNodeId].key,
            value: localVariables[newNodeId].data,
            localVariablesList: {},
          })
        );
      }

      if (Object.keys(aiVariableList).length > 0) {
        dispatcher(
          aiVariablesDataAction.setAiVariablesList({
            aiVariablesList: aiVariableList,
          })
        );
      }

      if (discountVariables[newNodeId]) {
        dispatcher(
          apiDataStateActions.addDiscountData({
            data: discountVariables[newNodeId].data,
            nodeId: discountVariables[newNodeId].key,
          })
        );
      }

      return newNodeUI;
    } catch (e) {
      captureErrorToSentry(e, `Error in pasting old node ${oldNodeId}`);
    }
  };

  const pasteStickyNote = (
    stickyNoteId: string,
    nodesAndEdges: ClipboardConfigType
  ) => {
    try {
      const oldStickyNodeSchema = nodesAndEdges["sticky-notes"][stickyNoteId];
      const oldStickyNodeUI = nodesAndEdges["nodes-ui"][stickyNoteId];

      const newNodeId = `S${(currentSubTypeMapping["sticky_notes"] || 0) + 1}`;

      const newNodeUI = cloneDeep(oldStickyNodeUI);
      const newDateString = getDateString(
        new Date(
          new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
        )
      );
      const newStickyNote: StickyNotesInterface = {
        nodeId: newNodeId,
        content: oldStickyNodeSchema.content,
        createdDate: newDateString,
        updatedDate: newDateString,
      };

      const newPosition = {
        x:
          mouseCursorPosition.x -
          nodesAndEdges["offset-position"][oldStickyNodeUI.id].x,
        y:
          mouseCursorPosition.y -
          nodesAndEdges["offset-position"][oldStickyNodeUI.id].y,
      };

      newNodeUI.id = newNodeId;
      newNodeUI.data.nodeData.nodeId = newNodeId;
      newNodeUI.data.nodeData.nodeIndex =
        (currentSubTypeMapping["sticky_notes"] || 0) + 1;

      currentSubTypeMapping["sticky_notes"] =
        (currentSubTypeMapping["sticky_notes"] || 0) + 1;

      dispatcher(
        nodeSubtypeMappingActions.updateState({
          data: {},
          type: newNodeUI.data.nodeData.subType,
          count: newNodeUI.data.nodeData.nodeIndex,
        })
      );
      dispatcher(
        stickyNotesActions.updateInsertNode({
          notes: {},
          id: newNodeId,
          data: newStickyNote,
        })
      );

      return {
        ...newNodeUI,
        position: newPosition,
        selected: false,
      };
    } catch (e) {
      captureErrorToSentry(
        e,
        `Error in pasting old sticky Note ${stickyNoteId}`
      );
    }
  };

  const prepareAndPasteNodesAndEdges = (nodesAndEdges: ClipboardConfigType) => {
    try {
      const nodesFromClipboard = Object.keys(nodesAndEdges["nodes-schema"]);
      const edgesFromClipboard = Object.keys(nodesAndEdges["edges"]);
      const stickyNotesFromClipboard = Object.keys(
        nodesAndEdges["sticky-notes"]
      );

      for (let nodeId of nodesFromClipboard) {
        prepareNode(nodeId, nodesAndEdges);
      }

      for (let edgeId of edgesFromClipboard) {
        prepareEdge(edgeId, nodesAndEdges);
      }

      const nodesUI: any = [];
      for (let oldNodeId of nodesFromClipboard) {
        const newNodeUi = pasteNodeAndVariables(oldNodeId);
        nodesUI.push(newNodeUi);
      }

      for (let stickyNoteId of stickyNotesFromClipboard) {
        const stickyNoteUi = pasteStickyNote(stickyNoteId, nodesAndEdges);
        nodesUI.push(stickyNoteUi);
      }

      setNodes((nds: any) => nds.concat(nodesUI));

      //Pasting external api variables
      if (externalApiVariables.length > 0) {
        dispatcher(
          apiDataStateActions.addExternalApiData({
            keys: [
              ...Object.keys(externalApiDataState),
              ...externalApiVariables,
            ],
          })
        );
      }

      if (nodesFromClipboard.length > 0) {
        setIndex(currentNodeId);
      }

      //Pasting Edge
      const newEdgesList = Object.keys(newEdges);
      // let edges: any = [];
      let copyOfEdges = edges;

      try {
        for (let newEdgeId of newEdgesList) {
          const newEdge = newEdges[newEdgeId];
          copyOfEdges = addEdge(
            { ...newEdge, type: "CustomEdge" },
            copyOfEdges
          );
        }
        setEdges(copyOfEdges);
      } catch (e) {
        captureErrorToSentry(e, "Error in pasting Edges");
      }
    } catch (e) {
      captureErrorToSentry(e, "Error in pasting nodes and edges");
    }
    resetKeyboardEventState();
  };

  const parseTextFromClipboard = async () => {
    const permissionStatus = await navigator.permissions.query({
      //@ts-ignore
      name: "clipboard-read",
    });

    let textFromClipboard = "";
    if (permissionStatus.state === "granted") {
      textFromClipboard = await navigator.clipboard.readText();
    } else if (permissionStatus.state === "denied") {
      showMessage(`Please allow permission to read from clipboard`, "");
      resetKeyboardEventState();
      return;
    } else if (permissionStatus.state === "prompt") {
      try {
        await navigator.clipboard.readText();
      } catch (e) {
        console.error("parseTextFromClipboard", e);
      } finally {
        resetKeyboardEventState();
      }
      return;
    }

    try {
      const nodesAndEdges = JSON.parse(textFromClipboard);

      //Channel specific restriction
      if (nodesAndEdges["channel"] !== channel) {
        showMessage(`Pasting is not allowed for different channels`, "");
        resetKeyboardEventState();
        return;
      }

      prepareAndPasteNodesAndEdges(nodesAndEdges);
    } catch (e) {
      console.error("Error in parsing the clipboard text", e);
      return;
    }
  };

  if (keyboardEventDataState?.keyPressAction === "paste") {
    if (isModalOpen || isAnalytics) {
      resetKeyboardEventState();
      return;
    }

    parseTextFromClipboard();
  }
};

export default usePaste;
