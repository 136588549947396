import React, { FunctionComponent, useEffect, useState } from "react";
import {
  ButtonActions,
  ButtonTypes,
  StateComponent,
  StateInterface,
  StyledModal,
} from "@bikdotai/bik-component-library";
import { homeDataAction } from "../../state/homeState";
import { useAppSelector } from "../../state/store";
import { useDispatch } from "react-redux";

const ShowNudgeModal: FunctionComponent<{
  isSaveStateEnabled: boolean;
}> = (props) => {
  const homeState = useAppSelector((state) => state.homeState);
  const nodeActionState = useAppSelector((state) => state.nodeActionState);
  const [showModal, setShowModal] = useState<boolean>(true);
  const dispatcher = useDispatch();

  const saveFlow = () => {
    if (props.isSaveStateEnabled) {
      dispatcher(
        homeDataAction.addState({
          ...homeState,
          saveFlow: true,
          publishFlow: false,
          skipStatModal: true,
        })
      );
    } else {
      window.location.reload();
    }
  };

  useEffect(() => {
    if (
      nodeActionState?.action === "save-success" ||
      nodeActionState?.action === "publish-success"
    ) {
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }, [nodeActionState]);

  return (
    <StyledModal
      width={"400"}
      onClose={() => setShowModal(false)}
      open={showModal}
    >
      <StateComponent
        states={StateInterface.ERROR}
        heading={"Journey builder has been updated"}
        subline={
          props.isSaveStateEnabled
            ? "To avoid losing your work, save and refresh the page."
            : "Refresh the page to continue working"
        }
        isButtonEnabled={true}
        type={ButtonTypes.DOUBLE}
        buttonText={props.isSaveStateEnabled ? "Save & Refresh" : "Refresh"}
        width={400}
        onButtonClick={(actions) => {
          if (actions === ButtonActions.CANCEL) {
            setShowModal(false);
          } else {
            saveFlow();
          }
        }}
      />
    </StyledModal>
  );
};

export default ShowNudgeModal;
