import {
  ALERT_TYPES,
  Alert,
  Button,
  COLORS,
  DropdownPopover,
  StyledModal,
  TitleMedium,
  TitleSmall,
  VariableListInterfaceV2,
} from "@bikdotai/bik-component-library";
import { Container, Footer, Header } from "../ig-modals/style";
import { useEffect, useState } from "react";
import AlertTriangle from "../../icons/alertTriangle";
import ArrowRightIcon from "../../icons/ArrowRightIcon";
import ArrowLeftIcon from "../../icons/arrowLeft";
import {
  generateUniqueId,
  generateUniqueIdWithDelay,
} from "../../app/action-block/Util";
import useVariablesListHookV2 from "../../services/helpers/VariablesListHookV2";
import { FirebaseService } from "../../services/firebase/FirebaseService";
import { useAppSelector } from "../../state/store";
import { v4 as uuidv4 } from "uuid";
import { payloadVariablesDataAction } from "../../state/payloadVariableState";
import { useDispatch } from "react-redux";
import { createList, processJson, updateSelectedVariables } from "./helpers";
import {
  StyledNext,
  FooterButtonsStyled,
  HeaderContent,
  DropdownContainer,
  DropdownWrapper,
} from "./styles";
import Page2 from "./page2";
import Page1 from "./page1";
import Page3 from "./page3";
import ChevronDownIcon from "../../icons/chevron_down";
import WhatsappIcon from "../../icons/whatsappIcon";
import { populateChannelIdsDropdown } from "../query-builder/utils";
import { ID_OPTIONS, ID_OPTIONS2 } from "./constants";

const WebhookBuilder: React.FC<{
  channel: string;
  visible: boolean;
  onClose: Function;
  storeId: string;
  accountId: string;
  nodes: [];
  mode: string;
  onSave: Function;
  data: any;
  currentChannelId: string;
}> = (props) => {
  const [isClickActive, setIsClickActive] = useState(false);
  const [json, setJson] = useState(
    JSON.stringify(
      {
        bikExampleId: 143671,
        bikExampleEmail: "abc@bik.ai",
        bikExampleItems: ["item1", "item2"],
      },
      null,
      2
    )
  );
  const [stepOneError, setStepOneError] = useState(false);
  const [page, setPage] = useState(1);
  const [pageTwoError, setPageTwoError] = useState({
    idMissing: false,
    payloadVarMissingOrInvalid: false,
    payloadVarsNotSelected: false,
  });
  const dispatcher = useDispatch();
  const [channelError, setChannelError] = useState<boolean>(false);
  const [pageThreeError, setPageThreeError] = useState<{
    [key: string]: string;
  }>({});
  const [payloadList, setPayloadList] = useState<VariableListInterfaceV2[]>([]);
  const [showVarPicker, setShowVarPicker] = useState(false);
  const [payloadVariable, setPayloadVariable] = useState<string>("");
  const [selectedPayloadVars, setSelectedPayloadVars] = useState<{
    [key: string]: string;
  }>({});
  const [clickedPayloadVar, setClickedPayloadVar] = useState({});
  const [mappingPayloadVar, setMappingPayloadVar] = useState<any>({});
  const [selectedCellId, setSelectedCellId] = useState<string>("");
  const storeId = useAppSelector((state) => state.homeState.storeId);
  const flowId = useAppSelector((state) => state.homeState.flowId);
  const channels: { [key: string]: any } = useAppSelector(
    (state) => state.flowMetaState.channels!
  );
  const [selectedVarTree, setSelectedVarTree] = useState<
    VariableListInterfaceV2[]
  >([]);
  const [tabId, setTabId] = useState(1);
  const [showVarPicker2, setShowVarPicker2] = useState(false);
  const [payloadVariable2, setPayloadVariable2] = useState<string>("");
  const [hideModalCrossButton, setHideModalCrossButton] = useState(false);
  const [idOptions, setIdOptions] = useState(ID_OPTIONS);
  const [idOptions2, setIdOptions2] = useState(ID_OPTIONS2);
  const [cells, setCells] = useState<
    {
      cellId: string;
      payloadVar: string;
      existingVar: string;
      dataType?: string;
    }[]
  >([
    {
      cellId: generateUniqueId(),
      payloadVar: "",
      existingVar: "",
    },
  ]);
  const [chooseType, setChooseType] = useState("info");
  const { variablesList } = useVariablesListHookV2({
    payloadConfig: {
      hide: true,
    },
  });
  const [uniqueId, setUniqueId] = useState<string>(uuidv4());
  const [focusedCell, setFocusedCell] = useState({
    cellId: "",
    place: "",
  });
  const [showId2, setShowId2] = useState(false);
  const [channelIdOptions, setChannelIdOptions] = useState<
    {
      label: string;
      value: string;
      selected: boolean;
    }[]
  >([]);
  const [selectedChannel, setSelectedChannel] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    populateChannelIdsDropdown(
      props.channel,
      channels,
      setIsLoading,
      setSelectedChannel,
      setChannelIdOptions,
      props.currentChannelId
    );
  }, []);

  useEffect(() => {
    if (channelIdOptions && channelIdOptions.length === 1) {
      setSelectedChannel(channelIdOptions[0]);
    }
  }, [channelIdOptions]);

  useEffect(() => {
    if (props.data.length) {
      const {
        idType,
        idPath,
        extractionType,
        payloadSchema,
        selectedPayloadSchema,
        mappingToStaticVariable,
        originalJson,
        id,
        secondaryIdType,
      } = props.data[0];

      setUniqueId(id);
      setIdOptions((prev) => {
        return prev.map((item) => {
          if (item.value === idType) {
            return {
              ...item,
              selected: true,
            };
          } else {
            if (secondaryIdType === item.value) {
              return {
                ...item,
                disabled: true,
              };
            } else {
              return item;
            }
          }
        });
      });

      setPayloadVariable(idPath);

      if (secondaryIdType) {
        setIdOptions2((prev) => {
          return prev.map((item) => {
            if (item.value === secondaryIdType) {
              return {
                ...item,
                selected: true,
              };
            } else {
              if (idType === item.value) {
                return {
                  ...item,
                  disabled: true,
                };
              } else {
                return item;
              }
            }
          });
        });
        setPayloadVariable2(props.data[0]?.secondaryIdPath);
        setShowId2(true);
      }

      if (extractionType === "all") {
        setTabId(1);
        setSelectedPayloadVars({});
      } else {
        setTabId(2);
        setSelectedPayloadVars(selectedPayloadSchema);
      }

      setJson(originalJson);

      const confCells: {
        cellId: string;
        payloadVar: string;
        existingVar: string;
      }[] = [];
      Object.entries(mappingToStaticVariable).forEach(([key, value], index) => {
        confCells.push({
          cellId: generateUniqueIdWithDelay(index),
          payloadVar: value as string,
          existingVar: key,
        });
      });
      if (!confCells.length) {
        confCells.push({
          cellId: generateUniqueId(),
          payloadVar: "",
          existingVar: "",
        });
      }
      setCells(confCells);
    }
  }, []);

  useEffect(() => {
    if (showVarPicker) {
      setHideModalCrossButton(true);
    } else {
      setHideModalCrossButton(false);
    }
  }, [showVarPicker]);

  useEffect(() => {
    if (showVarPicker2) {
      setHideModalCrossButton(true);
    } else {
      setHideModalCrossButton(false);
    }
  }, [showVarPicker2]);

  useEffect(() => {
    if (Object.keys(pageThreeError)) {
      const errors: { [key: string]: string } = {};
      cells.forEach((item) => {
        if (
          (item.payloadVar && !item.existingVar) ||
          (item.existingVar && !item.payloadVar)
        ) {
          if (pageThreeError[item.cellId]) {
            errors[item.cellId] =
              "Please map both payload and existing variables";
          }
        }
      });
      setPageThreeError(errors);
    }
  }, [cells]);

  useEffect(() => {
    if (mappingPayloadVar.actualValue) {
      setCells((prev) => {
        return prev.map((item) => {
          if (item.cellId === selectedCellId) {
            if (chooseType === "payload") {
              return {
                ...item,
                payloadVar: mappingPayloadVar.actualValue,
                dataType: mappingPayloadVar.variableType,
              };
            } else if (chooseType === "existing") {
              return {
                ...item,
                existingVar: mappingPayloadVar.actualValue,
                dataType: mappingPayloadVar.variableType,
              };
            } else {
              return item;
            }
          } else {
            return item;
          }
        });
      });
    }
  }, [mappingPayloadVar]);

  useEffect(() => {
    if (tabId == 2 && (clickedPayloadVar as any)?.actualValue) {
      setSelectedPayloadVars((prev) => {
        return {
          ...prev,
          [(clickedPayloadVar as any)?.actualValue]: (clickedPayloadVar as any)
            ?.variableType,
        };
      });
      // TODO: sumesh
      // const selectedVariables = [...Object.keys(selectedPayloadVars), (clickedPayloadVar as any)?.actualValue];
      // updateSelectedVariables(payloadList[0].variables, selectedVariables);

      if (pageTwoError.payloadVarsNotSelected) {
        setPageTwoError((prev) => {
          return {
            ...prev,
            payloadVarsNotSelected: false,
          };
        });
      }
    }
  }, [clickedPayloadVar]);

  useEffect(() => {
    if (payloadVariable) {
      setShowVarPicker(false);
      setPageTwoError((prev) => {
        return { ...prev, payloadVarMissingOrInvalid: false };
      });
    }
  }, [payloadVariable]);

  useEffect(() => {
    if (payloadVariable2) {
      setShowVarPicker2(false);
      setPageTwoError((prev) => {
        return { ...prev, secondaryIdMissing: false };
      });
    }

    if (idOptions2.filter((item) => (item as unknown as any).selected).length) {
      setPageTwoError((prev) => {
        return { ...prev, secondaryIdTypeMissing: false };
      });
    }

    if (!showId2) {
      setPageTwoError((prev) => {
        return {
          ...prev,
          secondaryIdTypeMissing: false,
          secondaryIdMissing: false,
        };
      });
    }
  }, [payloadVariable2, idOptions2, showId2]);

  useEffect(() => {
    const handleScroll = () => {
      setShowVarPicker(false);
      setShowVarPicker2(false);
    };
    const page2 = document.getElementById("page2") as any;
    if (page2) {
      page2.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (page2) {
        page2.removeEventListener("scroll", handleScroll);
      }
    };
  });

  const pageOne = (
    <Page1
      setIsClickActive={setIsClickActive}
      setJson={setJson}
      json={json}
      setStepOneError={setStepOneError}
      stepOneError={stepOneError}
      setTabId={setTabId}
      setPayloadVariable={setPayloadVariable}
      setSelectedPayloadVars={setSelectedPayloadVars}
      setCells={setCells}
      storeId={storeId}
      flowId={flowId}
      isClickActive={isClickActive}
    />
  );

  const pageTwo = (
    <Page2
      payloadVariable={payloadVariable}
      idOptions={idOptions}
      setPageTwoError={setPageTwoError}
      setIdOptions={setIdOptions}
      setPayloadVariable={setPayloadVariable}
      showVarPicker={showVarPicker}
      setShowVarPicker={setShowVarPicker}
      payloadList={payloadList}
      pageTwoError={pageTwoError}
      selectedPayloadVars={selectedPayloadVars}
      setSelectedPayloadVars={setSelectedPayloadVars}
      tabId={tabId}
      setTabId={setTabId}
      setClickedPayloadVar={setClickedPayloadVar}
      clickedPayloadVar={clickedPayloadVar}
      cells={cells}
      setCells={setCells}
      payloadVariable2={payloadVariable2}
      idOptions2={idOptions2}
      setIdOptions2={setIdOptions2}
      setPayloadVariable2={setPayloadVariable2}
      showVarPicker2={showVarPicker2}
      setShowVarPicker2={setShowVarPicker2}
      showId2={showId2}
      setShowId2={setShowId2}
    />
  );

  const pageThree = (
    <Page3
      variablesList={variablesList}
      setSelectedCellId={setSelectedCellId}
      setChooseType={setChooseType}
      setMappingPayloadVar={setMappingPayloadVar}
      mappingPayloadVar={mappingPayloadVar}
      setFocusedCell={setFocusedCell}
      focusedCell={focusedCell}
      chooseType={chooseType}
      selectedVarTree={selectedVarTree}
      pageThreeError={pageThreeError}
      cells={cells}
      setCells={setCells}
    />
  );

  function generateConf(ignoreCells: boolean = false) {
    const idType = idOptions.find((item: any) => item.selected)?.value;
    const idPath = payloadVariable;
    const extractionType = tabId == 1 ? "all" : "part";
    const payloadSchema = processJson(JSON.parse(json));
    const secondaryIdType =
      idOptions2.find((item: any) => item.selected)?.value || "";
    const secondaryIdPath = payloadVariable2 || "";
    let selectedPayloadSchema: { [key: string]: string } = {};
    if (extractionType == "part") {
      const reconstructedJson: any = {};
      Object.entries(selectedPayloadVars).forEach(([key, value]) => {
        key = key.replace("{{", "").replace("}}", "");
        let paths = key.split(/(\[.*?\])/);
        let path: string[] = [];
        paths.forEach((item) => {
          path.push(...item.split(".").filter((item) => item));
        });
        let nextJson = reconstructedJson;
        while (path.length > 1) {
          const curPath = path.shift()!;
          nextJson[curPath] = nextJson?.[curPath] || {};
          nextJson = nextJson[curPath];
        }

        nextJson[path.shift()!] = value;
      });
      selectedPayloadSchema = reconstructedJson;
    } else {
      selectedPayloadSchema = payloadSchema;
    }
    const mappingToStaticVariable: { [key: string]: string } = {};

    if (!ignoreCells) {
      cells.forEach((item) => {
        if (item.payloadVar && item.existingVar) {
          mappingToStaticVariable[item.existingVar] = item.payloadVar;
        }
      });
    }

    return {
      conf: {
        idType,
        idPath,
        secondaryIdType,
        secondaryIdPath,
        extractionType,
        payloadSchema,
        selectedPayloadSchema: selectedPayloadVars,
        mappingToStaticVariable,
        originalJson: json,
        flowId,
        storeId,
        type: "webhook",
        id: uniqueId,
        header: "Payload received on Webhook URL",
        channelId: selectedChannel.value,
      },
      typeTree: selectedPayloadSchema,
    };
  }

  const ChannelDropdown = (props: { error: boolean }) => {
    return (
      <DropdownContainer error={props.error}>
        <DropdownPopover
          width="200px"
          disabled={isLoading}
          onSelect={(c: any) => {
            setSelectedChannel(c);
            const modified = channelIdOptions.map((d) => {
              if (d.value === c.value) {
                return {
                  label: d.label,
                  value: d.value,
                  selected: true,
                };
              } else {
                return {
                  label: d.label,
                  value: d.value,
                  selected: false,
                };
              }
            });
            setChannelIdOptions(modified);
          }}
          options={channelIdOptions}
        >
          <DropdownWrapper>
            <WhatsappIcon />
            <TitleSmall
              className="dropdown-text"
              style={{
                fontWeight: 400,
                color: props.error
                  ? COLORS.content.negative
                  : COLORS.content.primary,
              }}
            >
              {selectedChannel.label || "Select an account"}
            </TitleSmall>
            <ChevronDownIcon
              color={
                props.error ? COLORS.content.negative : COLORS.content.primary
              }
            />
          </DropdownWrapper>
        </DropdownPopover>
      </DropdownContainer>
    );
  };

  return (
    <StyledModal
      hideCrossButton={hideModalCrossButton}
      centralContainerStyles={{ width: "1032px", height: "560px" }}
      open={props.visible}
      onClose={() => props.onClose()}
      zIndex={200}
    >
      <Container>
        <Header>
          <HeaderContent>
            {page > 1 ? (
              <div
                onClick={() => {
                  setPage((prev) => prev - 1);
                  setShowVarPicker(false);

                  // clear out errors
                  setStepOneError(false);
                  setPageTwoError({
                    idMissing: false,
                    payloadVarMissingOrInvalid: false,
                    payloadVarsNotSelected: false,
                  });
                  setPageThreeError({});
                }}
              >
                <ArrowLeftIcon
                  width={24}
                  height={24}
                  color={COLORS.content.primary}
                />{" "}
              </div>
            ) : (
              <></>
            )}
            <TitleMedium>{`Configure Webhook (Step ${page}/3)`}</TitleMedium>
          </HeaderContent>
          <ChannelDropdown error={channelError} />
        </Header>

        {page === 1 ? pageOne : page === 2 ? pageTwo : pageThree}

        <Footer>
          <div>
            {stepOneError ||
            channelError ||
            pageTwoError.idMissing ||
            pageTwoError.payloadVarMissingOrInvalid ||
            pageTwoError.payloadVarsNotSelected ? (
              <Alert
                icon={() => (
                  <AlertTriangle
                    width={20}
                    height={20}
                    color={COLORS.content.negative}
                  />
                )}
                text={"Some inputs are invalid or missing. Please check"}
                type={ALERT_TYPES.NEGATIVE}
              />
            ) : (
              <></>
            )}
          </div>

          <FooterButtonsStyled>
            <div
              className="cancel"
              onClick={() => {
                if (page === 3) {
                  const conf = generateConf(true);
                  const firebaseService = new FirebaseService(
                    () => {},
                    () => {}
                  );
                  firebaseService
                    .saveWebhookTypeTree(storeId, flowId, conf.typeTree)
                    .then((res) => {
                      dispatcher(
                        payloadVariablesDataAction.setWebhookVariables({
                          webhookPayload: conf.typeTree,
                        })
                      );
                      props.onSave(conf.conf);
                    });
                } else {
                  props.onClose();
                }
              }}
            >
              <Button
                buttonText={page == 3 ? "Skip and Create" : "Cancel"}
                buttonType="tertiaryGray"
              />
            </div>
            <div
              className="next"
              onClick={() => {
                if (page == 1) {
                  if (!selectedChannel.value) {
                    setChannelError(true);
                    return;
                  } else if (!json) {
                    setStepOneError(true);
                    return;
                  } else {
                    if (
                      !json.trim().startsWith("{") &&
                      !json.trim().endsWith("{")
                    ) {
                      setStepOneError(true);
                      return;
                    }
                    try {
                      JSON.parse(json);
                    } catch (e) {
                      setStepOneError(true);
                      return;
                    }
                    if (
                      Object.keys(JSON.parse(json)).filter((i) =>
                        i.trim().startsWith("bikExample")
                      ).length
                    ) {
                      setStepOneError(true);
                      return;
                    }
                  }
                  const skeletionJson = processJson(JSON.parse(json));
                  let variableList: VariableListInterfaceV2[] = [];
                  const payloadVariables = {
                    displayName: "Payload Variables",
                    description:
                      "These are variables passed from the payload received",
                    orderId: 1,
                    variables: (function () {
                      return createList(skeletionJson);
                    })(),
                  };
                  variableList.push(payloadVariables);
                  setPayloadList(variableList);
                  // TODO: sumesh
                  // if(Object.keys(selectedPayloadVars).length){
                  //   updateSelectedVariables(variableList[0].variables, Object.keys(selectedPayloadVars));
                  // }
                  setChannelError(false);
                  setPage(2);
                } else if (page == 2) {
                  let hasError = false;
                  if (tabId == 2 && !Object.keys(selectedPayloadVars).length) {
                    hasError = true;
                    setPageTwoError((prev) => {
                      return {
                        ...prev,
                        payloadVarsNotSelected: true,
                      };
                    });
                  }

                  if (!payloadVariable) {
                    hasError = true;
                    setPageTwoError((prev) => {
                      return {
                        ...prev,
                        payloadVarMissingOrInvalid: true,
                      };
                    });
                  }

                  if (!idOptions.find((item: any) => item.selected)) {
                    hasError = true;
                    setPageTwoError((prev) => {
                      return {
                        ...prev,
                        idMissing: true,
                      };
                    });
                  }

                  if (showId2) {
                    if (!idOptions2.find((item: any) => item.selected)) {
                      hasError = true;
                      setPageTwoError((prev) => {
                        return {
                          ...prev,
                          secondaryIdTypeMissing: true,
                        };
                      });
                    }
                    if (!payloadVariable2) {
                      hasError = true;
                      setPageTwoError((prev) => {
                        return {
                          ...prev,
                          secondaryIdMissing: true,
                        };
                      });
                    }
                  }

                  if (hasError) {
                    return;
                  }

                  let variableList: VariableListInterfaceV2[] = [];
                  const payloadVariables = {
                    displayName: "Payload Variables",
                    description:
                      "These are variables passed from the payload received",
                    orderId: 1,
                    variables: [],
                  };

                  setChooseType("info");

                  if (tabId == 1) {
                    payloadVariables.variables = createList(
                      processJson(JSON.parse(json))
                    );
                    variableList.push(payloadVariables);
                    setSelectedVarTree(variableList);
                  } else {
                    const reconstructedJson: any = {};
                    Object.entries(selectedPayloadVars).forEach(
                      ([key, value]) => {
                        key = key.replace("{{", "").replace("}}", "");
                        let paths = key.split(/(\[.*?\])/);
                        let path: string[] = [];
                        paths.forEach((item) => {
                          path.push(...item.split(".").filter((item) => item));
                        });
                        let nextJson = reconstructedJson;
                        while (path.length > 1) {
                          const curPath = path.shift()!;
                          nextJson[curPath] = nextJson?.[curPath] || {};
                          nextJson = nextJson[curPath];
                        }

                        nextJson[path.shift()!] = value;
                      }
                    );
                    payloadVariables.variables = createList(
                      processJson(reconstructedJson)
                    );
                    variableList.push(payloadVariables);
                    setSelectedVarTree(variableList);
                  }

                  setPage(3);
                } else {
                  let isError = false;
                  const existingCount: { [key: string]: number } = {};
                  cells.forEach((item) => {
                    if (!existingCount[item.existingVar]) {
                      existingCount[item.existingVar] = 1;
                    } else {
                      existingCount[item.existingVar] =
                        existingCount[item.existingVar] + 1;
                      isError = true;
                      setPageThreeError((prev) => {
                        return {
                          ...prev,
                          [item.cellId]:
                            "Please map existing variables only once",
                        };
                      });
                    }

                    if (
                      (item.payloadVar && !item.existingVar) ||
                      (item.existingVar && !item.payloadVar)
                    ) {
                      isError = true;
                      setPageThreeError((prev) => {
                        return {
                          ...prev,
                          [item.cellId]:
                            "Please map both payload and existing variables",
                        };
                      });
                    }
                  });

                  if (!isError) {
                    const conf = generateConf();
                    const firebaseService = new FirebaseService(
                      () => {},
                      () => {}
                    );
                    firebaseService
                      .saveWebhookTypeTree(storeId, flowId, conf.typeTree)
                      .then((res) => {
                        dispatcher(
                          payloadVariablesDataAction.setWebhookVariables({
                            webhookPayload: conf.typeTree,
                          })
                        );
                        props.onSave(conf.conf);
                      });
                  }
                }
              }}
            >
              <StyledNext>
                <Button
                  style={{
                    display: "flex",
                  }}
                  buttonText={page === 3 ? "Create Trigger" : "Next"}
                  TrailingIcon={ArrowRightIcon}
                />
              </StyledNext>
            </div>
          </FooterButtonsStyled>
        </Footer>
      </Container>
    </StyledModal>
  );
};

export default WebhookBuilder;
