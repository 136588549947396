import { CustomNodeIconProps } from "../utilities/flowBuilder.utility";

const StarHalfIcon = (props: CustomNodeIconProps) => {
  const { backgroundColor, color, height, width, style, showBorder } = props;
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="star_half">
        <path
          id="shape"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.99998 0.667969C8.25373 0.667969 8.48547 0.812017 8.59778 1.03955L10.5028 4.89895L14.7631 5.52165C15.0141 5.55834 15.2226 5.73434 15.3008 5.97568C15.3791 6.21702 15.3135 6.48185 15.1318 6.65887L12.0497 9.66088L12.7771 13.9019C12.82 14.1521 12.7171 14.4049 12.5118 14.554C12.3065 14.7032 12.0343 14.7228 11.8097 14.6047L7.99998 12.6012L4.19028 14.6047C3.96567 14.7228 3.69347 14.7032 3.48816 14.554C3.28284 14.4049 3.18001 14.1521 3.22291 13.9019L3.9503 9.66088L0.868157 6.65887C0.686411 6.48185 0.620896 6.21702 0.699148 5.97568C0.7774 5.73434 0.985853 5.55834 1.23689 5.52165L5.49714 4.89895L7.40217 1.03955C7.51449 0.812017 7.74623 0.667969 7.99998 0.667969ZM7.99998 2.84081L7.99996 11.1813C8.10655 11.1813 8.21315 11.2068 8.31028 11.2579L11.2345 12.7958L10.6762 9.54066C10.6391 9.32431 10.7109 9.10356 10.8682 8.9504L13.2344 6.6457L9.96356 6.16763C9.74666 6.13592 9.5592 5.99962 9.46217 5.80305L7.99998 2.84081Z"
          fill="#616161"
        />
      </g>
    </svg>
  );
};

export default StarHalfIcon;
