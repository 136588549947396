import { Icon } from "./icons-props";

const Upload: Icon = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill={color || "#212121"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.5891 1.91076C10.2637 1.58533 9.73602 1.58533 9.41058 1.91076L5.24392 6.07743C4.91848 6.40287 4.91848 6.93051 5.24392 7.25594C5.56935 7.58138 6.09699 7.58138 6.42243 7.25594L9.1665 4.51186V12.5C9.1665 12.9603 9.5396 13.3334 9.99984 13.3334C10.4601 13.3334 10.8332 12.9603 10.8332 12.5V4.51186L13.5772 7.25594C13.9027 7.58138 14.4303 7.58138 14.7558 7.25594C15.0812 6.93051 15.0812 6.40287 14.7558 6.07743L10.5891 1.91076Z" />
      <path d="M3.33317 12.5C3.33317 12.0398 2.96007 11.6667 2.49984 11.6667C2.0396 11.6667 1.6665 12.0398 1.6665 12.5V15.8334C1.6665 16.4964 1.9299 17.1323 2.39874 17.6011C2.86758 18.07 3.50346 18.3334 4.1665 18.3334H15.8332C16.4962 18.3334 17.1321 18.07 17.6009 17.6011C18.0698 17.1323 18.3332 16.4964 18.3332 15.8334V12.5C18.3332 12.0398 17.9601 11.6667 17.4998 11.6667C17.0396 11.6667 16.6665 12.0398 16.6665 12.5V15.8334C16.6665 16.0544 16.5787 16.2663 16.4224 16.4226C16.2661 16.5789 16.0542 16.6667 15.8332 16.6667H4.1665C3.94549 16.6667 3.73353 16.5789 3.57725 16.4226C3.42097 16.2663 3.33317 16.0544 3.33317 15.8334V12.5Z" />
    </svg>
  );
};

export default Upload;
