import { Icon } from "./icons-props";

const DeleteIcon: Icon = (props) => {
  const { width, height, color, backgroundColor } = props;
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill={color || "currentColor"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.33335 8.3335C8.79359 8.3335 9.16669 8.70659 9.16669 9.16683V14.1668C9.16669 14.6271 8.79359 15.0002 8.33335 15.0002C7.87312 15.0002 7.50002 14.6271 7.50002 14.1668V9.16683C7.50002 8.70659 7.87312 8.3335 8.33335 8.3335Z"
        fill={color || "currentColor"}
      />
      <path
        d="M12.5 14.1668V9.16683C12.5 8.70659 12.1269 8.3335 11.6667 8.3335C11.2064 8.3335 10.8334 8.70659 10.8334 9.16683V14.1668C10.8334 14.6271 11.2064 15.0002 11.6667 15.0002C12.1269 15.0002 12.5 14.6271 12.5 14.1668Z"
        fill={color || "currentColor"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.83335 4.16683V3.3335C5.83335 2.67045 6.09675 2.03457 6.56559 1.56573C7.03443 1.09689 7.67031 0.833496 8.33335 0.833496H11.6667C12.3297 0.833496 12.9656 1.09689 13.4345 1.56573C13.9033 2.03457 14.1667 2.67045 14.1667 3.3335V4.16683H17.5C17.9603 4.16683 18.3334 4.53993 18.3334 5.00016C18.3334 5.4604 17.9603 5.8335 17.5 5.8335H16.6667V16.6668C16.6667 17.3299 16.4033 17.9658 15.9345 18.4346C15.4656 18.9034 14.8297 19.1668 14.1667 19.1668H5.83335C5.17031 19.1668 4.53443 18.9034 4.06559 18.4346C3.59675 17.9658 3.33335 17.3299 3.33335 16.6668V5.8335H2.50002C2.03978 5.8335 1.66669 5.4604 1.66669 5.00016C1.66669 4.53993 2.03978 4.16683 2.50002 4.16683H5.83335ZM7.7441 2.74424C7.90038 2.58796 8.11234 2.50016 8.33335 2.50016H11.6667C11.8877 2.50016 12.0997 2.58796 12.2559 2.74424C12.4122 2.90052 12.5 3.11248 12.5 3.3335V4.16683H7.50002V3.3335C7.50002 3.11248 7.58782 2.90052 7.7441 2.74424ZM5.00002 5.8335V16.6668C5.00002 16.8878 5.08782 17.0998 5.2441 17.2561C5.40038 17.4124 5.61234 17.5002 5.83335 17.5002H14.1667C14.3877 17.5002 14.5997 17.4124 14.7559 17.2561C14.9122 17.0998 15 16.8878 15 16.6668V5.8335H5.00002Z"
        fill={color || "currentColor"}
      />
    </svg>
  );
};

export default DeleteIcon;
