import React, { useEffect, useState } from "react";
import { DefaultTheme, ThemeProvider } from "styled-components";

declare module "styled-components" {
  export interface DefaultTheme {
    fonts: { [key: string]: any };
    colors: { [key: string]: any };
  }
}

export const DEFAULT_THEME: DefaultTheme = {
  colors: {
    background: {
      content: "#FFFFFF",
      inactive: "#E0E0E0",
      base: "#F0F0F0",
      inverse: "#28034E",
      inverseLight: "#4B1583",
      brand: "#731DCF",
      orange: "#FA9C51",
      lightGreen: "#6DB278",
      lightYellow: "#FED779",
      lightRed: "#DD5D5D",
      brandLight: "#EFE6F9",
      negative: {
        vibrant: "#D53434",
        light: "#FFEBEF",
      },
      warning: {
        vibrant: "#FEC02D",
        light: "#FFF7E5",
      },
      positive: {
        vibrant: "#1A872C",
        light: "#E6F4E7",
      },
      whatsappChatWindow: "#fff6ed",
    },
    scrim: {
      standard: "#00000099",
    },
    surface: {
      standard: "#FFFFFF",
      subdued: "#FAFAFA",
      hovered: "#F0F0F0",
    },
    content: {
      primary: "#212121",
      secondary: "#616161",
      placeholder: "#9E9E9E",
      inactive: "#BDBDBD",
      brand: "#731DCF",
      primaryInverse: "#FFFFFF",
      secondaryInverse: "#ffffffcc",
      negative: "#B92321",
      warning: "#99631B",
      positive: "#00580E",
    },
    stroke: {
      primary: "#E0E0E0",
      brandLight: "#ECDBFF",
      brand: "#731DCF",
      orange: "#F98325",
      liteGreen: "#489F56",
      liteYellow: "#FECD57",
      negative: {
        vibrant: "#D53434",
        lightAlt: "#FFCED4",
      },
      warning: {
        lightAlt: "#FFEABE",
        vibrant: "#FEC02D",
      },
      positive: {
        lightAlt: "#C3E4C4",
      },
    },
    special: {
      whatsapp: {
        brand: "#25D366",
        gradient: "linear-gradient(360deg, #20B038 0%, #60D66A 100%)",
        dark: "#455A64",
        gradientArr: ["#20B038", "#60D66A"],
      },
      brand: {
        google: "#DD4B39",
        facebook: "#1877F2",
      },
    },
  },
  fonts: {
    titleLarge: {
      fontWeight: 600,
      fontSize: "28px",
      lineHeight: "40px",
    },
    titleMedium: {
      fontWeight: 600,
      fontSize: "20px",
      lineHeight: "28px",
    },
    titleRegular: {
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "24px",
    },
    titleSmall: {
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "20px",
    },
    display: {
      fontSize: "32px",
      fontWeight: 400,
      lineHeight: "40px",
    },
    bodyPrimary: {
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
    },
    bodySecondary: {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
    },
    bodyCaption: {
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "16px",
    },
    bodyTiny: {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "20px",
    },
    buttonLarge: {
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "20px",
    },
    buttonSmall: {
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "16px",
    },
    buttonRegular: {
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "20px",
    },
  },
};

const Theme: React.FC<DefaultTheme> = (props) => {
  const [defaultTheme, setDefaultTheme] = useState<DefaultTheme>(DEFAULT_THEME);

  useEffect(() => {
    if (props.colors) {
      setDefaultTheme((prevState) => ({
        ...prevState,
        colors: { ...prevState.colors, ...props.colors },
      }));
    }
    if (props.fonts) {
      setDefaultTheme((prevState) => ({
        ...prevState,
        fonts: { ...prevState.fonts, ...props.fonts },
      }));
    }
  }, []);

  return <ThemeProvider theme={defaultTheme as DefaultTheme}></ThemeProvider>;
};

export default Theme;
