import {
  BodyPrimary,
  FeatureModalV2,
  StyledModal,
  TitleMedium,
} from "@bikdotai/bik-component-library";
import { useFetchRestrictedFeature } from "../../utilities/useFetchRestrictedFeature";
import { bikRestrictedFeatureSliceActions } from "../../state/bikFeatureRestrictState";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getCookie, setCookie } from "../../app/helper/cookieHelper";
import { SlackNotification } from "../../utilities/slackNotification";
import { UserI } from "../../utilities/RecordEventHelpers";
import CheckIconGreenCopy from "../../icons/checkIconGreen copy";

export const FeatureModals: React.FC = () => {
  const { bikRestrictionProps, isBlockingThankYouModalOpen } =
    useFetchRestrictedFeature();
  const dispatch = useDispatch();
  const [isOpenFeatureRestrictModal, setIsOpenFeatureRestrictModal] =
    useState(false);
  const [isThankYouModalOpen, setIsThankYouModalOpen] = useState(false);

  useEffect(() => {
    setIsOpenFeatureRestrictModal(!!bikRestrictionProps?.isEnabled);
  }, [bikRestrictionProps?.isEnabled]);

  useEffect(() => {
    setIsThankYouModalOpen(!!isBlockingThankYouModalOpen);
  }, [isBlockingThankYouModalOpen]);

  const handleCloseFeatureModal = () => {
    dispatch(
      bikRestrictedFeatureSliceActions.setBikRestrictionProps({
        isEnabled: false,
      })
    );
  };

  const onConnectSupport = (type: string) => {
    sendSlackMessageWithRateLimit({
      uniqueKey:
        bikRestrictionProps?.uniqueIdAsFeatureOrSubFeatureName || "featureKey",
      title: bikRestrictionProps?.title || "",
    });
    dispatch(
      bikRestrictedFeatureSliceActions.setBikRestrictionProps({
        isEnabled: false,
      })
    );
    if (type == "cancel") {
      return;
    }
    setIsThankYouModalOpen(true);
  };

  const handleThankYouModal = () => {
    setIsThankYouModalOpen(false);
    dispatch(bikRestrictedFeatureSliceActions.setBlockingThankYouModal(false));
  };

  const sendSlackMessageWithRateLimit = async (extraMeta: {
    uniqueKey: string;
    title: string;
  }) => {
    const currentUser = JSON.parse(
      localStorage.getItem("Bik/CurrentUser") || "{}"
    ) as UserI;
    const messageToSend = `The user is interested in upgrading the feature, clicked modal title :  ${extraMeta?.title}, featureName : ${extraMeta?.uniqueKey}`;
    const keyToRateLimit = extraMeta?.uniqueKey;
    const customerDetails = `,name : ${currentUser?.name} ,email : ${currentUser?.email}, storeId : ${currentUser.storeId}`;
    const EXPIRATION_TIME_IN_DAYS = 0.0417; // 1 hour in days

    const isSlackMessageAlreadySentInThePastHour =
      keyToRateLimit && getCookie(keyToRateLimit)
        ? JSON.parse(getCookie(keyToRateLimit)) === true
        : false;

    if (!isSlackMessageAlreadySentInThePastHour) {
      const slackNotification = new SlackNotification();
      slackNotification.sendSlackMessageRequest(
        `${messageToSend} ${customerDetails}`
      );

      if (keyToRateLimit) {
        setCookie(
          keyToRateLimit,
          JSON.stringify(true),
          EXPIRATION_TIME_IN_DAYS
        );
      }
    }
  };

  return (
    <>
      {isOpenFeatureRestrictModal && (
        <FeatureModalV2
          onClose={handleCloseFeatureModal}
          onUpgrade={() => onConnectSupport("fromFeature")}
          zIndex={12}
          {...bikRestrictionProps}
        />
      )}

      {isThankYouModalOpen && (
        <StyledModal
          open={true}
          closeOnOutsideClick={false}
          onClose={handleThankYouModal}
          centralContainerStyles={{
            width: "400px",
          }}
          id="thankYouModal"
        >
          <div
            style={{
              height: 250,
              padding: 24,
              gap: 8,
            }}
            className="flex flex-column all--center"
          >
            <CheckIconGreenCopy
              width={64}
              height={64}
              style={{ marginBottom: 16 }}
            />

            <TitleMedium className="align--text">{"Thank you"}</TitleMedium>
            <BodyPrimary className="align--text">
              {
                "for reaching out to our support team! We’re here to help and will get back to you shortly"
              }
            </BodyPrimary>
          </div>
        </StyledModal>
      )}
    </>
  );
};
