import { DATA_SET } from "../../ui-components/dropdown";

export const channelTypeList: DATA_SET[] = [
  {
    id: 0,
    header: "whatsapp",
    choice: "whatsapp",
  },
  {
    id: 0,
    header: "instagram",
    choice: "instagram",
  },
  {
    id: 0,
    header: "email",
    choice: "email",
  },
];

export const NotAnEvent: { [key: string]: string } = {
  whatsAppCart: "whatsAppCart",
  storyReply: "storyReply",
  storyMention: "storyMention",
  instaComments: "instaComments",
};
