import { useEffect, useState } from "react";
import { fetchInstaIds } from "../../services/helpers/QueryBuilderHelper";
import {
  BodySecondary,
  COLORS,
  Dropdown,
  DropdownPopover,
} from "@bikdotai/bik-component-library";
import { DropdownOption } from "@bikdotai/bik-component-library/dist/esm/components/dropdown/type";
import InstagramIcon from "../../icons/instagramIcon";
import ArrowDown from "../../icons/arrowDown";
type Option = {
  label: string;
  value: string;
  selected: boolean;
};

const InstaIdsDropdown = (props: {
  storeId: string;
  instaId?: string;
  setSelectedInsta: React.Dispatch<
    React.SetStateAction<
      | {
          label: string;
          value: string;
          selected: boolean;
        }
      | undefined
    >
  >;
}) => {
  const [instaIds, setInstaIds] = useState<Option[]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);

  const populateInstaIds = async () => {
    setLoaded(false);
    const resp = await fetchInstaIds({ storeId: props.storeId });
    if (!Object.keys(resp).length) {
      props.setSelectedInsta({
        label: "ERROR",
        value: "ERROR",
        selected: true,
      });
      setLoaded(true);
      return;
    }
    const instaResp = resp.data.instagram;

    const instaIds = instaResp.map((item: any, i: number) => {
      if (!props.instaId && i === 0) {
        const obj = {
          label: instaResp[0].accountName,
          value: instaResp[0].accountId,
          selected: true,
        };
        props.setSelectedInsta(obj);
        return obj;
      }
      if (item.accountId === props.instaId) {
        const obj = {
          label: item.accountName,
          value: item.accountId,
          selected: true,
        };
        props.setSelectedInsta(obj);
        return obj;
      }
      return {
        label: item.accountName,
        value: item.accountId,
        selected: false,
      };
    });

    setInstaIds(instaIds);
    setLoaded(true);
  };

  useEffect(() => {
    populateInstaIds().then().catch();
  }, []);

  const getLabel = () => {
    const selected = instaIds.filter((item) => item.selected);
    if (!selected.length) {
      props.setSelectedInsta({
        label: "ERROR",
        value: "ERROR",
        selected: true,
      });
      return "Select an account";
    }
    return selected[0].label;
  };

  return loaded ? (
    <div
      style={{
        pointerEvents: "auto",
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <DropdownPopover
        options={instaIds}
        width="fit-content"
        placement="bottom"
        strategy="fixed"
        onSelect={(option: DropdownOption | DropdownOption[]) => {
          let instaIdsCopy = [...instaIds];

          instaIdsCopy = instaIdsCopy.map((item) => {
            if (
              item.value === (option as unknown as Option).value &&
              (option as unknown as Option).selected
            ) {
              return {
                ...item,
                selected: true,
              };
            } else {
              return {
                ...item,
                selected: false,
              };
            }
          });

          if (!instaIdsCopy.filter((item) => item.selected).length) {
            instaIdsCopy[0].selected = true;
          }

          props.setSelectedInsta(
            instaIdsCopy.filter((item) => item.selected)[0]
          );

          setInstaIds(instaIdsCopy);
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 8,
            borderRadius: 4,
            border: `1px solid ${COLORS.stroke.primary}`,
            padding: "4px 4px 4px 4px",
          }}
        >
          <InstagramIcon />
          <BodySecondary>{getLabel()}</BodySecondary>
          <ArrowDown />
        </div>
      </DropdownPopover>
    </div>
  ) : (
    <></>
  );
};

export default InstaIdsDropdown;
