// @ts-nocheck

import { unCamelCase } from "../../utilities/unCamelCase";
import { fetchIgAsTriggers } from "../../services/helpers/QueryBuilderHelper";

export const extractHeaderAndSubHeader = (builderState: any) => {
  const node = builderState.nodes[0];
  let header = unCamelCase(node.metas[0].value);
  let subHeader = "";
  node.properties.forEach((property, index) => {
    if (
      property.dataType === "specificStory" ||
      property.dataType === "specificReel" ||
      property.dataType === "specificPost" ||
      property.dataType === "anyIg" ||
      property.dataType === "anyAd"
    )
      return;
    let str = "";
    // Todo: remove this check once campaign fixes this
    if (
      !!node.metas.length &&
      (node.metas[0].value === "abandonedAProduct" ||
        node.metas[0].value === "productViewed") &&
      property.name === "productId"
    ) {
      str += unCamelCase("productName");
    } else {
      str += unCamelCase(property.name);
    }

    str += property.operator ? " " + unCamelCase(property.operator) : "";
    if (Array.isArray(property.value)) {
      property.value.forEach((v, i) => {
        if (typeof v === "undefined") return;
        if (typeof v.name === "undefined") {
          str += " " + v + `${i === property.value.length - 1 ? "" : ","}`;
        } else {
          str += " " + v.name + `${i === property.value.length - 1 ? "" : ","}`;
        }
      });
    } else {
      str += property.value ? " " + property.value : "";
    }
    if (node.type === "IG_TRIGGER" && index === 0) {
      str += `${index === node.properties.length - 2 ? "" : " , "}`;
    } else {
      if (node.properties.find((item) => item.dataType === "anyAd")) {
        // DO NOTHING
      } else {
        str += `${index === node.properties.length - 1 ? "" : " , "}`;
      }
    }
    subHeader += str;
  });
  return { header, subHeader, id: node._id };
};

export const extractIgMedia = (builderState: any) => {
  const node = builderState.nodes[0];
  let media = [];
  let mediaType = "";
  let tag = "";
  node.properties.forEach((property, index) => {
    if (
      property.dataType === "specificStory" ||
      property.dataType === "specificReel" ||
      property.dataType === "specificPost"
    ) {
      mediaType = extractMediaType(property.name);
      property.value.forEach((v) => {
        media.push(v);
      });
      tag = `${media.length} ${mediaType}(s)`;
    } else if (property.dataType === "anyIg" || property.dataType === "anyAd") {
      tag = unCamelCase(property.name);
    }
  });
  return { media, mediaType, tag };
};

export const extractMediaType = (name: string) => {
  if (name === "specificPosts") {
    return "post";
  } else if (name === "specificReels") {
    return "reel";
  } else {
    return "story";
  }
};

export const eventsTriggerApiHandler = async (
  payload: BaseQueryBuilderPayload<
    | undefined
    | { triggerName: string }
    | { triggerName: string; propertyName: string }
  >,
  storeId: string,
  instagramId: string
) => {
  if (!payload.query) {
    const response = await fetchIgAsTriggers({
      storeId: storeId,
      instagramId,
    });

    if (response.data) {
      return response.data;
    }
  }
  if (payload.query?.triggerName && !payload.query?.propertyName) {
    const response = await fetchIgAsTriggers({
      storeId: storeId,
      instagramId,
      ...payload,
    });
    if (response.data) {
      return response.data;
    }
  }
  if (payload.query?.triggerName && payload.query?.propertyName) {
    const response = await fetchIgAsTriggers({
      storeId: storeId,
      instagramId,
      ...payload,
    });
    if (response.data && response.data.data) {
      return response.data;
    }
  }
  return { status: 200 };
};

export const populateChannelIdsDropdown = async (
  channel: string,
  channels: any,
  setIsLoading: (value: boolean) => void,
  setSelectedChannel: (obj: any) => void,
  setChannelIdOptions: (options: any) => void,
  currentChannelId?: string
) => {
  setIsLoading(true);
  if (channel === "instagram") {
    const instaResp = channels.instagram;
    let isSelectChannel = false;

    const instaIds = instaResp.map((item: any, i: number) => {
      if (currentChannelId === item.accountId) {
        const obj = {
          label: item.accountName,
          value: item.accountId,
          selected: true,
        };
        isSelectChannel = true;
        setSelectedChannel(obj);
        return obj;
      } else {
        return {
          label: item.accountName,
          value: item.accountId,
          selected: false,
        };
      }
    });
    setChannelIdOptions(instaIds);
  } else if (channel === "whatsapp") {
    const whatsappResp = channels.multiWhatsappInfo;

    const whatsappIds = whatsappResp?.map((item: any, i: number) => {
      if (currentChannelId === item.phoneNumberId) {
        const obj = {
          label: item.accountNumber,
          value: item.phoneNumberId,
          selected: true,
        };
        setSelectedChannel(obj);
        return obj;
      } else {
        return {
          label: item.accountNumber,
          value: item.phoneNumberId,
          selected: false,
        };
      }
    });
    setChannelIdOptions(whatsappIds);
  } else if (channel === "email") {
    const emailResp = channels.multiChannelEmail;
    let isSelectChannel = false;

    const emailIds = emailResp?.map((email: any, i: number) => {
      if (currentChannelId === email) {
        const obj = {
          label: email,
          value: email,
          selected: true,
        };
        isSelectChannel = true;
        setSelectedChannel(obj);
        return obj;
      } else {
        return {
          label: email,
          value: email,
          selected: false,
        };
      }
    });
    setChannelIdOptions(emailIds);
  }
  setIsLoading(false);
};

export const getChannelIdName = (
  channel: string,
  channels: any,
  trigger: any
) => {
  let displayName = "";
  if (trigger.channelId && !trigger.isDuplicateChannelId) {
    if (channel === "instagram") {
      displayName = channels?.instagram?.find(
        (channel) => channel.accountId === trigger.channelId
      )?.accountName;
    } else if (channel === "whatsapp") {
      displayName = channels?.multiWhatsappInfo?.find(
        (channel: any) => channel.phoneNumberId === trigger.channelId
      )?.accountNumber;
    } else if (channel === "email") {
      displayName = channels?.multiChannelEmail?.find(
        (email: any) => email === trigger.channelId
      );
    }
  } else if (
    channel === "instagram" &&
    trigger.instaId &&
    !trigger.isDuplicateChannelId
  ) {
    displayName = channels?.instagram?.find(
      (channel) => channel.accountId === trigger.instaId
    )?.accountName;
  }
  return displayName;
};

export const hasChannelError = (
  channel: string,
  channelId: string,
  channels: any
) => {
  let hasError = false;
  if (!channelId || Object.keys(channels).length === 0) {
    return hasError;
  }
  if (channel === "instagram") {
    const instaChannel = channels?.instagram?.find(
      (channel) => channel.accountId === channelId
    );
    if (!instaChannel) {
      hasError = true;
    }
  } else if (channel === "whatsapp") {
    const whatsappChannel = channels?.multiWhatsappInfo?.find(
      (channel: any) => channel.phoneNumberId === channelId
    );
    if (!whatsappChannel) {
      hasError = true;
    }
  } else if (channel === "email") {
    const emailChannel = channels?.multiChannelEmail?.find(
      (email: any) => email === channelId
    );
    if (!emailChannel) {
      hasError = true;
    }
  }
  return hasError;
};
