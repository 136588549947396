
import { Icon } from "./icons-props";

const AiAskIcon: Icon = (props) => {
  const { width, height } = props;
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.6563 5.84768C10.3653 5.79776 10.066 5.85245 9.81146 6.00206C9.55689 6.15167 9.36349 6.38655 9.26551 6.66509C9.11278 7.09925 8.63702 7.32739 8.20286 7.17466C7.7687 7.02194 7.54055 6.54617 7.69328 6.11202C7.92188 5.46218 8.37309 4.91421 8.96699 4.56517C9.56089 4.21613 10.2592 4.08854 10.9381 4.205C11.6171 4.32146 12.2329 4.67445 12.6765 5.20146C13.1201 5.72834 13.3629 6.39517 13.362 7.08387C13.3616 8.22037 12.5202 8.95847 11.949 9.33921C11.6381 9.54653 11.333 9.69847 11.109 9.79803C10.9958 9.84834 10.9003 9.88659 10.8312 9.91293C10.7965 9.92613 10.7683 9.93641 10.7475 9.94379L10.7221 9.95271L10.7138 9.95555L10.7107 9.95657L10.7095 9.95698L10.7085 9.95732C10.2719 10.1029 9.79994 9.86689 9.6544 9.43027C9.50895 8.99394 9.74452 8.52233 10.1806 8.37646L10.1892 8.37343C10.199 8.36996 10.2155 8.36396 10.2378 8.35545C10.2826 8.33838 10.3499 8.31152 10.4321 8.27501C10.5988 8.20094 10.8146 8.09242 11.0245 7.95246C11.4951 7.63874 11.6953 7.33526 11.6953 7.0831V7.08186C11.6957 6.78659 11.5916 6.50069 11.4015 6.27479C11.2113 6.0489 10.9474 5.8976 10.6563 5.84768Z"
        fill="url(#paint0_linear_10260_63028)"/>
      <path
        d="M10.4998 10.8334C10.0396 10.8334 9.6665 11.2065 9.6665 11.6667C9.6665 12.127 10.0396 12.5001 10.4998 12.5001H10.5082C10.9684 12.5001 11.3415 12.127 11.3415 11.6667C11.3415 11.2065 10.9684 10.8334 10.5082 10.8334H10.4998Z"
        fill="url(#paint1_linear_10260_63028)"/>
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M2.89874 2.39898C3.36758 1.93014 4.00346 1.66675 4.6665 1.66675H16.3332C16.9962 1.66675 17.6321 1.93014 18.1009 2.39898C18.5698 2.86782 18.8332 3.50371 18.8332 4.16675V12.5001C18.8332 13.1631 18.5698 13.799 18.1009 14.2678C17.6321 14.7367 16.9962 15.0001 16.3332 15.0001H6.67835L3.58909 18.0893C3.35076 18.3277 2.99233 18.399 2.68093 18.27C2.36954 18.141 2.1665 17.8371 2.1665 17.5001V4.16675C2.1665 3.50371 2.4299 2.86782 2.89874 2.39898ZM4.6665 3.33341C4.44549 3.33341 4.23353 3.42121 4.07725 3.57749C3.92097 3.73377 3.83317 3.94573 3.83317 4.16675V15.4882L5.74391 13.5775C5.9002 13.4212 6.11216 13.3334 6.33317 13.3334H16.3332C16.5542 13.3334 16.7661 13.2456 16.9224 13.0893C17.0787 12.9331 17.1665 12.7211 17.1665 12.5001V4.16675C17.1665 3.94573 17.0787 3.73377 16.9224 3.57749C16.7661 3.42121 16.5542 3.33341 16.3332 3.33341H4.6665Z"
            fill="url(#paint2_linear_10260_63028)"/>
      <defs>
        <linearGradient id="paint0_linear_10260_63028" x1="2.1665" y1="3.05564" x2="19.5128" y2="3.87077"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF166A"/>
          <stop offset="1" stop-color="#FB5F08"/>
        </linearGradient>
        <linearGradient id="paint1_linear_10260_63028" x1="2.1665" y1="3.05564" x2="19.5128" y2="3.87077"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF166A"/>
          <stop offset="1" stop-color="#FB5F08"/>
        </linearGradient>
        <linearGradient id="paint2_linear_10260_63028" x1="2.1665" y1="3.05564" x2="19.5128" y2="3.87077"
                        gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF166A"/>
          <stop offset="1" stop-color="#FB5F08"/>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default AiAskIcon;


