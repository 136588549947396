import { DATA_SET } from "../../ui-components/dropdown";

export const Actions: DATA_SET[] = [
  { id: 1, header: "Start a flow" },
  { id: 2, header: "Store to a variable" },
  { id: 3, header: "Run" },
  { id: 4, header: "Assign an agent" },
  { id: 5, header: "Add an event" },
  { id: 6, header: "Call an API" },
  { id: 7, header: "Fetch product" },
  { id: 8, header: "Fetch catalog" },
  { id: 9, header: "AB test" },
  { id: 10, header: "Call Custom API" },
  { id: 11, header: "Store to a variable evaluation" },
  { id: 12, header: "Delay" },
];

export const LoopActions: DATA_SET[] = [
  { id: 1, header: "None" },
  { id: 2, header: "List of products" },
  { id: 3, header: "List of Collection" },
  { id: 4, header: "Search Products" },
  { id: 5, header: "List of Orders" },
];

export const AgentTypes: DATA_SET[] = [
  { id: 1, header: "Sales" },
  { id: 2, header: "Support" },
];

export const EventTypes: DATA_SET[] = [
  { id: 1, header: "order created" },
  { id: 2, header: "order status updated" },
  { id: 3, header: "new user created" },
  { id: 4, header: "catalog viewed" },
  { id: 5, header: "all catalog viewed" },
  { id: 6, header: "product viewed" },
  { id: 7, header: "add to cart" },
  { id: 8, header: "product searched" },
  { id: 9, header: "purchased" },
  { id: 10, header: "abandoned cart created" },
  { id: 11, header: "abandoned cart recovered" },
  { id: 12, header: "unsubscribe" },
  { id: 12, header: "lead qualified" },
  { id: 13, header: "customer data acquired" },
];

export const MessageType: DATA_SET[] = [
  { id: "list", header: "Show as list" },
  { id: "single", header: "Show Single Message" },
];

export const DelayType: DATA_SET[] = [
  { id: "mins", header: "mins" },
  { id: "hrs", header: "hrs" },
  { id: "days", header: "days" },
];

export const qrbData = [
  { value: "😀 Good", sentiment: "Positive" },
  { value: "😐 Okay", sentiment: "Neutral" },
  { value: "😔 Bad", sentiment: "Negative" },
];

export const npsFullData = [
  { value: "1", sentiment: "Negative" },
  { value: "2", sentiment: "Negative" },
  { value: "3", sentiment: "Negative" },
  { value: "4", sentiment: "Negative" },
  { value: "5", sentiment: "Neutral" },
  { value: "6", sentiment: "Neutral" },
  { value: "7", sentiment: "Positive" },
  { value: "8", sentiment: "Positive" },
  { value: "9", sentiment: "Positive" },
  { value: "10", sentiment: "Positive" },
];

export const npsFullDataEmoji = [
  { value: "😡 1: Terrible", sentiment: "Negative" },
  { value: "😠 2: Very Poor", sentiment: "Negative" },
  { value: "😞 3: Poor", sentiment: "Negative" },
  { value: "😟 4: Below Average", sentiment: "Negative" },
  { value: "😕 5: Average", sentiment: "Neutral" },
  { value: "😐 6: Satisfactory", sentiment: "Neutral" },
  { value: "🙂 7: Good", sentiment: "Positive" },
  { value: "😊 8: Very Good", sentiment: "Positive" },
  { value: "😀 9: Excellent", sentiment: "Positive" },
  { value: "😍 10: Outstanding", sentiment: "Positive" },
];

export const npsFullDataReverseEmoji = [
  { value: "😍 10: Outstanding", sentiment: "Positive" },
  { value: "😀 9: Excellent", sentiment: "Positive" },
  { value: "😊 8: Very Good", sentiment: "Positive" },
  { value: "🙂 7: Good", sentiment: "Positive" },
  { value: "😐 6: Satisfactory", sentiment: "Neutral" },
  { value: "😕 5: Average", sentiment: "Neutral" },
  { value: "😟 4: Below Average", sentiment: "Negative" },
  { value: "😞 3: Poor", sentiment: "Negative" },
  { value: "😠 2: Very Poor", sentiment: "Negative" },
  { value: "😡 1: Terrible", sentiment: "Negative" },
];

export const npsFullDataReverse = [
  { value: "10", sentiment: "Positive" },
  { value: "9", sentiment: "Positive" },
  { value: "8", sentiment: "Positive" },
  { value: "7", sentiment: "Positive" },
  { value: "6", sentiment: "Neutral" },
  { value: "5", sentiment: "Neutral" },
  { value: "4", sentiment: "Negative" },
  { value: "3", sentiment: "Negative" },
  { value: "2", sentiment: "Negative" },
  { value: "1", sentiment: "Negative" },
];

export const npsHalfData = [
  { value: "1", sentiment: "Negative" },
  { value: "2", sentiment: "Negative" },
  { value: "3", sentiment: "Neutral" },
  { value: "4", sentiment: "Positive" },
  { value: "5", sentiment: "Positive" },
];

export const npsHalfDataEmoji = [
  { value: "😡 1: Very Poor", sentiment: "Negative" },
  { value: "😞 2: Poor", sentiment: "Negative" },
  { value: "😐 3: Average", sentiment: "Neutral" },
  { value: "😊 4: Excellent", sentiment: "Positive" },
  { value: "😍 5: Outstanding", sentiment: "Positive" },
];

export const npsHalfDataReverseEmoji = [
  { value: "😍 5: Outstanding", sentiment: "Positive" },
  { value: "😊 4: Excellent", sentiment: "Positive" },
  { value: "😐 3: Average", sentiment: "Neutral" },
  { value: "😞 2: Poor", sentiment: "Negative" },
  { value: "😡 1: Very Poor", sentiment: "Negative" },
];

export const npsHalfDataReverse = [
  { value: "5", sentiment: "Positive" },
  { value: "4", sentiment: "Positive" },
  { value: "3", sentiment: "Neutral" },
  { value: "2", sentiment: "Negative" },
  { value: "1", sentiment: "Negative" },
];

export const nps1to3 = [
  { value: "1", sentiment: "Negative" },
  { value: "2", sentiment: "Neutral" },
  { value: "3", sentiment: "Positive" },
];

export const emailCsatIdMapping: Record<string, any[]> = {
  "bik-1-10": npsFullData,
  "bik-1-5": npsHalfData,
  "bik-5-1": npsHalfDataReverse,
  "bik-10-1": npsFullDataReverse,
  "bik-1-3": nps1to3,
};

export const sentiments = ["Negative", "Neutral", "Positive"];
export const sentimentsReverse = ["Positive", "Neutral", "Negative"];

export const generateUniqueId = () => {
  return Date.now().toString(36);
};

export const generateUniqueIdWithDelay = (delay: number) => {
  return Date.now().toString(36) + delay;
};
