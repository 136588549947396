import { Icon } from "./icons-props";

const InfoIcon: Icon = (props) => {
  const { width, height, color = "#616161" } = props;

  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 19 20"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.50016 9.16634C9.9604 9.16634 10.3335 9.53944 10.3335 9.99967V13.333C10.3335 13.7932 9.9604 14.1663 9.50016 14.1663C9.03992 14.1663 8.66683 13.7932 8.66683 13.333V9.99967C8.66683 9.53944 9.03992 9.16634 9.50016 9.16634Z" />
      <path d="M9.50016 7.49967C9.9604 7.49967 10.3335 7.12658 10.3335 6.66634C10.3335 6.2061 9.9604 5.83301 9.50016 5.83301C9.03992 5.83301 8.66683 6.2061 8.66683 6.66634C8.66683 7.12658 9.03992 7.49967 9.50016 7.49967Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.333496 9.99967C0.333496 4.93706 4.43755 0.833008 9.50016 0.833008C14.5628 0.833008 18.6668 4.93706 18.6668 9.99967C18.6668 15.0623 14.5628 19.1663 9.50016 19.1663C4.43755 19.1663 0.333496 15.0623 0.333496 9.99967ZM9.50016 2.49967C5.35803 2.49967 2.00016 5.85754 2.00016 9.99967C2.00016 14.1418 5.35803 17.4997 9.50016 17.4997C13.6423 17.4997 17.0002 14.1418 17.0002 9.99967C17.0002 5.85754 13.6423 2.49967 9.50016 2.49967Z"
        fill={color}
      />
    </svg>
  );
};

export default InfoIcon;
