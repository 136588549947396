export interface VariableData {
  name: string;
  type: VariableDataTypesEnum;
  defaultValue: any;
}

export interface VariableInfo {
  type: VariableDataTypesEnum;
  defaultValue: any;
}

export enum VariableDataTypesEnum {
  INTEGER = "Integer",
  NUMBER = "Number",
  STRING = "String",
  BOOLEAN = "Boolean",
  NULL = "",
  ANY = "any",
}
