import { useState, useRef, useEffect } from "react";
import { useAppSelector } from "../../state/store";
import { Unsubscribe } from "firebase/firestore";
import { BikStore } from "@bikdotai/bik-models/growth";
import { FirebaseService } from "../../services/firebase/FirebaseService";
import { getAiEnablemnetStatus } from "../../components/header/Utils";

export const AiStatusHook = () => {
  const [doSyncOpen, setDoSyncOpen] = useState(false);
  const [freeCreditsExhaustedModalOpen, setFreeCreditsExhaustedModalOpen] =
    useState(false);
  const [creditsExhaustedModalOpen, setCreditsExhaustedModalOpen] =
    useState(false);
  const [syncStatusModalOpen, setSyncStatusModalOpen] = useState(false);
  const [initAiModal, setInitAiModal] = useState(false);
  const [freeCreditsText, setFreeCreditsText] = useState("");
  const [shopifySyncPercent, setShopifySyncPercent] = useState<number>(0);
  const [isShopifySynced, setIsShopifySynced] = useState<boolean>(false);
  const storeInfoState = useAppSelector((state) => state.storeInfoState);
  const [aiEnablementStatus, setAiEnablementStatus] = useState<{
    syncStatus: string;
    currentBalance: number;
    isPaidUser: boolean;
  }>();
  const unSubFunc = useRef<Unsubscribe | undefined>(undefined);
  const flowState = useAppSelector((state) => state.storeState.flow);
  const homeState = useAppSelector((state) => state.homeState);

  const validateAiSync = () => {
    const hasAIBlocks = Object.values(flowState).some(
      (node) => node?.type === "AI"
    );
    const hasProductRecBlocks = Object.values(flowState).some(
      (node) => node?.sub_type === "ai_product_recommend"
    );

    let isPublishingBlocked = false;

    if (hasAIBlocks) {
      let productSyncIssue = true;

      if (hasProductRecBlocks) {
        if (aiEnablementStatus?.syncStatus === "NOT_SYNCED") {
          setDoSyncOpen(true);
          isPublishingBlocked = true;
        } else if (aiEnablementStatus?.syncStatus === "IN_PROGRESS") {
          setSyncStatusModalOpen(true);
          const unsubscribeFn = new FirebaseService(
            () => {},
            () => {}
          ).subscribeToShopifySync(homeState.storeId, handleShopifySync);
          unSubFunc.current = unsubscribeFn;
          isPublishingBlocked = true;
        } else {
          productSyncIssue = false;
        }
      }

      if (!aiEnablementStatus?.currentBalance && !productSyncIssue) {
        if (aiEnablementStatus?.isPaidUser) {
          setCreditsExhaustedModalOpen(true);
          isPublishingBlocked = true;
        } else {
          setFreeCreditsExhaustedModalOpen(true);
          isPublishingBlocked = true;
        }
      }
    }

    return isPublishingBlocked;
  };

  useEffect(() => {
    if (
      homeState?.storeId &&
      Object.keys(flowState).length > 1 &&
      !initAiModal
    ) {
      getAiEnablemnetStatus(homeState?.storeId).then((res) => {
        setAiEnablementStatus(res);
        const hasAIBlocks = Object.values(flowState).some(
          (node) => node?.type === "AI"
        );
        if (hasAIBlocks && !res?.currentBalance && homeState.status) {
          if (res?.isPaidUser) {
            setCreditsExhaustedModalOpen(true);
          } else {
            setFreeCreditsExhaustedModalOpen(true);
          }
        }
        setInitAiModal(true);
      });
    }
  }, [homeState?.storeId, flowState]);

  const handleShopifySync = async (
    unsubscribe: Unsubscribe | null,
    latestData: BikStore
  ) => {
    if (
      latestData?.storeIntegrationStatus?.partnerSyncStatus
        ?.instagramProductSync === "COMPLETED"
    ) {
      setShopifySyncPercent(100);
      setIsShopifySynced(true);
      setTimeout(() => {
        setSyncStatusModalOpen(false);
      }, 1000);
      unsubscribe!();
    } else {
      const totalProductCount =
        (storeInfoState.productCount ||
          latestData?.storeIntegrationStatus?.totalProducts) ??
        0;
      if (
        !totalProductCount ||
        !latestData.storeIntegrationStatus?.syncedProducts
      )
        setShopifySyncPercent(0);
      else {
        const percent =
          (latestData.storeIntegrationStatus?.syncedProducts /
            totalProductCount) *
          100;
        setShopifySyncPercent(percent);
      }
    }
  };

  return {
    validateAiSync,
    handleShopifySync,
    doSyncOpen,
    setDoSyncOpen,
    freeCreditsExhaustedModalOpen,
    setFreeCreditsExhaustedModalOpen,
    creditsExhaustedModalOpen,
    setCreditsExhaustedModalOpen,
    syncStatusModalOpen,
    setSyncStatusModalOpen,
    shopifySyncPercent,
    setShopifySyncPercent,
    unSubFunc,
  };
};
