import styled from "styled-components";

export const AdminTableStyles = styled.div`
  padding: 16px;
  h2 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  table {
    margin-top: 16px;
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #e7e7e7;

    th {
      padding: 8px;
      border: 1px solid #e7e7e7;
      text-align: left;
      width: 300px;
      max-width: 300px; /* add this */
    }

    td {
      font-size: 8px;
      width: 300px;
      max-width: 300px; /* add this */
      overflow: hidden;
      padding: 8px;
      border: 1px solid #e7e7e7;
      text-align: left;

      button {
        margin-right: 8px;
      }
    }
  }

  button {
    marign-left: 16px;
  }
`;
