import React, { LegacyRef } from "react";
import { DateWrapper, DateLabel } from "../BotInput.style";
import { InputDataProps } from "../InputModel";
export interface DateInputProps extends InputDataProps {
  inputEl?: LegacyRef<HTMLInputElement>;
}

const DateInput = (props: DateInputProps) => {
  return (
    <React.Fragment>
      <DateWrapper name={props.name}>
        <DateLabel {...props}>
          {props.placeHolder}
          {props.isRequired ? "*" : null}
        </DateLabel>

        <input
          name={props.name}
          ref={props.inputEl}
          type="date"
          onChange={props.onChange}
          disabled={props.isDisabled}
          onBlur={props.onBlur}
        />
      </DateWrapper>
    </React.Fragment>
  );
};

export default DateInput;
