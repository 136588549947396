import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import EditIcon from "../../icons/editIcon";
import { homeDataAction } from "../../state/homeState";
import { useAppSelector } from "../../state/store";
import ErrorBar from "../error-bar";
import { HeaderStyled, TestModeContainer } from "./style";
import SummaryAnalytics from "./SummaryAnalytics";
import {
  BodyCaption,
  BodySecondary,
  Button,
  ButtonTypes,
  COLORS,
  IconButton,
  MenuItemDropdown,
  StateInterface,
  StateModalComponent,
  Switch,
  TitleSmall,
  Toaster,
  Tooltip,
} from "@bikdotai/bik-component-library";
import {
  GetLastUpdatedMessage,
  getFlowDndSettings,
  getTestModeUsers,
  setDndSettings,
  getGlobalSpamSettings,
  setFlowFreqCapStatus,
} from "./Utils";
import { useDetectAnalytics } from "../../ui-components/flow-builder/useDetectAnalytics";
import TestModeIcon from "../../icons/testMode";
import TestModeTooltip from "./testModeTooltip";
import ProductTourIcon from "../../icons/productTour";
import { NodeActionActions } from "../../state/nodeActionState";
import AnalyticsIcon from "../../icons/analyticsIcon";
import JourneysIcon from "../../icons/journeysIcon";
import { dasboardUrl } from "../../config";
import { ConfirmationModal } from "../../ui-components/confirmation-modal";
import CloudOfflineIcon from "../../icons/cloudOfflineIcon";
import { builderStateActions } from "../../state/builderState";
import {
  DropdownOption,
  SingleOption,
} from "@bikdotai/bik-component-library/dist/esm/components/dropdown/type";
import {
  getAlreadyAddedDays,
  getFlowEnabledOnDate,
  getLastprofileAdditionDate,
  isPastProfileEligible,
} from "../add-past-profile/helpers/AddPastProfileHelpers";
import { AddPastProfileModal } from "../add-past-profile/AddPastProfile";
import styled from "styled-components";
import {
  FeatureCategory,
  AllModules,
  AllFeatureSubKey,
} from "@bikdotai/bik-models/growth/models/feature";
import { useFetchRestrictedFeature } from "../../utilities/useFetchRestrictedFeature";
import { FeatureModals } from "../../ui-components/feature-restriction-modal/FeatureRestrictionModal";

interface HeaderProps {
  title: string;
  redirect404: boolean;
  onEditClick: () => void;
  onAnalyticsClick: () => void;
  onShowTourClick: () => void;
}

const DndModalContent = {
  enabled: {
    body: "It will not apply for any journey. Disabling it for this journey will still turn off Do not Disturb for it if it is later enabled in settings.",
    button: "Continue to Disable",
  },
  disabled: {
    body: "It will not apply for any journey. Enabling it for this journey will turn on Do not Disturb only once it is also enabled in Settings.",
    button: "Continue to Enable",
  },
};

const FreqCapModalContent = {
  enabled: {
    body: '"It will not apply for any journey. Disabling it for this journey will still turn off Messaging Limits for it if it is later enabled in settings."',
    button: "Continue to Disable",
  },
  disabled: {
    body: '"It will not apply for any journey. Enabling it for this journey will still turn on Messaging Limits for it if it is later enabled in settings."',
    button: "Continue to Enable",
  },
};

const Header = (props: HeaderProps) => {
  const homeState = useAppSelector((state) => state.homeState);
  const flowState = useAppSelector((state) => state.storeState.flow);
  const builderState = useAppSelector((state) => state.builderState);
  const notesState = useAppSelector((state) => state.stickyNotesState.notes);
  const nodeActionState = useAppSelector((state) => state.nodeActionState);
  const flowMetaState = useAppSelector((state) => state.flowMetaState.flowMeta);
  const [isEditPageLoaded, setIsEditPageLoaded] = useState(false);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
  const errorDataState = useAppSelector((state) => state.errorDataState);
  const title = homeState.flowName || "Chatbot Builder";
  const [flowStatus, setFlowStatus] = useState<string>("");
  const dispatcher = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [status, setStatus] = useState<boolean>(homeState.status as boolean);
  const [loader, setLoader] = useState<boolean>(false);
  const [save, setSave] = useState<string>("");
  const [lastSaved, setLastSaved] = useState<string>("");
  const [isTestMode, setIsTestMode] = useState<boolean>(false);
  const [isSunset, setIsSunset] = useState<boolean>(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
  const [isDropdownTooltipVisible, setIsDropdownTooltipVisible] =
    useState<boolean>(false);
  const [showTestModeUserAlert, setShowTestModeUserAlert] =
    useState<boolean>(false);
  const [isRedirectionClicked, setIsRedirectionClicked] = useState(false);
  const [menuOptions, setMenuOptions] = useState<DropdownOption[]>([]);
  const [showAddPastProfileModal, setShowAddPastProfileModal] = useState(false);
  const [alreadyAddedDays, setAlreadyAddedDays] = useState<never>();
  const [lastprofileAdditionDate, setLastProfileAdditionDate] = useState<any>();
  const [dndStatus, setDndStatus] = useState<boolean>(false);
  const [isFlowFreqEnabled, setIsFlowFreqEnabled] = useState<boolean>(false);
  const [showToaster, setShowToaster] = useState(false);
  const [dndToasterType, setDndToasterType] = useState<"success" | "error">();
  const [toasterText, setToasterText] = useState<string>("");
  const [freqCapToasterType, setFreqCapToasterType] = useState<"success" | "error">();
  const [globalDndStatus, setGlobalDndStatus] = useState<boolean>(false);
  const [globalFreqCapStatus, setGlobalFreqCapStatus] = useState<boolean>(false);
  const [showDndModal, setShowDndModal] = useState<boolean>(false);
  const [showFreqCapModal, setShowFreqCapModal] = useState<boolean>(false);
  const storeInfoState = useAppSelector((state) => state.storeInfoState);
  const isAnalytics = useDetectAnalytics();
  const { hasAccessToBikFeatures } = useFetchRestrictedFeature();

  const initializeMenuOptions = async () => {
    const menuItems: DropdownOption[] = [
      {
        label: "Add Past Profiles",
        value: "add_past_profiles",
        subText: "Only for Active flows with Event Based Triggers",
        disabled: !isPastProfileEligible(
          homeState?.channel || "",
          (flowMetaState as any)?.[homeState?.flowId],
          homeState?.status as boolean,
          isTestMode
        ),
      },
    ];
    if ((flowMetaState as any)?.[homeState?.flowId] && (flowMetaState as any)?.[homeState?.flowId]?.globalFreqCapEnabled === false){
       menuItems.push({
         label: "Enable Messaging Limits",
         value: "global_freq_cap",
         subText: "Skips recipients who’ve received a lot of marketing messages already",
         disabled: homeState.journeyType === "utility",
       });
       setIsFlowFreqEnabled(false);
    } else {
       menuItems.push({
            label: "Disable Messaging Limits",
            value: "global_freq_cap",
            subText: "Skips recipients who’ve received a lot of marketing messages already",
            disabled: homeState.journeyType === "utility",
        });
       setIsFlowFreqEnabled(true);
    }
    getGlobalSpamSettings(homeState?.storeId).then((res) => {
      if(res.dndSettings && res.dndSettings.enabled) {
        setGlobalDndStatus(true);
      }
      if (res.globalFreqCap && res.globalFreqCap.enabled) {
        setGlobalFreqCapStatus(true);
      }
    });
    getFlowDndSettings(homeState?.storeId, homeState?.flowId).then((res) => {
      setDndStatus(res?.enabled);
      if (homeState.journeyType === "marketing") {
        menuItems.push({
            label: `${res?.enabled ? "Disable" : "Enable"} Do not Disturb`,
            value: "dnd",
            subText:
                "Pauses and resumes marketing journeys after DND time is over",
        })
      }
    });
    if (
      menuOptions.filter((option: any) => option.value === "add_past_profiles")
        .length === 0
    ) {
      setMenuOptions(menuItems);
    }
  };

  const initializePropsForAddPastProfile = async () => {
    setLastProfileAdditionDate(
      await getLastprofileAdditionDate(homeState?.storeId, homeState?.flowId)
    );
    setAlreadyAddedDays(
      await getAlreadyAddedDays(homeState?.storeId, homeState?.flowId)
    );
  };

  const handleDndStatusToggle = () => {
    if (!globalDndStatus) {
      setShowDndModal(true);
    } else {
      updateDndStatus();
    }
  };

  const handleFlowFreqCapStatusToggle = () => {
    if (!globalFreqCapStatus) {
      setShowFreqCapModal(true);
    } else {
      updateFlowFreqCapEnabled();
    }
  };

  const updateDndStatus = () => {
    setDndSettings(homeState?.storeId, homeState?.flowId, !dndStatus).then(
      (res) => {
        if (res) {
          const option = {
            label: `${dndStatus ? "Enable" : "Disable"} Do not Disturb`,
            value: "dnd",
            subText:
              "Pauses and resumes marketing journeys after DND time is over",
          };
          const menu = [...menuOptions];
          const optIndex = menuOptions.findIndex((option: any) => option.value === "dnd");
          if (optIndex) {
            menu[optIndex] = option;
          }
          setMenuOptions(menu);
          setToasterText(!dndStatus ? 'Do not Disturb is Enabled' : 'Do not Disturb is Disabled')
          setShowToaster(true);
          setDndToasterType("success");
          setDndStatus(!dndStatus);
        } else {
          setShowToaster(true);
          setDndToasterType("error");
          setToasterText('Something went wrong. Please try again')
        }
      }
    );
  };


  const updateFlowFreqCapEnabled = () => {
    setFlowFreqCapStatus(homeState?.storeId, homeState?.flowId, !isFlowFreqEnabled).then(
      (res) => {
        if (res) {
          const option = {
            label: `${isFlowFreqEnabled ? "Enable" : "Disable"} Messaging Limits`,
            value: "global_freq_cap",
            subText:
              "Skips recipients who’ve received a lot of marketing messages already",
          };
          const menu = [...menuOptions];
          const optIndex = menuOptions.findIndex((option: any) => option.value === "global_freq_cap");
          if (optIndex) {
            menu[optIndex] = option;
          }
          setMenuOptions(menu);
          setToasterText(!isFlowFreqEnabled ? 'Messaging Limits Enabled' : 'Messaging Limits Disabled')
          setShowToaster(true);
          setDndToasterType("success");
          setIsFlowFreqEnabled(!isFlowFreqEnabled);
        } else {
          setShowToaster(true);
          setDndToasterType("error");
          setToasterText('Something went wrong. Please try again')
        }
      }
    );
  };

  useEffect(() => {
    if (homeState?.storeId && homeState?.flowId) {
      initializeMenuOptions();
      initializePropsForAddPastProfile();
    }
  }, [flowMetaState, homeState, isTestMode]);

  useEffect(() => {
    if (homeState?.storeId) {
      getUsersForTestMode();
    }
  }, [homeState?.storeId, homeState?.flowId]);

  const hasAccessToBikFeaturesMain = async (featureKey?: AllFeatureSubKey) => {
    const hasAccess = await hasAccessToBikFeatures({
      category: FeatureCategory.module,
      featureName: AllModules.automations,
      subFeatureName: featureKey || AllFeatureSubKey.FLOW_ACTIVATIONS,
    });
    return !!hasAccess;
  };

  const getUsersForTestMode = async () => {
    const resp = await getTestModeUsers(homeState?.storeId);
    if (resp?.success && resp?.data) {
      const { data } = resp;
      const channel = homeState.channel!;
      if (channel && !data?.[channel]?.length) {
        setShowTestModeUserAlert(true);
      }
    }
  };

  useEffect(() => {
    if (
      typeof homeState?.publishFlow === "boolean" &&
      !homeState?.publishFlow &&
      homeState?.compilationStatus === "FAILED"
    ) {
      setIsTestMode(false);
      dispatcher(
        homeDataAction.addState({
          ...homeState,
          testMode: false,
        })
      );
    }
  }, [homeState?.publishFlow]);

  useEffect(() => {
    if (homeState) {
      setStatus(!homeState.status);
      setTimeout(() => {
        setStatus(homeState?.status as boolean);
      }, 10);
      if (
        (save === "publish" && homeState.status) ||
        (save === "save" && !homeState.status) ||
        (errorDataState?.errorModalOpenStatus === false && save === "error") ||
        homeState?.closeLoader
      ) {
        setLoader(false);
        setSave("");
        dispatcher(
          homeDataAction.addState({
            ...homeState,
            closeLoader: false,
          })
        );
      }

      if (homeState.status) {
        setFlowStatus("Active");
      } else {
        setFlowStatus("Inactive");
      }
    }
  }, [homeState?.status, homeState?.closeLoader, errorDataState]);

  useEffect(() => {
    const isSunset = homeState?.sunset ?? false;
    const isTestMode = homeState?.testMode ?? false;
    setIsSunset(isSunset);
    setIsTestMode(isTestMode);
  }, [homeState?.sunset, homeState?.testMode]);

  /**
   * Handling save button enablement when edit/analytics page loads and when save/publish is successful
   */
  useEffect(() => {
    if (
      nodeActionState?.action === "edit-page-loaded" &&
      location.pathname.includes("/edit")
    ) {
      setTimeout(() => {
        setIsEditPageLoaded(true);
      }, 800);

      dispatcher(
        NodeActionActions.updateState({
          nodeId: "",
          type: "",
          action: "",
        })
      );
    }

    if (
      nodeActionState?.action === "analytics-page-loaded" &&
      location.pathname.includes("/analytics")
    ) {
      setTimeout(() => {
        setIsEditPageLoaded(false);
        setIsSaveButtonEnabled(false);
      }, 800);

      dispatcher(
        NodeActionActions.updateState({
          nodeId: "",
          type: "",
          action: "",
        })
      );
    }

    if (
      nodeActionState?.action === "save-success" ||
      nodeActionState?.action === "publish-success"
    ) {
      dispatcher(
        builderStateActions.setState({
          ...builderState,
          save: false,
        })
      );

      setTimeout(() => {
        setIsSaveButtonEnabled(false);
      }, 1000);
    }

    if (nodeActionState?.action === "add-past-profiles-success") {
      initializePropsForAddPastProfile();
      dispatcher(
        NodeActionActions.updateState({
          nodeId: "",
          type: "",
          action: "",
        })
      );
    }
  }, [nodeActionState]);

  /**
   * Whenever anything in the nodes update, save button will be enabled
   * flowState is used for all nodes except sticky notes
   * notesState is used for sticky note
   */
  useEffect(() => {
    dispatcher(
      builderStateActions.setState({
        ...builderState,
        save: isEditPageLoaded,
      })
    );
    setIsSaveButtonEnabled(isEditPageLoaded);
  }, [flowState, notesState]);

  const saveFlow = (fromSwitch?: boolean) => {
    if (fromSwitch) {
      dispatcher(
        homeDataAction.addState({
          ...homeState,
          showSunsetModal: true,
          saveFlow: false,
          publishFlow: false,
          skipStatModal: true,
        })
      );
    } else {
      dispatcher(
        homeDataAction.addState({
          ...homeState,
          saveFlow: true,
          publishFlow: false,
          skipStatModal: false,
        })
      );
    }
    setSave("save");
  };

  const handleTestModeToggle = async (value: boolean) => {
    setIsTooltipVisible(false);
    if (!value && homeState.status) {
      const hasAccess = await hasAccessToBikFeaturesMain(
        AllFeatureSubKey.DISABLE_TEST_MODE
      );
      setIsTestMode(value);
      if (!hasAccess) {
        setIsTestMode(value);
        setTimeout(() => {
          setIsTestMode(!value);
        }, 100);
        return;
      }
    }
    dispatcher(
      homeDataAction.addState({
        ...homeState,
        testMode: value,
        saveFlow: false,
        publishFlow: true,
        skipStatModal: true,
        fromTestModeToggle: true,
      })
    );
  };

  const publishFlow = (fromSwitch?: boolean) => {
    if (fromSwitch) {
      dispatcher(
        homeDataAction.addState({
          ...homeState,
          saveFlow: false,
          publishFlow: true,
          skipStatModal: true,
        })
      );
    } else {
      dispatcher(
        homeDataAction.addState({
          ...homeState,
          saveFlow: false,
          publishFlow: true,
          skipStatModal: false,
        })
      );
    }
    setSave("publish");
  };

  const redirectionToEditFlow = () => {
    navigate(
      `/${homeState?.storeId}/${homeState?.flowId}/${homeState?.channel}/edit`
    );
  };
  useEffect(() => {
    if (errorDataState?.errorModalOpenStatus) {
      setSave("error");
    }
  }, [errorDataState?.errorModalOpenStatus]);

  useEffect(() => {
    if (homeState?.lastUpdated) {
      const lastUpdated = GetLastUpdatedMessage(homeState?.lastUpdated);
      setLastSaved(lastUpdated as string);
    } else {
      setLastSaved("");
    }
  }, [homeState?.lastUpdated]);

  const Edity = () => {
    return <EditIcon backgroundColor="#ffffff" color="#ffffff" width={18} />;
  };

  const editMode = location.pathname.includes("/edit");

  /**
   * Things to happen when user should move to analytics page
   */
  const redirectToAnalytics = () => {
    setIsEditPageLoaded(false);
    setIsSaveButtonEnabled(false);
    navigate(
      `/${homeState?.storeId}/${homeState?.flowId}/${homeState?.channel}/analytics`
    );
  };

  return (
    <>
      <HeaderStyled className="header">
        {homeState.flowId && lastSaved && (
          <div className="last__updated">
            {isSaveButtonEnabled && (
              <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                <CloudOfflineIcon />
                <TitleSmall style={{ color: COLORS.content.secondary }}>
                  {" Unsaved Changes"}
                </TitleSmall>
              </div>
            )}
            <BodyCaption style={{ color: COLORS.content.secondary }}>
              {lastSaved}
            </BodyCaption>
          </div>
        )}
        <div className="header__chatflow-name">
          {homeState.flowId && (
            <>
              <Tooltip
                body="Journey Dashboard"
                children={
                  <IconButton
                    width={24}
                    height={24}
                    Icon={JourneysIcon}
                    onClick={() => {
                      if (!editMode || !isSaveButtonEnabled)
                        window.location.href = `${dasboardUrl}/journeys/home`;
                      else setIsRedirectionClicked(true);
                    }}
                    style={{
                      display: "flex",
                    }}
                  />
                }
              />
              <div
                style={{ height: 24, width: 1, backgroundColor: "#D9D9D9" }}
              />
            </>
          )}
          {title && (
            <Tooltip
              body={title}
              children={<span className="name-top">{title}</span>}
            />
          )}
          {homeState.flowId && (
            <>
              <div id="status-toggle">
                <Switch
                  value={status}
                  type="brand"
                  onValueChange={async (value: any) => {
                    if (!!value) {
                      const hasAccess = await hasAccessToBikFeaturesMain();
                      setLoader(true);
                      setStatus(!homeState.status);
                      if (!hasAccess) {
                        setStatus(!homeState.status);
                        setTimeout(() => {
                          setLoader(false);
                          setStatus(homeState?.status as boolean);
                        }, 100);
                        return;
                      }
                    }
                    setLoader(true);
                    value ? publishFlow(true) : saveFlow(true);
                  }}
                  isLoading={loader}
                />
              </div>
              <span
                className={`status ${
                  flowStatus === "Active"
                    ? "active"
                    : isSunset
                      ? "sunset"
                      : "stop"
                }`}
              >
                {isSunset ? "Sunset" : flowStatus}
              </span>
              <div id="test-mode">
                <Tooltip
                  alwaysVisible={isTooltipVisible}
                  hideArrow={true}
                  placement="bottom-start"
                  body=""
                  onClickOutside={() => setIsTooltipVisible(false)}
                  tooltipContent={
                    <TestModeTooltip
                      showAlert={showTestModeUserAlert}
                      channel={homeState.channel!}
                      onValueChange={handleTestModeToggle}
                      value={isTestMode}
                    />
                  }
                >
                  <TestModeContainer
                    active={isTestMode}
                    onClick={() => setIsTooltipVisible(true)}
                    onMouseEnter={() => setIsTooltipVisible(true)}
                  >
                    <TestModeIcon
                      backgroundColor={
                        isTestMode
                          ? COLORS.content.warning
                          : COLORS.background.base
                      }
                      color={isTestMode ? "white" : "black"}
                    />
                    <BodyCaption
                      style={{
                        color: isTestMode ? COLORS.content.warning : "black",
                      }}
                    >
                      Test Mode
                    </BodyCaption>
                  </TestModeContainer>
                </Tooltip>
              </div>
            </>
          )}
        </div>
        {!location.pathname.includes("/v2/bik-chatbot/flows") && !location.pathname.includes("/v2/bik/growth") && (
          <div className="header__right-menu">
            <Tooltip
              placement="left"
              body="Product Tour"
              children={
                <IconButton
                  Icon={() => <ProductTourIcon width={24} height={24} />}
                  onClick={props.onShowTourClick}
                />
              }
            />

            {editMode && !props.redirect404 ? (
              <div className="parent__relative">
                <Button
                  buttonText="View Analytics"
                  buttonType="tertiary"
                  size="small"
                  LeadingIcon={AnalyticsIcon}
                  onClick={(e) => {
                    e.stopPropagation();

                    if (!isSaveButtonEnabled) {
                      redirectToAnalytics();
                      props.onAnalyticsClick();
                    } else setIsRedirectionClicked(true);
                  }}
                />
                <div id="save">
                  <Button
                    buttonType="primary"
                    size="small"
                    buttonText="Save Journey"
                    onClick={() => {
                      saveFlow(false);
                    }}
                    disabled={false}
                  />
                </div>

                <div id="menu-dropdown">
                  <Tooltip
                    alwaysVisible={isDropdownTooltipVisible}
                    hideArrow={true}
                    placement="bottom-start"
                    body=""
                    onClickOutside={() => setIsDropdownTooltipVisible(false)}
                    tooltipContent={
                      <DropdownMenu isVisible={isDropdownTooltipVisible}>
                        {menuOptions.map((option) => {
                          if (
                            "value" in option &&
                            "label" in option &&
                            "subText" in option
                          ) {
                            const isDisabled = option.disabled ?? false;

                            return (
                              <MenuItem
                                key={option.value}
                                onClick={() => {
                                  if (
                                    option.value === "add_past_profiles" &&
                                    !isDisabled
                                  ) {
                                    setShowAddPastProfileModal(true);
                                  } else if (
                                    option.value === "dnd" &&
                                    !isDisabled
                                  ) {
                                    handleDndStatusToggle();
                                  } else if (
                                    option.value === "global_freq_cap" &&
                                    !isDisabled
                                  ) {
                                    handleFlowFreqCapStatusToggle();
                                  }

                                  if (!isDisabled) {
                                    setIsDropdownTooltipVisible(false);
                                  }
                                }}
                                style={{
                                  opacity: isDisabled ? 0.5 : 1,
                                  cursor: isDisabled ? "no-drop" : "pointer",
                                }}
                              >
                                <BodySecondary>{option.label}</BodySecondary>

                                <BodyCaption
                                  style={{ fontSize: "12px", color: "#666" }}
                                >
                                  {option.subText}
                                </BodyCaption>
                              </MenuItem>
                            );
                          }
                        })}
                      </DropdownMenu>
                    }
                  >
                    <MenuDropdownIcon
                      onClick={() => setIsDropdownTooltipVisible(true)}
                      onMouseEnter={() => setIsDropdownTooltipVisible(true)}
                    >
                      <DotsContainer>
                        <Dot />
                        <Dot />
                        <Dot />
                      </DotsContainer>
                    </MenuDropdownIcon>
                  </Tooltip>
                </div>
              </div>
            ) : !location.pathname.includes("/404") &&
              !location.pathname.includes("/login") &&
              !props.redirect404 ? (
              <div className="parent__relative" id="edit">
                <Button
                  buttonType="primary"
                  size="small"
                  buttonText="Edit flow"
                  onClick={() => {
                    // dispatcher(
                    //   storeDataActions.restoreFlow({
                    //     flow: {},
                    //     nodeId: "",
                    //     data: {},
                    //   })
                    // );
                    props.onEditClick();
                    redirectionToEditFlow();
                  }}
                  LeadingIcon={Edity}
                  matchParentWidth={true}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        )}
      </HeaderStyled>
      {editMode &&
        !errorDataState.errorModalOpenStatus &&
        (errorDataState.errorsCount > 0 ||
          errorDataState.warningsCount > 0) && <ErrorBar />}

      {/*Opens when user redirects without saving changes*/}
      <ConfirmationModal
        open={isRedirectionClicked}
        onPositiveClick={() => {
          setIsRedirectionClicked(false);
          saveFlow(false);
        }}
        onNegativeClick={() => {
          setIsRedirectionClicked(false);
          redirectToAnalytics();
        }}
        onCloseClick={() => setIsRedirectionClicked(false)}
        title={"Save changes"}
        subTitle={
          "You have unsaved changes in this journey. Do you wish to save the changes?"
        }
        positiveButtonText={"Save changes"}
        negativeButtonText={"Discard"}
      />

      {showToaster && (
        <Toaster
          text={toasterText}
          width={"300px"}
          type={dndToasterType}
          autoClose={3000}
          onCloseToast={() => {
            setShowToaster(false);
          }}
        />
      )}

      <AddPastProfileModal
        open={showAddPastProfileModal}
        onClose={() => {
          setShowAddPastProfileModal(false);
          return;
        }}
        alreadyAddedDays={alreadyAddedDays ?? 0}
        flowEnabledDate={getFlowEnabledOnDate(
          (flowMetaState as any)?.[homeState?.flowId]
        )}
        lastProfileAdditionDate={lastprofileAdditionDate ?? ""}
      />

      {isAnalytics && <SummaryAnalytics />}
      {showDndModal && (
        <StateModalComponent
          onButtonClick={(action) => {
            if (action === "cancel") {
              setShowDndModal(false);
            } else {
              updateDndStatus();
              setShowDndModal(false);
            }
          }}
          onClose={() => setShowDndModal(false)}
          states={StateInterface.WARNING}
          heading="Do not Disturb is disabled globally"
          width={440}
          isButtonEnabled={true}
          type={ButtonTypes.DOUBLE}
          buttonText={
            DndModalContent[dndStatus ? "enabled" : "disabled"].button
          }
          subline={DndModalContent[dndStatus ? "enabled" : "disabled"].body}
        />
      )}
      <FeatureModals />
      {showFreqCapModal && (
        <StateModalComponent
          onButtonClick={(action) => {
            if (action === "cancel") {
              setShowFreqCapModal(false);
            } else {
              updateFlowFreqCapEnabled();
              setShowFreqCapModal(false);
            }
          }}
          onClose={() => setShowFreqCapModal(false)}
          states={StateInterface.WARNING}
          heading="Messaging limits is disabled globally"
          width={440}
          isButtonEnabled={true}
          type={ButtonTypes.DOUBLE}
          buttonText={
            FreqCapModalContent[isFlowFreqEnabled ? "enabled" : "disabled"].button
          }
          subline={FreqCapModalContent[isFlowFreqEnabled ? "enabled" : "disabled"].body}
        />
      )}
    </>
  );
};

export default Header;

const MenuDropdownIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  width: 34px;
  margin-left: 8px;
  border-radius: 4px;
  border: 1px solid ${COLORS.stroke.primary};
  cursor: pointer;

  &:hover {
    background-color: ${COLORS.surface.hovered};
  }
`;

const DotsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 16px;
`;

const Dot = styled.div`
  width: 3px;
  height: 3px;
  background-color: black;
  border-radius: 50%;
`;

interface DropdownMenuProps {
  isVisible: boolean;
}

const DropdownMenu = styled.div<DropdownMenuProps>`
  width: 348px;
  background-color: ${COLORS.surface.standard};
  border: 1px solid ${COLORS.stroke.primary};
  border-radius: 4px;
  box-shadow: 0 2px 5px ${COLORS.surface.grey};
  z-index: 9;
`;

const MenuItem = styled.div`
  padding: 16px;
  cursor: pointer;
  border-bottom: 1px solid ${COLORS.stroke.primary};
  &:hover {
    background-color: ${COLORS.surface.hovered};
  }
`;
