import {
  BodySecondary,
  COLORS,
  Dropdown,
  Button,
  VariablePicker,
  PickerType,
  CustomPortal,
} from "@bikdotai/bik-component-library";
import CrossTagIcon from "../../icons/crossTagIcon";
import PlusIcon from "../../icons/plusIcon";
import { StyledDash } from "./styles";
import { useEffect, useRef } from "react";
import { ID_Option } from "./constants";
import { RecordEventHelper } from "../../utilities/RecordEventHelpers";
import {
  AmplitudeEventStep,
  AmplitudeKeys,
  TargetPlatform,
} from "../../utilities/AnalyticsTypes";

export const IdSelection = (props: any) => {
  const pickerRef = useRef<HTMLDivElement>(null);
  const {
    payloadVariable,
    idOptions,
    setPageTwoError,
    setIdOptions,
    setPayloadVariable,
    showVarPicker,
    setShowVarPicker,
    payloadList,
    primary,
    setSecondaryIdOptions,
  } = props;

  const element = document.getElementById("id-selection");
  const eventHelper = new RecordEventHelper();

  useEffect(() => {
    function handleClickOutside(e: any) {
      if (pickerRef.current && !pickerRef.current.contains(e.target as Node)) {
        setShowVarPicker(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div id="id-selection">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          padding: "12px 24px",
          gap: 18,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            width: 250,
          }}
        >
          <BodySecondary color={COLORS.content.primary}>
            {!primary ? "Secondary ID Type" : "Type of ID"}
            {primary && (
              <span style={{ color: COLORS.content.negative }}>*</span>
            )}
          </BodySecondary>

          <Dropdown
            width="250px"
            options={idOptions}
            isMultiSelect={false}
            onSelect={(value: any) => {
              if (value.selected) {
                setPageTwoError((prev: any) => {
                  return { ...prev, idMissing: false };
                });
              }

              setIdOptions((prev: any) => {
                return prev.map((item: any) => {
                  if (item.label === value.label && value.selected) {
                    return {
                      ...item,
                      selected: true,
                    };
                  } else {
                    return {
                      ...item,
                      selected: false,
                    };
                  }
                });
              });

              setSecondaryIdOptions((prev: ID_Option[]) => {
                return prev.map((i) => {
                  if (value.value === i.value && value.selected) {
                    return {
                      ...i,
                      disabled: true,
                      selected: false,
                    };
                  } else {
                    return {
                      ...i,
                      disabled: false,
                    };
                  }
                });
              });
            }}
          />
        </div>
        <div>
          {payloadVariable ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <BodySecondary color={COLORS.content.primary}>
                Payload Variable
              </BodySecondary>
              <div
                style={{
                  display: "flex",
                  padding: "6px 12px 6px 8px",
                  alignItems: "center",
                  width: "fit-content",
                  height: "48px",
                  gap: "8px",
                  borderRadius: "4px",
                  border: `1px solid ${COLORS.stroke.primary}`,
                  cursor: "pointer",
                  background: COLORS.background.base,
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <BodySecondary color={COLORS.content.primary}>
                  {payloadVariable}
                </BodySecondary>
                <div style={{}} onClick={() => setPayloadVariable("")}>
                  <CrossTagIcon width={16} height={16} />
                </div>
              </div>{" "}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <BodySecondary color={COLORS.content.primary}>
                Payload Variable
              </BodySecondary>
              <StyledDash
                isActive={showVarPicker ? true : false}
                id={primary ? "add-payload-var" : "add-payload-var2"}
              >
                <Button
                  style={{
                    width: "250px",
                  }}
                  matchParentWidth={true}
                  onClick={() => {
                    setShowVarPicker(true);
                  }}
                  LeadingIcon={PlusIcon}
                  buttonText="Payload Variable"
                  buttonType="dashBold"
                  size="large"
                />
              </StyledDash>
            </div>
          )}
        </div>
        {element && showVarPicker && (
          <CustomPortal element={element!}>
            <div ref={pickerRef} style={{ height: "100%" }}>
              <VariablePicker
                autoOpenFirst
                headerText="Select Payload Variable"
                showHeader
                width="344px"
                type={PickerType.EMBEDDED}
                data={payloadList}
                onClose={() => setShowVarPicker(false)}
                onSelect={(variable) => {
                  setPayloadVariable(variable.actualValue);
                  setShowVarPicker(false);
                  eventHelper.trackEvent(
                    TargetPlatform.Amplitude,
                    AmplitudeKeys.service_used,
                    {
                      step: AmplitudeEventStep.variable_used,
                      variableName: variable.displayName,
                      screen: "journey_builder",
                    }
                  );
                }}
              />
            </div>
          </CustomPortal>
        )}
      </div>
    </div>
  );
};
