import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    AllChannels,
    AllFeatureSubKey,
  AllModules,
  FeatureCategory,
  FeatureSubKeyToPlanMappingInterface,
} from "@bikdotai/bik-models/growth/models/feature";

export const FEATURE_LOCK_IMAGE =
  'https://cdn.bikayi.app/ci/images/Frame%2026086481.png';

export const DEFAULT_FR_TITLE = 'Feature Not Available';

export const DEFAULT_FR_SUBTITLE =
  'This feature is not available on your current plan. Please reach out to our team to explore upgrade options.';

export const DEFAULT_FR_BUTTON_TEXT = 'Contact Support';

export type FeatureDetailsKeys = AllModules | AllChannels | AllFeatureSubKey;

export const FEATURE_DETAILS_MAPPING : Partial<Record<FeatureDetailsKeys,{
    title: string;
    subTitle?: string;
    primaryButtonText?: string;
    icon?: string;
    featureImage?: string;
  }>>= {
    [AllFeatureSubKey.FLOW_ACTIVATIONS]: {
      title: 'Taking Journey Live is Locked 🔒',
    },
    [AllFeatureSubKey.DISABLE_TEST_MODE]: {
      title: 'Disabling test mode is blocked 🔒',
    }
  };

export interface BikFeatureRestrictionProps {
  isEnabled: boolean;
  category?: FeatureCategory;
  title?: string;
  subTitle?: string;
  primaryButtonText?: string;
  icon?: string;
  featureImage?: string;
  redirect?: boolean;
  uniqueIdAsFeatureOrSubFeatureName?: string;
}

export interface BikFeatureLocalRequest {
  route?: string;
  category?: FeatureCategory;
  featureName: string;
  subFeatureName?: string;
  skipPopup?: boolean;
  returnOnlyValues?: boolean;
  redirect?: boolean;
}

export interface BikFeatureRestrictionPropsCompulsory
  extends BikFeatureRestrictionProps {
  title: string;
  subTitle: string;
}

export interface BikRestrictedFeaturesI {
  isLoading: boolean;
  featureDoc?: FeatureSubKeyToPlanMappingInterface;
  isFeatureDocExists?: boolean;
  bikFeatureRestrictionProps?: BikFeatureRestrictionProps;
  isBlockingThankYouModalOpen: boolean;
}

const initialFeatureDoc: BikRestrictedFeaturesI = {
  isLoading: true,
  bikFeatureRestrictionProps: {
    isEnabled: false,
  } as BikFeatureRestrictionProps,
  isBlockingThankYouModalOpen: false,
};

const bikRestrictedFeatureSlice = createSlice({
  name: "bikRestrictedFeaturesContext",
  initialState: initialFeatureDoc,
  reducers: {
    setFeatureRestrictedState(
      state,
      action: PayloadAction<FeatureSubKeyToPlanMappingInterface>
    ) {
      state.featureDoc = action.payload;
      state.isFeatureDocExists = true;
      if (state.isLoading) {
        state.isLoading = false;
      }
    },
    resetFeatureRestrictionState(state) {
      state.isFeatureDocExists = undefined;
      state.isLoading = false;
      state.featureDoc = undefined;
    },
    setBikRestrictionProps(
      state,
      action: PayloadAction<BikFeatureRestrictionProps>
    ) {
      state.bikFeatureRestrictionProps = {
        ...state.bikFeatureRestrictionProps,
        ...action.payload,
      };
    },
    setBlockingThankYouModal(state, action: PayloadAction<boolean>) {
      state.isBlockingThankYouModalOpen = action.payload;
    },
  },
});

export const bikRestrictedFeatureSliceActions =
  bikRestrictedFeatureSlice.actions;

export const bikRestrictedFeatureSliceReducer =
  bikRestrictedFeatureSlice.reducer;
