export interface ID_Option {
  label: string;
  value: string;
  selected?: boolean;
  disabled?: boolean;
}

export const ID_OPTIONS = [
  {
    label: "Phone Number",
    value: "phone_number",
  },
  {
    label: "E-mail ID",
    value: "email_id",
  },
  {
    label: "Shopify Customer ID",
    value: "shopify_customer_id",
  },
  {
    label: "Order ID",
    value: "order_id",
  },
  {
    label: "Order Name",
    value: "order_name",
  },
  {
    label: "External ID",
    value: "external_id",
  },
];

export const ID_OPTIONS2 = [
  {
    label: "Phone Number",
    value: "phone_number",
  },
  {
    label: "E-mail ID",
    value: "email_id",
  },
];
