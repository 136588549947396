import { Icon } from "./icons-props";

const TemplateBlock: Icon = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2 0C0.89543 0 0 0.895432 0 2V18C0 19.1046 0.895432 20 2 20H18C19.1046 20 20 19.1046 20 18V2C20 0.89543 19.1046 0 18 0H2ZM2 2L18 2V9H2V2ZM2 11V18H18V11H2ZM3 13C3 12.4477 3.44772 12 4 12H16C16.5523 12 17 12.4477 17 13C17 13.5523 16.5523 14 16 14H4C3.44772 14 3 13.5523 3 13ZM4 15C3.44772 15 3 15.4477 3 16C3 16.5523 3.44772 17 4 17H11C11.5523 17 12 16.5523 12 16C12 15.4477 11.5523 15 11 15H4Z"
        fill={color || "#212121"}
      />
    </svg>
  );
};

export default TemplateBlock;
