import { Icon } from "./icons-props";

const TruckIcon: Icon = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4 3C2.34315 3 1 4.34315 1 6V16C1 16.5523 1.44772 17 2 17H3.03544C3.01209 17.1633 3 17.3302 3 17.5C3 19.433 4.567 21 6.5 21C8.433 21 10 19.433 10 17.5C10 17.3302 9.98791 17.1633 9.96456 17H15C15.0119 17 15.0237 16.9998 15.0355 16.9994C15.0121 17.1629 15 17.33 15 17.5C15 19.433 16.567 21 18.5 21C20.433 21 22 19.433 22 17.5C22 17.33 21.9879 17.1629 21.9645 16.9994C21.9763 16.9998 21.9881 17 22 17C22.5523 17 23 16.5523 23 16V11C23 10.7348 22.8946 10.4804 22.7071 10.2929L19.7071 7.29289C19.5196 7.10536 19.2652 7 19 7H16V6C16 4.34315 14.6569 3 13 3H4ZM21 15.0505V11.4142L18.5858 9H16V15.0505C16.6353 14.4022 17.5207 14 18.5 14C19.4793 14 20.3647 14.4022 21 15.0505ZM5.30796 16.5894C5.30273 16.5965 5.29741 16.6036 5.292 16.6106C5.10847 16.8595 5 17.1671 5 17.5C5 18.3284 5.67157 19 6.5 19C7.32843 19 8 18.3284 8 17.5C8 17.1671 7.89153 16.8595 7.708 16.6106C7.70259 16.6036 7.69727 16.5965 7.69204 16.5894C7.41793 16.2311 6.98595 16 6.5 16C6.01405 16 5.58207 16.2311 5.30796 16.5894ZM6.5 14C7.45365 14 8.31822 14.3814 8.94949 15H14V6C14 5.44772 13.5523 5 13 5H4C3.44772 5 3 5.44772 3 6V15H4.05051C4.68178 14.3814 5.54635 14 6.5 14ZM17 17.5C17 16.6716 17.6716 16 18.5 16C19.3284 16 20 16.6716 20 17.5C20 18.3284 19.3284 19 18.5 19C17.6716 19 17 18.3284 17 17.5Z"
        fill="white"
      />
    </svg>
  );
};

export default TruckIcon;
