import styled from "styled-components";
import { COLORS } from "@bikdotai/bik-component-library";
import { borderColorBasedOnSubType } from "./util";

export const NodeStyle = styled.div.attrs(
  (props: { nodeColor: string; subType: string; mediaUrl?: string }) => props
)`
  position: relative;
  z-index: 99;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 16px;

  .customNode {
    &__header {
      gap: 15px;
      padding-bottom: 16px;
      border-bottom: 1px solid ${(props) => props.nodeColor};
      &__title {
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        color: #212121;
        margin-top: 6px;
      }
      &__menu {
        margin-left: auto;
        margin-top: 6px;
      }
    }
    &__body {
      padding-top: 16px;
      gap: 16px;
      &__no-content {
        height: 48px;
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        color: #9e9e9e;
        justify-content: center;
        align-items: center;
      }
      &__image {
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 72px;
        background: ${(props) => (!props.mediaUrl ? "#fafafa" : "")};
        border: ${(props) => (!props.mediaUrl ? "1px solid #e0e0e0" : "")};
        border-radius: ${(props) => (!props.mediaUrl ? "4px" : "")};
        overflow: hidden;
        img {
          width: 100%;
        }
        video {
          width: 100%;
        }
      }
      &__header {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #212121;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: -10px;
      }
      &__description {
        border: 1px solid ${COLORS.stroke.primary};
        background-color: ${COLORS.surface.subdued};
        border-radius: 4px;
        padding: 0 10px;
        align-items: center;
        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }
        &__icon {
          padding-top: 6px;
        }
        &__desc {
        }
      }
      &__description-message {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        word-break: break-all;
      }
      &__slang {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #616161;
      }
    }
    &__footer {
      padding-top: 16px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #616161;
      margin-left: auto;
      width: 100%;
    }
  }
`;

export const ChildNodeStyle = styled.div.attrs(
  (props: { nodeType: string; subType?: string; isAnalytics?: boolean }) =>
    props
)`
  display: flex;
  align-items: center;
  ${(props) =>
    props.nodeType === "Trigger" ||
    props.nodeType === "Condition" ||
    props.nodeType === "Action"
      ? "justify-content: right"
      : "justify-content: center"};
  text-align: end;
  z-index: 99;
  color: #616161;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${(props) =>
    props.subType !== "carousel" && props.subType !== "wa_carousel"
      ? ["Message"].includes(props.nodeType)
        ? "padding: 12px 16px 12px 12px; border-bottom: 1px solid #E0E0E0; text-align: center;"
        : ""
      : ["Message"].includes(props.nodeType)
        ? "padding: 12px 16px 12px 12px; text-align: center;"
        : ""}
  .button__text {
    ${(props) =>
      props.nodeType !== "Trigger"
        ? "max-width:120px;overflow: hidden;text-overflow: ellipsis;"
        : ""}
  }
  .single-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
    display: block;
  }
`;

export const EdgeStyle = styled.div.attrs((props: {}) => props)`
  background: #ffffff;
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 38px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  &:hover {
    background: #f0f0f0;
  }
  &:active {
    background: #efe6f9;
  }
`;

export const FlowBuilderGlobalStyle = styled.div.attrs(
  (props: {
    addedNodeId: string;
    proximityHoverNodeId: string;
    clickNodeId: string;
    subType: string;
    channel: string;
  }) => props
)`
  width: 100%;
  height: 100%;

  .react-flow__node-CustomNode[data-id="${(props) =>
      props?.proximityHoverNodeId || ""}"] {
    outline: 2px solid
      ${(props) => borderColorBasedOnSubType(props.subType, props.channel)} !important;
  }

  .react-flow__node-CustomNode[data-id="${(props) =>
      props?.clickNodeId || ""}"] {
    outline: 2px solid ${COLORS.background.brand} !important;
  }

  .react-flow__node-CustomNode[data-id="${(props) =>
      props?.addedNodeId || ""}"] {
    --borderWidth: 2px;
    border-radius: 8px;
  }

  .react-flow__node-CustomNode {
    padding: 0px !important;
    :hover {
      box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 12px !important;
    }
  }

  .react-flow__node-CustomNode[data-id="${(props) =>
      props?.addedNodeId || ""}"]:after {
    content: "";
    position: absolute;
    top: calc(-1 * var(--borderWidth));
    left: calc(-1 * var(--borderWidth));
    height: calc(100% + var(--borderWidth) * 2);
    width: calc(100% + var(--borderWidth) * 2);
    background: linear-gradient(
      120deg,
      #4b1583,
      #4b1583,
      #ffffff,
      #4b1583,
      #4b1583
    );
    border-radius: 6px;
    z-index: -1;
    animation: animatedgradient 1s ease alternate infinite;
    background-size: 300% 300%;
  }

  @keyframes animatedgradient {
    0% {
      background-position: 0% 35%;
    }
    35% {
      background-position: 65% 35%;
    }
    65% {
      background-position: 100% 65%;
    }
    100% {
      background-position: 65% 35%;
    }
  }
`;

export const RedirectButtonContainer = styled.div`
  //position: absolute;
  margin: 1.5px 2px 0 0;
`;

export const CTRRenderStyle = styled.div<{
  secondaryType?: boolean;
}>`
  background: #efe6f9;
  border: 1px solid #ddccf0;
  border-radius: 4px;
  color: #4b1583;
  padding: 4px 8px;
  width: fit-content;
  max-width: 100%;
  ${(props) =>
    props?.secondaryType ? "margin-right: auto" : "margin-left: auto"};
  font-family: "Inter";
  font-style: "normal";
  font-weight: 400;
  font-size: 12;
  line-height: 16px;
`;

export const ChildNodeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
`;

export const DripNodeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const ChildNodeText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #616161;
  margin-left: auto;
`;

export const StyledAnalyticsCard = styled.div`
  position: relative;
  margin-top: -10px;
  left: 0;
  right: 0;
  z-index: -1;
  border-radius: 0 0 8px 8px;
`;
