import { useOnSelectionChange, XYPosition } from "reactflow";
import { useAppSelector } from "../../state/store";
import { useDetectAnalytics } from "../../ui-components/flow-builder/useDetectAnalytics";
import { useState } from "react";
import { keyboardEventDataActions } from "../../state/keyboardEventState";
import { useDispatch } from "react-redux";

/**
 * useMultipleSelection hook will run when user presses shift and drags the nodes.
 * Returns selected Node ids within the area.
 * Returns end position (bottom right corner of selection area) w.r.t. canvas
 *
 * @param mouseCursorPosition - Required because end position of selection area to be calculated and returned.
 */
const useMultipleSelection = (
  mouseCursorPosition: XYPosition
): {
  selectedNodeIds: Array<string>;
  selectionEndPosition: XYPosition;
} => {
  const dispatcher = useDispatch();
  const isAnalytics = useDetectAnalytics();
  const [selectedNodeIds, setSelectedNodeIds] = useState<Array<string>>([]);
  const [selectionEndPosition, setSelectionEndPosition] = useState<XYPosition>({
    x: 0,
    y: 0,
  });
  const keyboardEventDataState = useAppSelector(
    (state) => state.keyboardEventState
  );

  /**
   *  When user presses Shift + Drags the nodes, this hook returns selected Nodes & Edges
   *  Works only on Range based selection
   */
  useOnSelectionChange({
    onChange: ({ nodes, edges }) => {
      if (isAnalytics) {
        setSelectedNodeIds([]);
      }

      //outside click
      if (nodes.length === 0) {
        setSelectedNodeIds([]);
      }

      if (keyboardEventDataState.keyPressAction === "shift-down") {
        const nodesWithoutTrigger = nodes.filter(
          (node) => node.data.nodeData.type !== "Trigger"
        );
        setSelectedNodeIds(nodesWithoutTrigger.map((node) => node.id));
      }
    },
  });

  //When user releases shift button after selecting nodes
  if (keyboardEventDataState.keyPressAction === "shift-up") {
    setSelectionEndPosition(mouseCursorPosition);
    dispatcher(
      keyboardEventDataActions.keyPressed({
        keyPressAction: "",
      })
    );
  }

  return { selectedNodeIds, selectionEndPosition };
};

export default useMultipleSelection;
