// @ts-nocheck
import React, { useEffect, useState, useRef } from "react";
import {
  QueryBuilder,
  BaseQueryBuilderPayload,
  StyledModal,
  TitleSmall,
  DropdownPopover,
  COLORS,
  Tag,
  BikShimmer,
  StateComponent,
  StateInterface,
  BodyCaption,
} from "@bikdotai/bik-component-library";
import {
  fetchIgAsTriggers,
  fetchInstaIds,
} from "../../services/helpers/QueryBuilderHelper";
import {
  BuilderContainer,
  InstaDropdownContainer,
  InstaDropdownWrapper,
} from "./styled";
import InstagramIcon from "../../icons/instagramIcon";
import ChevronDownIcon from "../../icons/chevron_down";
import { extractHeaderAndSubHeader, extractIgMedia } from "./utils";
import { QueryValidator } from "./queryValidator";
import { WarnIcon } from "../../icons/warnIcon";
import { eventsTriggerApiHandler } from "./utils";

let builderState = {};
const queryValidator = new QueryValidator();

const IgBuilder: React.FC<{
  visible: boolean;
  onClose: Function;
  storeId: string;
  instaId: string;
  nodes: [];
  mode: string;
  onSave: Function;
}> = (props) => {
  const [instaIds, setInstaIds] = useState([]);
  const [selectedInsta, setSelectedInsta] = useState({});
  const [errorState, setErrState] = useState(undefined);
  const [errorMsg, setErrorMsg] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const igQbRef = useRef();

  const handleModalClose = () => {
    builderState = {};
    setErrState(undefined);
    if (igQbRef && igQbRef.current) {
      igQbRef.current.clearAll("IG_TRIGGER");
    }
    props.onClose();
  };

  useEffect(() => {
    populateInstaDropdown();
  }, []);

  const warning = {
    disabled: true,
    customComponent: (
      <div
        style={{
          cursor: "default",
          display: "flex",
          alignItems: "center",
          height: 26,
          width: "calc(100% + 32px)",
          padding: "13px 7px",
          margin: "-14px -16px",
          backgroundColor: COLORS.background.warning.light,
        }}
      >
        <WarnIcon width={15} height={15} />
        <BodyCaption style={{ marginLeft: 4, color: COLORS.content.warning }}>
          Changing this will reset the entire query
        </BodyCaption>
      </div>
    ),
  };

  const populateInstaDropdown = async () => {
    setIsLoading(true);
    const resp = await fetchInstaIds({ storeId: props.storeId });
    const instaResp = resp.data.instagram;

    const instaIds = instaResp.map((item, i) => {
      if (!props.instaId && i === 0) {
        const obj = {
          label: instaResp[0].accountName,
          value: instaResp[0].accountId,
          selected: true,
        };
        setSelectedInsta(obj);
        return obj;
      }
      if (item.accountId === props.instaId) {
        const obj = {
          label: item.accountName,
          value: item.accountId,
          selected: true,
        };
        setSelectedInsta(obj);
        return obj;
      }
      return {
        label: item.accountName,
        value: item.accountId,
      };
    });
    if (!!instaIds.length && instaIds.length > 1) {
      instaIds.push(warning);
    }
    setInstaIds(instaIds);
    setIsLoading(false);
  };

  const parseBuilderData = () => {
    if (!selectedInsta) {
      setErrorMsg("Please connect an account!");
      return;
    }

    const { isError, state } = checkForErros();
    if (isError) return;
    const { id, subHeader } = extractHeaderAndSubHeader(state);
    const { media, mediaType, tag } = extractIgMedia(state);
    props.onSave({
      ...state,
      header: state.nodes[0].metas[0].value,
      subHeader,
      id,
      type: "ig",
      media,
      mediaType,
      tag,
      instaId: selectedInsta?.value,
    });
  };

  const checkForErros = () => {
    const errorStateRes = queryValidator.validate({
      include: builderState,
    });
    setErrState({ ...errorStateRes[1].include });
    setErrorMsg(errorStateRes[0]);
    return { isError: errorStateRes[0], state: errorStateRes[1].include };
  };

  const InstaDropdown = () => {
    return (
      <InstaDropdownContainer>
        <DropdownPopover
          width="180px"
          disabled={isLoading || !instaIds.length}
          onSelect={(c) => {
            setSelectedInsta(c);
            const data = instaIds;
            const modified = data
              .filter((d) => typeof d.value !== "undefined")
              .map((d) => {
                if (d.value === c.value) {
                  return {
                    label: d.label,
                    value: d.value,
                    selected: true,
                  };
                } else if (typeof d.value !== "undefined") {
                  return {
                    label: d.label,
                    value: d.value,
                  };
                }
              });
            if (!!modified.length && modified.length > 1) {
              modified.push(warning);
            }
            if (c.value !== selectedInsta.value) {
              if (igQbRef && igQbRef.current) {
                igQbRef.current.clearAll("IG_TRIGGER");
              }
            }
            setInstaIds(modified);
          }}
          options={instaIds}
        >
          <InstaDropdownWrapper>
            <InstagramIcon />
            <>
              {isLoading ? (
                <TitleSmall className="dropdown-text">Loading ...</TitleSmall>
              ) : (
                <>
                  {!instaIds.length ? (
                    <TitleSmall className="dropdown-text">
                      No accounts connected
                    </TitleSmall>
                  ) : (
                    <TitleSmall className="dropdown-text">
                      {selectedInsta.label || "Select an account"}
                    </TitleSmall>
                  )}
                </>
              )}
            </>
            <ChevronDownIcon color={COLORS.content.primary} />
          </InstaDropdownWrapper>
        </DropdownPopover>
      </InstaDropdownContainer>
    );
  };

  return (
    <>
      <StyledModal
        headingTitle="Create trigger based on Instagram activity"
        centralContainerStyles={{ width: "80%", minHeight: "80%" }}
        open={props.visible}
        onClose={() => handleModalClose()}
        zIndex={200}
        primaryButton={
          !isLoading &&
          !!instaIds.length && {
            buttonType: "primary",
            buttonText: "Create",
            onClick: () => parseBuilderData(),
          }
        }
        headerRightCustomElement={<InstaDropdown />}
        footerLeftCustomElement={
          errorMsg && (
            <Tag tagText={errorMsg} type="negative" LeadingIcon={WarnIcon} />
          )
        }
      >
        <BuilderContainer>
          {!isLoading ? (
            <>
              {!instaIds.length ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "100%",
                    alignItems: "center",
                  }}
                >
                  <StateComponent
                    states={StateInterface.ERROR}
                    heading="No instagram accounts connected"
                    subline="Please connect atleast one instagram account from inbox."
                    isButtonEnabled={false}
                    width={undefined}
                  />
                </div>
              ) : (
                <QueryBuilder
                  ref={igQbRef}
                  mode={props.mode}
                  state={
                    props.nodes && props.nodes.length
                      ? { nodes: props.nodes }
                      : undefined
                  }
                  selectorKey="eventTrigger"
                  addNodeBtnText="Add Trigger"
                  queryPreText="Whenever user performs"
                  infinite={false}
                  onStateChange={(state: any) => {
                    builderState = state;
                  }}
                  errorState={errorState}
                  typesAPIHandlers={{
                    IG_TRIGGER: (
                      payload: BaseQueryBuilderPayload<
                        | { triggerName: string }
                        | { triggerName: string; propertyName: string }
                        | undefined
                      >
                    ) =>
                      eventsTriggerApiHandler(
                        payload,
                        props.storeId,
                        selectedInsta.value
                      ),
                  }}
                />
              )}
            </>
          ) : (
            <>
              <BikShimmer
                boxes={[
                  { height: "24px", width: "450px" },
                  { height: "24px", width: "120px" },
                ]}
              />
              <div
                style={{
                  marginLeft: 24,
                  marginTop: 16,
                  display: "flex",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: 2,
                    height: 48,
                    backgroundColor: COLORS.stroke.primary,
                  }}
                ></div>
                <div
                  style={{
                    backgroundColor: COLORS.surface.subdued,
                    padding: 12,
                    borderRadius: 4,
                    marginLeft: 16,
                    width: "calc(100% - 40px)",
                  }}
                >
                  <BikShimmer
                    boxes={[
                      { height: "24px", width: "120px", marginTop: "0px" },
                    ]}
                  />
                </div>
              </div>
            </>
          )}
        </BuilderContainer>
      </StyledModal>
    </>
  );
};

export default IgBuilder;
