import { useDispatch } from "react-redux";
import AlertIcon from "../../icons/alertIcon";
import { errorDataActions } from "../../state/errorDataState";
import { useAppSelector } from "../../state/store";
import { ErrorBarStyled } from "./style";

const ErrorBar = () => {
  const errorDataState = useAppSelector((state) => state.errorDataState);
  const dispatcher = useDispatch();

  const viewErrorModal = () => {
    dispatcher(
      errorDataActions.updateErrorState({
        ...errorDataState,
        errorModalOpenStatus: true,
      })
    );
  };

  return (
    <ErrorBarStyled>
      <div
        className={`error__bar ${
          errorDataState.errorsCount > 0
            ? "error__background"
            : "warning__background"
        }`}
      >
        <div className="alert__icon">
          <AlertIcon color={errorDataState.errorsCount > 0 ? "" : "#99631B"} />
        </div>
        <div
          className={`error__message ${
            errorDataState.errorsCount > 0 ? "error__color" : "warning__color"
          }`}
        >
          {errorDataState.errorsCount > 0
            ? `${errorDataState.errorsCount} error `
            : errorDataState.errorsCount > 1
              ? `${errorDataState.errorsCount} errors `
              : errorDataState.warningsCount > 0
                ? `${errorDataState.warningsCount} warning `
                : `${errorDataState.warningsCount} warnings `}
          found. Please fix these issues to ensure smooth working of the
          journey.
        </div>
        <div className="view" onClick={viewErrorModal}>
          View
        </div>
      </div>
    </ErrorBarStyled>
  );
};

export default ErrorBar;
