import { memo, useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import DeleteIcon from "../../icons/DeleteIcon";
import DuplicateIcon from "../../icons/duplicateIcon";
import { NodeActionActions } from "../../state/nodeActionState";
import { useAppSelector } from "../../state/store";
import { apiDataStateActions } from "../../state/apiDataState";
import { IconButton } from "@bikdotai/bik-component-library";
import { TickIcon } from "../../icons/tickIcon";

export interface CustomNodeOptionsProps {
  nodeId: string;
  width: number;
  height: number;
  nodeType: string;
  nodeIndex: string;
  subType: string;
}

const CustomNodeOptions = (props: CustomNodeOptionsProps) => {
  const dispatcher = useDispatch();

  const deleteNode = () => {
    dispatcher(
      NodeActionActions.updateState({
        action: "delete",
        type: "node",
        nodeId: props?.nodeId,
      })
    );
  };

  const duplicateNode = () => {
    dispatcher(
      NodeActionActions.updateState({
        action: "duplicate",
        type: "node",
        nodeId: props.nodeId,
        nodeType: props.nodeType,
      })
    );
  };

  const CopyIcon = () => {
    return (
      <DuplicateIcon
        height={`${props.height}px`}
        width={`${props.width}px`}
        color={"#616161"}
      />
    );
  };

  const TrashIcon = () => {
    return (
      <DeleteIcon
        height={`${props.height}px`}
        width={`${props.width}px`}
        color={"#616161"}
      />
    );
  };

  return (
    <CustomNodeOptionsStyle
      className="options flex--row"
      width={props.width}
      height={props.height}
    >
      <div className="options__extra_spaces"></div>
      <div className="options__menu flex--column">
        {props.subType !== "browse_collection" && (
          <>
            <IconButton
              style={{ display: "flex" }}
              Icon={CopyIcon}
              onClick={duplicateNode}
            />
            <hr></hr>
          </>
        )}
        <IconButton
          style={{ display: "flex" }}
          Icon={TrashIcon}
          onClick={deleteNode}
        />
      </div>
    </CustomNodeOptionsStyle>
  );
};

const CustomNodeOptionsStyle = styled.div.attrs(
  (props: { width: number; height: number }) => props
)`
  // props.width and props.height will be dynamic w.r.t to zoomLevel therefore multiplying it with
  // digit to mask the icon and overall component dimension w.r.t to zoomLevel.
  // width: 2.8 => 2 x icon + 0.8 (~ 35% of 2 because 35% is assinged to extra_spaces)
  // height: 3.4 => As per design height is always greater than width
  // right: 2.4 => to change dimension of options from only right side otherwise it will overlap the node itself
  // when zoom out at max
  width: ${(props) => props.width * 2.8}px;
  position: absolute;
  right: ${(props) => -(props.width * 2.2)}px;
  top: 0px;
  z-index: 999;
  hr {
    border: none;
    width: 100%;
    height: 1px;
    background: ${(props) => props.theme.colors.background.inactive};
    margin: 0 !important;
  }
  .options {
    &__extra_spaces {
      width: 35%;
      height: 100%;
    }
    &__menu {
      height: 100%;
      align-items: center;
      border: 1px solid ${(props) => props.theme.colors.stroke.primary};
      border-radius: 4px;
      filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.12));
      background-color: ${(props) => props.theme.colors.background.content};
      cursor: pointer;
      &__duplicate_node,
      &__delete_node {
        height: 48%;
        width: 100%;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;

export default memo(CustomNodeOptions);
