//to delete a cookie send expiration date past current day

export const setCookie = (
    cookieName: string,
    cookieValue: string,
    expirationDays?: number,
    deleteOperation?: boolean,
  ) => {
    let expires = '';
  
    if (deleteOperation) {
      expires = 'max-age=0'; // Deletes the cookie by setting max-age to 0
    } else {
      const maxAge = expirationDays
        ? expirationDays * 60 * 60
        : 15 * 24 * 60 * 60; // Default to 15 days
      expires = `max-age=${maxAge}`;
    }
  
    document.cookie = `${cookieName}=${cookieValue}; ${expires}; path=/`;
  };
  
  export const getCookie = (cookieName: string) => {
    const name = cookieName + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  };
  