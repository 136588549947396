import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NodeActionState } from "./storeDataStateType";

const initialState = {};

const NodeActionSlice = createSlice({
  name: "NodeActionState",
  initialState: initialState,
  reducers: {
    updateState: (state, action: PayloadAction<NodeActionState>) => {
      state = action.payload;
      return state;
    },
  },
});

export const NodeActionActions = NodeActionSlice.actions;
export default NodeActionSlice;
