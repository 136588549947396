import styled from "styled-components";

export const StickyNotesNodeStyle = styled.div`
  border-radius: 8px;
  position: relative;
  background-color: ${(props) => props.theme.colors.background.warning.light};
  padding: 20px 24px 20px 24px;
  .ql-editor > * {
    cursor: pointer !important;
  }

  a {
    color: ${(props) => props.theme.colors.background.brand} !important;
  }

  .customNode {
    gap: 8px;
    &__body {
      &__no-content {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: ${(props) => props.theme.colors.content.placeholder};
      }
      &__with-content {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: ${(props) => props.theme.colors.content.primary};
        word-break: break-word;
        padding: 4px 0px 4px 0px;
      }
    }
    &__footer {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: ${(props) => props.theme.colors.content.placeholder};
    }
  }
`;
