import { AllModules } from "@bikdotai/bik-models/growth/models/feature";
import { Timestamp } from "firebase/firestore";

export enum SidebarEnums {
  "tailored-section" = "tailored-section",
  "recommended-section" = "recommended-section",
  "whats-new-section" = "whats-new-section",
}
export interface UiMeta {
  title: string;
  desc: string;
  image: string;
  butttonText?: string;
  redirectUrl: string;
}

export enum JourneyLevels {
  BASIC = "basic",
  ADVANCED = "advanced",
  INTERMEDIATE = "intermediate",
}

export interface PostOnboardingJourneyRawI {
  id: AllModules | string;
  features: { level: JourneyLevels; uiMeta: UiMeta }[];
  type: string;
  selectedIndices?: number[];
  indicesToShow ?: {[key in JourneyLevels]: number[]}
}

export type PostOnboardingJourney = {
  [key in Partial<AllModules>]: {
    features: EachTailoredSectionI[];
    selectedIndices?: number[];
  };
};

export type LocalIndicesI = {
  [key in Partial<AllModules>]: {
    [key in JourneyLevels]: number[];
  }
};
export interface EachTailoredSectionI {
  level: JourneyLevels;
  uiMeta: UiMeta;
  isSelected?: boolean;
}
export interface ChipButtonProps {
  onClick: () => void;
  buttonText: string;
  isSelected: boolean;
  dataTest?: string;
}

export interface EachWhatsNewContent {
  title: string;
  body: string;
  content: string;
  image: string;
  buttonText: string;
  redirectUrl: string;
  docId: string;
  createdAt: Timestamp;
}

