import styled from "styled-components";

export const NewFlowModalStyle = styled.div`
  width: 512px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 24px;

  .body {
    padding-bottom: 20px;
  }

  .heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 24px;
  }
  .title {
    flex: 1;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #212121;
  }
  .footer {
    display: flex;
    flex-direction: row-reverse;
    gap: 16px;
    padding-top: 24px;
  }
  .discard-text {
    flex: 1;
    text-align: right;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #616161;
    padding-top: 10px;
  }
`;

export const RenameModalStyle = styled.div`
  width: 448px;
  height: 236px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 24px;
  display: flex;
  flex-direction: column;

  .heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 24px;
  }
  .title {
    flex: 1;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #212121;
  }
  .footer {
    display: flex;
    flex-direction: row-reverse;
    gap: 16px;
    padding-top: 24px;
  }
  .discard-text {
    flex: 1;
    text-align: right;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #616161;
    padding-top: 10px;
  }
`;

export const DeleteModalStyle = styled.div`
  width: 400px;
  height: 195px;
  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 24px;
  .heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 24px;
  }
  .title {
    flex: 1;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #616161;
  }
  .footer {
    display: flex;
    flex-direction: row-reverse;
    gap: 16px;
    padding-top: 24px;
  }
  .discard-text {
    flex: 1;
    text-align: right;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #616161;
    padding-top: 10px;
    cursor: pointer;
  }
`;

export const EnablePodsModalStyle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  flex-wrap: wrap;
`;

export const KeywordsModalStyle = styled.div<{ hideExtras?: boolean }>`
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-width: 400px;
  padding: ${(props) => (props?.hideExtras ? "0" : "24px")};
  *::-webkit-scrollbar {
    display: none;
  }
  .heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 4px;
  }
  .subHeading {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #616161;
    padding-bottom: 16px;
  }
  .title {
    flex: 1;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #212121;
  }
  .footer {
    display: flex;
    flex-direction: row-reverse;
    gap: 16px;
    padding-top: 24px;
  }
  .discard-text {
    flex: 1;
    text-align: right;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #616161;
    padding-top: 10px;
    cursor: pointer;
  }

  .body-used {
    padding-bottom: 8px;
  }

  .body-div {
  }

  .used-label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #212121;
    padding-bottom: 8px;
  }
  .used-keywords {
    display: flex;
    flex-direction: row;
    gap: 12px;
    flex-wrap: wrap;
  }

  .add-label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #212121;
  }

  .add-footnote {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #616161;
  }
`;

const UploadFileStyle = styled.div`
  width: 568px;
  height: 334px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 24px;
  .heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 24px;
  }
  .title {
    flex: 1;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #616161;
  }
  .footer {
    display: flex;
    flex-direction: row-reverse;
    gap: 16px;
    padding-top: 24px;
  }
  .discard-text {
    flex: 1;
    text-align: right;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #616161;
    padding-top: 10px;
    cursor: pointer;
  }
  .error-text {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #b92321;
  }
  .upload__section > input {
    display: none;
  }
  .uploading__section,
  .uploaded__section {
    width: 544px;
    height: 260px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
  #loader {
    animation: spin 1.2s linear infinite;
  }
`;

export default UploadFileStyle;

export const DeleteVariablesStyle = styled.div`
  width: 400px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 24px;

  display: flex;
  flex-direction: column;
  row-gap: 24px;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__text {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
    }

    &__cross_icon {
    }
  }

  .body {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    column-gap: 12px;

    &__cancel_text {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }
  }
`;
