import styled, { keyframes } from "styled-components";
import { ButtonDataProps } from "./index";

const getCssFromProps = (buttonDataProps: ButtonDataProps) => {
  const ret: any = {};
  if (buttonDataProps.buttonStyle === "outline") {
    ret["background-color"] = buttonDataProps.backgroundColor
      ? buttonDataProps.backgroundColor
      : "#ffffff";
    ret["color"] = buttonDataProps.textColor
      ? buttonDataProps.textColor
      : "#8440AD";
    ret["border"] = `1px solid ${
      buttonDataProps.textColor ? buttonDataProps.textColor : "#8440AD"
    }`;
  } else if (buttonDataProps.buttonStyle === "primary") {
    ret["color"] = buttonDataProps.textColor
      ? buttonDataProps.textColor
      : "white";
    ret["background-color"] =
      buttonDataProps.isLoading && buttonDataProps.loaderBackground
        ? buttonDataProps.loaderBackground
        : buttonDataProps.backgroundColor
          ? buttonDataProps.backgroundColor
          : "#731DCF";

    if (buttonDataProps.isDisabled) {
      ret["color"] = "#848B95";
      ret["background-color"] = "#FFFFFF";
    }
  } else if (buttonDataProps.buttonStyle === "secondary") {
    ret["color"] = buttonDataProps.textColor
      ? buttonDataProps.textColor
      : "#8440AD";
    ret["background-color"] = "transparent";
  }

  if (buttonDataProps.fontSize && buttonDataProps.fontSize.length > 0) {
    ret["font-size"] = buttonDataProps.fontSize;
  }

  if (buttonDataProps.fontWeight && buttonDataProps.fontWeight.length > 0) {
    ret["font-weight"] = buttonDataProps.fontWeight;
  }
  return ret;
};

export const ButtonStyle = styled.button.attrs((props: ButtonDataProps) => {
  return {
    className:
      props.buttonSize === "small"
        ? ("button-small" as string)
        : props.buttonSize === "large"
          ? ("button-large" as string)
          : null,
    disabled: props.isDisabled ? true : props.isLoading ? true : false,
  };
})<ButtonDataProps>`
  color: ${(props) => props.color};
  width: ${(props) =>
    props.buttonType === "parent-relative" ? "100%" : "unset"};
  cursor: pointer;
  text-align: center;
  display: flex;
  gap: 8px;
  justify-content: center;
  text-overflow: ellipsis;
  align-items: center;
  font-family: inherit;
  border-radius: 4px;
  margin: ${(props) => (props.buttonMargin ? props.buttonMargin : "auto")};
  padding: ${(props) => (props.buttonPadding ? props.buttonPadding : "12px")};
  border: none;
  outline: none;
  width: ${(props) => (props.buttonWidth ? props.buttonWidth : "48px")};
  height: ${(props) => (props.buttonHeight ? props.buttonHeight : "24px")};
  disabled: ${(props) => (props.isLoading ? "true" : "false")};
  white-space: ${(props) => (props.textWrap ? "normal" : "nowrap")};
  overflow: hidden;
  ${(props) => getCssFromProps(props)};
  ${(props) => props.style as unknown as string}
`;

const Spin = keyframes`
   0% { transform: rotate(0deg); }
   100% { transform: rotate(360deg); }
`;

export const Loader = styled.div<ButtonDataProps>`
  color: ${(props) => props.textColor ?? "#ffffff"};
  margin: 0 auto;
  border: 3px solid transparent;
  border-radius: 50%;
  border-right: 3px solid #ffffff;

  border-top: 3px solid #555555;

  border-bottom: 3px solid #555555;

  width: 20px;
  height: 20px;
  animation: ${Spin} 1.3s linear infinite;
`;
export default ButtonStyle;
