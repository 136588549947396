import { useEffect, useMemo, useState } from "react";
import { RecomendedColumns } from "./CustomizedJourneys";
import AddOrEditFeatureModal from "./common/AddOrEditFeatureModal";
import CommonTable from "./common/ComonTable";
import { EachWhatsNewContent, SidebarEnums } from "./type";
import { GrowthRepo } from "./GrowthRepo";
import EditIcon from "../../icons/editIcon";
import { Button } from "@bikdotai/bik-component-library";

const WhatsNewPanel: React.FC = () => {
  const [articles, setArticles] = useState<EachWhatsNewContent[]>([]);
  const growthRepo = useMemo(() => new GrowthRepo(), []);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [itemSelectedForEditing, setItemSelectedForEditing] =
    useState<EachWhatsNewContent>();
  const [indexToUpdate, setIndexToUpdate] = useState<string>();
  const [operationtype, setOperationType] = useState<"add" | "edit">("add");

  useEffect(() => {
    setUpWhatsNew();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setUpWhatsNew = async () => {
    setLoading(true);
    const allArticles = await growthRepo.fetchBikFeaturesCustomized();
    setArticles(allArticles);
    setLoading(false);
  };

  const editIconClicked = (each: EachWhatsNewContent) => {
    setItemSelectedForEditing(each);
    setOperationType("edit");
    setIndexToUpdate(each.docId);
    setOpenModal(true);
  };
  const formWhatsNewTable = (data: EachWhatsNewContent[]) => {
    return data.map((each, index) => ({
      title: each.title,
      body: each.body,
      icons: (
        <div onClick={() => editIconClicked(each)}>
          <EditIcon height={24} width={24} />
        </div>
      ),
    }));
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Button
        style={{ margin: "16px 0px" }}
        buttonText="Add a feature"
        onClick={() => {
          setOperationType("add");
          setOpenModal(true);
        }}
      ></Button>
      {articles.length === 0 ? (
        <div>No data found</div>
      ) : (
        <CommonTable
          tableHeadData={RecomendedColumns}
          tableData={formWhatsNewTable(articles)}
          appplyAnalyticsStyle={true}
        />
      )}
      {openModal && (
        <AddOrEditFeatureModal
          isOpen={openModal}
          curentMenu={SidebarEnums["whats-new-section"]}
          onCloseModal={(reload) => {
            if (!!reload) {
              setUpWhatsNew();
            }
            setOpenModal(false);
          }}
          menuItemToEdit={
            operationtype === "edit" ? itemSelectedForEditing : undefined
          }
          operationtype={operationtype}
          indexToUpdate={indexToUpdate}
        />
      )}
    </>
  );
};

export default WhatsNewPanel;
