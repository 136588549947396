import { Icon } from "./icons-props";

const ProductRecommendIcon: Icon = (props) => {
  const { width, height } = props;
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 11.5V22.5M11 22.5L2.55279 18.2764C2.214 18.107 2 17.7607 2 17.382V7.5884C2 7.22524 2.19689 6.89062 2.51436 6.71425L10.5144 2.2698C10.8164 2.10201 11.1836 2.10201 11.4856 2.2698L19.4856 6.71425C19.8031 6.89062 20 7.22524 20 7.5884V8.5M11 22.5L17.5 18.5"
        stroke="url(#paint0_linear_39_22995)"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.5 6.5L11.4928 11.2101C11.1875 11.3897 10.8099 11.3944 10.5002 11.2223L2 6.5"
        stroke="url(#paint1_linear_39_22995)"
        stroke-width="2"
      />
      <path
        d="M17 13.5714C17 12.4074 17.9583 11 19.5 11C21.0417 11 22.5 12.368 22.5 13.5714C22.5 15.4357 20 15.3363 20 17.4286"
        stroke="url(#paint2_linear_39_22995)"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M21 20C21 20.5523 20.5523 21 20 21C19.4477 21 19 20.5523 19 20C19 19.4477 19.4477 19 20 19C20.5523 19 21 19.4477 21 20Z"
        fill="url(#paint3_linear_39_22995)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_39_22995"
          x1="2"
          y1="3.70833"
          x2="20.7435"
          y2="4.4817"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF166A" />
          <stop offset="1" stop-color="#FB5F08" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_39_22995"
          x1="2"
          y1="6.91667"
          x2="19.7731"
          y2="9.83982"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF166A" />
          <stop offset="1" stop-color="#FB5F08" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_39_22995"
          x1="17"
          y1="11.5357"
          x2="22.7277"
          y2="11.766"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF166A" />
          <stop offset="1" stop-color="#FB5F08" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_39_22995"
          x1="19"
          y1="19.1667"
          x2="21.0816"
          y2="19.2645"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF166A" />
          <stop offset="1" stop-color="#FB5F08" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ProductRecommendIcon;
