import { FunctionComponent } from "react";
import styled from "styled-components";
import { WarnIcon } from "../../icons/warnIcon";
import AlertIcon from "../../icons/alertIcon";
import { Button } from "@bikdotai/bik-component-library";

interface FooterProps {
  onClickSave: () => void;
  onClickCancel: () => void;
  error?: string;
  loading: boolean;
  isReadOnly: boolean;
}

type Props = FooterProps;

const FooterComponent: FunctionComponent<Props> = (props) => {
  const { onClickSave, onClickCancel, error, loading, isReadOnly } = props;
  return (
    <>
      {props.error && (
        <div
          style={{
            height: 36,
            background: "#FFEBEF",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="my-auto d-flex" style={{ marginRight: 12 }}>
            <AlertIcon width={20} height={20} />
          </div>
          <div className="body-secondary" style={{ color: "#B92321" }}>
            {props.error}
          </div>
        </div>
      )}
      <FooterStyle>
        <Button
          style={{ marginRight: isReadOnly ? 12 : "unset" }}
          buttonText={isReadOnly ? "Close" : "Cancel"}
          onClick={onClickCancel}
          buttonType="tertiaryGray"
        />
        {!isReadOnly && (
          <Button
            style={{ marginRight: 12 }}
            buttonText="Save"
            onClick={onClickSave}
            isLoading={loading}
          />
        )}
      </FooterStyle>
    </>
  );
};

const FooterStyle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: flex-end;
  background: #ffffff;
  height: 56px;
  border-top: 1px solid #e0e0e0;
  align-items: center;

  .cancel {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #616161;
    cursor: pointer;
  }

  .save {
    align-items: center;
    justify-content: center;
    padding: 0px 12px;
    width: 120px;
    height: 32px;
    background: #731dcf;
    border-radius: 4px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    cursor: pointer;
  }
`;

export default FooterComponent;
