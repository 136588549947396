import { CustomNodeIconProps } from "../utilities/flowBuilder.utility";

const TagNodeIcon = (props: CustomNodeIconProps) => {
  const { backgroundColor, color, height, width, style } = props;
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66668 8.33337C7.58715 8.33337 8.33334 7.58718 8.33334 6.66671C8.33334 5.74623 7.58715 5.00004 6.66668 5.00004C5.7462 5.00004 5.00001 5.74623 5.00001 6.66671C5.00001 7.58718 5.7462 8.33337 6.66668 8.33337Z"
        fill={color || "#212121"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.66668 0.833374C1.20644 0.833374 0.833344 1.20647 0.833344 1.66671V10C0.833344 10.2212 0.921274 10.4333 1.07776 10.5896L8.2356 17.7391C8.46775 17.9715 8.74341 18.1558 9.04683 18.2816C9.35032 18.4074 9.67564 18.4722 10.0042 18.4722C10.3327 18.4722 10.658 18.4074 10.9615 18.2816C11.2649 18.1559 11.5408 17.9713 11.7729 17.739L17.7476 11.7643L17.7493 11.7625C18.215 11.2941 18.4763 10.6605 18.4763 10C18.4763 9.33958 18.215 8.70595 17.7494 8.23754L10.5893 1.07745C10.433 0.921171 10.221 0.833374 10 0.833374H1.66668ZM16.5681 10.5868L16.5673 10.5875L10.5938 16.5611C10.5164 16.6386 10.4245 16.7001 10.3233 16.742C10.2221 16.7839 10.1137 16.8055 10.0042 16.8055C9.89466 16.8055 9.78622 16.7839 9.68506 16.742C9.58389 16.7001 9.49199 16.6386 9.41459 16.5611L9.41392 16.5604L2.50001 9.65458V2.50004H9.65483L16.5673 9.41254L16.5681 9.41331C16.7228 9.56937 16.8097 9.78025 16.8097 10C16.8097 10.2198 16.7228 10.4307 16.5681 10.5868Z"
        fill={color || "#212121"}
      />
    </svg>
  );
};

export default TagNodeIcon;
