import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { KeyboardEventState } from "./storeDataStateType";

const initialState: KeyboardEventState = {
  keyPressAction: "",
};

const keyboardEventDataSlice = createSlice({
  name: "keyboardEventData",
  initialState: initialState,
  reducers: {
    keyPressed: (state, action: PayloadAction<KeyboardEventState>) => {
      state = action.payload;
      return state;
    },
  },
});

export const keyboardEventDataActions = keyboardEventDataSlice.actions;
export default keyboardEventDataSlice;
