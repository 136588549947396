import Styled from "styled-components";
import { InputDataProps } from "./InputModel";
import styled from "styled-components";

export const SpanStyle = styled.div`
  ${(props) => props.theme.fonts.bodyPrimary};
  color: ${(props) => props.theme.colors.content.placeholder} !important;
`;

export const LabelStyle = Styled.div`
  .label {
    padding-bottom: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #212121;
}
`;

export const InputWrapper = Styled.div.attrs((props: InputDataProps) => ({
  className:
    props.iconLeft || (props.iconLeft && props.iconRight) ? "margin16" : null,
}))<InputDataProps>`
  position: relative;
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "46px")};
  box-sizing: border-box;
  order: ${(props) => (props.iconLeft ? 2 : 1)};

  &.margin16 {
    margin-left: 16px
  }

  input {
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    position: absolute;
    bottom: 0;
    padding-left: 0;
    ${(props) => props.theme.fonts.bodyPrimary};
    z-index: 1;
    box-sizing: border-box;
    -webkit-background-clip: text !important;
  }
`;

export const ErrorMain = Styled.div.attrs((props: InputDataProps) => {
  return { className: [props.error ? "error" : "subText"] };
})<InputDataProps>`
  &.error {
    color: #D53434;
    font-family: inherit;
    font-weight: 500;
    font-size: 12px;
    height: fit-content;
    margin-top: 4px;
  }

  &.subText {
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    font-size: 12px;
    height: fit-content;
    margin-top: 4px;
  }
`;
/// //Input type file
export const FileWrapper = Styled.div<InputDataProps>`
   width: 100%;
   min-height: ${(props) => (props.height ? props.height : "46px")};
   position: relative;
   display: flex;
   flex-direction: column;
   justify-content: center;
`;
export const FileLabel = Styled.label.attrs((props: InputDataProps) => ({
  className: [props.fileName ? "caption" : "body-small"],
}))<InputDataProps>`
  display: block;
  margin: 0;
  color: ${(props) => (props.error ? "red" : "grey")}

`;
export const FileContainer = Styled.div`
   width: 100%;
   position: relative;

   input {
    width: 100%;
    position: absolute;
    opacity: 0;
    z-index: 999999;
    box-sizing: border-box;
   }
`;
export const FileName = Styled.span`
   top: 0;
   left: 0;
   color: black;
`;
export const SVGWrapper = Styled.div``;

/// //Input type date
export const DateWrapper = Styled.div<InputDataProps>`
   width: 100%;
   height: ${(props) => (props.height ? props.height : "46px")};
   position: relative;

   input{
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    font-size: 14px;
    position: absolute;
    bottom: 0%;
    padding-top: 15px;
    box-sizing: border-box;
    background-color: transparent;

    &::-webkit-inner-spin-button {
      display: none;
    }
    &::-webkit-inner-spin-button {
      display: none;
    }
    &::-webkit-calendar-picker-indicator {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0, -50%);
      font-size: unset;
      background-image:url("data:image/svg+xml, %3Csvg%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20width%3D%2224%22%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20height%3D%2224%22%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20viewBox%3D%220%200%2024%2024%22%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20fill%3D%22none%22%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%0A%20%20%20%20%20%20%20%20%20%20%20%20%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20fillRule%3D%22evenodd%22%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20clipRule%3D%22evenodd%22%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20d%3D%22M17%202C17%201.44772%2016.5523%201%2016%201C15.4477%201%2015%201.44772%2015%202V3H9V2C9%201.44772%208.55228%201%208%201C7.44772%201%207%201.44772%207%202V3H5C3.34315%203%202%204.34315%202%206V20C2%2021.6569%203.34315%2023%205%2023H19C20.6569%2023%2022%2021.6569%2022%2020V6C22%204.34315%2020.6569%203%2019%203H17V2ZM20%209V6C20%205.44772%2019.5523%205%2019%205H17V6C17%206.55228%2016.5523%207%2016%207C15.4477%207%2015%206.55228%2015%206V5H9V6C9%206.55228%208.55228%207%208%207C7.44772%207%207%206.55228%207%206V5H5C4.44772%205%204%205.44772%204%206V9H20ZM4%2011H20V20C20%2020.5523%2019.5523%2021%2019%2021H5C4.44772%2021%204%2020.5523%204%2020V11Z%22%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20fill%3D%22%238C8E95%22%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%2F%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3C%2Fsvg%3E")
    }
   }
`;
export const DateLabel = Styled.label.attrs(() => ({
  className: ["caption"],
}))<InputDataProps>`
  display: block;
  transform-origin: top left;
  position: absolute;
  top: 12%;
  left: 0;
  z-index: 1;
  color: ${(props) => (props.error ? "red" : "grey")}

`;

export const InputMain = Styled.div.attrs((props: InputDataProps) => {
  return {
    className: [
      props.iconLeft || (props.iconLeft && props.iconRight)
        ? "orderElems"
        : null,
      props.error ? "error" : null,
    ],
  };
})<InputDataProps>`
  width: 100%;
  border: 1px solid #d6dbe2;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => (props?.padding ? props.padding : "11px 16px")};
  font-family: inherit;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  box-sizing: border-box;
  outline: 1px solid white;
  background-color: ${(props) => (props.isDisabled && !props.background ? "#e8eced" : props.background ? props.background : "#FFFFFF")};

  &.error {
    outline: ${(props) => `1px solid red`} !important;
    border: 1px solid #b92321 !important;
    color: #b92321;
  }
  
  .iconLeft {
    order: 1;
  }
  
  .iconRight {
    order: 3
  }
  
  &:focus-within {
    outline: ${(props) => `1px solid ${!props.error ? props.theme.colors.content.brand : "#b92321"}`} !important;
    border: ${(props) => `1px solid ${props.theme.colors.content.brand}`};
    color: ${(props) => (!props.error ? "purple" : "#b92321")};
  }

  &:focus-within ${DateWrapper} ${DateLabel} {
    color: ${(props) => (!props.error ? "purple" : "#b92321")}
  }
`;

export const SearchInputStyle = Styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px;
    gap: 12px;
    width: 100%;
    height: 47px; 
    background: #FFFFFF; 
    border-radius: 4px;
    border-bottom: 1px solid #E0E0E0;
     input {
     border: none
     }
     input: focus {
     outline: none;
     }
`;
