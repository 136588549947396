import { Icon } from "./icons-props";

export const MetaLogoIcon: Icon = (props) => {
  const { height, width } = props;
  return (
    <svg
      width={height || "20"}
      height={width || "20"}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3308_33725)">
        <path
          d="M2.78637 11.984C2.78637 12.6864 2.94028 13.2235 3.14144 13.5492C3.24864 13.7346 3.40301 13.8884 3.58886 13.9948C3.77472 14.1012 3.98544 14.1566 4.19961 14.1552C4.71689 14.1552 5.19009 14.0275 6.10203 12.7656C6.83259 11.7547 7.69344 10.3389 8.27329 9.44486L9.2542 7.93774C9.93559 6.89106 10.723 5.72752 11.6285 4.93628C12.3661 4.29512 13.1625 3.9375 13.9639 3.9375C15.3095 3.9375 16.5912 4.71724 17.5721 6.17965C18.6456 7.78128 19.1686 9.79865 19.1686 11.8805C19.1686 13.1181 18.9246 14.0275 18.5095 14.7459C18.1091 15.4407 17.3249 16.1349 16.01 16.1349V14.1552C17.1359 14.1552 17.415 13.1207 17.415 11.9367C17.415 10.2495 17.0216 8.3771 16.155 7.03922C15.54 6.09025 14.743 5.51039 13.8662 5.51039C12.9179 5.51039 12.1548 6.22563 11.2971 7.50093C10.8411 8.1785 10.373 9.00422 9.84746 9.93595L9.26888 10.9577C8.10662 13.0185 7.81222 13.4879 7.23109 14.2625C6.21442 15.624 5.34464 16.1349 4.19961 16.1349C2.84129 16.1349 1.98237 15.5468 1.4536 14.6604C1.01615 13.9381 0.802856 12.9904 0.802856 11.9105L2.78637 11.984Z"
          fill="#0081FB"
        />
        <path
          d="M2.36682 6.31951C3.2762 4.91776 4.58854 3.9375 6.09374 3.9375C6.96544 3.9375 7.83203 4.19294 8.73694 4.93437C9.72678 5.74221 10.7805 7.07243 12.0979 9.26477L12.5699 10.0515C13.7091 11.9495 14.358 12.9253 14.7367 13.3863C15.2246 13.9777 15.5668 14.1527 16.01 14.1527C17.1359 14.1527 17.415 13.1181 17.415 11.9342L19.1648 11.8792C19.1648 13.1169 18.9208 14.0262 18.5057 14.7447C18.1091 15.4407 17.3249 16.1349 16.01 16.1349C15.1926 16.1349 14.4684 15.9574 13.6676 15.2019C13.052 14.6221 12.3323 13.592 11.7786 12.666L10.1317 9.91487C9.3053 8.5342 8.54727 7.50476 8.10855 7.04114C7.63598 6.53727 7.02994 5.93187 6.06181 5.93187C5.27824 5.93187 4.61281 6.48171 4.05594 7.32276L2.36682 6.31951Z"
          fill="url(#paint0_linear_3308_33725)"
        />
        <path
          d="M6.06179 5.93187C5.27822 5.93187 4.61279 6.48171 4.05593 7.32276C3.26852 8.51121 2.78637 10.2814 2.78637 11.984C2.78637 12.6864 2.94028 13.2235 3.14144 13.5492L1.4504 14.6604C1.01615 13.9381 0.802856 12.9904 0.802856 11.9105C0.802856 9.9468 1.34184 7.90006 2.36681 6.31951C3.27619 4.91776 4.58852 3.9375 6.09372 3.9375L6.06179 5.93187Z"
          fill="url(#paint1_linear_3308_33725)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_3308_33725"
          x1="4.78395"
          y1="9.7201"
          x2="17.4284"
          y2="10.3587"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#0064E1" />
          <stop offset="0.4" stop-color="#0064E1" />
          <stop offset="0.83" stop-color="#0073EE" />
          <stop offset="1" stop-color="#0082FB" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3308_33725"
          x1="3.44797"
          y1="12.8142"
          x2="3.44797"
          y2="8.15231"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#0082FB" />
          <stop offset="1" stop-color="#0064E0" />
        </linearGradient>
        <clipPath id="clip0_3308_33725">
          <rect
            width="18.3638"
            height="12.1974"
            fill="white"
            transform="translate(0.802795 3.9375)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
