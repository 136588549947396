import { CustomNodeIconProps } from "../utilities/flowBuilder.utility";

const BackInStockIcon = (props: CustomNodeIconProps) => {
  const { backgroundColor, color, height, width } = props;
  return (
    <svg
      width={width || "16"}
      height={height || "16"}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.14276 2.01979L2.5999 4.5436V10.3912L6.54276 12.3626V6.76198H7.74276V12.4088C7.74276 13.2796 6.82634 13.846 6.04745 13.4566L2.04745 11.4566C1.65059 11.2581 1.3999 10.8525 1.3999 10.4088V4.52678C1.3999 4.10136 1.63055 3.70938 2.00243 3.50277L6.57386 0.96309C6.92766 0.766535 7.35786 0.766535 7.71165 0.96309L12.2831 3.50277C12.655 3.70938 12.8856 4.10136 12.8856 4.52678V7.33342H11.6856V4.5436L7.14276 2.01979Z"
        fill={color || "#1A872C"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.13423 6.07066L2.29127 3.38013L1.7085 4.42912L6.56574 7.12758C6.92852 7.32913 7.37086 7.32368 7.72857 7.11327L12.3041 4.42178L11.6957 3.38746L7.13423 6.07066Z"
        fill={color || "#1A872C"}
      />
      <path
        d="M11.057 8.11823C11.5438 7.97742 12.0584 7.96222 12.5526 8.07406C13.0469 8.18589 13.5048 8.42111 13.8836 8.75778C14.2624 9.09444 14.5497 9.52156 14.7187 9.9993C14.8109 10.2596 14.6745 10.5453 14.4142 10.6375C14.1539 10.7296 13.8681 10.5932 13.776 10.3329C13.6633 10.0144 13.4718 9.72966 13.2193 9.50521C12.9667 9.28077 12.6615 9.12396 12.332 9.0494C12.0024 8.97484 11.6594 8.98498 11.3349 9.07885C11.0103 9.17272 10.7149 9.34728 10.476 9.58623C10.4723 9.58993 10.4686 9.59357 10.4648 9.59714L10.0957 9.94394H10.5002C10.7763 9.94394 11.0002 10.1678 11.0002 10.4439C11.0002 10.7201 10.7763 10.9439 10.5002 10.9439H8.8335C8.71728 10.9439 8.61033 10.9043 8.52543 10.8378C8.40963 10.7471 8.33485 10.6064 8.33351 10.4482L8.3335 10.4439V8.77727C8.3335 8.50113 8.55735 8.27727 8.8335 8.27727C9.10964 8.27727 9.3335 8.50113 9.3335 8.77727V9.28795L9.77455 8.87351C10.1318 8.51805 10.5728 8.25828 11.057 8.11823Z"
        fill={color || "#1A872C"}
      />
      <path
        d="M15.4448 11.555C15.4448 11.2906 15.2396 11.0741 14.9797 11.0562C14.9682 11.0554 14.9565 11.055 14.9448 11.055H13.2782C13.002 11.055 12.7782 11.2789 12.7782 11.555C12.7782 11.8312 13.002 12.055 13.2782 12.055H13.6823L13.3133 12.4017C13.3095 12.4053 13.3058 12.4089 13.3021 12.4126C13.0632 12.6516 12.7678 12.8261 12.4432 12.92C12.1187 13.0139 11.7757 13.024 11.4461 12.9495C11.1166 12.8749 10.8114 12.7181 10.5588 12.4937C10.3063 12.2692 10.1148 11.9845 10.0021 11.666C9.90996 11.4056 9.62425 11.2693 9.36392 11.3614C9.1036 11.4535 8.96724 11.7392 9.05936 11.9996C9.22842 12.4773 9.51574 12.9044 9.89452 13.2411C10.2733 13.5778 10.7312 13.813 11.2255 13.9248C11.7197 14.0366 12.2343 14.0215 12.7211 13.8806C13.2053 13.7406 13.6463 13.4808 14.0036 13.1254L14.4448 12.7107V13.2217C14.4448 13.4978 14.6687 13.7217 14.9448 13.7217C15.221 13.7217 15.4448 13.4978 15.4448 13.2217V11.555Z"
        fill={color || "#1A872C"}
      />
    </svg>
  );
};

export default BackInStockIcon;
