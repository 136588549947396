import { useEffect, useRef, useState } from "react";
import { ChatbotBuilderStyled } from "./style";
import NodeProvider from "../../ui-components/flow-builder/nodeProvider";
import { useLocation, useParams } from "react-router-dom";
import { useAppSelector } from "../../state/store";
import "react-toastify/dist/ReactToastify.css";
import {
  NotificationDataState,
  userDataState,
} from "../../state/storeDataStateType";
import { notificationDataStateActions } from "../../state/notificationDataState";
import { useDispatch } from "react-redux";
import SideModal, {
  SideModalProps,
} from "../../ui-components/side-modal/sideModal";
import {
  sideModalConfigsWhatsapp,
  sideModalConfigsInstagram,
  sideModalConfigsGmail,
} from "../../ui-components/modal-configs/whatsappMessageBlockConfigs";
import ComponentModal from "../../ui-components/component-modal/componentModal";
import AddNodePopup from "../../ui-components/flow-builder/addNodePopup";
import { NodeConfig } from "../../utilities/flowBuilder.utility";
import { homeDataAction } from "../../state/homeState";
import {
  FirebaseService,
  PAGES,
  PartnerStoreSource,
} from "../../services/firebase/FirebaseService";
import { flowMetaStateActions } from "../../state/flowMetaState";
import { useOutside } from "../../ui-components/rich-text-editor/useOutside";
import { NodeActionActions } from "../../state/nodeActionState";
import { ErrorStateHelper } from "../../services/helpers/errorStateHelper";
import { errorDataActions } from "../../state/errorDataState";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { isProd } from "../../config";
import { COLORS, Toaster } from "@bikdotai/bik-component-library";
import { useDetectAnalytics } from "../../ui-components/flow-builder/useDetectAnalytics";
import useKeyboardEventListener from "../../utilities/keyboardEventListner";
import { storeInfoStateActions } from "../../state/storeInfoState";
import ReactJoyride, { STATUS, Step } from "react-joyride";
import TourTooltip from "../tour-tooltip/tourTooltip";
import {
  tourSteps,
  tourStepsAnalytics,
} from "../../app/messageBlock/Constants";
import { useProductTourMeta } from "../../utilities/productTourHook";
import {
  AmplitudeEventStep,
  AmplitudeKeys,
  TargetPlatform,
} from "../../utilities/AnalyticsTypes";
import { RecordEventHelper } from "../../utilities/RecordEventHelpers";
import { BikStore } from "@bikdotai/bik-models/growth/models/store";
import { getCurrentUser } from "../../services/helpers/StoreHelper";
interface ChatbotBuilderProps {
  userData: userDataState;
  forceFetch?: boolean;
  showTour: number;
}

if (isProd) {
  Sentry.init({
    dsn: "https://dd96b51ce03e48dfbd12e1cca3bbfd1f@o1418593.ingest.sentry.io/4504872250638336",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    beforeSend: (event) => {
      if (window.location.hostname === "localhost") {
        return null;
      }
      return event;
    },
  });
}

const ChatbotBuilder = (props: ChatbotBuilderProps) => {
  const { storeId, flowId, type, channel } = useParams();
  const homeState = useAppSelector((state) => state.homeState);
  const dispatcher = useDispatch();
  const notificationDataState = useAppSelector(
    (state) => state.notificationDataState
  );
  const nodeClickState = useAppSelector((state) => state.nodeClickState);
  const [showRightModal, setShowRightModal] = useState<boolean>(false);
  const [config, setConfig] = useState<NodeConfig>(new NodeConfig());
  const [index, setIndex] = useState<number>(1);
  const [newNodeData, setNewNodeData] = useState<{ [key: string]: any }>({});
  const [showLeftModal, setShowLeftModal] = useState<boolean>(false);
  const isAnalytics = useDetectAnalytics();
  const location = useLocation();
  const eventHelper = RecordEventHelper.getInstance();

  const nodeSubtypeMapping = useAppSelector(
    (state) => state.nodeSubtypeMappingState
  );
  const firebaseService = new FirebaseService(
    () => {},
    () => {}
  );
  const superRef = useRef<HTMLDivElement | null>(null);
  const nodeActions = useAppSelector((state) => state.nodeActionState);
  const [sideModalConfigs, setSideModalConfigs] = useState<{
    [key: string]: Partial<SideModalProps>;
  }>({});
  const errorStateComplete = useAppSelector((state) => state.errorDataState);
  const [toasterString, setToasterString] = useState("");
  const [showToaster, setShowToaster] = useState(false);
  const [toasterType, setToasterType] = useState<any>("info");
  const [steps, setSteps] = useState<Array<Step>>(tourSteps);
  const [runTour, setRunTour] = useState<boolean>(false);
  const [resetPort, setResetPort] = useState<number>(0);
  const { fetchPageTourStatus, updatePageTourStatus, productTourMeta } =
    useProductTourMeta();
  const prevTourRef = useRef(props.showTour);

  //Initializing Keyboard Event listener - This captures keyboard event and handles them accordingly.
  useKeyboardEventListener();

  useOutside(superRef, () => setShowRightModal(false));

  useEffect(() => {
    if (channel && channel.toLowerCase() === "instagram") {
      setSideModalConfigs(sideModalConfigsInstagram);
    } else if (channel && channel.toLowerCase() === "email") {
      setSideModalConfigs(sideModalConfigsGmail);
    } else {
      setSideModalConfigs(sideModalConfigsWhatsapp);
    }
  }, [channel]);

  useEffect(() => {
    const fetchData = async () => {
      let storedUser = JSON.parse(
        localStorage.getItem("Bik/CurrentUser") || "{}"
      );

      if (
        !Object.keys(storedUser).length ||
        storedUser.email !== homeState?.userData?.email
      ) {
        const currentUser = await getCurrentUser(
          homeState?.storeId,
          homeState?.userData?.email ?? ""
        );

        localStorage.setItem("Bik/CurrentUser", JSON.stringify(currentUser));
        storedUser = currentUser;
      }

      dispatcher(
        homeDataAction.updateState({
          userData: { ...homeState?.userData, agentId: storedUser.id },
        })
      );

      const bikStoreData: BikStore = (await firebaseService.getBikStoreData(
        storeId || ""
      )) as BikStore;

      const eventHelper = RecordEventHelper.getInstance();
      eventHelper.init(bikStoreData);
    };

    if (homeState?.userData?.email && homeState?.storeId) {
      fetchData();
    }
  }, [homeState?.userData?.email, homeState?.storeId]);

  useEffect(() => {
    // get store info
    firebaseService
      .getStoreInfo(storeId || "")
      .then((data) => {
        dispatcher(
          storeInfoStateActions.addStoreInfoData({
            currency: data?.currency || "INR",
            countryCode: data?.countryCode || "IND",
            partnerStoreDomain:
              data?.storeIntegrationStatus?.partnerStoreDomain,
            chatbotAttributionWindow:
              data?.attributionDetails?.chatbotAttributionWindow || 0,
            productCount: data?.shopifyStats?.productCount || 0,
            isNonShopifyStore:
              (data?.partnerStore?.metaData?.source ?? "")?.toLowerCase() ===
                PartnerStoreSource.CUSTOM || false,
          })
        );
      })
      .catch();
    firebaseService.getFlowKeywordsByFlowId(storeId!, flowId!).then((data) => {
      dispatcher(
        flowMetaStateActions.setFlowMeta({
          flowMeta: {
            [flowId!]: data,
          },
          updateFlowMeta: true,
        })
      );
      if (
        data &&
        data.compilationStatus &&
        data.compilationStatus !== "SUCCESS"
      ) {
        firebaseService
          .getCompilationReportByFlowId(storeId!, flowId!)
          .then((data) => {
            const formattedErrorState = new ErrorStateHelper().formatErrors(
              data,
              flowId!
            );
            if (data && (data.compilation_date || data.compilationDate)) {
              delete data["compilation_date"];
              delete data["compilationDate"];
            }
            dispatcher(
              errorDataActions.updateErrorState({
                ...errorStateComplete,
                errorModalOpenStatus: false,
                errors: { ...formattedErrorState.errors },
                errorsCount: formattedErrorState.errorsCount,
                warningsCount: formattedErrorState.warningsCount,
              })
            );
            if (formattedErrorState.errorsCount > 0) {
              setTimeout(() => {
                dispatcher(
                  errorDataActions.updateErrorState({
                    ...errorStateComplete,
                    errorModalOpenStatus: formattedErrorState.errorsCount > 0,
                    errors: { ...formattedErrorState.errors },
                    errorsCount: formattedErrorState.errorsCount,
                    warningsCount: formattedErrorState.warningsCount,
                  })
                );
              }, 2000);
            }
          });
      }
      const today = new Date();
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);

      const last7Days = new Date(today.getTime());
      last7Days.setDate(last7Days.getDate() - 6);
      const startDate = last7Days.getTime();
      const endDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        23,
        59,
        59
      ).getTime();

      dispatcher(
        homeDataAction.addState({
          storeId: storeId || "",
          flowId: flowId || "",
          flowName: data?.flowName || "",
          type: type || "",
          channel: channel || "",
          userData: props.userData,
          compilationStatus: data?.compilationStatus,
          status: data?.status,
          sunset: data?.isSunset,
          testMode: data?.isTestMode,
          lastUpdated: data?.lastUpdated || "",
          analyticsData: {
            startDate: startDate,
            endDate: endDate,
          },
          createdAt: data?.createdAt,
          journeyType: data?.journeyType || "utility",
          dndEnabled: data?.dndEnabled,
        })
      );
    });
  }, [Object.keys(props?.userData)?.length]);

  useEffect(() => {
    if (
      (notificationDataState?.errorMessage || "").length ||
      (notificationDataState?.successMessage || "").length ||
      (notificationDataState?.warningMessage || "").length
    ) {
      showNotification(notificationDataState);
      dispatcher(
        notificationDataStateActions.setNotifications({
          errorMessage: "",
          successMessage: "",
          warningMessage: "",
        })
      );
    }
  }, [
    notificationDataState.errorMessage,
    notificationDataState.successMessage,
    notificationDataState.warningMessage,
  ]);

  useEffect(() => {
    if (nodeClickState.nodeId) {
      setConfig((prevState: NodeConfig) => {
        return {
          ...prevState,
          nodeId: nodeClickState.nodeId,
          type: nodeClickState.nodeType!,
          subType: nodeClickState.nodeSubType!,
          nodeIndex: nodeClickState.nodeIndex!,
          automationChannel: nodeClickState.automationChannel!,
        };
      });

      if (nodeClickState.hideLeftModal) {
        setShowLeftModal(false);
      } else {
        setShowLeftModal(true);
      }
    }
  }, [nodeClickState.nodeId]);

  useEffect(() => {
    if (nodeActions?.type === "node" && nodeActions?.action === "created") {
      setNewNodeData({});
      dispatcher(
        NodeActionActions.updateState({
          type: "",
          action: "",
          nodeId: "",
        })
      );
    }
  }, [nodeActions]);

  const rightModalToggler = () => {
    setShowRightModal((prevState: boolean) => !prevState);
  };

  const closeLeftModal = () => {
    setShowLeftModal(false);
  };

  const addNewNode = (nodeName: string) => {
    if (nodeName === "Condition") {
      const id = getNodeId();
      const index = (nodeSubtypeMapping.data?.[nodeName] || 0) + 1;
      setNewNodeData({
        type: nodeName,
        subType: nodeName,
        nodeIndex: index,
        title: `Condition #${index}`,
        id: id,
      });
      setShowRightModal(false);
    } else if (nodeName === "Tags") {
      const id = getNodeId();
      const index = (nodeSubtypeMapping.data?.["add_tags"] || 0) + 1;
      setNewNodeData({
        type: "Action",
        subType: "add_tags",
        nodeIndex: index,
        title: `Tags #${index}`,
        id: id,
      });
      setShowRightModal(false);
    } else if (nodeName === "Start flow") {
      const id = getNodeId();
      const index = (nodeSubtypeMapping.data?.["start_flow"] || 0) + 1;
      setNewNodeData({
        type: "Action",
        subType: "start_flow",
        nodeIndex: index,
        title: `Start flow #${index}`,
        id: id,
      });
      eventHelper.trackEvent(
        TargetPlatform.Amplitude,
        AmplitudeKeys.service_used,
        {
          step: AmplitudeEventStep.block_used,
          blockName: "start_flow",
          screen: "journey_builder",
        }
      );
      setShowRightModal(false);
    } else if (nodeName === "Sticky notes") {
      const index = (nodeSubtypeMapping.data?.["sticky_notes"] || 0) + 1;
      setNewNodeData({
        type: nodeName,
        subType: "sticky_notes",
        nodeIndex: index,
        title: `Sticky notes #${index}`,
        id: `S${index}`,
      });
      setShowRightModal(false);
      eventHelper.trackEvent(
        TargetPlatform.Amplitude,
        AmplitudeKeys.service_used,
        {
          step: AmplitudeEventStep.block_used,
          blockName: "sticky_notes",
          screen: "journey_builder",
        }
      );
    } else if (nodeName === "Flow control") {
      setSideModalConfigs(sideModalConfigsInstagram);
      setNewNodeData({
        type: "Condition",
      });
      setShowRightModal(true);
    } else {
      if (
        nodeName === "Whatsapp" ||
        nodeName === "Instagram" ||
        nodeName === "Email"
      ) {
        nodeName === "Whatsapp"
          ? setSideModalConfigs(sideModalConfigsWhatsapp)
          : nodeName === "Instagram"
            ? setSideModalConfigs(sideModalConfigsInstagram)
            : setSideModalConfigs(sideModalConfigsGmail);
        setNewNodeData({
          type: "Message",
        });
        setShowRightModal(true);
      } else {
        channel === "instagram"
          ? setSideModalConfigs(sideModalConfigsInstagram)
          : channel === "whatsapp"
            ? setSideModalConfigs(sideModalConfigsWhatsapp)
            : setSideModalConfigs(sideModalConfigsGmail);
        const type = nodeName === "Bik AI" ? "AI" : nodeName;
        setNewNodeData({ type });
        setShowRightModal(true);
      }
    }
  };

  const setNodeDataFromSideModal = (
    blockId: string,
    blockName: string,
    blockChannel?: string
  ) => {
    const id = getNodeId();
    const index = (nodeSubtypeMapping.data?.[blockId] || 0) + 1;
    setNewNodeData((prevState: { [key: string]: string | number }) => {
      return {
        ...prevState,
        subType: blockId,
        nodeIndex: index,
        title: `${blockName} #${index}`,
        automationChannel: blockChannel || "",
        id: id,
      };
    });
    setShowRightModal((prevState: boolean) => !prevState);
  };

  const showNotification = (notificationDataState: NotificationDataState) => {
    if (notificationDataState.successMessage) {
      setToasterString(notificationDataState.successMessage);
      setToasterType("success");
      setShowToaster(true);
    } else if (notificationDataState.errorMessage) {
      setToasterString(notificationDataState.errorMessage);
      setToasterType("error");
      setShowToaster(true);
    } else if (notificationDataState.warningMessage) {
      setToasterString(notificationDataState.warningMessage);
      setToasterType("warning");
      setShowToaster(true);
    }
  };

  const getNodeId = (): string => {
    let ix = index + 1;
    setIndex(ix);
    return `N${ix}`;
  };

  const resetRedux = () => {
    dispatcher({ type: "reset" });
    setIndex((prevIndex: number) => 1);
  };

  useEffect(() => {
    return () => {
      resetRedux();
    };
  }, []);

  useEffect(() => {
    if (!homeState.userData || !homeState.userData.uid) {
      return;
    }
    if (homeState.userData && homeState.userData?.loginType === "sso") {
      setRunTour(false);
      return;
    }
    if (!props.showTour || prevTourRef.current === props.showTour) {
      return;
    }

    prevTourRef.current = props.showTour;
    setRunTour(false);
    setResetPort((prev) => prev + 1);
    const isAnalyticsPath =
      location.pathname.endsWith("/analytics") ||
      location.pathname.includes("/analytics/login/");
    if (isAnalyticsPath) {
      setTimeout(() => {
        setSteps(tourStepsAnalytics);
        setRunTour(true);
      }, 1000);
    } else {
      setTimeout(() => {
        setSteps(tourSteps);
        setRunTour(true);
      }, 1000);
    }
  }, [props.showTour, homeState.userData]);

  useEffect(() => {
    if (!homeState.userData || !homeState.userData.uid) {
      return;
    }
    if (homeState.userData && homeState.userData?.loginType === "sso") {
      setRunTour(false);
      return;
    }
    setRunTour(false);
    const isAnalyticsPath =
      location.pathname.endsWith("/analytics") ||
      location.pathname.includes("/analytics/login/");

    if (isAnalyticsPath) {
      setShowRightModal(false);
      setShowLeftModal(false);
    }

    setTimeout(() => {
      const enabled = (function () {
        if (typeof localStorage === "undefined") {
          return false;
        }
        return !fetchPageTourStatus(
          isAnalyticsPath ? PAGES.NLA_ANALYTICS : PAGES.JOURNEY_BUILDER_HOME
        );
      })();
      if (!enabled) {
        return;
      }
      if (isAnalyticsPath) {
        setResetPort((prev) => prev + 1);
        setSteps(tourStepsAnalytics);
        setRunTour(true);
      } else {
        setResetPort((prev) => prev + 1);
        setSteps(tourSteps);
        setRunTour(true);
      }
    }, 3000);
  }, [location, homeState.userData]);

  return (
    <>
      {showToaster && (
        <Toaster
          text={toasterString}
          type={toasterType}
          autoClose={3000}
          onCloseToast={() => {
            setShowToaster(false);
          }}
          width={"300px"}
        />
      )}
      <ReactJoyride
        steps={steps}
        disableScrolling={false}
        disableScrollParentFix={false}
        run={runTour}
        scrollToFirstStep={true}
        tooltipComponent={TourTooltip}
        continuous
        showSkipButton
        callback={(data) => {
          if (
            ([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(
              data.status
            ) ||
            ["skip", "reset", "close"].includes(data.action)
          ) {
            const isAnalyticsPath =
              location.pathname.endsWith("/analytics") ||
              location.pathname.includes("/analytics/login/");
            const currentUser = JSON.parse(
              localStorage.getItem("journey_login_uid") || "{}"
            );
            if (currentUser) {
              updatePageTourStatus(
                storeId!,
                currentUser,
                isAnalyticsPath
                  ? PAGES.NLA_ANALYTICS
                  : PAGES.JOURNEY_BUILDER_HOME
              ).then();
            }
          }
        }}
        styles={{
          buttonNext: {
            background: COLORS.background.brand,
          },
          tooltipContainer: {
            textAlign: "left",
          },
          options: {
            primaryColor: COLORS.content.brand,
            spotlightShadow: "0px 0px 14px 0px rgba(255, 255, 255, 0.90)",
          },
        }}
      />
      <ChatbotBuilderStyled>
        <NodeProvider
          forceFetch={props?.forceFetch}
          newNodeData={newNodeData}
          storeId={storeId!}
          flowId={flowId!}
          type={type!}
          setIndex={(idx) => setIndex(idx)}
          index={index}
          getNodeId={getNodeId}
          resetViewPort={resetPort}
        />
        <div
          ref={superRef}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {!isAnalytics && (
            <AddNodePopup
              click={addNewNode}
              isActive={showRightModal}
              activeNode={newNodeData?.type?.toString()}
              channel={channel as string}
            />
          )}

          {sideModalConfigs[newNodeData?.type]?.subSections &&
            !PreventSideBar[newNodeData?.subType] && (
              <SideModal
                isOpen={showRightModal}
                storeId={storeId!}
                header={sideModalConfigs[newNodeData?.type]?.header!}
                caption={sideModalConfigs[newNodeData?.type]?.caption!}
                subSections={sideModalConfigs[newNodeData?.type]?.subSections!}
                closeModal={rightModalToggler}
                setNewNodeData={setNodeDataFromSideModal}
              />
            )}
        </div>
        {showLeftModal && !isAnalytics && (
          <ComponentModal
            storeId={storeId!}
            flowId={flowId!}
            config={config}
            closeModal={closeLeftModal}
          />
        )}
      </ChatbotBuilderStyled>
    </>
  );
};

const PreventSideBar: { [key: string]: boolean } = {
  Condition: true,
  add_tags: true,
  start_flow: true,
};

export default ChatbotBuilder;
