import styled from "styled-components";

export const GrowthContainer = styled.div`
  .growth-global-container {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .growth-component-container {
    flex: 1 1 0%;
    background-color: #f0f0f0;
    padding: 24px;
  }
`;

export const TabContent = styled.div`
  padding: 24px;
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .each--row {
    display: flex;
    flex-direction: row;
    padding: 12px;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 16px;
  }
`;

export const TailoredTopSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 24px;
`;

export const AddOrEditFeatureModalContainer = styled.div`
  margin: 20px;
  margin-top: -10px;
  max-height: 600px;
  overflow-y: auto;
  .each--section {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
    width: 100%;
  }
`;

export const CustomizedSection = styled.div`
  .all-journey-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 16px;
  }
  .chip--wrapper {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    flex-wrap: wrap;
  }
  .selected--feature--container {
    padding: 4px 8px 4px 8px;
    gap: 6px;
    border-radius: 4px;
    border: 1px solid #ddccf0;
    opacity: 0px;
    background-color: #efe6f9;
  }
  .feature--text {
    color: #4b1583 !important;
  }
  .feature--wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
  }
`;

export const BackgroundImageContainer = styled.div<{
  imageUrl: string;
  height?: string;
  width?: string;
}>`
  height: ${(props) => props.height || "140px"};
  background: ${(props) => (props.imageUrl ? `url(${props.imageUrl})` : "")};
  background-repeat: no-repeat;
  width: ${(props) => props.width || props.height || "140px"};
  background-size: cover;
`;
