import { COLORS } from "@bikdotai/bik-component-library";
import styled from "styled-components";

export const PageTwoStyled = styled.div`
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  padding: 28px 24px 28px 24px;
  overflow-y: scroll;
`;

export const CellHeaderStyled = styled.div`
  display: flex;
  width: 218px;
  height: 44px;
  justify-content: flex-start;
  align-items: center;
`;

export const StyledAddField = styled.div`
  display: inline-flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  border-radius: 4px;
  border: 1px dashed var(--content-color-content-inactive, #bdbdbd);
`;

export const StyledLeftCell = styled.div<{
  focusPlace: string;
  isFirstCell: boolean;
  isLastCell: boolean;
}>`
  border: ${(props) =>
    `1px ${props.focusPlace === "left" ? "dashed" : "solid"} ${
      props.focusPlace === "left"
        ? COLORS.content.brand
        : COLORS.background.inactive
    }`};
  border-left: ${(props) =>
    `${props.focusPlace === "left" ? "1px" : "2px"} ${
      props.focusPlace === "left" ? "dashed" : "solid"
    } ${
      props.focusPlace === "left"
        ? COLORS.content.brand
        : COLORS.background.inactive
    }`};
  width: 218px;
  height: 44px;
  padding: 12px 12px 12px 12px;
  background: ${(props) =>
    props.focusPlace === "left"
      ? COLORS.background.brandLight
      : COLORS.surface.standard};
  display: flex;
  :hover {
    background-color: ${(props) =>
      props.focusPlace === "left"
        ? COLORS.background.brandLight
        : COLORS.background.base};
  }
  justify-content: center;
  align-items: center;
  border-top-left-radius: ${(props) => `${props.isFirstCell ? "4px" : "0px"}`};
  border-bottom-left-radius: ${(props) =>
    `${props.isLastCell ? "4px" : "0px"}`};
  border-top: ${(props) =>
    `${
      props.isFirstCell
        ? `${props.focusPlace === "left" ? "1px" : "2px"} ${
            props.focusPlace === "left" ? "dashed" : "solid"
          } ${
            props.focusPlace === "left"
              ? COLORS.content.brand
              : COLORS.background.inactive
          }`
        : `1px ${props.focusPlace === "left" ? "dashed" : "solid"} ${
            props.focusPlace === "left"
              ? COLORS.content.brand
              : COLORS.background.inactive
          }`
    }`};
  border-bottom: ${(props) =>
    `${
      props.isLastCell
        ? `${props.focusPlace === "left" ? "1px" : "2px"} ${
            props.focusPlace === "left" ? "dashed" : "solid"
          } ${
            props.focusPlace === "left"
              ? COLORS.content.brand
              : COLORS.background.inactive
          }`
        : `1px ${props.focusPlace === "left" ? "dashed" : "solid"} ${
            props.focusPlace === "left"
              ? COLORS.content.brand
              : COLORS.background.inactive
          }`
    }`};
`;

export const StyledRightCell = styled.div<{
  focusPlace: string;
  isFirstCell: boolean;
  isLastCell: boolean;
}>`
  border: ${(props) =>
    `1px ${props.focusPlace === "right" ? "dashed" : "solid"} ${
      props.focusPlace === "right"
        ? COLORS.content.brand
        : COLORS.background.inactive
    }`};
  width: 218px;
  height: 44px;
  padding: 12px 12px 12px 12px;
  background: ${(props) =>
    props.focusPlace === "right"
      ? COLORS.background.brandLight
      : COLORS.surface.standard};
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    background-color: ${(props) =>
      props.focusPlace === "right"
        ? COLORS.background.brandLight
        : COLORS.background.base};
  }
  border-right: ${(props) =>
    `${props.focusPlace === "right" ? "1px" : "2px"} ${
      props.focusPlace === "right" ? "dashed" : "solid"
    } ${
      props.focusPlace === "right"
        ? COLORS.content.brand
        : COLORS.background.inactive
    }`};
  border-top: ${(props) =>
    `${
      props.isFirstCell
        ? `${props.focusPlace === "right" ? "1px" : "2px"} ${
            props.focusPlace === "right" ? "dashed" : "solid"
          } ${
            props.focusPlace === "right"
              ? COLORS.content.brand
              : COLORS.background.inactive
          }`
        : `1px ${props.focusPlace === "right" ? "dashed" : "solid"} ${
            props.focusPlace === "right"
              ? COLORS.content.brand
              : COLORS.background.inactive
          }`
    }`};
  border-bottom: ${(props) =>
    `${
      props.isLastCell
        ? `${props.focusPlace === "right" ? "1px" : "2px"} ${
            props.focusPlace === "right" ? "dashed" : "solid"
          } ${
            props.focusPlace === "right"
              ? COLORS.content.brand
              : COLORS.background.inactive
          }`
        : `1px ${props.focusPlace === "right" ? "dashed" : "solid"} ${
            props.focusPlace === "right"
              ? COLORS.content.brand
              : COLORS.background.inactive
          }`
    }`};
  border-top-right-radius: ${(props) => `${props.isFirstCell ? "4px" : "0px"}`};
  border-bottom-right-radius: ${(props) =>
    `${props.isLastCell ? "4px" : "0px"}`};
`;

export const StyledDash = styled.div<{ isActive: boolean }>`
  .button-container {
    .icon-leading {
      svg {
        color: ${(props) =>
          props.isActive ? COLORS.content.brand : COLORS.content.secondary};
      }
    }

    :hover {
      background-color: ${(props) =>
        props.isActive ? COLORS.background.brandLight : "9E9E9E"};
    }

    background-color: ${(props) =>
      props.isActive ? COLORS.background.brandLight : COLORS.surface.standard};

    .text {
      color: ${(props) =>
        props.isActive ? COLORS.content.brand : COLORS.content.secondary};
    }

    .line-bottom {
      background: ${(props) =>
        props.isActive
          ? `repeating-linear-gradient(
                to right,
                ${COLORS.content.brand},
                ${COLORS.content.brand}
                    6px,
            transparent 6px,
            transparent 12px
            )`
          : `repeating-linear-gradient(
                to right,
                #9E9E9E,
                #9E9E9E
                    6px,
            transparent 6px,
            transparent 12px
            )`};
    }

    .line-top {
      background: ${(props) =>
        props.isActive
          ? `repeating-linear-gradient(
                to right,
                ${COLORS.content.brand},
                ${COLORS.content.brand}
                    6px,
            transparent 6px,
            transparent 12px
            )`
          : `repeating-linear-gradient(
                to right,
                #9E9E9E,
                #9E9E9E
                    6px,
            transparent 6px,
            transparent 12px
            )`};
    }

    .line-right {
      background: ${(props) =>
        props.isActive
          ? `repeating-linear-gradient(
                to bottom,
                ${COLORS.content.brand},
                ${COLORS.content.brand}
                    6px,
            transparent 6px,
            transparent 12px
            )`
          : `repeating-linear-gradient(
                to bottom,
                #9E9E9E,
                #9E9E9E
                    6px,
            transparent 6px,
            transparent 12px
            )`};
    }

    .line-left {
      background: ${(props) =>
        props.isActive
          ? `repeating-linear-gradient(
                to bottom,
                ${COLORS.content.brand},
                ${COLORS.content.brand}
                    6px,
            transparent 6px,
            transparent 12px
            )`
          : `repeating-linear-gradient(
                to bottom,
                #9E9E9E,
                #9E9E9E
                    6px,
            transparent 6px,
            transparent 12px
            )`};
    }
  }
`;

export const CopyButton = styled.div`
  display: flex;
  height: 32px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid ${COLORS.stroke.brand};
  :hover {
    background-color: rgba(0, 0, 0, 0.03);
  }
`;

export const StyledNext = styled.div`
  .button-container {
    .icon-trailing {
      display: flex;
    }
  }
`;

export const LeftPanelStyled = styled.div`
  ::-webkit-scrollbar {
    display: none;
  }
  width: 500px;
  min-height: 400px;
  max-height: 400px;
  overflow: scroll;
`;

export const VariableExtractionPanel = styled.div`
  width: 500px;
  height: 320px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const RightPanelStyled = styled.div`
  width: 478px;
  .bulb-box {
    margin-left: 24px;

    &__icon {
      margin-left: 16px;
      margin-bottom: -20px;
    }

    &__content {
      display: flex;
      flex-direction: column;
      background: #f0f0f0;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      padding: 24px 16px 16px 16px;
      color: ${(props) => props.theme.colors.content.secondary};
      ${(props) => props.theme.fonts.bodyCaption}
      row-gap: 4px;
    }
  }
`;

export const FooterButtonsStyled = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;

  .cancel {
    display: flex;
    height: 40px;
    padding: 0px 4px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }

  .next {
    display: flex;
    height: 40px;
    padding: 0px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const CopyUrlStyled = styled.div`
  .copy {
    display: flex;
    flex-direction: row;
    gap: 16px;

    &__space_divison {
      margin-top: 16px;
    }

    &__url_copy {
      display: flex;
      width: 932px;
      padding: 12px 16px;
      align-items: center;
      gap: 7px;
      border-radius: 8px;
      border: 1px solid ${COLORS.stroke.primary};
      background-color: ${COLORS.background.base};
      justify-content: space-between;
    }
  }
`;

export const EditorWrapper = styled.div<{
  stepOneError: boolean;
  width: string;
}>`
  display: flex;
  width: ${(props) => props.width || "932px"};
  height: 248px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0px;
  border-radius: 0px 0px 4px 4px;
  border-left: ${(props) =>
    `1px solid ${
      props.stepOneError
        ? COLORS.stroke.negative.vibrant
        : COLORS.stroke.primary
    }`};
  border-right: ${(props) =>
    `1px solid ${
      props.stepOneError
        ? COLORS.stroke.negative.vibrant
        : COLORS.stroke.primary
    }`};
  border-bottom: ${(props) =>
    `1px solid ${
      props.stepOneError
        ? COLORS.stroke.negative.vibrant
        : COLORS.stroke.primary
    }`};
`;

export const TooltipContainerStyled = styled.div`
  .tooltip-container {
    display: flex;
    align-items: flex-start;
    border-radius: 4px;
    border: 1px solid ${COLORS.stroke.primary};

    &__wrap {
      display: flex;
      width: 32px;
      height: 32px;
      padding: 6px;
      justify-content: center;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      align-items: center;
      border-radius: 4px;
      background: ${COLORS.surface.standard};
    }

    &__paste {
      display: flex;
      width: 32px;
      height: 32px;
      padding: 6px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      background: ${COLORS.surface.standard};
      borderright: 1px solid ${COLORS.stroke.primary};
    }
  }
`;

export const EditorHeaderStyled = styled.div<{
  stepOneError: boolean;
  width: string;
}>`
  display: flex;
  width: ${(props) => props.width || "932px"};
  padding: 12px 16px;
  alignitems: center;
  gap: 8px;
  border-radius: 4px 4px 0px 0px;
  border: ${(props) =>
    `1px solid ${
      props.stepOneError
        ? COLORS.stroke.negative.vibrant
        : COLORS.stroke.primary
    }`};
  background-color: ${COLORS.surface.subdued};
  justify-content: space-between;
`;

export const EditorLayout = styled.div<{ width: string }>`
  display: flex;
  width: ${(props) => props.width || "932px"};
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 4px;
  background: ${COLORS.content.primaryInverse};
`;

export const DropdownContainer = styled.div<{ error: boolean }>`
  border-radius: 4px;
  border: 1px solid
    ${(props) =>
      props.error ? COLORS.content.negative : COLORS.stroke.primary};
  cursor: pointer;
  background-color: #fff;
  &:hover {
    background-color: ${COLORS.surface.hovered};
  }
`;

export const DropdownWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 12px;
  .dropdown-text {
    margin-left: 12px;
    margin-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
