import { useDispatch } from "react-redux";
import { CallApiDataSet, DelayDataSet } from "../../constants/DataSet";
import { getLabels } from "../../services/helpers/CategoryHelper";
import flowMetaState from "../../state/flowMetaState";
import { useAppSelector } from "../../state/store";
import { DATA_SET } from "../../ui-components/dropdown";
import { ListItemProps } from "../../ui-components/searchBar";
import { SuperAdminAccess } from "../entry-point/SuperAdminAccess";
import { Actions, AgentTypes, DelayType, EventTypes } from "./Util";

export const convertObjectToList = (ObjectList: any = {}, isGlobal = true) => {
  if (Object.keys(ObjectList).length === 0) {
    return [];
  }
  const keys = Object.keys(ObjectList);
  let results: ListItemProps[] = keys.map((k) => {
    if (isGlobal) {
      return {
        id: `{{${ObjectList[k]}}}`,
        text: ObjectList[k],
        type: "Global",
      };
    } else {
      return {
        id: `{{${ObjectList[k]}}}`,
        text: k,
        type: "Local",
      };
    }
  });
  return results;
};

export const generateRandomNumber = (n: number = 6) => {
  // generating n digit random number super fast
  let result = "";
  [...Array(n).keys()].map(
    (_) => (result += Math.floor(Math.random() * 10).toString())
  );
  return result;
};

export const MapActionBlock = (
  storeId: string,
  setFlows: any,
  setLoadedLabels: any
) => {
  let loopCount = 0;
  let loopAction = {};
  let agentType = {};
  let label = {};
  let eventType = {};
  let apiName = "";
  let messageType = {};
  let limit = 0;
  let selectedProduct = {};
  let selectedCatalog = {};
  let actionSelected: any, value1: any, valueToBeStored: any, flowSelected: any;
  let callApiData: any = new CallApiDataSet();
  let editorText = "";
  let valueType = {};
  let delay: DelayDataSet = new DelayDataSet();
  const flowMeta = useAppSelector((state) => state.flowMetaState.flowMeta);

  const currentFlow = useAppSelector((state) => state.homeState.flowId);
  const channelName = useAppSelector((state) => state.homeState.channel);
  const currentFlowType = useAppSelector((state) => state.homeState.type);
  const dispatcher = useDispatch();

  const getFlows = async (): Promise<any[]> => {
    const superAdminAccess = new SuperAdminAccess();
    let flowKeyWords: { [key: string]: any } = {};
    if (Object.keys(flowMeta).length <= 1) {
      flowKeyWords = await superAdminAccess.getFlowsForStore(storeId);
      let filteredFlow: { [key: string]: any } = {};
      for (const [key, value] of Object.entries(flowKeyWords)) {
        if (key == currentFlow) {
          filteredFlow = { ...filteredFlow, [key]: value };
          continue;
        }
        if (key && value) {
          let channel = value?.channel?.toUpperCase();
          if (channel !== channelName?.toUpperCase()) {
            continue;
          }
          filteredFlow = { ...filteredFlow, [key]: value };
        }
      }
      dispatcher(
        flowMetaState.actions.setFlowMeta({
          flowMeta: filteredFlow as { [key: string]: any },
          updateFlowMeta: true,
        })
      );
      flowKeyWords = filteredFlow;
    } else {
      flowKeyWords = flowMeta;
    }
    const filteredFlowData: any[] = [];
    Object.keys(flowKeyWords).forEach((key) => {
      filteredFlowData.push({
        id: key,
        header: flowKeyWords[key].flowName,
        status: flowKeyWords[key].status,
        isTestMode: flowKeyWords[key].isTestMode,
      });
    });
    return filteredFlowData;
  };

  const getFilteredFlows = (flowKeyWords: { [key: string]: any }) => {
    const filteredFlowData: any[] = [];
    Object.keys(flowKeyWords).forEach((key) => {
      filteredFlowData.push({
        id: key,
        header: flowKeyWords[key].flowName,
      });
    });
    setFlows(filteredFlowData);
    return filteredFlowData;
  };

  const mapAction = async (nodeData: any, key: string) => {
    switch (key) {
      case "start_flow": {
        let flowData = [];
        if (!Object.keys(flowMeta).length) {
          flowData = await getFlows();
        } else {
          flowData = getFilteredFlows(flowMeta);
        }
        const action = "Start a flow";
        actionSelected = Actions.filter((val) => val.header === action)?.[0];
        const tempFlow = nodeData?.actions?.start_flow?.flow_id;
        flowSelected = flowData.filter((flow) => {
          return flow.id === tempFlow;
        })?.[0];

        break;
      }
      case "assign_to_agent": {
        const action = "Assign an agent";
        actionSelected =
          Actions.filter((val) => val.header === action)?.[0] || {};
        agentType =
          AgentTypes.filter(
            (val) =>
              val.header === nodeData?.actions?.assign_to_agent?.agent_type
          )?.[0] || "";
        const labels = await getLabels(storeId);
        setLoadedLabels(labels as DATA_SET[]);
        label =
          (labels || []).filter(
            (val: any) =>
              val.header ===
              nodeData.actions.assign_to_agent.assigned_label?.header
          )?.[0] || "";
        break;
      }
      case "add_event": {
        const action = "Add an event";
        actionSelected =
          Actions.filter((val) => val.header === action)?.[0] || {};
        eventType = EventTypes.filter(
          (val) => val.header === nodeData?.actions?.add_event?.event_type
        )?.[0];
        break;
      }
      case "call_api": {
        if ((nodeData?.actions?.call_api?.valueType || "").length) {
          let type = nodeData?.actions?.call_api?.valueType;
          valueType = { header: type, id: type };
        } else {
          valueType = { header: "", id: "" };
        }

        if (
          Object.keys(nodeData?.actions?.call_api?.contentData || {}).length &&
          nodeData?.actions?.call_api?.contentData?.htmlFormat?.content
            ?.jsxElement
        ) {
          editorText =
            nodeData?.actions?.call_api?.contentData?.htmlFormat?.content
              ?.jsxElement || "";
        } else {
          editorText = nodeData.actions?.call_api?.payloadObject?.title;

          editorText = `<p>${editorText}</p>`;
        }

        const action = "Call an API";
        actionSelected =
          Actions.filter((val) => val.header === action)?.[0] || {};
        apiName = nodeData?.actions?.call_api?.api_name;
        break;
      }
      case "run": {
        const action = "Run";
        actionSelected =
          Actions.filter((val) => val.header === action)?.[0] || {};
        loopCount = nodeData?.actions?.run?.loop_times;
        if (nodeData?.actions?.run?.action_name === "fetch_products") {
          loopAction = { id: 2, header: "List of products" };
        } else if (nodeData?.actions?.run?.action_name === "search_products") {
          loopAction = { id: 4, header: "Search Products" };
        } else if (nodeData?.actions?.run?.action_name === "fetch_catalogs") {
          loopAction = { id: 3, header: "List of Collection" };
        } else {
          loopAction = { id: 1, header: "None" };
        }
        limit = nodeData?.actions?.run?.limit;
        messageType =
          nodeData?.msg_type === "single"
            ? { id: "single", header: "Show Single Message" }
            : { id: "list", header: "Show as list" };

        break;
      }
      case "fetch_catalog": {
        const action = "Fetch catalog";
        actionSelected = Actions.filter((val) => val.header === action)?.[0];
        selectedCatalog = nodeData?.actions?.fetch_catalog?.catalog || {};
        break;
      }
      case "fetch_product": {
        const action = "Fetch product";
        actionSelected = Actions.filter((val) => val.header === action)?.[0];
        selectedProduct = nodeData?.actions?.fetch_product?.product || {};
        break;
      }
      case "ab_test": {
        const action = "AB test";
        actionSelected = Actions.filter((val) => val.header === action)?.[0];
        break;
      }
      case "call_custom_api": {
        const action = "Call Custom API";
        actionSelected =
          Actions.filter((val) => val.header === action)?.[0] || {};
        apiName = nodeData?.actions?.call_custom_api?.api_name;
        let apiParams: any = [];
        let apiBodyForm: any = [];
        if (nodeData?.actions?.call_custom_api?.payload_mappings) {
          apiParams = Object.entries(
            nodeData?.actions?.call_custom_api?.payload_mappings
          ).map(([k, v]) => {
            const paramVal = (v as any)["variable_name"];
            const paramDataType = (v as any)["datatype"];
            return {
              id: generateRandomNumber(),
              key: k,
              value:
                "[" === paramVal[0] && "]" === paramVal[paramVal.length - 1]
                  ? paramVal
                  : "{{" + paramVal + "}}",
              value_type: paramDataType,
            };
          });
        }

        if (nodeData?.actions?.call_custom_api?.response_mappings) {
          apiBodyForm = Object.entries(
            nodeData?.actions?.call_custom_api?.response_mappings
          ).map(([k, v]) => {
            const formValue = v as any;
            return {
              id: generateRandomNumber(),
              key: k,
              value:
                "[" === formValue[0] && "]" === formValue[formValue.length - 1]
                  ? formValue
                  : "{{" + formValue + "}}",
            };
          });
        }

        callApiData["apiParams"] = apiParams;
        callApiData["apiBodyForm"] = apiBodyForm;
        callApiData["apiHeaders"] = [
          {
            id: generateRandomNumber(),
            key: "content-type",
            value: "application/json",
          },
        ];
        callApiData["actionType"] = "POST";
        callApiData["apiName"] =
          nodeData?.actions?.call_custom_api?.api_name || "Select an Option";
        callApiData["apiId"] = nodeData?.actions?.call_custom_api?.api_id || "";
        callApiData["apiPartner"] =
          nodeData?.actions?.call_custom_api?.api_partner || "SHOPIFY";
        break;
      }
    }

    return {
      loopCount,
      loopAction,
      agentType,
      label,
      eventType,
      apiName,
      messageType,
      limit,
      selectedProduct,
      selectedCatalog,
      actionSelected,
      value1,
      valueToBeStored,
      flowSelected,
      callApiData,
      editorText,
      valueType,
    };
  };

  return {
    getFlows,
    getFilteredFlows,
    mapAction,
  };
};
