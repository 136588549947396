const HeartIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.125 18.861C10.7 18.861 15.1422 15.0828 17.7344 11.975C20.2281 8.98441 19.3281 5.45316 18.7797 4.47035C18.1313 3.30941 9.88283 7.12816 9.88283 7.12816C9.88283 7.12816 2.19846 2.81097 1.62346 3.74847C0.843768 5.02035 -0.248419 8.36566 1.95627 11.4766C4.29689 14.7813 9.50783 18.861 10.125 18.861Z"
        fill="#DB0A28"
      />
      <path
        d="M10.086 17.8435C10.086 17.8435 18.2516 11.7998 18.9532 8.10914C19.6172 4.61383 18.2579 2.91852 16.5844 2.10133C12.3422 0.0294534 10.2016 4.09508 10 4.09508C9.79849 4.09508 7.80474 0.021641 3.70317 1.78414C1.4438 2.75445 0.496923 5.39508 1.39224 8.37008C2.7063 12.7357 10.086 17.8435 10.086 17.8435Z"
        fill="#FF262E"
      />
    </svg>
  );
};

export default HeartIcon;
