import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OrderTemplateVariableState } from "./storeDataStateType";

const initialState: OrderTemplateVariableState = {
  orderTemplateVariablesList: {},
};

const orderTemplateVariablesDataSlice = createSlice({
  name: "orderTemplateVariables",
  initialState: initialState,
  reducers: {
    setOrderVariablesList: (
      state,
      action: PayloadAction<Partial<OrderTemplateVariableState>>
    ) => {
      const { orderTemplateVariablesList } = action.payload;
      state.orderTemplateVariablesList = {
        ...state.orderTemplateVariablesList,
        ...orderTemplateVariablesList,
      };
      return state;
    },
    removeFromList: (state, action: PayloadAction<string[]>) => {
      const keys = action.payload;
      keys.forEach((key) => {
        delete state.orderTemplateVariablesList[key];
      });
      return state;
    },
  },
});

export const orderTemplateVariablesDataAction =
  orderTemplateVariablesDataSlice.actions;
export default orderTemplateVariablesDataSlice;
