import styled from "styled-components";

export const ConditionNodeStyle = styled.div`
  .customNode {
    &__header {
      &__icon {
        background-color: #fff7e5;
      }
      &__menu {
        width: 10px;
      }
    }
    &__child-nodes {
      gap: 16px;
    }
  }
`;
