import { Icon } from "./icons-props";

const StickyNoteIcon: Icon = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      width={width || "15"}
      height={height || "18"}
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.7381 2.33329C2.42067 2.33329 2.13499 2.44463 1.93998 2.61526C1.74852 2.78279 1.66667 2.98425 1.66667 3.16663V14.8333C1.66667 15.0157 1.74852 15.2171 1.93998 15.3847C2.13499 15.5553 2.42067 15.6666 2.7381 15.6666H12.2619C12.5793 15.6666 12.865 15.5553 13.06 15.3847C13.2515 15.2171 13.3333 15.0157 13.3333 14.8333V3.16663C13.3333 2.98425 13.2515 2.78279 13.06 2.61526C12.865 2.44463 12.5793 2.33329 12.2619 2.33329H2.7381ZM0.842471 1.36097C1.36188 0.906481 2.04518 0.666626 2.7381 0.666626H12.2619C12.9548 0.666626 13.6381 0.906481 14.1575 1.36097C14.6805 1.81856 15 2.46494 15 3.16663V14.8333C15 15.535 14.6805 16.1814 14.1575 16.639C13.6381 17.0934 12.9548 17.3333 12.2619 17.3333H2.7381C2.04518 17.3333 1.36188 17.0934 0.842471 16.639C0.319505 16.1814 0 15.535 0 14.8333V3.16663C0 2.46494 0.319505 1.81856 0.842471 1.36097ZM3.80952 4.83329C3.80952 4.37306 4.18262 3.99996 4.64286 3.99996H10.3571C10.8174 3.99996 11.1905 4.37306 11.1905 4.83329C11.1905 5.29353 10.8174 5.66663 10.3571 5.66663H4.64286C4.18262 5.66663 3.80952 5.29353 3.80952 4.83329ZM3.80952 8.16663C3.80952 7.70639 4.18262 7.33329 4.64286 7.33329H10.3571C10.8174 7.33329 11.1905 7.70639 11.1905 8.16663C11.1905 8.62686 10.8174 8.99996 10.3571 8.99996H4.64286C4.18262 8.99996 3.80952 8.62686 3.80952 8.16663ZM3.80952 11.5C3.80952 11.0397 4.18262 10.6666 4.64286 10.6666H8.45238C8.91262 10.6666 9.28571 11.0397 9.28571 11.5C9.28571 11.9602 8.91262 12.3333 8.45238 12.3333H4.64286C4.18262 12.3333 3.80952 11.9602 3.80952 11.5Z"
        fill={color || "#B92321"}
      />
    </svg>
  );
};

export default StickyNoteIcon;
