import React, { FunctionComponent, useState } from "react";
import BikayiButton from "../../../ui-components/button";
import BotInput from "../../../ui-components/input";

interface OwnProps {
  onChange: (storeId: string, phoneNumber: string) => void;
}

type Props = OwnProps;

const RunsSearch: FunctionComponent<Props> = (props) => {
  const [storeId, setStoreId] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const search = () => {
    props.onChange(storeId, phoneNumber);
  };
  return (
    <div style={{ padding: 16 }}>
      <div className="title-regular">Search</div>
      <div
        style={{ display: "flex", columnGap: 20, width: 500, marginTop: 16 }}
      >
        <BotInput
          id={"store_id"}
          height={"20px"}
          value={storeId}
          placeHolder={"Enter store id"}
          type={"text"}
          background={"#FFFFFF"}
          onChange={(event) => {
            setStoreId(event.target.value);
          }}
        ></BotInput>
        <BotInput
          id={"phone_number"}
          height={"20px"}
          value={phoneNumber}
          placeHolder={"Enter phone number"}
          type={"text"}
          background={"#FFFFFF"}
          onChange={(event) => {
            setPhoneNumber(event.target.value);
          }}
        ></BotInput>
      </div>
      <BikayiButton
        style={{ marginTop: "16px", marginLeft: 0 }}
        buttonName={"Submit"}
        buttonStyle="primary"
        buttonWidth="75px"
        buttonPadding={"6px 12px"}
        buttonHeight="32px"
        click={() => {
          search();
        }}
      />
    </div>
  );
};

export default RunsSearch;
