import {
  BASE_COLORS,
  BodySecondary,
  Button,
  COLORS,
  Dropdown,
  DropdownPopover,
  IconButton,
  Input,
  StyledModal,
  Switch,
  Tag,
  TitleMedium,
  TitleRegular,
  TitleSmall,
  Tooltip,
} from "@bikdotai/bik-component-library";
import React, { useEffect, useState } from "react";
import { KeywordsModalStyle } from "../../app/entry-point/modals/EntryModal.style";
import TagManager from "../../app/entry-point/tag-manager";
import BotInput from "../../ui-components/input";
import {
  keyWordCounter,
  keyWordsStringify,
} from "../AutomationTrigger/AutomationTrigger";
import { Body, Container, Footer, Header } from "../ig-modals/style";
import { fetchInstaIds } from "../../services/helpers/QueryBuilderHelper";
import { InstaDropdownContainer, InstaDropdownWrapper } from "./styled";
import InstagramIcon from "../../icons/instagramIcon";
import ChevronDownIcon from "../../icons/chevron_down";
import WhatsappIcon from "../../icons/whatsappIcon";
import { cloneDeep } from "lodash";
import { useAppSelector } from "../../state/store";
import { populateChannelIdsDropdown } from "./utils";
import { WarnIcon } from "../../icons/warnIcon";
import InfoIcon from "../../icons/infoIcon";
import { is } from "date-fns/locale";
import PlusIcon from "../../icons/plusIcon";
import DeleteIcon from "../../icons/DeleteIcon";
import { useVersionJet } from "../../utilities/useVersionJet";

export interface KeywordProps {
  onClose: () => void;
  onSave: (data?: any) => void;
  setKeywordsObj: (data: any) => void;
  keywordObject: any[];
  storeId: string;
  channel: string;
  currentChannelId?: string;
  aiExample?: string[];
  aiSettings?: any;
  setAiDescription?: (data: string, selectedChannleId: string) => void;
  setAiEnabled?: (data: boolean, selectedChannleId: string) => void;
}

export interface keywordsType {
  BEGINS_WITH: string[];
  EXACT: string[];
  CONTAINS: string[];
  INCLUDES: string[];
  AI_EXAMPLES: string[];
}

export interface AIInputProps {
  setAiDescription: (data: string) => void;
  aiExamples: string[];
  aiDescription: string;
  setAiExamples: (data: string[]) => void;
  aiExampleError?: string;
  aiDescError?: string;
}

export const AIInputs: React.FC<AIInputProps> = (props: AIInputProps) => {
  const [aiExamplesError, setAiExamplesError] = useState<string>(
    props.aiExampleError || ""
  );
  const [aiDescError, setAiDescError] = useState<string>(
    props.aiDescError || ""
  );
  useEffect(() => {
    setAiExamplesError(props.aiExampleError || "");
    setAiDescError(props.aiDescError || "");
  }, [props.aiExampleError, props.aiDescError]);
  return (
    <div
      style={{
        display: "grid",
        columnGap: 24,
        gridAutoFlow: "column",
        gridTemplateColumns: "1fr 1fr",
        height: "90%",
      }}
    >
      <div>
        <TitleRegular>Sample customer message</TitleRegular>
        {props.aiExamples.map((example: string | undefined, index: number) => (
          <div style={{ marginTop: 8, display: "flex", width: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <Input
                onChangeText={(input) => {
                  if (
                    props.aiExamples.filter((item: string) => item === "")
                      .length > 0
                  ) {
                    setAiExamplesError("");
                  }
                  const newState = cloneDeep(props.aiExamples);
                  newState[index] = input;
                  props.setAiExamples(newState);
                }}
                value={example}
                key={index}
                errorMessage={aiExamplesError}
              />
            </div>
            {props.aiExamples.length > 1 && (
              <IconButton
                Icon={() => <DeleteIcon color={"#9E9E9E"} />}
                style={{
                  marginLeft: "5px",
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  const newState = cloneDeep(props.aiExamples);
                  newState.splice(index, 1);
                  props.setAiExamples(newState);
                  event.stopPropagation();
                }}
              />
            )}
          </div>
        ))}
        {props.aiExamples.length < 5 && (
          <div style={{ marginTop: 8 }}>
            <Button
              matchParentWidth
              style={{ flex: 1, display: "flex", width: "100%" }}
              size={"medium"}
              buttonText={"Sample customer message"}
              buttonType={"dashBold"}
              LeadingIcon={PlusIcon}
              onClick={(e) => {
                const newState = cloneDeep(props.aiExamples);
                newState.push("");
                props.setAiExamples(newState);
                e.stopPropagation();
              }}
            />
          </div>
        )}
      </div>
      <div style={{ height: "100%" }}>
        <TitleRegular>Journey description</TitleRegular>
        <div style={{ height: "100%" }}>
          <Input
            onChangeText={(input) => {
              setAiDescError("");
              props.setAiDescription(input);
              props.setAiDescription && props.setAiDescription(input);
            }}
            value={props.aiDescription}
            type={"multiline"}
            height={"100%"}
            maxCharLimit={200}
            maxCharLimitPosition={"BOTTOM"}
            errorMessage={aiDescError}
          />
        </div>
      </div>
    </div>
  );
};

const KeywordsTrigger: React.FC<KeywordProps> = (props) => {
  const [inputType, setShowInputType] = useState("");
  const [errorTypes, setErrorTypes] = useState<string[]>([]);
  const emptyKeywordsNewObject = {
    BEGINS_WITH: [],
    EXACT: [],
    CONTAINS: [],
    INCLUDES: [],
    AI_EXAMPLES: [""],
  };
  const emptyUnsavedKeywordsNewObject = {
    BEGINS_WITH: "",
    EXACT: "",
    CONTAINS: "",
    INCLUDES: "",
    AI_EXAMPLES: "",
  };
  const [unsavedKeyword, setUnsavedKeyword] = useState<any>(
    emptyUnsavedKeywordsNewObject
  );
  const [keywordsListObj, setKeywordsListObj] = useState<any>(
    emptyKeywordsNewObject
  );
  const [channelIdOptions, setChannelIdOptions] = useState<
    {
      label: string;
      value: string;
      selected: boolean;
    }[]
  >([]);
  const [selectedChannel, setSelectedChannel] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAiEnabled, setIsAiEnabled] = useState<boolean>(false);
  const [aiDescription, setAiDescription] = useState<string>("");
  const channels: { [key: string]: any } = useAppSelector(
    (state) => state.flowMetaState.channels!
  );
  const { isVersionApplicabletoPlan } = useVersionJet();
  const [aiDescError, setAiDescError] = useState<string>("");
  const [aiExamplesError, setAiExamplesError] = useState<string>("");

  useEffect(() => {
    setIsAiEnabled(
      props.aiSettings?.[selectedChannel.value]?.aiEnabled || false
    );
    setAiDescription(
      props.aiSettings?.[selectedChannel.value]?.aiDescription || ""
    );
  }, [selectedChannel, props.aiSettings]);

  useEffect(() => {
    populateChannelIdsDropdown(
      props.channel,
      channels,
      setIsLoading,
      setSelectedChannel,
      setChannelIdOptions,
      props.currentChannelId
    );
  }, []);

  useEffect(() => {
    if (channelIdOptions && channelIdOptions.length === 1) {
      setSelectedChannel(channelIdOptions[0]);
    }

    if (selectedChannel) {
      if (selectedChannel.value === "ERROR" && props?.currentChannelId) {
        const tempKeywordsListObj = cloneDeep(
          props.keywordObject[props?.currentChannelId as any]
        );
        if (tempKeywordsListObj && !tempKeywordsListObj.AI_EXAMPLES) {
          tempKeywordsListObj.AI_EXAMPLES = [""];
        }
        setKeywordsListObj(tempKeywordsListObj || emptyKeywordsNewObject);
      } else {
        const tempKeywordsListObj = cloneDeep(
          props.keywordObject[selectedChannel.value]
        );
        if (tempKeywordsListObj && !tempKeywordsListObj.AI_EXAMPLES) {
          tempKeywordsListObj.AI_EXAMPLES = [""];
        }
        setKeywordsListObj(tempKeywordsListObj || emptyKeywordsNewObject);
      }
    }
  }, [channelIdOptions]);

  const InstaDropdown = () => {
    return (
      <InstaDropdownContainer>
        <DropdownPopover
          width="200px"
          disabled={isLoading}
          onSelect={(c: any) => {
            setSelectedChannel(c);
            const modified = channelIdOptions.map((d) => {
              if (d.value === c.value) {
                return {
                  label: d.label,
                  value: d.value,
                  selected: true,
                };
              } else {
                return {
                  label: d.label,
                  value: d.value,
                  selected: false,
                };
              }
            });
            setChannelIdOptions(modified);
          }}
          options={channelIdOptions}
        >
          <InstaDropdownWrapper>
            {props.channel === "whatsapp" ? (
              <WhatsappIcon />
            ) : (
              <InstagramIcon />
            )}
            <TitleSmall className="dropdown-text" style={{ fontWeight: 400 }}>
              {selectedChannel.label || "Select an account"}
            </TitleSmall>
            <ChevronDownIcon color={COLORS.content.primary} />
          </InstaDropdownWrapper>
        </DropdownPopover>
      </InstaDropdownContainer>
    );
  };

  const onClickUpdateKeywordsList = async (keywordsNewObj: string[]) => {
    const keywordsObj = cloneDeep(props.keywordObject || []);
    keywordsObj[selectedChannel.value] = keywordsNewObj;
    props.setKeywordsObj && props.setKeywordsObj(keywordsObj);
  };

  const keywordComponent = (
    title: string,
    type: string,
    tooltipBody?: string
  ) => {
    return (
      <div
        className="body body-div"
        style={{
          backgroundColor:
            type === "EXCLUDES" ? COLORS.surface.subdued : "white",
          padding: type === "EXCLUDES" ? 16 : 0,
          borderRadius: type === "EXCLUDES" ? 4 : 0,
          border:
            type === "EXCLUDES" ? `1px solid ${COLORS.stroke.primary}` : "none",
        }}
      >
        <div className="body-used">
          <div className="used-label" style={{ alignItems: "center" }}>
            {title}
            {tooltipBody && (
              <Tooltip body={tooltipBody} placement="right" variant="dark">
                <div style={{ marginLeft: "8px", display: "inline" }}>
                  <InfoIcon />
                </div>
              </Tooltip>
            )}
          </div>
          <div className="used-keywords" style={{ display: "flex" }}>
            {keywordsListObj &&
              keywordsListObj[type] &&
              keywordsListObj[type]?.map((val: any, index: any) => {
                return (
                  <div key={index}>
                    <TagManager
                      textColor={"#616161"}
                      tagColor={"#FAFAFA"}
                      text={val}
                      borderColor={"#E0E0E0"}
                      showCross={true}
                      ClickCross={() => onClickRemove(index, type)}
                    />
                  </div>
                );
              })}
          </div>
        </div>
        <div className="body-add">
          <div className="add-keywords">
            <BotInput
              error={errorTypes.includes(type)}
              height={"24px"}
              width={"980px"}
              type="text"
              onBlur={() => {
                setShowInputType("");
              }}
              onFocus={() => {
                setShowInputType(type);
              }}
              subText={
                inputType === type || errorTypes.includes(type)
                  ? "Press Enter to add a keyword"
                  : undefined
              }
              onChange={(e) => handleUnsavedChanges(e, type)}
              onKeyDown={(e) => checkKeyPress(e, type)}
            />
          </div>
        </div>
      </div>
    );
  };

  const checkUnsavedChanges = () => {
    const errors: string[] = [];
    const bulkKeys: { type: string; value: string }[] = [];
    Object.keys(unsavedKeyword).forEach((key: string) => {
      if (unsavedKeyword[key] !== "") {
        bulkKeys.push({
          type: key,
          value: unsavedKeyword[key],
        });
      }
    });
    const tempkeywordsListObj = addKeywordBulk(bulkKeys);
    setErrorTypes(errors);
    return tempkeywordsListObj;
  };

  const checkForAiInputErrors = () => {
    if (aiDescription === "") {
      setAiDescError("Please enter a description");
    } else {
      setAiDescError("");
    }
    if (
      keywordsListObj.AI_EXAMPLES.filter((item: string) => item !== "")
        .length === 0
    ) {
      setAiExamplesError("Please enter at least one example");
    } else {
      setAiExamplesError("");
    }
    return (
      aiDescription !== "" &&
      keywordsListObj.AI_EXAMPLES.filter((item: string) => item !== "").length >
        0
    );
  };

  const checkKeyPress = (e: any, type: string) => {
    if (e.target.value === "") {
      return;
    }
    if (e.key === "Enter" && e.target.value && e.target.value.trim()) {
      addKeyword(type, e.target.value.trim());
      e.target.value = "";
      setErrorTypes(errorTypes.filter((item) => item !== type));
      setUnsavedKeyword((prev: any) => {
        const newState = { ...prev };
        newState[type] = "";
        return newState;
      });
    }
  };

  const handleUnsavedChanges = (e: any, type: string) => {
    if (e.target.value === "") {
      setErrorTypes(errorTypes.filter((item) => item !== type));
    }
    if (e.target.value && e.target.value.trim()) {
      setUnsavedKeyword((prev: any) => {
        const newState = { ...prev };
        newState[type] = e.target.value.trim();
        return newState;
      });
    }
  };

  const addKeyword = (type: string, value: string) => {
    let tempkeywordsListObj = { ...keywordsListObj };
    const copyArray = tempkeywordsListObj?.[type]
      ? [...tempkeywordsListObj?.[type]]
      : [];
    if (!copyArray.find((item) => item === value)) {
      copyArray.push(value);
    }
    tempkeywordsListObj[type] = copyArray;
    setKeywordsListObj(tempkeywordsListObj);
  };

  const addKeywordBulk = (bulkKeys: { type: string; value: string }[]) => {
    let tempkeywordsListObj = { ...keywordsListObj };
    for (let bKey of bulkKeys) {
      const copyArray = tempkeywordsListObj?.[bKey.type]
        ? [...tempkeywordsListObj?.[bKey.type]]
        : [];
      if (!copyArray.find((item) => item === bKey.value)) {
        copyArray.push(bKey.value);
      }
      tempkeywordsListObj[bKey.type] = copyArray;
    }
    setKeywordsListObj(tempkeywordsListObj);
    return tempkeywordsListObj;
  };

  const onClickRemove = (index: number, type: string) => {
    let tempkeywordsListObj = { ...keywordsListObj };
    let copyArray = [...tempkeywordsListObj[type]];
    copyArray.splice(index, 1);
    tempkeywordsListObj[type] = copyArray;
    setKeywordsListObj(tempkeywordsListObj);
  };

  return (
    <StyledModal
      zIndex={200}
      open={true}
      centralContainerStyles={{ width: "1032px", height: "560px" }}
      onClose={() => {
        props.onClose();
      }}
    >
      <Container>
        <div style={{ display: "flex" }}>
          <Header style={{ borderBottom: "none", paddingBottom: "4px" }}>
            <TitleMedium>{`Add keywords to trigger a flow`}</TitleMedium>
          </Header>
          <div
            style={{
              marginTop: 15,
              paddingRight: 15,
            }}
          >
            <InstaDropdown />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0px 24px 20px 24px",
            borderBottom: `1px solid ${COLORS.stroke.primary}`,
          }}
        >
          {isVersionApplicabletoPlan("ai-triggers") && (
            <>
              <BodySecondary>Trigger journey using AI instead</BodySecondary>
              <div style={{ marginLeft: 12 }}>
                <Tooltip
                  body={
                    !selectedChannel.value
                      ? "Please select an account to continue"
                      : ""
                  }
                >
                  <div>
                    <Switch
                      value={isAiEnabled}
                      type="brand"
                      onValueChange={(value: any) => {
                        setIsAiEnabled(value);
                        setKeywordsListObj(emptyKeywordsNewObject);
                        props.setAiEnabled &&
                          props.setAiEnabled(value, selectedChannel.value);
                      }}
                      disabled={!selectedChannel.value}
                    ></Switch>
                  </div>
                </Tooltip>
              </div>
            </>
          )}
        </div>

        <Body>
          {isAiEnabled ? (
            <AIInputs
              aiDescription={aiDescription}
              setAiDescription={(data: string) => {
                setAiDescription(data);
                if (props.setAiDescription && selectedChannel.value) {
                  props.setAiDescription(data, selectedChannel.value);
                }
              }}
              aiExamples={keywordsListObj.AI_EXAMPLES}
              setAiExamples={(data: string[]) => {
                const tempkeywordsListObj = { ...keywordsListObj };
                tempkeywordsListObj.AI_EXAMPLES = data;
                setKeywordsListObj(tempkeywordsListObj);
              }}
              aiDescError={aiDescError}
              aiExampleError={aiExamplesError}
            />
          ) : (
            <KeywordsModalStyle hideExtras={true}>
              {keywordComponent(
                "If message is",
                "EXACT",
                "Will only trigger the flow if the entire message is the same as the keyword. For example, the keyword ‘Hey’ will not trigger flow for ‘Hey there’"
              )}
              {keywordComponent("If message begins with", "BEGINS_WITH")}
              {keywordComponent(
                "If message contains",
                "INCLUDES",
                "Will trigger the flow even if the keyword is part of another word. For example, will trigger for the keyword ‘Hell’ in ‘Hello World’"
              )}
              {keywordComponent(
                "If message contains the whole word",
                "CONTAINS",
                "Will trigger the flow only if full keyword is in the message. For example, will trigger for keyword ‘Hello’ in ‘Hello there,’ but not for ‘Hell.’"
              )}
              {keywordComponent(
                "Exclude messages which contain keywords",
                "EXCLUDES"
              )}
            </KeywordsModalStyle>
          )}
        </Body>

        <Footer>
          {selectedChannel.value === "ERROR" ? (
            <Tag
              tagText={"No account is selected"}
              type="negative"
              LeadingIcon={WarnIcon}
            />
          ) : (
            <BodySecondary style={{ color: BASE_COLORS.grayscale["700"] }}>
              Create trigger to start any journey as per your use case
            </BodySecondary>
          )}
          <Button
            disabled={isLoading}
            buttonText="Create"
            size={"medium"}
            onClick={() => {
              if (!selectedChannel.value) {
                setSelectedChannel({ value: "ERROR" });
                return;
              }
              if (selectedChannel.value === "ERROR") {
                return;
              }
              if (isAiEnabled && !checkForAiInputErrors()) {
                return;
              }
              const tempkeywordsListObj = checkUnsavedChanges();
              if (
                (tempkeywordsListObj &&
                  keyWordCounter(tempkeywordsListObj) > 0) ||
                (isAiEnabled &&
                  keywordsListObj.AI_EXAMPLES.filter(
                    (item: string) => item !== ""
                  ).length > 0)
              ) {
                onClickUpdateKeywordsList(tempkeywordsListObj);
                const keywordData = {
                  type: "keyword",
                  header: "Customer says",
                  channelId: selectedChannel.value,
                  subHeader: isAiEnabled
                    ? keywordsListObj.AI_EXAMPLES.filter(
                        (item: string) => item !== ""
                      ).join(",")
                    : keyWordsStringify(tempkeywordsListObj),
                };
                props.onSave(keywordData);
                props.onClose();
              } else {
                const keywordData = {
                  type: "keyword",
                  header: "Customer says",
                  channelId: selectedChannel.value,
                  subHeader: isAiEnabled
                    ? keywordsListObj.AI_EXAMPLES.filter(
                        (item: string) => item !== ""
                      ).join(",")
                    : "",
                };
                props.onSave(keywordData);
                props.onClose();
              }
            }}
          />
        </Footer>
      </Container>
    </StyledModal>
  );
};

export default KeywordsTrigger;
