export enum CHANNELS {
  INSTAGRAM = "instagram",
  WHATSAPP = "whatsapp",
  EMAIL = "email",
}

export const isValidChannel = (channel: string) => {
  if (
    channel === CHANNELS.INSTAGRAM ||
    channel === CHANNELS.WHATSAPP ||
    channel === CHANNELS.EMAIL
  ) {
    return true;
  }
  return false;
};
