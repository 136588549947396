export class ErrorStateHelper {
  formatErrors(compilationResponse: any, flowId: string) {
    const report = compilationResponse[flowId];
    let errors = 0;
    let warnings = 0;
    if (report) {
      Object.keys(report).forEach((key) => {
        if (key === "child_reports" && report["child_reports"]) {
          Object.keys(report["child_reports"]).forEach((childFlowId) => {
            const childFlowReport = report["child_reports"][childFlowId];
            Object.keys(childFlowReport).forEach((nodeId) => {
              if (nodeId === "combined_errors") {
                errors = errors + Object.keys(childFlowReport[nodeId]).length;
              } else if (nodeId === "combined_warnings") {
                warnings =
                  warnings + Object.keys(childFlowReport[nodeId]).length;
              }
            });
          });
        } else {
          const nodeLevelData = report[key];
          if (key === "combined_errors") {
            errors = errors + Object.keys(nodeLevelData).length;
          } else if (key === "combined_warnings") {
            warnings = warnings + Object.keys(nodeLevelData).length;
          }
        }
      });
    }
    return {
      errors: compilationResponse,
      errorModalOpenStatus: errors > 0 || warnings > 0,
      errorsCount: errors,
      warningsCount: warnings,
    };
  }
}
