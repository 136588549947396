import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AiVariablesState } from "./storeDataStateType";

const initialState: AiVariablesState = {
  aiVariablesList: {},
  manifestVariablesList: {},
};

const aiVariablesDataSlice = createSlice({
  name: "AiVariableData",
  initialState: initialState,
  reducers: {
    setAiVariablesList: (
      state,
      action: PayloadAction<Partial<AiVariablesState>>
    ) => {
      const { aiVariablesList } = action.payload;
      state.aiVariablesList = { ...state.aiVariablesList, ...aiVariablesList };
      return state;
    },
    removeFromList: (state, action: PayloadAction<string[]>) => {
      const keys = action.payload;
      keys.forEach((key) => {
        delete state.aiVariablesList[key];
      });
      return state;
    },
    setManifestVariablesList: (
      state,
      action: PayloadAction<Partial<AiVariablesState>>
    ) => {
      const { manifestVariablesList } = action.payload;
      state.manifestVariablesList = {
        ...state.manifestVariablesList,
        ...manifestVariablesList,
      };
      return state;
    },
    removeFromManifestList: (state, action: PayloadAction<string[]>) => {
      const keys = action.payload;
      keys.forEach((key) => {
        delete state.manifestVariablesList[key];
      });
      return state;
    },
  },
});

export const aiVariablesDataAction = aiVariablesDataSlice.actions;
export default aiVariablesDataSlice;
