export enum NodeType {
  TRIGGER = "trigger",
  CONDITION = "condition",
  AI = "AI",
  ACTION = "action",
  MESSAGE = "message",
  RUN = "run",
  SPM = "SPM",
  MPM = "MPM",
  INSTAGRAM_MESSAGE = "instagram_message",
  AI_ASSISTANT = "ai_assistant",
  ORDER = "order",
}
