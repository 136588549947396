export const GMAIL_LABELS = [
  "INBOX",
  "SPAM",
  "TRASH",
  "UNREAD",
  "STARRED",
  "IMPORTANT",
  "SENT",
  "DRAFT",
  "CATEGORY_PERSONAL",
  "CATEGORY_SOCIAL",
  "CATEGORY_PROMOTIONS",
  "CATEGORY_UPDATES",
  "CATEGORY_FORUMS",
];

export const customValuesQuery = [
  {
    triggerName: "gmailReceived",
    propertyName: "labels",
  },
];

export const generateDummyQueryBuilderResponse = (values: string[]) => {
  return {
    status: 200,
    data: values.map((value) => {
      return {
        id: value,
        name: value,
      };
    }),
  };
};
