import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BuilderState } from "./storeDataStateType";

const initialState: BuilderState = {
  mode: "edit",
  flow_id: "",
};

const builderStateSlice = createSlice({
  name: "BuilderStateManager",
  initialState: initialState,
  reducers: {
    setState: (state, action: PayloadAction<BuilderState>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const builderStateActions = builderStateSlice.actions;
export default builderStateSlice;
