import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  where,
  writeBatch,
  documentId,
  setDoc,
  runTransaction,
} from "firebase/firestore";
import { getApp } from "firebase/app";
import { updateKeywordCache } from "../../services/helpers/StoreHelper";
import { copyFlowToStores } from "../../services/helpers/SuperAdminHelper";
import { captureErrorToSentry } from "../../utilities/sentryHelper";

export class SuperAdminAccess {
  async checkForDuplicateId(storeIds: string[], flowIds: string[]) {
    for (const storeId of storeIds) {
      let flowKeyWords = await this.getFlowKeyWords(storeId, flowIds);
      if (Object.keys(flowKeyWords).length) {
        for (const key of Object.keys(flowKeyWords)) {
          if (Object.keys(flowKeyWords[key])?.length) {
            return true;
          }
        }
      }
    }
    return false;
  }

  async duplicateToStores(
    storeIds: string[],
    flowIds: string[],
    adminStoreId: string
  ) {
    const resp = await copyFlowToStores({
      parent_store_id: adminStoreId,
      store_ids: storeIds,
      flow_ids: flowIds,
    });
    if (resp.data?.status !== 200) {
      return "Something went wrong";
    }
    if (!!Object.keys(resp.data?.details.failed).length) {
      return "Something went wrong";
    }
    return "";
  }

  async duplicateToStoresOld(
    storeIds: string[],
    flowIds: string[],
    adminStoreId: string
  ) {
    const flowIdMap: { [key: string]: { [key: string]: any } } =
      await this.getMultipleFlowsForStore(flowIds, adminStoreId);
    const database = this.getDatabase();
    const batch = writeBatch(database);
    const messageTemplates = [];
    let flowKeyWords = await this.getFlowKeyWords(adminStoreId, flowIds);
    const duplicateExist = await this.checkForDuplicateId(storeIds, flowIds);
    if (duplicateExist) {
      return "Flows could not be published as some stores already have these flows. Please delete them and try republishing";
    }
    const cacheToBeUpdated: { [key: string]: { [key: string]: any } } = {};
    for (const storeId in storeIds) {
      for (const flowIdIndex in flowIds) {
        if (
          flowIdMap[flowIds[flowIdIndex]] &&
          flowIdMap[flowIds[flowIdIndex]]["chat-nodes"]
        ) {
          const chatNodes = flowIdMap[flowIds[flowIdIndex]]["chat-nodes"];
          for (const node in chatNodes) {
            if (chatNodes[node].message_template_id) {
              messageTemplates.push(chatNodes[node].message_template_id);
            }
            const flowRef = doc(
              database,
              "chatflows",
              storeIds[storeId],
              "flows",
              flowIds[flowIdIndex],
              "chat-nodes",
              chatNodes[node].nodeId
            );
            batch.set(flowRef, chatNodes[node]);
          }
          const flow = flowIdMap[flowIds[flowIdIndex]]["react-flow"];
          for (const flowIndex in flow) {
            const flowRef = doc(
              database,
              "chatflows",
              storeIds[storeId],
              "flows",
              flowIds[flowIdIndex],
              "react-flow",
              flow[flowIndex].flowId
            );
            delete flow[flowIndex].id;
            batch.set(flowRef, flow[flowIndex]);
          }
        }
        const keywordRef = doc(
          database,
          "chatflows",
          storeIds[storeId],
          "flow-keywords",
          flowIds[flowIdIndex]
        );
        cacheToBeUpdated[flowIds[flowIdIndex]] =
          flowKeyWords[flowIds[flowIdIndex]];
        batch.set(keywordRef, flowKeyWords[flowIds[flowIdIndex]]);
      }
    }
    await batch.commit();
    for (const storeId in storeIds) {
      await updateKeywordCache(storeIds[storeId], cacheToBeUpdated);
    }
  }

  async cloneFlow(storeId: string, flowId: string) {
    const flowIdMap: { [key: string]: { [key: string]: any } } =
      await this.getMultipleFlowsForStore([flowId], storeId);
    const flowData = flowIdMap[flowId];
    if (!flowData || !flowData["chat-nodes"]) {
      return "Something went wrong, flow not found!";
    }
    const chatNodes = flowData["chat-nodes"];
    if (this.isOldFlow(chatNodes)) {
      return "Cannot duplicate old flow! Please create a new flow.";
    }
    const database = this.getDatabase();
    const batch = writeBatch(database);
    const flowKeyWords = await this.getFlowKeywordsByFlowId(storeId, flowId);
    const builderKey = flowKeyWords.flowName;
    const date = new Date().getTime().toString();
    const newflowId = `${builderKey
      .toLowerCase()
      .replace(/\s/g, "")
      .trim()}${+date.slice(date.length - 4)}`;
    const keywordRef = doc(
      database,
      "chatflows",
      storeId,
      "flow-keywords",
      newflowId
    );
    const newflowKeyWords = {
      ...flowKeyWords,
      flowName: flowKeyWords.flowName + "_1",
      createdAt: new Date().getTime(),
      status: false,
    };
    batch.set(keywordRef, newflowKeyWords);
    for (const node in chatNodes) {
      const flowRef = doc(
        database,
        "chatflows",
        storeId,
        "flows",
        newflowId,
        "chat-nodes",
        chatNodes[node].nodeId
      );
      batch.set(flowRef, chatNodes[node]);
    }
    const flow = flowData["react-flow"];
    for (const flowIndex in flow) {
      const flowRef = doc(
        database,
        "chatflows",
        storeId,
        "flows",
        newflowId,
        "react-flow",
        newflowId
      );
      delete flow[flowIndex].id;
      batch.set(flowRef, flow[flowIndex]);
    }
    await batch.commit();
    await updateKeywordCache(storeId, { [newflowId]: newflowKeyWords });
    return flowKeyWords;
  }

  async saveFlowMeta(
    storeId: string,
    flowId: string,
    flowData: { [key: string]: any }
  ) {
    const database = this.getDatabase();
    await runTransaction(database, async (transaction) => {
      const keywordRef = doc(
        database,
        "chatflows",
        storeId,
        "flow-keywords",
        flowId
      );
      transaction.set(keywordRef, flowData[flowId], { merge: true });
    }).catch((error) =>
      captureErrorToSentry(error, `Exception in SaveFlowMeta`)
    );
    await updateKeywordCache(storeId, { [flowId]: flowData[flowId!] });
  }

  async getFlowsForStore(storeId: string): Promise<{ [key: string]: any }> {
    const database = this.getDatabase();
    const data: any = {};

    const keywordDocs = await getDocs(
      collection(database, "chatflows", storeId, "flow-keywords")
    );
    keywordDocs.forEach((doc) => {
      // TODO we can remove this after migration and deletion of oldflowKeyWords
      if (doc.id !== "flows") {
        data[doc.id] = doc.data();
      }
    });

    return data;
  }

  async getPublishRequests(storeId: string): Promise<{ [key: string]: any }> {
    const database = this.getDatabase();
    const publishRef = doc(
      database,
      "chatflows",
      storeId,
      "requests",
      "publish"
    );
    const publishSnap = await getDoc(publishRef);
    const data = publishSnap.data();
    if (data) {
      return data;
    } else {
      return {};
    }
  }

  async getCustomisationRequests(
    storeId: string
  ): Promise<{ [key: string]: any }> {
    const database = this.getDatabase();
    const customizationRef = doc(
      database,
      "chatflows",
      storeId,
      "requests",
      "customizations"
    );
    const customizationSnap = await getDoc(customizationRef);
    const data = customizationSnap.data();
    if (data) {
      return data;
    } else {
      return {};
    }
  }

  async archiveCustomisation(storeId: string, customisationRequests: any) {
    const database = this.getDatabase();
    const customisationRef = doc(
      database,
      "chatflows",
      storeId,
      "requests",
      "customizations"
    );
    await setDoc(customisationRef, customisationRequests);
  }

  async archivePublishRequests(storeId: string, publishRequests: any) {
    const database = this.getDatabase();
    const publishRef = doc(
      database,
      "chatflows",
      storeId,
      "requests",
      "publish"
    );
    await setDoc(publishRef, publishRequests);
  }

  async fetchTemplates(
    storeId: string,
    templateIds: string[]
  ): Promise<{ [key: string]: any }> {
    const database = this.getDatabase();
    const templateMaps: { [key: string]: any } = {};
    const templateRef = collection(
      database,
      "chatflows",
      storeId,
      "message-templates"
    );
    const chunkSize = 10;
    for (let i = 0; i < templateIds.length; i += chunkSize) {
      const chunk = templateIds.slice(i, i + chunkSize);
      const queryRef = query(templateRef, where(documentId(), "in", chunk));
      const querySnapshot = await getDocs(queryRef);
      querySnapshot.forEach((doc) => {
        templateMaps[doc.id] = doc.data();
      });
    }
    return templateMaps;
  }

  async getFlowKeyWords(
    storeId: string,
    flowIds: string[]
  ): Promise<{ [key: string]: any }> {
    const keywordMap = await this.getFlowsForStore(storeId);
    const duplicateMap: { [key: string]: any } = {};
    if (Object.keys(keywordMap).length) {
      for (const index in flowIds) {
        duplicateMap[flowIds[index]] = keywordMap[flowIds[index]] || {};
      }
    }
    return duplicateMap;
  }

  async deleteFlows(
    storeId: string,
    flowId: string
  ): Promise<{ [key: string]: any }> {
    const database = this.getDatabase();
    const batch = writeBatch(database);
    const nodeIdsRef = await getDocs(
      collection(database, "chatflows", storeId, "flows", flowId, "chat-nodes")
    );
    nodeIdsRef.forEach((document) => {
      const nodeDoc = doc(
        database,
        "chatflows",
        storeId,
        "flows",
        flowId,
        "chat-nodes",
        document.id
      );
      batch.delete(nodeDoc);
    });
    const flowIdsRef = await getDocs(
      collection(database, "chatflows", storeId, "flows", flowId, "react-flow")
    );
    flowIdsRef.forEach((document) => {
      const flowDoc = doc(
        database,
        "chatflows",
        storeId,
        "flows",
        flowId,
        "react-flow",
        document.id
      );
      batch.delete(flowDoc);
    });
    const flowParentDoc = doc(database, "chatflows", storeId, "flows", flowId);
    batch.delete(flowParentDoc);
    const keywordsDoc = doc(
      database,
      "chatflows",
      storeId,
      "flow-keywords",
      flowId
    );
    batch.delete(keywordsDoc);
    await batch.commit();
    await updateKeywordCache(storeId, {}, true);
    return await this.getFlowsForStore(storeId);
  }

  getDatabase() {
    const app = getApp();
    return getFirestore(app);
  }

  async getMultipleFlowsForStore(flowIds: string[], adminStoreId: string) {
    let result = {};
    try {
      const flowIdMap: { [key: string]: { [key: string]: any } } = {};
      const database = this.getDatabase();
      for (const flowId in flowIds) {
        const nodeRef = collection(
          database,
          "chatflows",
          adminStoreId,
          "flows",
          flowIds[flowId],
          "chat-nodes"
        );
        const flowRef = collection(
          database,
          "chatflows",
          adminStoreId,
          "flows",
          flowIds[flowId],
          "react-flow"
        );
        const flowDocsSnap = await getDocs(flowRef);
        const nodeDocsSnap = await getDocs(nodeRef);
        nodeDocsSnap.forEach((doc) => {
          if (
            flowIdMap[flowIds[flowId]] &&
            flowIdMap[flowIds[flowId]]["chat-nodes"]
          ) {
            flowIdMap[flowIds[flowId]]["chat-nodes"].push(doc.data());
          } else if (
            flowIdMap[flowIds[flowId]] ||
            !flowIdMap[flowIds[flowId]]?.["chat-nodes"] ||
            !flowIdMap[flowIds[flowId]]?.["chat-nodes"].length
          ) {
            flowIdMap[flowIds[flowId]] = {
              "chat-nodes": [doc.data()],
              "react-flow": flowIdMap[flowIds[flowId]]?.["react-flow"] || [],
            };
          }
        });
        flowDocsSnap.forEach((doc) => {
          if (
            flowIdMap[flowIds[flowId]] &&
            flowIdMap[flowIds[flowId]]["react-flow"]
          ) {
            flowIdMap[flowIds[flowId]]["react-flow"].push(doc.data());
          } else if (
            flowIdMap[flowIds[flowId]] ||
            !flowIdMap[flowIds[flowId]]?.["react-flow"] ||
            !flowIdMap[flowIds[flowId]]?.["react-flow"].length
          ) {
            flowIdMap[flowIds[flowId]] = {
              "chat-nodes": flowIdMap[flowIds[flowId]]?.["chat-nodes"] || [],
              "react-flow": [doc.data()],
            };
          }
        });
      }
      result = flowIdMap;
    } catch (error: any) {
      captureErrorToSentry(error, `Error in getMultipleFlowsForStore`);
    } finally {
      return result;
    }
  }

  async deleteMedia(flowMeta: any, storeId: string, flowId: string) {
    try {
      const database = this.getDatabase();
      delete flowMeta[flowId].mediaName;
      delete flowMeta[flowId].mediaSize;
      delete flowMeta[flowId].mediaType;
      delete flowMeta[flowId].mediaUrl;
      const keywordRef = doc(
        database,
        "chatflows",
        storeId,
        "flow-keywords",
        "flows"
      );
      await setDoc(keywordRef, flowMeta);
    } catch (error: any) {
      captureErrorToSentry(error, `Error in deleteMedia`);
    }
  }

  async getFlowKeywordsByFlowId(storeId: string, flowId: string) {
    const database = this.getDatabase();
    const docRef = doc(database, "chatflows", storeId, "flow-keywords", flowId);
    const keywordDoc = await getDoc(docRef);
    return keywordDoc.data() || {};
  }

  isOldFlow(chatNodes: any) {
    for (const node of chatNodes) {
      if ("migratedV2" in node && node.migratedV2) {
        return true;
      }
    }
    return false;
  }

  async deleteTriggersById(
    storeId: string,
    flowId: string,
    deletedTriggersId: Array<string>
  ) {
    try {
      const database = this.getDatabase();
      const batch = writeBatch(database);
      const docIdRefs = await getDocs(
        collection(database, "chatflows", storeId, "event-trigger")
      );
      docIdRefs.forEach((document) => {
        if (deletedTriggersId.includes(document.id)) {
          const nodeDoc = doc(
            database,
            "chatflows",
            storeId,
            "event-trigger",
            document.id
          );
          batch.delete(nodeDoc);
        }
      });
      await batch.commit();
    } catch (error: any) {
      captureErrorToSentry(error, `Error in deleteTriggersById `);
    }
  }
}
