/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from "react";

import { useDispatch } from "react-redux";
import { nodeClickDataActions } from "../../state/nodeClickDataState";
import { useAppSelector } from "../../state/store";
import { storeDataActions } from "../../state/storeDataState";
import { AddNodeDataProps } from "../add-node-options/addNodePopover";
import CustomNode from "./customNode";
import { NodeManager } from "./nodeManager";
import { globalVariablesDataAction } from "../../state/globalVariablesState";
import { localVariablesDataAction } from "../../state/localVariablesState";
import { notificationDataStateActions } from "../../state/notificationDataState";
import Modal from "../modal";
import SaveFlowModal from "../../app/entry-point/modals/saveFlowModal";
import { homeDataAction } from "../../state/homeState";
import {
  CREATE_NODE_DATA,
  NodeConfig,
  StickyNotesInterface,
  SyncTemplateResponse,
  snakeToCamel,
} from "../../utilities/flowBuilder.utility";
import CustomEdge from "./customEdge";
import { nodeSubtypeMappingActions } from "../../state/nodeSubtypeMappingState";
import { proximityConnectActions } from "../../state/proximityConnect";
import { apiDataStateActions } from "../../state/apiDataState";
import { useLocation, useParams } from "react-router-dom";
import { FlowBuilderGlobalStyle } from "./style";
import { NodeActionActions } from "../../state/nodeActionState";
import { EventPropertyActions } from "../../state/eventPropertyState";
import { NotAnEvent } from "../../app/entry-point/Constants";
import { useFirebaseHelperHook } from "../../utilities/firebaseHelperHook";
import { errorDataActions } from "../../state/errorDataState";
import { cloneDeep } from "lodash";
import ErrorsModal from "../../components/errorsModal";
import { ErrorStateHelper } from "../../services/helpers/errorStateHelper";
import { NodeRearranger } from "./rearranger";
import { useGetVariablesForStoreQuery } from "../../state/variables";
import { useGetIntegrationsByStoreQuery } from "../../state/integrations";
import IntegrationsNotAvailableModal from "../../components/IntegrationsNotAvailableModal";
import { stickyNotesActions } from "../../state/stickyNotesState";
import { getDateString } from "../../components/sticky-notes/utils";
import {
  SyncFlowTemplateToLibrary,
  compileFlow,
} from "../../services/helpers/StoreHelper";
import { ADMIN_STORE_ID, RetryPw, isProd } from "../../config";
import {
  fetchInstaIds,
  getFilters,
} from "../../services/helpers/QueryBuilderHelper";
import {
  getAnalyticsData,
  getSummaryAnalyticsData,
} from "../../services/helpers/AnalyticsHelper";
import { PreConfigurationHelper } from "./preConfigurationHelper";
import {
  HandleNodeClick,
  StoreDataState,
} from "../../state/storeDataStateType";
import { useDetectAnalytics } from "./useDetectAnalytics";
import { payloadVariablesDataAction } from "../../state/payloadVariableState";
import ReactFlow, {
  addEdge,
  Background,
  BackgroundVariant,
  MarkerType,
  ReactFlowInstance,
  useEdgesState,
  useOnViewportChange,
  useNodesState,
  useReactFlow,
  Viewport,
  MiniMap,
  XYPosition,
} from "reactflow";
import { PasswordModal } from "../../app/entry-point/modals";
import { FilterActions } from "../../app/action-block/filter/models";
import { SunsetModal } from "../../app/entry-point/modals/SunsetModal";
import useVariablesListHookV2 from "../../services/helpers/VariablesListHookV2";
import {
  activateVariablesIfAvailable,
  onNodeAdd,
} from "../../services/helpers/VariableV2Mapper";
import {
  getViewPortFromLocalStorage,
  setViewPortToLocalStorage,
} from "../../utilities/localStorageUtility";
import {
  ActivateBikAi,
  AiCreditsExhausted,
  AiCreditsLow,
  AiSyncProgress,
  COLORS,
  DeleteConfirmationModal,
  POD,
} from "@bikdotai/bik-component-library";
import { keyboardEventDataActions } from "../../state/keyboardEventState";
import useMultipleSelection from "../../utilities/copyPasteCustomHooks/useMultipleSelection";
import useCopy from "../../utilities/copyPasteCustomHooks/useCopy";
import usePaste from "../../utilities/copyPasteCustomHooks/usePaste";
import useDelete from "../../utilities/copyPasteCustomHooks/useDelete";
import { aiVariablesDataAction } from "../../state/aiVariablesState";
import { flowMetaStateActions } from "../../state/flowMetaState";
import {
  canConnectEdge,
  enableWebhookVars,
  findDescription,
  getAllEventNames,
  getIndex,
} from "./helper";
import { NodeProps } from "./model";
import CustomControls from "./customControls";
import ChatbotLoader from "./loader";
import { getLast7DaysStartAndEndDate } from "../../utilities/dateUtils";
import { AiStatusHook } from "./aiStatusHook";
import { BUSINESS_EVENT_VARIABLES } from "../../services/helpers/VariableDirectory";
import { ManifestBlocksVariableHelper } from "../../components/manifest/search-node/variableHelper";
import { NodeSubType } from "../../models/nodeSubType";
import { useFetchRestrictedFeature } from "../../utilities/useFetchRestrictedFeature";
import {
  AllFeatureSubKey,
  AllModules,
  FeatureCategory,
} from "@bikdotai/bik-models/growth/models/feature";
import { orderTemplateVariablesDataAction } from "../../state/orderTemplateVariablesState";

const initBgColor = "#FFFFFF";
const connectionLineStyle = {
  stroke: COLORS.background.brand,
  strokeWidth: "2",
  fill: "none",
};
const snapGrid: [number, number] = [25, 25];
const nodeTypes = { CustomNode: (props: any) => <CustomNode {...props} /> };
const edgeTypes = {
  CustomEdge,
};
const customStyle = {
  outline: "2px solid transparent",
  padding: 2,
  backgroundColor: "#FFFFFF",
  height: "auto",
  zIndex: 99,
};
const edgeOptions = {
  animated: false,
  style: {
    stroke: "#a1a1a5",
    strokeWidth: "2",
    fill: "none",
  },
  interactionWidth: 40,
  markerEnd: {
    type: MarkerType.ArrowClosed,
    strokeWidth: 5,
  },
};

let deletedNodes: string[] = [];
let deletedStickyNotes: string[] = [];

const { startDate, endDate } = getLast7DaysStartAndEndDate();

const Node = (props: NodeProps) => {
  const { newNodeData, storeId, flowId, setIndex, index } = props;
  const { type, channel, nodeId } = useParams();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const { setViewport, getViewport, project, setCenter } = useReactFlow();
  const store = useAppSelector((state: any) => state);
  const storeState = useAppSelector((state: any) => state.storeState);
  const dispatcher = useDispatch();
  const nodeManager = new NodeManager();
  const [sourceNodeData, setSourceNodeData] = useState<{
    [key: string]: string | null;
  } | null>();
  const [targetNodeId, setTargetNodeId] = useState<string>("");
  const [addedNodeId, setAddedNodeId] = useState<string>("");
  const [clickedNodeId, setClickedNodeId] = useState<string>("");
  const [nodeAnalytics, setNodeAnalytics] = useState<any>();
  const [flowAnalytics, setFlowAnalytics] = useState<any>();
  const [proximityHoverNodeId, setProximityHoverNodeId] = useState<string>("");
  const [proximityHoverNodeSubType, setProximityHoverNodeSubType] =
    useState<string>("");
  const { getFirebaseObject } = useFirebaseHelperHook();
  const firebaseService = getFirebaseObject();
  const [syncTemplateError, setSyncTemplateError] = useState<string>("");
  const errorState = useAppSelector((state) => state.errorDataState.errors);
  const errorStateComplete = useAppSelector((state) => state.errorDataState);
  useGetVariablesForStoreQuery(storeId);
  const nodeActionState = useAppSelector((state) => state.nodeActionState);
  const location = useLocation();
  const defaultViewport: Viewport = { x: 10, y: 15, zoom: 1 };
  const { variablesList, setVariablesList, constructVariableList } =
    useVariablesListHookV2();
  const [hasRendered, setHasRendered] = useState<boolean>(false);
  const [mouseCursorPosition, setMouseCursorPosition] = useState<XYPosition>({
    x: 0,
    y: 0,
  });

  const localVariableList = useAppSelector(
    (state) => state.localVariablesState.localVariablesList
  );
  const aiVariableList = useAppSelector(
    (state) => state.aiVariablesState.aiVariablesList
  );
  const orderTemplateVariablesList = useAppSelector(
    (state) => state.orderTemplateVariablesState.orderTemplateVariablesList
  );
  const manifestVariableList = useAppSelector(
    (state) => state.aiVariablesState.manifestVariablesList
  );
  const localVariableListType = useAppSelector(
    (state) => state.localVariablesState.localVariablesListType
  );
  const [flowEditHistory, setFlowEditHistory] = useState<any>({});
  const [oldNodeCount, setOldNodeCount] = useState<number>(1);
  const [newNodeCount, setNewNodeCount] = useState<number>(1);
  const [showSaveModal, setShowSaveModal] = useState<boolean>(false);
  const [showSunsetModal, setShowSunsetModal] = useState<boolean>(false);
  const [checkError, setCheckError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [saveType, setSaveType] = useState<string>("");
  const [defectedNodeIds, setDefectedNodeIds] = useState<string[]>([]);
  const subtypeMapping = useAppSelector(
    (state) => state.nodeSubtypeMappingState.data
  );
  const response = useAppSelector((state) => state.apiDataState.apiData);
  const flowMetaState = useAppSelector((state) => state.flowMetaState);
  const flowMeta = useAppSelector((state) => state.flowMetaState.flowMeta);
  const [reactFlowInstance, setReactFlowInstance] =
    useState<ReactFlowInstance>();
  const [pageLoaded, setPageLoaded] = useState<boolean>(false);
  const [builderDimension, setBuilderDimension] = useState<any>({});
  const stickyNotes = useAppSelector((state) => state.stickyNotesState.notes);
  useGetIntegrationsByStoreQuery(storeId);
  const [buttonLoader, setButtonLoader] = useState<boolean>(false);
  const isAnalytics = useDetectAnalytics();
  const homeState = useAppSelector((state) => state.homeState);
  const automationTriggerState = useAppSelector(
    (state) => state.automationTriggerState
  );
  const customApiResponses = useAppSelector(
    (state) => state.apiDataState.apiData
  );
  const discountResponses = useAppSelector(
    (state) => state.apiDataState.discountData
  );
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);
  const keyboardEventDataState = useAppSelector(
    (state) => state.keyboardEventState
  );
  const { hasAccessToBikFeatures } = useFetchRestrictedFeature();

  const {
    validateAiSync,
    doSyncOpen,
    setDoSyncOpen,
    freeCreditsExhaustedModalOpen,
    setFreeCreditsExhaustedModalOpen,
    creditsExhaustedModalOpen,
    setCreditsExhaustedModalOpen,
    syncStatusModalOpen,
    setSyncStatusModalOpen,
    shopifySyncPercent,
    unSubFunc,
  } = AiStatusHook();

  const { selectedNodeIds, selectionEndPosition } =
    useMultipleSelection(mouseCursorPosition);
  useCopy(selectedNodeIds, selectionEndPosition, nodes, edges);
  usePaste({
    index,
    setIndex,
    variablesList,
    setVariablesList,
    setNodes,
    edges,
    setEdges,
    mouseCursorPosition,
  });
  const { deleteNodes } = useDelete({
    nodes,
    setNodes,
    edges,
    setEdges,
    deletedNodes,
    deletedStickyNotes,
    variablesList,
    setVariablesList,
  });

  /**
   * Runs when any action is dispatched when on keyboardEventDataState.
   * Basically, anytime a keyboard event which is used in the code is captured, the action to be dispatched.
   */
  useEffect(() => {
    //When user clicks on delete
    if (keyboardEventDataState?.keyPressAction === "delete") {
      const isModalOpen =
        document.getElementById("cmpModal") ||
        document.getElementById("chatbot-builder-modal") ||
        document.getElementById("bik-modal");

      setIsDeleteConfirmationModalOpen(
        selectedNodeIds.length > 0 && !isModalOpen && !isAnalytics
      );
      resetKeyboardEventState();
    }
  }, [keyboardEventDataState?.keyPressAction]);

  /**
   * Resets keyboard event state to blank state
   */
  const resetKeyboardEventState = () => {
    dispatcher(
      keyboardEventDataActions.keyPressed({
        keyPressAction: "",
      })
    );
  };

  const getScreenHeight = () => {
    const builder = document.getElementsByClassName("react-flow__pane")?.[0];
    setBuilderDimension(builder?.getBoundingClientRect());
    return builder?.clientHeight;
  };

  const showMessage = (errorMessage: string, successMessage: string) => {
    dispatcher(
      notificationDataStateActions.setNotifications({
        errorMessage,
        successMessage,
      })
    );
  };

  const onLoad = (rf: ReactFlowInstance) => {
    setReactFlowInstance(rf);
  };

  useEffect(() => {
    if (reactFlowInstance && nodes.length > 4 && !pageLoaded) {
      reactFlowInstance.fitView();
      setPageLoaded(true);
    }
  }, [reactFlowInstance, nodes?.length]);

  /**
   * Whenever viewport (position of the screen/zoom of the screen) changes,
   * the code in this hook runs.
   */
  useOnViewportChange({
    onStart: useCallback((viewport: Viewport) => {}, []),
    onChange: useCallback((viewport: Viewport) => {}, []),
    onEnd: useCallback((viewport: Viewport) => {
      if (storeId && flowId)
        setViewPortToLocalStorage(storeId, flowId, viewport);
    }, []),
  });

  useEffect(() => {
    if (props.resetViewPort && !showLoader) {
      const triggerNode = nodes.filter((i) => i.id === "N1")?.[0];
      setCenter(triggerNode.position.x, triggerNode.position.y, { zoom: 0.5 });
    }
  }, [props.resetViewPort]);

  useEffect(() => {
    const action = store?.nodeActionState;
    if (action && Object.keys(action).length) {
      if (action.type === "node" && action.action === "delete") {
        deleteNodes([action.nodeId]);
      } else if (action.type === "edge" && action.action === "delete") {
        onEdgesDelete(action.edgeData);
      } else if (action.type === "edge" && action.action === "deleteMultiple") {
        onMultipleEdgeDelete(action.edgeData);
      } else if (
        action?.type === "edges" &&
        action?.action === "deleteAll" &&
        action?.edgeData?.length
      ) {
        let Edges = [];
        Edges =
          edges.filter((edge: any) => !action.edgeData.includes(edge.target)) ||
          edges;
        setEdges([...Edges]);
      } else if (action?.type === "node" && action?.action === "duplicate") {
        onNodeDuplicate(action.nodeId, action.nodeType);
      } else if (action?.type === "edge" && action?.action === "hover") {
        let updatedEdges = edges.map((edge: any) => {
          if (action.edgeData?.id === edge.id) {
            edge.style = { ...edge.style, stroke: COLORS.background.brand };
          } else if (!action.edgeData) {
            edge.style = { ...edge.style, stroke: "#a1a1a5" };
          }
          return edge;
        });
        setEdges(updatedEdges);
      }
    }
  }, [store?.nodeActionState]);

  const onMultipleEdgeDelete = (edgeData: any) => {
    let Edges = [];
    Edges =
      edges.filter((edge: any) => {
        for (let data of edgeData) {
          if (
            edge.target === data.target &&
            edge.source === data.source &&
            edge.sourceHandle === data.sourceHandle
          ) {
            return false;
          }
        }
        return true;
      }) || edges;
    setEdges([...Edges]);
  };

  useEffect(() => {
    setProximityHoverNodeId(store.nodeActionState.nodeId);
    setProximityHoverNodeSubType(store.nodeActionState.subType);
    setTargetNodeId(store.nodeActionState.nodeId);
  }, [store?.nodeActionState?.nodeId]);

  const getTriggerOperatorValues = () => {
    let op = {};
    switch (type) {
      default: {
        op = {
          [channel!]: {
            key: channel!,
            value: "",
          },
          fallback: {
            key: "fallback",
            value: "",
          },
        };
        break;
      }
    }
    return op;
  };

  const fetchFlow = useCallback(async () => {
    setShowLoader(true);
    let flow: any = {};
    let nodesSchema: any = {};
    setTriggerNode();

    const operator_values = getTriggerOperatorValues();
    dispatcher(
      storeDataActions.restoreFlow({
        flow: {
          N1: {
            conditions: [{ operator_values }],
            type: "trigger",
          },
        },
        nodeId: "",
        data: {},
      })
    );
    // handling edit mode
    if (
      (flowId &&
        (!Object.keys(storeState?.flow).length ||
          Object.keys(storeState?.flow).length == 1)) ||
      props.forceFetch
    ) {
      try {
        flow = await firebaseService.getReactFlow(flowId, storeId);
        let nodes = flow.nodes;
        for (let i = 0; i < nodes.length; i++) {
          if (
            nodes[i].data.nodeData.subType &&
            (nodes[i].data.nodeData.subType === "carousel" ||
              nodes[i].data.nodeData.subType === "wa_carousel")
          ) {
            nodes[i].data.nodeData.childNodes =
              firebaseService.objectToArrayCarousal(
                nodes[i].data.nodeData.childNodes
              );
          }
        }
        nodesSchema = await firebaseService.getAllNodes(flowId, storeId);
        const variables = await constructVariableList();
        for (const nodeId of Object.keys(nodesSchema)) {
          if (nodeId != "N1") {
            const conf = nodesSchema[nodeId];
            onNodeAdd(nodeId, conf, setVariablesList, variables);
          }
        }
      } catch (error: any) {}
      if (flow && Object.keys(flow).length && flow.nodes) {
        setViewport(
          getViewPortFromLocalStorage(storeId, flowId, flow?.viewport)
        );
        setNodes(flow.nodes || []);
        setEdges(flow.edges || []);
        updateNodesBasedOnErrorState();
        const ix =
          flow.index > 1 ? flow.index : getIndex(flow.nodes.at(-1) || {});
        setIndex(ix);
        const numberOfNodes = Object.keys(nodesSchema)?.length || 1;
        setOldNodeCount(numberOfNodes);
        const externalApiResponses = Object.keys(nodesSchema)
          .filter((node: any) => {
            return nodesSchema[node].sub_type === "call_external_api";
          })
          .map((node: any) => {
            return nodesSchema[node]?.actions?.call_external_api.response;
          });

        const variables: any[] = []
          .concat(...externalApiResponses.map((subArray: any) => subArray))
          .map((response: any) => {
            return response.variable;
          });
        dispatcher(
          localVariablesDataAction.setLocalVariablesList({
            key: "",
            value: "",
            localVariablesList: flow.localVariableList || {},
            localVariablesListType: flow.localVariableListType || {},
          })
        );

        dispatcher(
          aiVariablesDataAction.setAiVariablesList({
            aiVariablesList: flow.aiVariableList || {},
          })
        );
        dispatcher(
          orderTemplateVariablesDataAction.setOrderVariablesList({
            orderTemplateVariablesList: flow.orderTemplateVariablesList || {},
          })
        );
        dispatcher(
          storeDataActions.restoreFlow({
            flow: nodesSchema,
            nodeId: "",
            data: {},
          })
        );
        if (flow.payloadTypeTree) {
          dispatcher(
            payloadVariablesDataAction.setPayloadVariables({
              payload: flow.payloadTypeTree,
            })
          );
        }
        if (flow.webhookTypeTree) {
          dispatcher(
            payloadVariablesDataAction.setWebhookVariables({
              webhookPayload: flow.webhookTypeTree,
            })
          );
        }
        dispatcher(
          apiDataStateActions.addExternalApiData({
            keys: variables,
          })
        );
        dispatcher(
          nodeSubtypeMappingActions.insertState({
            data: flow.subtypeMapping || {},
            type: "",
            count: 0,
          })
        );
        dispatcher(
          apiDataStateActions.addApiData({
            data: flow.response || {},
          })
        );

        dispatcher(
          aiVariablesDataAction.setManifestVariablesList({
            manifestVariablesList: flow.manifestVariableList || {},
          })
        );

        dispatcher(
          apiDataStateActions.setDiscountData({
            data: flow.discountResponses || {},
          })
        );

        // fetching sticky notes after react flow get render
        const _stickyNotes = await firebaseService.getStickyNotes(
          storeId,
          flowId
        );
        dispatcher(
          stickyNotesActions.restoreNotes({
            notes: _stickyNotes,
            id: "",
            data: {} as StickyNotesInterface,
          })
        );
      }
    }

    const globalVariablesList: {
      [key: string]: {
        defaultValue: string;
        type: string;
      };
    } = await firebaseService.getGlobalVariablesV2(storeId);

    Object.entries(globalVariablesList).forEach(([key, value]) => {
      let varType = value?.type || "any";
      if (["Number", "Integer"].includes(varType)) {
        varType = "number";
      } else if (varType === "String") {
        varType = "string";
      } else if (varType === "Boolean") {
        varType = "boolean";
      }
      value.type = varType;
      globalVariablesList[key] = value;
    });

    dispatcher(
      globalVariablesDataAction.setGlobalVariablesList({
        globalVariablesList: (globalVariablesList as unknown as any) || {},
      })
    );
    setShowLoader(false);
  }, [storeId, flowId, homeState?.storeId]);

  useEffect(() => {
    if (!showLoader && nodes && nodes.length > 0) {
      nodes.forEach((node: any) => {
        if (nodeId && node.id === nodeId) {
          zoomIntoNodeId(node);
        }
      });
    }
  }, [showLoader]);

  useEffect(() => {
    if (location.pathname.includes("/edit") && storeId) {
      fetchFlow().then(() => {
        updateNodeActionInStore("edit-page-loaded");
      });
    } else if (location.pathname.includes("/analytics") && storeId) {
      fetchFlow().then(() => {
        getFlowAnalytics().then(() => {
          updateNodeActionInStore("analytics-page-loaded");
        });
      });
    }
  }, [location.pathname, storeId]);

  const zoomIntoNodeId = (node: any) => {
    const centerX = window.innerWidth / 2;
    const centerY = window.innerHeight / 2;

    const translateX = centerX - node.position.x - node.width / 2;
    const translateY = centerY - node.position.y - node.height / 2;

    setViewport({ x: translateX, y: translateY, zoom: 1 }, { duration: 800 });

    const { nodeData } = node.data;

    dispatcher(
      nodeClickDataActions.setEditNode({
        nodeType: nodeData.type,
        nodeId: node.id,
        nodeSubType: nodeId === "N1" ? "starting_point" : nodeData.subType,
        nodeIndex: nodeData.nodeIndex,
        automationChannel: "whatsapp",
      })
    );
  };

  const getFlowAnalytics = async () => {
    const payload = {
      storeId: storeId,
      flowId: flowId,
      startDate,
      endDate,
      channel: channel,
    };
    await getAnalyticsData(payload).then(async (nodeData) => {
      await getSummaryAnalyticsData(payload).then((flowData) => {
        setNodeAnalytics(nodeData);
        setFlowAnalytics(flowData);
      });
    });
  };

  useEffect(() => {
    if (
      homeState?.flowId &&
      homeState?.channel &&
      nodeAnalytics &&
      flowAnalytics &&
      homeState?.analyticsData?.startDate &&
      homeState?.analyticsData?.endDate
    ) {
      dispatcher(
        homeDataAction.addState({
          ...homeState,
          analyticsData: {
            nodeAnalytics: nodeAnalytics,
            flowAnalytics: flowAnalytics,
            startDate,
            endDate,
            shimmer: false,
          },
        })
      );
      setNodeAnalytics(undefined);
      setFlowAnalytics(undefined);
    }
  }, [
    homeState?.flowId,
    homeState?.channel,
    nodeAnalytics,
    flowAnalytics,
    homeState?.analyticsData?.startDate,
    homeState?.analyticsData?.endDate,
  ]);

  useEffect(() => {
    if (
      variablesList &&
      variablesList.length > 0 &&
      !hasRendered &&
      flowMeta &&
      Object.keys(flowMeta).length > 0
    ) {
      setHasRendered(true);
      onNodeAdd(
        "N0",
        null,
        setVariablesList,
        variablesList,
        "channel",
        channel
      );
      const flowData = flowMeta as { [key: string]: any };
      if (Object.keys(flowData).length > 0) {
        onNodeAdd(
          "N1",
          flowData?.[flowId],
          setVariablesList,
          variablesList,
          "starting_point"
        );
      }
    }
  }, [variablesList, hasRendered, flowMeta]);

  useEffect(() => {
    const flowData = flowMeta as { [key: string]: any };
    const triggers = flowData?.[flowId]?.triggers || [];
    const triggerType = flowData?.[flowId]?.triggerType;
    if (!Array.isArray(triggers)) return;

    if (triggerType === "webhook") {
      const docId = triggers?.[0]?.id;
      firebaseService.getWebhookByDocId(storeId, docId).then((res) => {
        const { selectedHeaders, selectedValues } = enableWebhookVars(res);
        if (selectedHeaders.length > 0) {
          onNodeAdd(
            "N1",
            flowData?.[flowId],
            setVariablesList,
            variablesList,
            "starting_point",
            channel,
            selectedHeaders
          );
        }

        if (selectedValues.length > 0) {
          activateVariablesIfAvailable(
            "N1",
            setVariablesList,
            variablesList,
            selectedValues
          );
        }
      });
    }

    const addEventVariables = async () => {
      const eventNamesResponse = await getAllEventNames(storeId, channel!);
      const eventProperties = {} as { [key: string]: any };
      for (const trigger of triggers) {
        if (!NotAnEvent[trigger.header]) {
          eventProperties[`${trigger.header}Event`] = [];
          const description = findDescription(
            trigger.header,
            eventNamesResponse
          );
          const properties = await getFilters({
            storeId,
            token: storeId,
            action: FilterActions.GET_EVENT_PROPERTY_NAMES,
            eventId: trigger.header,
          });
          /* @ts-ignore */
          properties?.data &&
            /* @ts-ignore */
            properties?.data?.length &&
            /* @ts-ignore */
            properties?.data?.filter((x: any) =>
              eventProperties[`${trigger.header}Event`].push({
                id: x.id,
                name: x.name,
                description: x.description,
                headerDescription: description,
                type: x.type === "integer" ? "number" : x.type,
                needsFetching: x.needValuesRetrieval || false,
              })
            );
        }
      }
      dispatcher(
        EventPropertyActions.insertProperty({
          data: eventProperties,
        })
      );
    };

    /**
     * Hardcoding businessEventNotification variables | If added in model, it will impact query builder
     */
    const addBusinessEventVariables = async () => {
      const eventProperties = {} as { [key: string]: any };
      eventProperties["businessEventNotificationEvent"] =
        BUSINESS_EVENT_VARIABLES;

      dispatcher(
        EventPropertyActions.insertProperty({
          data: eventProperties,
        })
      );
    };

    if (flowData?.[flowId]?.triggerType === "event") {
      addEventVariables().then();
      return;
    }

    if (flowData?.[flowId]?.triggerType === "businessEvents") {
      addBusinessEventVariables().then();
      return;
    }
  }, [(flowMeta as { [key: string]: any })?.[flowId]?.triggers]);

  useEffect(() => {
    const fetchChannelIds = async () => {
      const resp: any = await fetchInstaIds({ storeId: props.storeId });
      dispatcher(flowMetaStateActions.setChannels(resp.data));
    };
    fetchChannelIds().then();
  }, []);

  const highlightDefectedNodes = () => {
    const nodeSchema = firebaseService.objectToArray(storeState.flow);
    const nodeSchemaIds = nodeSchema.map((el) => el.nodeId);
    const reactNodesIds = nodes
      .filter((node) => node.id.startsWith("N"))
      .map((el) => el.id);
    const defectedNodeIds = reactNodesIds.filter(
      (el) => !nodeSchemaIds.includes(el)
    );
    setDefectedNodeIds(defectedNodeIds);
    setNodes((nds: any) =>
      nds.map((node: any) => {
        if (defectedNodeIds.includes(node.id)) {
          node.style = {
            ...node.style,
            outline: `2px solid ${COLORS.background.negative.vibrant}`,
            "border-radius": "8px",
          };
        }
        return node;
      })
    );
  };

  const canSaveFlow = () => {
    const reactFlowNodeCount = nodes.filter((node) =>
      node.id.startsWith("N")
    )?.length;
    const nodeCount = Object.keys(storeState.flow).length;
    highlightDefectedNodes();
    if (reactFlowNodeCount > nodeCount) {
      dispatcher(
        notificationDataStateActions.setNotifications({
          errorMessage: "Node updation failed! Highlighted nodes are defected.",
          successMessage: "",
        })
      );
      return false;
    }
    deletedNodes = deletedNodes.concat(
      Object.keys(storeState.flow).filter((id) => {
        const _node = nodes.find((element) => element.id === id);
        return !_node;
      })
    );

    return true;
  };

  useEffect(() => {
    updateNodesBasedOnErrorState();
  }, [errorState, nodes.length]);

  const updateNodesBasedOnErrorState = () => {
    if (errorState && nodes.length) {
      let defectedNodesFromBE: { [key: string]: { [key: string]: boolean } } =
        {};
      if (errorState && errorState[flowId]) {
        Object.keys(errorState[flowId]).map((key) => {
          const errorData: { [key: string]: boolean } = {
            errors: false,
            warnings: false,
          };
          if (
            errorState[flowId][`${key}`] &&
            errorState[flowId][`${key}`].errors?.length
          ) {
            errorData.errors = true;
          }
          if (
            errorState[flowId][`${key}`] &&
            errorState[flowId][`${key}`].warnings?.length
          ) {
            errorData.warnings = true;
          }
          defectedNodesFromBE[key] = errorData;
        });
      }
      setNodes((nds: any) =>
        nds.map((node: any) => {
          const nodeData = storeState.flow[node.id];
          if (
            nodeData &&
            nodeData["type"] === "action" &&
            nodeData["actions"]["start_flow"] &&
            errorState[flowId] &&
            errorState[flowId]["child_reports"]
          ) {
            const errorData: { [key: string]: boolean } = {
              errors: false,
              warnings: false,
            };
            const startFlowId = nodeData.actions.start_flow.flow_id;
            const nodeErrorState =
              errorState[flowId]["child_reports"][`${startFlowId}`];
            if (nodeErrorState) {
              Object.keys(nodeErrorState).forEach((nodeId) => {
                const nodeLevelData = nodeErrorState[nodeId];
                if (
                  nodeLevelData &&
                  nodeLevelData.errors &&
                  nodeLevelData.errors.length
                ) {
                  errorData.errors = true;
                }
                if (
                  nodeLevelData &&
                  nodeLevelData.warnings &&
                  nodeLevelData.warnings.length
                ) {
                  errorData.warnings = true;
                }
              });
            }
            defectedNodesFromBE[node.id] = errorData;
          }
          if (
            defectedNodesFromBE[node.id] &&
            defectedNodesFromBE[node.id]?.errors
          ) {
            node.style = {
              ...node.style,
              border: "none",
              outline: "2px solid #D53434",
              "border-radius": "8px",
            };
          } else if (
            defectedNodesFromBE[node.id] &&
            defectedNodesFromBE[node.id]?.warnings
          ) {
            node.style = {
              ...node.style,
              border: "none",
              outline: `${isAnalytics ? "none" : "2px solid #FEC02D"}`,
              "border-radius": "8px",
            };
          } else {
            node.style = {
              ...node.style,
              "border-radius": "8px",
              border: "none",
              outline: "none",
            };
          }
          return node;
        })
      );
    }
  };

  useEffect(() => {
    if (newNodeData.type && newNodeData.subType) {
      const nodeData = CREATE_NODE_DATA.filter(
        (op) => op.name === newNodeData.type
      );
      onAdd({
        ...nodeData[0],
        subType: newNodeData?.subType?.toString(),
        nodeIndex: Number(newNodeData?.nodeIndex),
        title: newNodeData?.title?.toString(),
        automationChannel: newNodeData?.automationChannel?.toString(),
        nodeId: newNodeData.id?.toString(),
      });
      dispatcher(
        nodeSubtypeMappingActions.updateState({
          data: {},
          type: newNodeData?.subType?.toString(),
          count: Number(newNodeData?.nodeIndex),
        })
      );
    }
  }, [newNodeData.type, newNodeData.subType]);

  useEffect(() => {
    const saveFlowClicked = store.homeState?.saveFlow;
    const skipSaveModal = store.homeState?.skipStatModal;
    if (saveFlowClicked) {
      if (canSaveFlow()) {
        firebaseService.getFlowEditHistory(storeId, flowId).then((res) => {
          setFlowEditHistory(res);
          setNewNodeCount(Object.keys(storeState?.flow)?.length || 1);
          setShowSaveModal(true);
          setSaveType("Save");
          dispatcher(
            homeDataAction.addState({
              ...store.homeState,
              saveFlow: false,
            })
          );
          if (skipSaveModal) {
            onFlowSaveConfirmed(false, "Save");
          }
        });
      } else {
        dispatcher(
          homeDataAction.addState({
            ...store.homeState,
            saveFlow: false,
            closeLoader: true,
          })
        );
      }
    }
  }, [store.homeState?.saveFlow]);

  useEffect(() => {
    const showSunsetModal = store.homeState?.showSunsetModal;
    if (showSunsetModal) {
      setShowSunsetModal(true);
    } else {
      setShowSunsetModal(false);
    }
  }, [store.homeState?.showSunsetModal]);

  const saveFlow = () => {
    setSaveType("Save");
    onFlowSaveConfirmed(false, "Save")
      .then(() => {
        dispatcher(
          errorDataActions.updateErrorState({
            ...errorStateComplete,
            errorModalOpenStatus: false,
          })
        );
      })
      .catch();
  };

  const publishFlow = () => {
    setSaveType("Publish");
    onFlowSaveConfirmed(true, "Publish")
      .then(() => {
        dispatcher(
          errorDataActions.updateErrorState({
            ...errorStateComplete,
            errorModalOpenStatus: false,
          })
        );
      })
      .catch();
  };

  const getChangedStatus = (
    saveOnly?: boolean,
    testMode?: boolean,
    fromTestModeToggle?: boolean,
    status?: boolean
  ) => {
    // saveonly has some value if you click on that save button or it will be undefined

    // if function is called from test mode toggle, only in one scenario we make the status true,
    // rest of the scenario we have the same status
    if (fromTestModeToggle) {
      if (testMode && !status) {
        return true;
      }

      return status;
    }

    if (status && saveOnly !== undefined) {
      return status;
    }

    return !status;
  };

  const onFlowSaveConfirmed = async (
    forcePublish = false,
    _saveType?: string,
    saveOnly?: boolean
  ) => {
    !saveOnly && setCheckError(true);
    const nodesCarousel = cloneDeep(nodes);
    for (let i = 0; i < nodesCarousel.length; i++) {
      if (
        nodesCarousel[i].data.nodeData.subType &&
        (nodes[i].data.nodeData.subType === "carousel" ||
          nodes[i].data.nodeData.subType === "wa_carousel")
      ) {
        if (Array.isArray(nodesCarousel[i].data.nodeData.childNodes)) {
          nodesCarousel[i].data.nodeData.childNodes =
            firebaseService.arrayToObjectCarousal(
              nodesCarousel[i].data.nodeData.childNodes
            );
        }
      }
    }
    let syncTemplateResponse: SyncTemplateResponse = {};

    const viewport = getViewport();
    const flow = {
      nodes: nodesCarousel,
      edges,
      viewport,
      index,
      flowId,
      aiVariableList,
      manifestVariableList,
      localVariableList,
      localVariableListType,
      subtypeMapping,
      response,
      discountResponses,
      orderTemplateVariablesList,
    };

    if (
      flowMeta &&
      Object.keys(flowMeta).length &&
      (flowMeta as { [key: string]: any })[flowId]
    ) {
      const flowData = (flowMeta as { [key: string]: any })[flowId];
      const { status } = homeState;

      const sunset = _saveType === "Publish" ? false : store.homeState?.sunset;
      const testMode = store.homeState?.testMode ?? false;
      const fromTestModeToggle = store.homeState?.fromTestModeToggle ?? false;

      let changedStatus = getChangedStatus(
        saveOnly,
        testMode,
        fromTestModeToggle,
        status
      );

      const result: any = await firebaseService.saveFlow(
        storeId,
        flow,
        storeState.flow,
        deletedNodes,
        _saveType || saveType,
        flowData,
        stickyNotes,
        deletedStickyNotes,
        forcePublish,
        sunset,
        testMode,
        changedStatus,
        homeState?.userData?.agentId || homeState?.userData?.email,
        saveOnly,
        flowMeta,
        automationTriggerState?.triggers[0] ?? "",
        homeState?.dndEnabled ?? false
      );
      handleSaveButtonLogicOfHeader(result?.response?.compilation_status);
      if (saveOnly) {
        return;
      }

      const compilationResponse =
        result.response && result.response["report"]
          ? result.response["report"]
          : {};
      if (forcePublish) {
        dispatcher(
          homeDataAction.addState({
            ...store.homeState,
            status: forcePublish,
            lastUpdated: new Date().getTime(),
          })
        );
      }
      if (compilationResponse && compilationResponse[flowId] && !forcePublish) {
        const formattedErrorState = new ErrorStateHelper().formatErrors(
          compilationResponse,
          flowId
        );
        dispatcher(
          errorDataActions.updateErrorState({
            ...errorStateComplete,
            errorModalOpenStatus: formattedErrorState.errorsCount > 0,
            errors: { ...formattedErrorState.errors },
            errorsCount: formattedErrorState.errorsCount,
            warningsCount: formattedErrorState.warningsCount,
          })
        );
        if (result.response && result.response.compilation_status) {
          dispatcher(
            homeDataAction.addState({
              ...store.homeState,
              compilationStatus: result.response.compilation_status,
              status: result.response.flow_status,
              publishFlow: false,
              lastUpdated: new Date().getTime(),
            })
          );
        }
      } else {
        if (saveType === "Save" || _saveType === "Save") {
          dispatcher(
            homeDataAction.addState({
              ...store.homeState,
              compilationStatus: "DRAFT",
              status: false,
              saveFlow: false,
              lastUpdated: new Date().getTime(),
            })
          );
          if (storeId === ADMIN_STORE_ID) {
            syncTemplateResponse = await SyncFlowTemplateToLibrary(
              storeId,
              flowId
            );
          }
        } else if (result.response && result.response.compilation_status) {
          dispatcher(
            homeDataAction.addState({
              ...store.homeState,
              compilationStatus: result.response.compilation_status,
              lastUpdated: new Date().getTime(),
            })
          );
        }
        if (result?.response?.compilation_status === "SUCCESS") {
          dispatcher(
            homeDataAction.addState({
              ...store.homeState,
              status: true,
              sunset: false,
              publishFlow: false,
              lastUpdated: new Date().getTime(),
              compilationStatus: result.response.compilation_status,
            })
          );
          dispatcher(
            errorDataActions.updateErrorState({
              ...errorStateComplete,
              errorModalOpenStatus: false,
              errors: {},
              errorsCount: 0,
              warningsCount: 0,
            })
          );
        }
      }
      setCheckError(false);
      if (
        result.response &&
        result.response.data &&
        result.response.data.status === 200 &&
        (typeof result.response.compilation_status === "undefined" ||
          result.response.compilation_status === "WARNING")
      ) {
        setShowSaveModal(true);
        setSuccess(true);
        dispatcher(
          homeDataAction.addState({
            ...store.homeState,
            lastUpdated: new Date().getTime(),
            status: result.response.flow_status,
            publishFlow: false,
            saveFlow: false,
            closeLoader: true,
          })
        );
      } else if (
        (result.completionStatus &&
          result.response &&
          result.response.compilation_status === "SUCCESS") ||
        _saveType === "Save" ||
        forcePublish
      ) {
        if (
          syncTemplateResponse?.errors &&
          syncTemplateResponse?.errors?.length > 0
        ) {
          setSyncTemplateError(syncTemplateResponse?.errors?.[0].message);
        }
        setShowSaveModal(true);
        setSuccess(true);
      } else if (result?.response?.compilation_status === "FAILED") {
        dispatcher(
          homeDataAction.addState({
            ...store.homeState,
            status: result.response.flow_status,
            publishFlow: false,
            saveFlow: false,
            closeLoader: true,
            testMode: fromTestModeToggle ? !testMode : testMode, //undo test mode if compilation has failed
          })
        );
        setSuccess(false);
        setShowSaveModal(false);
        setSaveType("");
      } else {
        dispatcher(
          homeDataAction.addState({
            ...store.homeState,
            status: result.response.flow_status,
            publishFlow: false,
            saveFlow: false,
            closeLoader: true,
          })
        );
        setSuccess(false);
        setShowSaveModal(false);
        setSaveType("");
      }
      //updating flow meta with first enabled on
      const clonedFlowMeta = cloneDeep(flowMeta) as {
        [key: string]: any;
      };
      const clonedFlowMetaWithFlowId = clonedFlowMeta?.[flowId];
      if (clonedFlowMetaWithFlowId) {
        clonedFlowMetaWithFlowId.firstEnabledOn =
          result?.response?.first_enabled_on;

        dispatcher(
          flowMetaStateActions.setFlowMeta({
            ...flowMetaState,
            flowMeta: clonedFlowMeta,
          })
        );
      }
    } else {
      setCheckError(false);
      firebaseService.alertError(
        `Something went wrong while publishing flow`,
        "publishFlow"
      );
      setSaveType("");
    }
    setOldNodeCount(Object.keys(storeState?.flow)?.length || oldNodeCount);
  };

  /**
   * When user clicks on Save/Publish, this function gets called.
   * If save/publish is successful, this function changes data in store so that save button can be disabled.
   */
  const handleSaveButtonLogicOfHeader = (compilation_status?: string) => {
    // On save Success - When clicking on save button and disabling publish
    if (!compilation_status) {
      updateNodeActionInStore("save-success");
    }

    // On Publish Success
    if (
      compilation_status &&
      ["WARNING", "SUCCESS"].includes(compilation_status)
    ) {
      updateNodeActionInStore("publish-success");
    }
  };

  /**
   * Updates NodeAction in store - Used to disable/enable save button in header
   */
  const updateNodeActionInStore = (newNodeAction: string) => {
    dispatcher(
      NodeActionActions.updateState({
        nodeId: "",
        type: "",
        action: newNodeAction,
      })
    );
  };

  useEffect(() => {
    const publishFlowClicked = store.homeState?.publishFlow;
    const skipSaveModal = store.homeState?.skipStatModal;

    if (publishFlowClicked) {
      const publishBlocked = validateAiSync();

      if (publishBlocked) {
        dispatcher(
          homeDataAction.addState({
            ...store.homeState,
            publishFlow: false,
            closeLoader: true,
          })
        );
        return;
      }

      if (canSaveFlow()) {
        firebaseService.getFlowEditHistory(storeId, flowId).then((res) => {
          setFlowEditHistory(res);
          setNewNodeCount(Object.keys(storeState?.flow)?.length || 1);
          setShowSaveModal(true);
          setSaveType("Publish");
          if (skipSaveModal) {
            onFlowSaveConfirmed(false, "Publish");
          }
        });
      } else {
        dispatcher(
          homeDataAction.addState({
            ...store.homeState,
            publishFlow: false,
            closeLoader: true,
          })
        );
      }
    }
  }, [store.homeState?.publishFlow]);

  const setTriggerNode = () => {
    const trigger = getTriggerOperatorValues();
    const childNodes = [];
    for (const [key, values] of Object.entries(trigger || {})) {
      childNodes.push({
        buttonId: key,
        name: (values as unknown as any).key,
        isConnectable: true,
      });
    }
    const triggerNode = {
      id: "N1",
      type: "CustomNode",
      data: {
        color: initBgColor,
        nodeData: { ...CREATE_NODE_DATA[0], childNodes: childNodes },
      },
      style: customStyle,
      position: { x: 20, y: (getScreenHeight() - 180) / 2 || 250 },
    };

    setNodes([triggerNode]);
  };

  useEffect(() => {
    const { nodeId, nodeSubType, data } = store.nodeClickState;
    setClickedNodeId(nodeId);
    if (nodeId && nodeSubType && data && Object.keys(data).length) {
      setTimeout(() => {
        updateNode(nodeId, nodeSubType!);
        setClickedNodeId("");
        dispatcher(
          nodeClickDataActions.setEditNode({
            nodeId: "",
            nodeType: "",
            nodeSubType: "",
            data: {},
          })
        );
      }, 0);
    }
  }, [store.nodeClickState, setNodes]);

  const updateNode = (
    nodeId: string,
    nodeType: string,
    edge_data: any = {},
    oldEdge: any = null
  ) => {
    let error = "";
    const refactoredNodeId = store?.nodeClickState?.nodeId;
    setNodes((nds: any) =>
      nds.map((node: any) => {
        if (defectedNodeIds.includes(refactoredNodeId)) {
          node.style = {
            ...node.style,
            "border-radius": "none",
          };
          setDefectedNodeIds((prevState: string[]) => {
            return prevState.filter((id) => id !== refactoredNodeId);
          });
        }
        if (node.id === nodeId) {
          if (nodeType && nodeType !== "custom") {
            // remove amber color of unconfigured node
            if (node.style.outline === "2px solid #FEC02D") {
              node.style = {
                ...node.style,
                outline: "unset",
              };
            }
            return {
              ...node,
              ...nodeManager.handleSideBarActions(node, store),
            };
          } else if (Object.keys(edge_data).length) {
            const err = handleEdgeConnections(edge_data, oldEdge);
            if (err) error = err;
          }
        }
        return node;
      })
    );
    return error;
  };

  const handleEdgeConnections = (edge_data: any, oldEdge: any = null) => {
    try {
      let fromNode = storeState.flow[edge_data?.fromNodeId];

      const { editableNode, error } = nodeManager.handleEdgeConnections(
        fromNode,
        edge_data,
        oldEdge
      );

      dispatcher(
        storeDataActions.updateInsertNode({
          flow: {},
          nodeId: edge_data?.fromNodeId,
          data: editableNode,
        })
      );
      return error;
    } catch (error: any) {
      return error?.message || "error";
    }
  };

  const onConnect = useCallback(
    (params: any) => {
      const edge_data = nodeManager.getEdgeConnections(params);
      const canConnect = canConnectEdge(edge_data, reactFlowInstance);
      if (canConnect) {
        const err = updateNode(edge_data.fromNodeId, "custom", edge_data);
        if (!err) {
          setEdges((eds: any) =>
            addEdge({ ...params, type: "CustomEdge" }, eds)
          );
        }
      }
      updateProximityConnect(null);
      setProximityHoverNodeId("");
      setProximityHoverNodeSubType("");
      setTargetNodeId("");
    },
    [setEdges, storeState]
  );

  const onAdd = (nodeData: AddNodeDataProps | NodeConfig) => {
    let x = builderDimension.width / 2 || 1440;
    let y = builderDimension.height / 2 || 800;
    const xy = project({ x, y });
    x = xy.x;
    y = xy.y;

    const data = nodeData as unknown as any;
    const id: string = data.nodeId;
    if (id) {
      const newNode = {
        id: id,
        type: "CustomNode",
        data: { color: initBgColor, nodeData: nodeData },
        style: customStyle,
        position: { x, y },
      };
      setNodes((nds: any) => nds.concat(newNode));
      setAddedNodeId(id);
      setTimeout(() => {
        setAddedNodeId("");
      }, 700);
      if (PreConfigurationHelper.checkForPreConfiguration(nodeData)) {
        dispatcher(
          nodeClickDataActions.setEditNode(
            PreConfigurationHelper.getPreConfiguredFrontendData(
              nodeData
            ) as HandleNodeClick
          )
        );
        dispatcher(
          storeDataActions.updateInsertNode(
            PreConfigurationHelper.getPreConfiguredBackendData(
              nodeData
            ) as StoreDataState
          )
        );
      }

      dispatcher(
        NodeActionActions.updateState({
          type: "node",
          action: "created",
          nodeId: "",
        })
      );
    }
  };

  const onEdgesDelete = async (edgeData: any) => {
    if (Object.keys(edgeData)?.length) {
      const flowSchema = storeState.flow;
      const { flow, newEdges, error } = nodeManager.handleEdgesDeletion(
        edges,
        edgeData,
        flowSchema
      );
      if (error) {
        showMessage(error, "");
        return;
      }
      setEdges([...newEdges]);
      dispatcher(
        storeDataActions.restoreFlow({
          flow,
          nodeId: "",
          data: {},
        })
      );
    }
  };

  const proximityConnect = () => {
    /*
 check in onConnect method that if user have already connected this connection with handler then
 we don't need to connect the connection manually else connect them using the proximity connect.
 */
    if (sourceNodeData && Object.keys(sourceNodeData).length && targetNodeId) {
      if (typeof storeState.flow[targetNodeId] === "undefined") {
        setNodes((nds: any) => {
          return nds.map((node: any) => {
            if (node.id === targetNodeId) {
              node.style = {
                ...node.style,
                outline: "2px solid #FEC02D",
                "border-radius": "8px",
              };
              dispatcher(
                notificationDataStateActions.setNotifications({
                  warningMessage: "Please provide the block configuration",
                  successMessage: "",
                  errorMessage: "",
                })
              );
            }
            return node;
          });
        });
      } else {
        const config = {
          ...edgeOptions,
          source: sourceNodeData.nodeId,
          sourceHandle: sourceNodeData.handleId,
          target: targetNodeId,
          targetHandle: "target",
        };
        onConnect(config);
      }
      return;
    }
    updateProximityConnect(null);
    setProximityHoverNodeId("");
    setProximityHoverNodeSubType("");
  };

  const updateProximityConnect = (state: any) => {
    setSourceNodeData(state);
    dispatcher(
      proximityConnectActions.setState({
        listenToHover: state && !!Object.keys(state).length ? true : false,
      })
    );
  };

  const duplicateStickyNote = (nodeId: string) => {
    const duplicateNodeId = `S${subtypeMapping["sticky_notes"] + 1}`;
    const duplicateNodeUI = cloneDeep(
      nodes.filter((node: any) => node.id === nodeId)?.[0]
    );
    const duplicateDateString = getDateString(
      new Date(new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata" }))
    );
    const duplicateStickyNote: StickyNotesInterface = {
      nodeId: duplicateNodeId,
      content: stickyNotes[nodeId]?.content,
      createdDate: duplicateDateString,
      updatedDate: duplicateDateString,
    };
    duplicateNodeUI.id = duplicateNodeId;
    duplicateNodeUI.data.nodeData.nodeId = duplicateNodeId;
    duplicateNodeUI.data.nodeData.nodeIndex =
      subtypeMapping["sticky_notes"] + 1;
    setNodes((nds: any) =>
      nds.concat({
        ...duplicateNodeUI,
        position: {
          x: duplicateNodeUI.position.x + duplicateNodeUI.width! + 50,
          y: duplicateNodeUI.position.y,
        },
        selected: false,
      })
    );
    setAddedNodeId(duplicateNodeId);
    setTimeout(() => {
      setAddedNodeId("");
    }, 700);
    dispatcher(
      nodeSubtypeMappingActions.updateState({
        data: {},
        type: duplicateNodeUI.data.nodeData.subType,
        count: duplicateNodeUI.data.nodeData.nodeIndex,
      })
    );
    dispatcher(
      stickyNotesActions.updateInsertNode({
        notes: {},
        id: duplicateNodeId,
        data: duplicateStickyNote,
      })
    );
  };

  const approve = (key: string) => {
    if (key === RetryPw) {
      dispatcher(
        homeDataAction.addState({
          ...homeState,
          retryAccess: true,
          showPwModal: false,
        })
      );
      dispatcher(
        notificationDataStateActions.setNotifications({
          successMessage: "Authenticated!! Now try again",
          errorMessage: "",
        })
      );
    } else {
      dispatcher(
        homeDataAction.addState({
          ...homeState,
          showPwModal: false,
        })
      );
      dispatcher(
        notificationDataStateActions.setNotifications({
          successMessage: "",
          errorMessage: "Wrong Password!!",
        })
      );
    }
  };

  const cancel = () => {
    dispatcher(
      homeDataAction.addState({
        ...homeState,
        showPwModal: false,
      })
    );
  };

  const onNodeDuplicate = (nodeId: string, nodeType: string) => {
    // Handling sticky note duplication
    if (nodeType === "Sticky notes") return duplicateStickyNote(nodeId);

    // handling chat nodes duplication
    const duplicateNodeId = props.getNodeId();
    if (!storeState.flow?.[nodeId]) {
      dispatcher(
        notificationDataStateActions.setNotifications({
          errorMessage: `Cannot duplicate empty block! Please fill some data to duplicate block.`,
          successMessage: "",
        })
      );
      return;
    }

    const duplicateNodeSchema = cloneDeep(storeState.flow?.[nodeId] || {});
    const nodeIndex = duplicateNodeSchema?.node_index;
    const subType = duplicateNodeSchema?.sub_type;

    const duplicateNodeUI = cloneDeep(
      nodes.filter((node: any) => node.id === nodeId)?.[0]
    );
    if (!duplicateNodeUI || !Object.keys(duplicateNodeUI).length) {
      dispatcher(
        notificationDataStateActions.setNotifications({
          errorMessage: `Error in duplicating node: Node not found!`,
          successMessage: "",
        })
      );
      return;
    }
    duplicateNodeUI.id = duplicateNodeId;
    duplicateNodeUI.data.nodeData.nodeId = duplicateNodeId;
    duplicateNodeUI.data.nodeData.nodeIndex =
      subtypeMapping[duplicateNodeUI.data.nodeData.subType] + 1;
    const title =
      duplicateNodeUI.data.nodeData.title.split("#")?.[0] ||
      duplicateNodeUI.data.nodeData.title;
    duplicateNodeUI.data.nodeData.title = `${title}#${duplicateNodeUI.data.nodeData.nodeIndex}`;
    duplicateNodeSchema.nodeId = duplicateNodeId;
    duplicateNodeSchema.node_index = duplicateNodeUI.data.nodeData.nodeIndex;
    const { nodeData, error } = nodeManager.removeReferences(
      duplicateNodeSchema,
      "",
      "",
      true
    );

    if (error) {
      dispatcher(
        notificationDataStateActions.setNotifications({
          successMessage: "",
          errorMessage: error,
        })
      );
      return;
    }
    setNodes((nds: any) =>
      nds.concat({
        ...duplicateNodeUI,
        position: {
          x: duplicateNodeUI.position.x + duplicateNodeUI.width! + 50,
          y: duplicateNodeUI.position.y,
        },
        selected: false,
      })
    );
    setAddedNodeId(duplicateNodeId);
    setTimeout(() => {
      setAddedNodeId("");
    }, 700);
    dispatcher(
      storeDataActions.updateInsertNode({
        flow: {},
        nodeId: duplicateNodeId,
        data: nodeData,
      })
    );
    onNodeAdd(nodeData.nodeId, nodeData, setVariablesList, variablesList);
    dispatcher(
      nodeSubtypeMappingActions.updateState({
        data: {},
        type: duplicateNodeUI.data.nodeData.subType,
        count: duplicateNodeUI.data.nodeData.nodeIndex,
      })
    );
    if (Object.values(localVariableList).includes(`${nodeId}_response`)) {
      dispatcher(
        localVariablesDataAction.addLocalVariables({
          key: `${duplicateNodeUI.data.nodeData.subType} ${duplicateNodeUI.data.nodeData.nodeIndex}`,
          value: `${duplicateNodeId}_response`,
          localVariablesList: {},
          type: duplicateNodeUI.data.nodeData.type,
        })
      );
    }
    if (Object.keys(aiVariableList).includes(nodeId)) {
      const key = Object.keys(aiVariableList).find((key) => key === nodeId);
      dispatcher(
        aiVariablesDataAction.setAiVariablesList({
          aiVariablesList: {
            [duplicateNodeId]: {
              ...aiVariableList[key!], // for AI Ask we'll have payload as well
              name: duplicateNodeUI.data.nodeData.title,
              value: `${snakeToCamel(duplicateNodeUI.data.nodeData.subType)}${
                duplicateNodeUI.data.nodeData.nodeIndex
              }.response`,
              type: aiVariableList[key!].type,
            },
          },
        })
      );
    }

    if (
      subType === NodeSubType.MANIFEST_LLM_CALL ||
      subType === NodeSubType.MANIFEST_SEARCH
    ) {
      const duplicateNodeKey = `${duplicateNodeId} - ${duplicateNodeUI.data.nodeData.nodeIndex} - ${subType}`;
      const originalNodeKey = `${nodeId} - ${nodeIndex} - ${subType}`;
      const updatePayload = {
        manifestVariablesList: {
          [duplicateNodeKey]: manifestVariableList[originalNodeKey],
        },
      };
      dispatcher(aiVariablesDataAction.setManifestVariablesList(updatePayload));
    } else if (subType === NodeSubType.MANIFEST_BUILD_LLM_INPUT) {
      const duplicateNodeKey = `${duplicateNodeId} - ${duplicateNodeUI.data.nodeData.nodeIndex} - ${subType}`;
      const updatePayload = {
        manifestVariablesList: {
          [duplicateNodeKey]: "",
        },
      };
      dispatcher(aiVariablesDataAction.setManifestVariablesList(updatePayload));
    }

    if (
      subType === "call_custom_api" &&
      customApiResponses?.[`${nodeId} - ${nodeIndex}`]
    ) {
      dispatcher(
        apiDataStateActions.addCustomApiData({
          data: customApiResponses[`${nodeId} - ${nodeIndex}`],
          nodeId: `${duplicateNodeId} - ${duplicateNodeUI.data.nodeData.nodeIndex}`,
        })
      );
    }

    if (
      subType &&
      customApiResponses?.[`${nodeId} - ${nodeIndex} - ${subType}`]
    ) {
      dispatcher(
        apiDataStateActions.addCustomApiData({
          data: customApiResponses[`${nodeId} - ${nodeIndex} - ${subType}`],
          nodeId: `${duplicateNodeId} - ${duplicateNodeUI.data.nodeData.nodeIndex} - ${subType}`,
        })
      );
    }
    if (discountResponses?.[`${nodeId} - ${nodeIndex}`]) {
      dispatcher(
        apiDataStateActions.addDiscountData({
          data: discountResponses[`${nodeId} - ${nodeIndex}`],
          nodeId: `${duplicateNodeId} - ${duplicateNodeUI.data.nodeData.nodeIndex}`,
        })
      );
    }
  };
  const hasAccessToBikFeaturesMain = async () => {
    const hasAccess = await hasAccessToBikFeatures({
      category: FeatureCategory.module,
      featureName: AllModules.automations,
      subFeatureName: AllFeatureSubKey.FLOW_ACTIVATIONS,
    });
    return !!hasAccess;
  };

  const OnActivate = async () => {
    const hasAccess = await hasAccessToBikFeaturesMain();
    if (!hasAccess) {
      return;
    }
    const publishBlocked = validateAiSync();
    if (publishBlocked) {
      return;
    }
    setShowSaveModal(true);
    setSaveType("Publish");
    setSuccess(false);
    onFlowSaveConfirmed(false, "Publish");
  };

  const handleSunset = () => {
    dispatcher(
      homeDataAction.addState({
        ...homeState,
        showSunsetModal: false,
        sunset: true,
        saveFlow: true,
      })
    );
    setShowSunsetModal(false);
  };

  const handleInactive = () => {
    dispatcher(
      homeDataAction.addState({
        ...homeState,
        showSunsetModal: false,
        sunset: false,
        saveFlow: true,
      })
    );
    setShowSunsetModal(false);
  };

  const generateHeader = () => {
    let heading = "";
    const isSunset = store.homeState?.sunset ?? false;
    const isTestMode = store.homeState?.testMode ?? false;
    const isFromSwitch = store.homeState?.skipStatModal ?? false;
    const fromTestModeToggle = store.homeState?.fromTestModeToggle ?? false;

    if (isFromSwitch) {
      if (saveType === "Publish") {
        if (fromTestModeToggle || isTestMode) {
          heading += `Test mode switched ${isTestMode ? "ON" : "OFF"}`;
        } else {
          heading += "Journey is activated";
        }
      } else if (isSunset) {
        heading += "Journey is in sunset mode";
      } else {
        heading += "Journey is deactivated";
      }
    } else {
      if (saveType === "Publish") {
        heading += "Journey is activated";
      } else {
        heading += "Journey saved successfully";
      }
    }
    return heading;
  };

  const generateSubheader = () => {
    let subHeading = "";
    const isSunset = store.homeState?.sunset ?? false;
    const isTestMode = store.homeState?.testMode ?? false;
    const isFromSwitch = store.homeState?.skipStatModal ?? false;
    const fromTestModeToggle = store.homeState?.fromTestModeToggle ?? false;

    if (isFromSwitch) {
      if (saveType === "Publish") {
        if (fromTestModeToggle || isTestMode) {
          subHeading += isTestMode
            ? "Journey  will be active only for customers added in test mode settings"
            : "Journey will be active/inactive for all customers.";
        } else {
          subHeading += "Journey is now live for all customers";
        }
      } else if (isSunset) {
        subHeading +=
          "Journey is deactivated for new customers and will be eventually stopped for all customers.";
      } else {
        subHeading +=
          "Journey is deactivated and will not run for any customers.";
        if (syncTemplateError) {
          subHeading +=
            "Flow Templates could not be synced to library because " +
            syncTemplateError;
        }
      }
    } else {
      if (saveType === "Publish") {
        subHeading += "Journey is now live for all customers";
      } else {
        subHeading += "Journey is saved successfully.";
        if (syncTemplateError) {
          subHeading +=
            "Flow Templates could not be synced to library because " +
            syncTemplateError;
        } else {
          subHeading +=
            "If the flow is complete, go ahead and publish the flow for your customers.";
        }
      }
    }

    return subHeading;
  };

  return (
    <FlowBuilderGlobalStyle
      channel={channel}
      addedNodeId={addedNodeId}
      proximityHoverNodeId={proximityHoverNodeId}
      clickNodeId={clickedNodeId}
      subType={proximityHoverNodeSubType}
    >
      {homeState?.integration?.name && (
        <IntegrationsNotAvailableModal
          errType={homeState.integration.errorType}
          partnerName={homeState.integration.name || ""}
          onClickClose={() => {
            dispatcher(
              homeDataAction.addState({
                ...homeState,
                integration: {
                  name: "",
                  nodeId: "",
                  errorType: "",
                },
              })
            );
          }}
        />
      )}
      {showLoader && <ChatbotLoader />}
      <ReactFlow
        className="validationflow"
        onInit={onLoad}
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onEdgeUpdate={undefined}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        defaultEdgeOptions={edgeOptions}
        connectionLineStyle={connectionLineStyle}
        snapToGrid={true}
        snapGrid={snapGrid}
        defaultViewport={defaultViewport}
        minZoom={0.2}
        attributionPosition="bottom-left"
        deleteKeyCode={null}
        onConnectStart={(_: any, params: any) => {
          if (isAnalytics) return;
          updateProximityConnect(params);
        }}
        onConnectEnd={(e: any) =>
          setTimeout(() => {
            proximityConnect();
          }, 0)
        }
        connectOnClick={!isAnalytics}
        panOnDrag={true}
        panOnScroll={true}
        selectionOnDrag={true}
        nodesConnectable={!isAnalytics}
        onPaneMouseMove={(event) => {
          const x = event.clientX;
          const y = event.clientY;
          setMouseCursorPosition(project({ x, y }));
        }}
      >
        <Background
          variant={BackgroundVariant.Dots}
          size={0}
          style={{ backgroundColor: "F5F5F5" }}
        />
        <CustomControls storeId={storeId} flowId={flowId} />
        <MiniMap
          zoomable
          nodeColor={COLORS.background.brandLight}
          maskColor={"rgba(0,0,0,0.3)"}
          pannable={true}
          ariaLabel={"Mini map"}
        />
      </ReactFlow>
      {showSaveModal && (
        <Modal>
          <SaveFlowModal
            saveType={saveType}
            flowEditHistory={flowEditHistory}
            oldNodeCount={oldNodeCount}
            newNodeCount={newNodeCount}
            onClickCancel={() => {
              setShowSaveModal(false);
              setSaveType("");
              setCheckError(false);
              setSuccess(false);
              dispatcher(
                homeDataAction.addState({
                  ...homeState,
                  fromTestModeToggle: false,
                })
              );
            }}
            buttonLoader={buttonLoader}
            onClickSave={
              store.homeState?.status
                ? async () => {
                    // click on save on already published flow
                    setButtonLoader(true);
                    await onFlowSaveConfirmed(false, "Save", true);
                    setButtonLoader(false);

                    const publishBlocked = validateAiSync();
                    if (publishBlocked) {
                      compileFlow(
                        storeId,
                        flowId,
                        undefined,
                        storeState.flow,
                        undefined,
                        false,
                        homeState?.sunset ?? false,
                        undefined,
                        homeState?.userData?.agentId ||
                          homeState?.userData?.email,
                        flowMeta,
                        automationTriggerState?.triggers[0] ?? "",
                        homeState?.dndEnabled ?? false
                      ).then();
                      setShowSaveModal(false);
                      dispatcher(
                        homeDataAction.addState({
                          ...store.homeState,
                          status: false,
                          publishFlow: false,
                          saveFlow: false,
                          closeLoader: true,
                        })
                      );
                      setSuccess(false);
                      return;
                    }

                    setSaveType("Publish");
                    await onFlowSaveConfirmed(false, "Publish", false);
                  }
                : () => {
                    // clicks on save on not published flow
                    onFlowSaveConfirmed(false, "Save", false);
                  }
            }
            checkError={checkError}
            showSuccess={success}
            error={syncTemplateError}
            heading={generateHeader()}
            subheading={generateSubheader()}
            onActivate={() => {
              OnActivate();
            }}
            showButtons={saveType === "Save" && !store.homeState?.skipStatModal}
          />
        </Modal>
      )}
      {!isAnalytics && errorStateComplete.errorModalOpenStatus && (
        <ErrorsModal onSave={saveFlow} onPublish={publishFlow} />
      )}
      {homeState.showPwModal && (
        <Modal>
          <PasswordModal onSubmit={approve} onClickCancel={cancel} />
        </Modal>
      )}
      {showSunsetModal && (
        <SunsetModal
          onClose={() => {
            setShowSunsetModal(false);
            dispatcher(
              homeDataAction.addState({
                ...homeState,
                closeLoader: true,
                showSunsetModal: false,
              })
            );
          }}
          onSunset={() => handleSunset()}
          onInactive={() => handleInactive()}
        />
      )}
      {isDeleteConfirmationModalOpen && (
        <DeleteConfirmationModal
          confirmationText="Delete"
          header="Delete these nodes?"
          onCancelClicked={() => {
            setIsDeleteConfirmationModalOpen(false);
            resetKeyboardEventState();
          }}
          onDeleteConfirmed={() => {
            setIsDeleteConfirmationModalOpen(false);
            deleteNodes(selectedNodeIds);
            resetKeyboardEventState();
          }}
          subHeader="This action cannot be undone."
        />
      )}
      {doSyncOpen && (
        <ActivateBikAi
          open={doSyncOpen}
          onModalClose={() => {
            setDoSyncOpen(false);
          }}
          onRightBtnClick={() => {
            window.open(
              isProd
                ? "https://dashboard.bik.ai/bik-ai"
                : "https://staging.dashboard.bik.ai/bik-ai",
              "_blank"
            );
          }}
          onLeftBtnClick={() => {
            window.open(
              "https://help.bik.ai/en/articles/9610069-bik-ai-settings",
              "_blank"
            );
          }}
          source={POD.CHATBOT}
        />
      )}
      {syncStatusModalOpen && (
        <AiSyncProgress
          open={syncStatusModalOpen}
          onModalClose={function (): void {
            setSyncStatusModalOpen(false);
            unSubFunc.current && unSubFunc.current();
          }}
          completedPercent={shopifySyncPercent}
          onRightBtnClick={() => {
            window.open(
              isProd
                ? "https://dashboard.bik.ai/bik-ai"
                : "https://staging.dashboard.bik.ai/bik-ai",
              "_blank"
            );
          }}
          onLeftBtnClick={() => {
            setSyncStatusModalOpen(false);
            unSubFunc.current && unSubFunc.current();
          }}
        />
      )}
      {freeCreditsExhaustedModalOpen && (
        <AiCreditsExhausted
          open={freeCreditsExhaustedModalOpen}
          onModalClose={function (): void {
            setFreeCreditsExhaustedModalOpen(false);
          }}
          body={""}
          onRightBtnClick={() => {
            window.open(
              isProd
                ? "https://dashboard.bik.ai/bik-ai"
                : "https://staging.dashboard.bik.ai/bik-ai",
              "_blank"
            );
          }}
          onLeftBtnClick={() => {
            window.open(
              "https://help.bik.ai/en/articles/9610069-bik-ai-settings",
              "_blank"
            );
          }}
        />
      )}
      {creditsExhaustedModalOpen && (
        <AiCreditsLow
          open={creditsExhaustedModalOpen}
          onModalClose={function (): void {
            setCreditsExhaustedModalOpen(false);
          }}
          onRightBtnClick={() => {
            window.open(
              isProd
                ? "https://dashboard.bik.ai/bik-ai"
                : "https://staging.dashboard.bik.ai/bik-ai",
              "_blank"
            );
          }}
          onLeftBtnClick={() => {
            setCreditsExhaustedModalOpen(false);
          }}
        />
      )}
    </FlowBuilderGlobalStyle>
  );
};

export default Node;
