import React, { ChangeEventHandler, LegacyRef } from "react";
import {
  FileContainer,
  FileLabel,
  FileName,
  FileWrapper,
  SVGWrapper,
} from "../BotInput.style";
import { InputDataProps } from "../InputModel";

export interface FileInputProps extends InputDataProps {
  inputEl?: LegacyRef<HTMLInputElement>;
  onChangeHandle: ChangeEventHandler<HTMLInputElement>;
  onSVGClick: any;
}

const FileInput = (props: FileInputProps) => {
  return (
    <React.Fragment>
      <FileWrapper name={props.name}>
        <FileLabel {...props}>
          {props.placeHolder}
          {props.isRequired ? "*" : null}
        </FileLabel>
        <FileContainer>
          <input
            name={props.name}
            ref={props.inputEl}
            type={props.type}
            onChange={props.onChangeHandle}
            disabled={props.isDisabled}
            onBlur={props.onBlur}
          />
          {props.fileName && (
            <FileName className="body-small">{props.fileName}</FileName>
          )}
        </FileContainer>
      </FileWrapper>
      <SVGWrapper onClick={props.onSVGClick}>
        <svg
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            _ngcontent-dlw-c55=""
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.78 2.3877C14.9838 2.3877 14.2201 2.704 13.6571 3.26703L4.46712 12.457C3.52881 13.3953 3.00168 14.668 3.00168 15.9949C3.00168 17.3219 3.52881 18.5945 4.46712 19.5328C5.40543 20.4711 6.67805 20.9983 8.00502 20.9983C9.33198 20.9983 10.6046 20.4711 11.5429 19.5328L20.7329 10.3428C21.1234 9.95229 21.7566 9.95229 22.1471 10.3428C22.5376 10.7333 22.5376 11.3665 22.1471 11.757L12.9571 20.947C11.6437 22.2604 9.86242 22.9983 8.00502 22.9983C6.14761 22.9983 4.36629 22.2604 3.05291 20.947C1.73953 19.6336 1.00168 17.8523 1.00168 15.9949C1.00168 14.1375 1.73953 12.3562 3.05291 11.0428L12.2429 1.85281C13.181 0.914714 14.4533 0.387695 15.78 0.387695C17.1067 0.387695 18.379 0.914714 19.3171 1.85281C20.2552 2.79091 20.7822 4.06325 20.7822 5.38992C20.7822 6.71659 20.2552 7.98893 19.3171 8.92703L10.1171 18.117C9.55431 18.6798 8.79096 18.996 7.99502 18.996C7.19907 18.996 6.43573 18.6798 5.87291 18.117C5.31009 17.5542 4.9939 16.7909 4.9939 15.9949C4.9939 15.199 5.31009 14.4356 5.87291 13.8728L14.3633 5.3924C14.7541 5.0021 15.3872 5.00247 15.7775 5.39323C16.1678 5.78398 16.1675 6.41715 15.7767 6.80744L7.28712 15.287C7.09964 15.4747 6.9939 15.7296 6.9939 15.9949C6.9939 16.2604 7.09938 16.5151 7.28712 16.7028C7.47487 16.8906 7.7295 16.996 7.99502 16.996C8.26053 16.996 8.51516 16.8906 8.70291 16.7028L17.9029 7.51281C18.4657 6.94982 18.7822 6.18598 18.7822 5.38992C18.7822 4.59368 18.4659 3.83005 17.9029 3.26703C17.3399 2.704 16.5763 2.3877 15.78 2.3877Z"
            fill="#8C8E95"
          />
        </svg>
      </SVGWrapper>
    </React.Fragment>
  );
};

export default FileInput;
