import { Icon } from "./icons-props";

const RazorpayCreateTicketIcon: Icon = (props) => {
  const { width, height, color, backgroundColor } = props;
  return (
    <svg
      width={width || "32"}
      height={height || "32"}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7791 12.5007L13.8125 16.0583L19.345 12.4805L15.7266 25.9805L19.4014 25.9836L24.7467 6.04346"
        fill="#3395FF"
      />
      <path
        d="M8.82421 20.3081L7.30225 25.9835H14.8341L17.9161 14.4381L8.82421 20.3081Z"
        fill="#072654"
      />
    </svg>
  );
};

export default RazorpayCreateTicketIcon;
