import {
  HTTPMethods,
  RequestExecutor,
} from "../../api-helpers/RequestExecutor";
import { getChatbotEndpoints, getCRMEndpoints } from "../../config";

export const getLabels = async (storeId: string): Promise<any> => {
  const url = getCRMEndpoints();

  const response: any = await RequestExecutor.fetch({
    apiPath: `${url}/labelsApiFunctions-getAllLabels`,
    apiMethod: HTTPMethods.POST,
    requestBody: {
      storeId: storeId,
    },
  });

  if (response?.data && response?.data?.status === 200) {
    const filteredData = response.data.labels.map((data: any) => {
      return LabelParser(data);
    });
    return filteredData.map((item: any) => {
      return {
        id: item.id,
        header: item.labelName,
      };
    });
  }
  return {};
};

export const LabelParser = (unparsedData: any) => {
  const allowed = ["id", "labelName"];

  const newObj = {};
  for (const [key, value] of Object.entries(unparsedData)) {
    if (allowed.includes(key)) {
      // @ts-ignore
      newObj[key] = value;
    }
  }
  return newObj;
};

export const getCategories = async () => {
  const response: any = await RequestExecutor.fetch({
    apiPath: `${getChatbotEndpoints()}/get-journey-categories`,
    apiMethod: HTTPMethods.GET,
  });
  if (response?.data && response?.status === 200) {
    const data = response.data.data;

    return data;
  }
  return {};
};
