import { apiDataStateActions } from "../../state/apiDataState";
import { useAppSelector } from "../../state/store";
import { useDispatch } from "react-redux";
import { cloneDeep } from "lodash";
import { stickyNotesActions } from "../../state/stickyNotesState";
import { StickyNotesInterface } from "../flowBuilder.utility";
import { getConnectedEdges } from "reactflow";
import { storeDataActions } from "../../state/storeDataState";
import { onNodeDelete } from "../../services/helpers/VariableV2Mapper";
import { localVariablesDataAction } from "../../state/localVariablesState";
import { nodeClickDataActions } from "../../state/nodeClickDataState";
import { NodeManager } from "../../ui-components/flow-builder/nodeManager";
import { VariableListInterfaceV2 } from "../../services/helpers/VariablesListHookV2";
import { notificationDataStateActions } from "../../state/notificationDataState";
import { captureErrorToSentry } from "../sentryHelper";
import { aiVariablesDataAction } from "../../state/aiVariablesState";
import { orderTemplateVariablesDataAction } from "../../state/orderTemplateVariablesState";

export type DeleteNodes = {
  nodes: any;
  setNodes: any;
  edges: any;
  setEdges: any;
  deletedNodes: string[];
  deletedStickyNotes: string[];
  variablesList: VariableListInterfaceV2[];
  setVariablesList: any;
};

/**
 * Returns deleteNodes function which is used to deleteNodes.
 */
const useDelete = ({
  nodes,
  setNodes,
  edges,
  setEdges,
  deletedNodes,
  deletedStickyNotes,
  variablesList,
  setVariablesList,
}: DeleteNodes) => {
  const dispatcher = useDispatch();
  const nodeManager = new NodeManager();

  const storeState = useAppSelector((state: any) => state.storeState);
  const customApiResponses = useAppSelector(
    (state) => state.apiDataState.apiData
  );
  const discountResponses = useAppSelector(
    (state) => state.apiDataState.discountData
  );
  const stickyNotes = useAppSelector((state) => state.stickyNotesState.notes);
  const localVariableList = useAppSelector(
    (state) => state.localVariablesState.localVariablesList
  );
  const aiVariableList = useAppSelector(
    (state) => state.aiVariablesState.aiVariablesList
  );
  const orderTemplateVariableList = useAppSelector(
    (state) => state.orderTemplateVariablesState.orderTemplateVariablesList
  );

  const showMessage = (errorMessage: string, successMessage: string) => {
    dispatcher(
      notificationDataStateActions.setNotifications({
        errorMessage,
        successMessage,
      })
    );
  };

  /**
   * Deletes the given list of nodes in UI and redux
   */
  const onNodesDelete = (deletedNodeIds: string[]) => {
    try {
      const flowSchema = storeState.flow;
      const nodesTobeDeleted = nodes.filter((node: any) =>
        deletedNodeIds.includes(node.id)
      );
      if (!nodesTobeDeleted) {
        return;
      }
      // handling deletion of sticky notes
      deleteStickyNote(deletedNodeIds);

      const connectedEdges = getConnectedEdges(nodesTobeDeleted, edges);
      const { newNodes, flow, error } = nodeManager.handleNodesDeletion(
        nodes,
        flowSchema,
        deletedNodeIds,
        connectedEdges
      );
      if (error) {
        showMessage(error, "");
        return;
      }
      deletedNodes.push(...deletedNodeIds);
      if (connectedEdges && connectedEdges.length) {
        const newEdges = edges.filter(
          (edge: any) =>
            !connectedEdges.some((conn: any) => conn.id === edge.id)
        );
        setEdges([...newEdges]);
      }
      setNodes([...newNodes]);
      dispatcher(
        storeDataActions.restoreFlow({
          flow,
          nodeId: "",
          data: {},
        })
      );

      for (let node of nodesTobeDeleted) {
        onNodeDelete(node.data.nodeData, setVariablesList, variablesList);
      }

      deleteVariablesOfSelectedNode(deletedNodeIds);

      dispatcher(
        nodeClickDataActions.setEditNode({
          nodeType: "",
          nodeId: "",
          nodeSubType: "",
          data: {},
        })
      );
    } catch (e) {
      captureErrorToSentry(e, `Error in deleting nodes`);
    }
  };

  /**
   * Checks if sticky nodes are present in selection and deletes them in redux.
   */
  const deleteStickyNote = (deletedNodeIds: string[]) => {
    try {
      const stickyNoteIds = deletedNodeIds.filter((deletedNodeId) =>
        deletedNodeId.startsWith("S")
      );
      const notes = cloneDeep(stickyNotes);
      deletedStickyNotes.push(...stickyNoteIds);
      stickyNoteIds.forEach((stickyNoteId) => {
        delete notes[stickyNoteId];
      });
      dispatcher(
        stickyNotesActions.restoreNotes({
          notes: notes,
          id: "",
          data: {} as StickyNotesInterface,
        })
      );
    } catch (e) {
      captureErrorToSentry(e, `Error in deleting sticky Note`);
    }
  };

  /**
   * All the variables of selected Nodes are deleted.
   */
  const deleteVariablesOfSelectedNode = (nodeIds: string[]) => {
    try {
      let copyApiData = { ...customApiResponses };
      let copyDiscountData = { ...discountResponses };

      for (let nodeId of nodeIds) {
        const deletedNode = storeState.flow[nodeId];

        const nodeIndex = deletedNode?.node_index;
        const subType = deletedNode?.sub_type;

        let toDeleteKey = `${nodeId} - ${nodeIndex}`;
        if (subType != "call_custom_api") {
          toDeleteKey = `${nodeId} - ${nodeIndex} - ${subType}`;
        }

        if (customApiResponses?.[toDeleteKey]) {
          delete copyApiData[toDeleteKey];
        }

        toDeleteKey = `${nodeId} - ${nodeIndex}`;
        if (discountResponses?.[toDeleteKey]) {
          delete copyDiscountData[toDeleteKey];
        }
      }

      let removeDeletedLocalVariable: any = {};
      let removeDeletedAiVariable: string[] = [];
      let removeDeletedOrderTemplateVariable: string[] = [];
      for (const [key, value] of Object.entries(localVariableList)) {
        const nodeIdFromValue = value.split("_")[0];

        if (!nodeIds.includes(nodeIdFromValue)) {
          removeDeletedLocalVariable[key] = value;
        }
      }

      for (const [key] of Object.entries(aiVariableList)) {
        if (nodeIds.includes(key)) {
          removeDeletedAiVariable.push(key);
        }
      }

      for (const [key] of Object.entries(orderTemplateVariableList)) {
        const nodeIdFromKey = key.split("-")[0].trim();
        if (nodeIds.includes(nodeIdFromKey)) {
          removeDeletedOrderTemplateVariable.push(key);
        }
      }

      dispatcher(
        apiDataStateActions.setCustomApiData({
          data: copyApiData,
        })
      );

      dispatcher(
        apiDataStateActions.setDiscountData({
          data: copyDiscountData,
        })
      );

      dispatcher(
        localVariablesDataAction.setLocalVariablesList({
          key: "",
          value: "",
          localVariablesList: removeDeletedLocalVariable,
        })
      );
      dispatcher(aiVariablesDataAction.removeFromList(removeDeletedAiVariable));
      dispatcher(
        orderTemplateVariablesDataAction.removeFromList(
          removeDeletedOrderTemplateVariable
        )
      );
    } catch (e) {
      captureErrorToSentry(e, `Error in deleting variables when deleting node`);
    }
  };

  /**
   * This function is exposed and can be called with list of nodes to delete.
   */
  const deleteNodes = (nodeIds: string[]) => {
    onNodesDelete(nodeIds);
  };

  return {
    deleteNodes,
  };
};

export default useDelete;
