import { AddNodeDataProps } from "../add-node-options/addNodePopover";
import { NodeConfig } from "../../utilities/flowBuilder.utility";
import {
  HandleNodeClick,
  StoreDataState,
} from "../../state/storeDataStateType";

export class PreConfigurationHelper {
  static checkForPreConfiguration(nodeData: AddNodeDataProps | NodeConfig) {
    const data = nodeData as unknown as any;
    const id: string = data.nodeId;
    if (data.subType === "cart_info") {
      return true;
    }
  }

  static getPreConfiguredFrontendData(
    nodeData: AddNodeDataProps | NodeConfig
  ): HandleNodeClick | undefined {
    const data = nodeData as unknown as any;
    const id: string = data.nodeId;
    if (data.subType === "cart_info") {
      const payload = {
        type: "Message",
        subType: "cart_info",
        isMicroFlow: false,
        description: "Cart info message",
        slang: "",
        childNodes: {},
      };
      return {
        nodeId: id,
        nodeType: payload.type,
        nodeSubType: payload.subType,
        data: payload,
        hideLeftModal: true,
      };
    }
  }

  static getPreConfiguredBackendData(
    nodeData: AddNodeDataProps | NodeConfig
  ): StoreDataState | undefined {
    const data = nodeData as unknown as any;
    const id: string = data.nodeId;
    if (data.subType === "cart_info") {
      return {
        flow: {},
        nodeId: data.nodeId,
        data: {
          default_node: "",
          message_template: {
            actionType: "CART_INFO",
            body: {
              content: "",
              selectedFields: [],
            },
          },
          type: "message",
          sub_type: "cart_info",
          node_index: data.nodeIndex,
          nodeId: id,
        },
      };
    }
  }
}
