import React, { FunctionComponent } from "react";
import { ShimmerStyled } from "./style";

interface ShimmerBox {
  height: string;
  width: string;
}

// Only supports a vertical lists of boxes right now.
interface OwnProps {
  boxes: ShimmerBox[];
  style?: Object;
}

type Props = OwnProps;

export const ShimmerHelper: FunctionComponent<Props> = (props) => {
  return (
    <ShimmerStyled>
      {props.boxes.map((box, index) => {
        return (
          <div
            className={"shine"}
            style={{ width: box.width, height: box.height, ...props.style }}
            key={index}
          />
        );
      })}
    </ShimmerStyled>
  );
};

export default ShimmerHelper;
