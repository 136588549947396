import { Icon } from "./icons-props";

const AlertCircle: Icon = (props) => {
  const { width, height, color } = props;
  return (
    <svg
      width={width || "16"}
      height={height || "16"}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99996 7.33317C8.36815 7.33317 8.66663 7.63165 8.66663 7.99984V10.6665C8.66663 11.0347 8.36815 11.3332 7.99996 11.3332C7.63177 11.3332 7.33329 11.0347 7.33329 10.6665V7.99984C7.33329 7.63165 7.63177 7.33317 7.99996 7.33317Z"
        fill={color || "#99631B"}
      />
      <path
        d="M7.99996 5.99984C8.36815 5.99984 8.66663 5.70136 8.66663 5.33317C8.66663 4.96498 8.36815 4.6665 7.99996 4.6665C7.63177 4.6665 7.33329 4.96498 7.33329 5.33317C7.33329 5.70136 7.63177 5.99984 7.99996 5.99984Z"
        fill={color || "#99631B"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.666626 7.99984C0.666626 3.94975 3.94987 0.666504 7.99996 0.666504C12.05 0.666504 15.3333 3.94975 15.3333 7.99984C15.3333 12.0499 12.05 15.3332 7.99996 15.3332C3.94987 15.3332 0.666626 12.0499 0.666626 7.99984ZM7.99996 1.99984C4.68625 1.99984 1.99996 4.68613 1.99996 7.99984C1.99996 11.3135 4.68625 13.9998 7.99996 13.9998C11.3137 13.9998 14 11.3135 14 7.99984C14 4.68613 11.3137 1.99984 7.99996 1.99984Z"
        fill={color || "#99631B"}
      />
    </svg>
  );
};

export default AlertCircle;
