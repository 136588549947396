import { Icon } from "./icons-props";

const FullScreenIcon: Icon = (props) => {
  const { backgroundColor, color, height, width } = props;
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.7092 0.295017C19.8041 0.3904 19.8757 0.500141 19.9241 0.617216C19.9727 0.734253 19.9996 0.862504 20 0.997001L20 1V1.00069V7C20 7.55228 19.5523 8 19 8C18.4477 8 18 7.55228 18 7V3.41421L12.7071 8.70711C12.3166 9.09763 11.6834 9.09763 11.2929 8.70711C10.9024 8.31658 10.9024 7.68342 11.2929 7.29289L16.5858 2H13C12.4477 2 12 1.55228 12 1C12 0.447715 12.4477 0 13 0H18.9998H19C19.2751 0 19.5242 0.111056 19.705 0.290776L19.7092 0.295017ZM8.70711 12.7071L3.41421 18H7C7.55228 18 8 18.4477 8 19C8 19.5523 7.55228 20 7 20H1.00069H1L0.997001 20C0.743006 19.9992 0.489245 19.9023 0.295017 19.7092L0.290776 19.705C0.195951 19.6096 0.124319 19.4999 0.0758788 19.3828C0.0269856 19.2649 0 19.1356 0 19V18.9998V13C0 12.4477 0.447715 12 1 12C1.55228 12 2 12.4477 2 13V16.5858L7.29289 11.2929C7.68342 10.9024 8.31658 10.9024 8.70711 11.2929C9.09763 11.6834 9.09763 12.3166 8.70711 12.7071Z"
        fill={color || "#212121"}
      />
    </svg>
  );
};

export default FullScreenIcon;
